import React, { FC, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import Select, { ValueType } from 'react-select'
import SetStateType from 'types/SetStateType'
import { OptionStateType, SearchStateType } from 'types/TravelInsuranceType'
import InsuranceCard from './InsuranceCard'

type OptionType = {
  value: string | null
  label: string
}
type FilterType = {
  companyCode: string | null
  coverCurrent: number | null
}
type SearchProductType = {
  data: Array<SearchStateType> | []
  options: OptionStateType | null
  compareData: Array<SearchStateType> | []
  filter: FilterType
  setFilter: Function
  location: string
  setCompareData: SetStateType<Array<SearchStateType> | []>
  setIsCompare: SetStateType<boolean>
}

const Container = styled.div`
  width: 100%;
  max-width: 832px;
  height: 100%;
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  .product_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .product_header .sort_wrapper {
    display: flex;
    align-items: center;
  }
  .product_body .top {
    background-color: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    text-align: right;
    padding: 4px 16px;
    margin-bottom: 8px;
  }
  label {
    margin-right: 16px;
  }
`

const Selector = styled(Select)`
  border-radius: 4px;
  width: 220px;
  align-self: center;
  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1fhf3k1-control {
    height: 48px;
  }
  .css-26l3qy-menu {
    z-index: 99;
  }
`

const SortByOptions: Array<OptionType> = [
  { label: 'ราคาน้อย-มาก', value: 'priceAsc' },
  {
    label: 'ราคามาก-น้อย',
    value: 'priceDesc',
  },
]

const SearchProduct: FC<SearchProductType> = (props) => {
  const { data, compareData, location, setCompareData, setIsCompare } = props
  const [newData, setNewData] = useState<Array<SearchStateType>>([])
  const [sortByOption, setSortByOption] = useState<ValueType<OptionType>>({
    label: 'ราคาน้อย-มาก',
    value: 'priceAsc',
  })

  useEffect(() => {
    if (data.length > 0) {
      setNewData(data)
    }
  }, [data])

  const sortHandle = (sortBy: string | null) => {
    switch (sortBy) {
      case 'priceAsc':
        setNewData([...newData].sort((a, b) => parseFloat(a.netAmount) - parseFloat(b.netAmount)))
        break
      case 'priceDesc':
        setNewData([...newData].sort((a, b) => parseFloat(b.netAmount) - parseFloat(a.netAmount)))
        break
    }
  }

  return (
    <Container>
      <div className="product_header">
        <div className="sort_wrapper">
          <label>เรียงตาม</label>
          <Selector
            value={sortByOption}
            onChange={(option: OptionType) => {
              setSortByOption(option)
              const value = (option as OptionType).value
              sortHandle(value)
            }}
            options={SortByOptions}
          />
        </div>
        <div>
          <label>สูงสุด 3 รายการ</label>
          <button
            type="button"
            onClick={() => setIsCompare(true)}
            disabled={compareData.length < 1}
          >
            เปรียบเทียบ ({compareData.length})
          </button>
        </div>
      </div>
      <div className="product_body">
        <div className="top">ทั้งหมด {newData.length || 0} รายการ</div>
        {(newData || []).map((d, index) => (
          <InsuranceCard
            key={index}
            data={d}
            location={location}
            setIsCompare={setIsCompare}
            compareData={compareData}
            setCompareData={setCompareData}
          />
        ))}
      </div>
    </Container>
  )
}

export default SearchProduct
