import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'routes'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Formik } from 'formik'
import queryString from 'querystring'

import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import Loader from 'components/BackgroundLoading'
import HistoryType from 'types/HistoryType'
import { TravelType, SearchStateType } from 'types/TravelInsuranceType'
import { RootState } from 'store/rootReducer'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import { getTravelOptions, getSearch, getSearchByTypeTa } from 'store/travelInsuranceSlice'
import InsuranceDetail from './InsuranceDetail'
import DropleadForm from './form'
// import DropleadSchema from './form/form.validate'

type ParamsType = {
  id: string
  matrix_id: string
}

type AssuredTypes = {
  prefixName: string
  firstName: string
  lastName: string
  xxxx: string
  beneficiary: string
  idCard: string
  email: string
  phoneNumber: string
  birthdate: Date | null
  national: string
}

type DropleadTypes = {
  // isType: string
  // assured: Array<AssuredTypes>
  // policyType: string
  // startAt: Date
  // numberStart: string
  // endAt: Date
  // numberEnd: string
  // address: {
  //   aaaaaa: string
  //   housingNo: string
  //   villageNo: string
  //   building: string
  //   lane: string
  //   road: string
  //   subDistrict: string
  //   district: string
  //   province: string
  //   postcode: string
  // }
}

const defaultProps: DropleadTypes = {
  // isType: '',
  // assured: [
  //   {
  //     firstName: '',
  //     lastName: '',
  //     prefixName: '',
  //     xxxx: '',
  //     idCard: '',
  //     beneficiary: '',
  //     email: '',
  //     phoneNumber: '',
  //     birthdate: null,
  //     national: '',
  //   },
  // ],
  // policyType: '',
  // startAt: new Date(),
  // numberStart: '',
  // endAt: new Date(),
  // numberEnd: '',
  // address: {
  //   aaaaaa: '',
  //   housingNo: '',
  //   villageNo: '',
  //   building: '',
  //   lane: '',
  //   road: '',
  //   subDistrict: '',
  //   district: '',
  //   province: '',
  //   postcode: '',
  // },
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
  font-size: 17px;
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const BreadCrumbStyle = styled(BreadCrumb)`
  border-bottom: 1px solid var(--agt-secondary-color-1);
  padding-bottom: 16px;
`
const DropleadContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const TravelDropleadProduct: FC = () => {
  let { id, matrix_id } = useParams<ParamsType>()
  const history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const location = useLocation().search
  const jsonStr = queryString.parse(location.replace('?', ''))
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const { travelInsurances, loading, options, optionsByTypeTa } = useSelector(
    (state: RootState) => state.travel,
  )
  const [insurancesById, setInsurancesById] = useState<SearchStateType>()

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: get(opportunity, 'name', 'โอกาสในการขาย') },
    { path: `/opportunities/${id}/catalog`, label: 'เลือกหมวดประกัน' },
    { path: `/opportunities/${id}/catalog/travel`, label: 'ประกันเดินทาง' },
    {
      path: `/opportunities/${id}/catalog/travel/insurance_product${location}`,
      label: 'แสดงแบบประกัน',
    },
    { label: 'ข้อมูลผู้เอาประกันภัย' },
  ]

  useEffect(() => {
    if (id) {
      dispatch(getNewOpportunityById(id))
      dispatch(getTravelOptions(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    dispatch(
      getSearchByTypeTa(id, {
        travelType: jsonStr.travelType.toString(),
        travelTypeCountry: jsonStr.travelTypeCountry.toString(),
      }),
    )
  }, [dispatch, id])

  useEffect(() => {
    if (id && jsonStr) {
      dispatch(getSearch(id, jsonStr))
    }
  }, [dispatch, id])

  useEffect(() => {
    setInsurancesById(travelInsurances.find((d) => d.productId === matrix_id))
  }, [matrix_id, travelInsurances])

  const onSaving = (values: DropleadTypes) => {
    let params = { ...values, type: 'update' }
    //  dispatch(
    //    createOrder(id, params, () => {
    //      history.push('/opportunities')
    //    }),
    //  )
  }

  const insuranceData = useMemo(
    () => ({
      companyLogoUrl: insurancesById?.companyLogoUrl || '',
      companyName: insurancesById?.companyName || '',
      netAmount: insurancesById?.netAmount || '',
      minAge: insurancesById?.minAge || '',
      maxAge: insurancesById?.maxAge || '',
      coverDetail: insurancesById?.coverDetail || null,
      // ----------------
      insureLevel: jsonStr?.insureLevel?.toString() || '',
      travelTypeCountry: jsonStr?.travelTypeCountry?.toString() || '',
    }),
    [insurancesById, jsonStr],
  )

  return (
    <Container>
      <Header>ประกันเดินทาง</Header>
      <BreadCrumbStyle links={breadCrumbArr} hasBackToRoot iframe />
      {(loading.options || loading.search) && <Loader />}
      <DropleadContainer>
        <InsuranceDetail data={insuranceData} />
        <Formik
          enableReinitialize
          initialValues={{
            // ...defaultProps,
            countryId: jsonStr?.countryId?.toString() || '',
            dsc: jsonStr?.dsc?.toString() || '',
            src: jsonStr?.src?.toString() || '',
            travelDateFrom: jsonStr?.travelDateFrom?.toString() || '',
            travelDateTo: jsonStr?.travelDateTo?.toString() || '',
            travelPassenger: jsonStr?.travelPassenger?.toString() || '',
            travelType: jsonStr?.travelType?.toString() || '',
            travelTypeCountry: jsonStr?.travelTypeCountry?.toString() || '',
            vehicleType: jsonStr?.vehicleType?.toString() || '',
            // assured: [
            //   {
            //     firstName: 'firstName',
            //     lastName: 'lastName',
            //     prefixName: 'นาย',
            //     xxxx: 'online',
            //     idCard: '17399633562111',
            //     beneficiary: 'original',
            //     email: 'email@gmail.com',
            //     phoneNumber: '0874154139',
            //     birthdate: new Date(),
            //     national: '',
            //   },
            // ],
            policyType: '',
            startAt: new Date(),
            numberStart: '777',
            endAt: new Date(),
            numberEnd: '778',
            // address: {
            //   aaaaaa: 'ระบุเอง',
            //   housingNo: '',
            //   villageNo: '',
            //   building: '',
            //   lane: '',
            //   road: '',
            //   subDistrict: '',
            //   district: '',
            //   province: '',
            //   postcode: '',
            // },
          }}
          // validationSchema={DropleadSchema}
          onSubmit={(values) => {
            const modifiedValues = {
              ...values,
              type: 'droplead',
            }
            console.log('modifiedValues', modifiedValues)
          }}
        >
          {(props) => (
            <DropleadForm
              id={id}
              handleSubmit={props.handleSubmit}
              values={props.values}
              options={options}
              optionsByTypeTa={optionsByTypeTa}
              onSaving={onSaving}
            />
          )}
        </Formik>
      </DropleadContainer>
    </Container>
  )
}
export default TravelDropleadProduct
