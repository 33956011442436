import React, { FC, useState } from 'react'
import styled from '@emotion/styled'
import { Link, useHistory } from 'routes'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Icon from 'components/Icon'
import { RootState } from 'store/rootReducer'
import { loginUser, loginUserBySocial, setIsNavbarErrorShow } from 'store/appSlice'
import { get } from 'lodash'

type LoginValues = {
  agentCode: string
  password: string
}
type LoginPageType = {
  onForgotPasswordClick: () => void
}
type FieldValueProps = {
  isError?: boolean
}

const AnotherSignIn = styled.div`
  display: flex;
  flex-direction: column;
  .suggest {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .line {
      border: 0px;
      border-top: 1px solid var(--agt-secondary-color-1);
      height: 1px;
      width: 150px;
    }
  }

  .google {
    width: 100%;
    height: 48px;
    margin-bottom: 24px;
    border: 1px solid var(--agt-primary-color-1);
    border-radius: 5px;
    background: var(--agt-white-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .label {
      color: var(--agt-primary-color-1);
    }
    :disabled {
      border: 1px solid var(--agt-disable-color-1);
      background: var(--agt-white-color);
      cursor: no-drop;
      .label {
        color: var(--agt-disable-color-2);
      }
      .icon {
        opacity: 0.5;
      }
    }
  }
  .facebook {
    width: 100%;
    height: 48px;
    margin-bottom: 24px;
    border: 1px solid var(--agt-primary-color-1);
    border-radius: 5px;
    background: var(--agt-white-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .label {
      color: var(--agt-primary-color-1);
    }
    :disabled {
      border: 1px solid var(--agt-disable-color-1);
      background: var(--agt-white-color);
      cursor: no-drop;
      .label {
        color: var(--agt-disable-color-2);
      }
      .icon {
        opacity: 0.5;
      }
    }
  }
`
const Loading = styled.div`
  padding: 40px 20px;
  text-align: center;
`
const ErrorBar = styled.div`
  position: absolute;
  z-index: 1;
  top: 80px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  color: white;
  background: var(--agt-error-color);
  .resend {
    color: white;
    cursor: pointer;
  }
`
const IconStyles = styled(Icon)`
  margin-right: 13px;
`
const IconSignIn = styled(Icon)`
  margin-top: 10px;
  margin-right: 10px;
`
const SelectTitle = styled.h4`
  width: 100%;
  height: 36px;
  margin-bottom: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .primary {
  }
  .secondary {
    color: var(--agt-secondary-color-1) !important;
    text-decoration: none;
  }
  .center {
    border-left: 1px solid var(--agt-secondary-color-1);
    height: 100%;
  }
`
const Container = styled.div`
  padding: 50px 0;
`
const FormValue = styled(Form)`
  width: 100%;
  max-width: 352px;
`
const FormContainer = styled.div`
  margin: 0 0 24px;
`
const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 0 8px;
`
const FieldValue = styled(Field)<FieldValueProps>`
  width: 352px;
  border: ${(props) =>
    props.isError
      ? '1px solid var(--agt-error-color) !important'
      : '1px solid var(--agt-secondary-color-1) !important'};
`
const ErrorContainer = styled.div`
  margin-top: 8px;
`
const InputContainer = styled.div`
  position: relative;
`
const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
`
const ForgetPassword = styled.div`
  color: var(--agt-secondary-text-color);
  cursor: pointer;
  :hover {
    color: var(--agt-disable-color-1);
  }
`
const SubmitButton = styled.button`
  width: 100%;
  margin-bottom: 24px;
`

function validatePassword(value: string) {
  let error: string | undefined
  if (!value) {
    error = 'กรุณากรอกข้อมูล'
  } else if (value.length < 8) {
    error = 'รหัสผ่านต้องไม่น้อยกว่า 8 ตัวอักษร'
  }
  return error
}

function validateUsername(value: string) {
  let error: string | undefined
  if (!value) {
    error = 'กรุณากรอกข้อมูล'
  }
  return error
}

const Error = (msg: string) => <ErrorContainer className="error agt-small">{msg}</ErrorContainer>

const PasswordField = (props) => {
  const { field, form, onChange, isNavbarError = '' } = props
  const { touched, errors } = form
  const { value } = field
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const togglePassword = () => setShowPassword(!showPassword)
  return (
    <InputContainer>
      <FieldValue
        {...field}
        type={showPassword ? 'text' : 'password'}
        placeholder="โปรดระบุ"
        isError={(touched.password && errors.password) || isNavbarError !== ''}
        onChange={(e) => {
          field.onChange(e)
          if (onChange) {
            onChange(e)
          }
        }}
      />
      {value && (
        <IconContainer onClick={() => togglePassword()}>
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            color="#ACB1BF"
            style={{ fontSize: '18px' }}
          />
        </IconContainer>
      )}
    </InputContainer>
  )
}

const Login: FC<LoginPageType> = (props) => {
  const { onForgotPasswordClick } = props
  const [isLoading, setLoading] = useState<boolean>(false)
  let history = useHistory()
  const dispatch = useDispatch()
  const isNavbarError = useSelector((state: RootState) => state.app.isNavbarErrorShow)
  const [isResp, setIsResp] = useState({ email: '' })
  const clearError = async () => {
    dispatch(setIsNavbarErrorShow(''))
  }
  function resendEmail() {
    return (
      <div>
        อีเมลนี้ยังไม่ได้รับการยืนยันตัวตน&nbsp;
        <Link
          to={{ pathname: '/register', state: { resendEmail: isResp.email, callApi: 'Y' } }}
          className="resend"
        >
          โปรดยืนยันตัวตนหรือคลิกที่นี่เพื่อขออีเมลอีกครั้ง
        </Link>
      </div>
    )
  }

  return (
    <>
      {isNavbarError && (
        <ErrorBar>
          <IconStyles name="cross" />
          &nbsp;
          <span>
            {isNavbarError === 'Unauthorized.' || isNavbarError === 'Member not found.'
              ? 'อีเมลหรือรหัสผ่านไม่ถูกต้อง'
              : isNavbarError === 'อีเมลยังไม่ได้รับการยืนยัน'
              ? resendEmail()
              : isNavbarError}
          </span>
        </ErrorBar>
      )}
      <Container>
        <SelectTitle>
          <div className="primary">เข้าสู่ระบบ</div>
          <div className="center"></div>
          <Link to="/register" className="ghost">
            <div className="secondary">ลงทะเบียน</div>
          </Link>
        </SelectTitle>
        <Formik
          initialValues={{
            agentCode: '',
            password: '',
          }}
          onSubmit={(values: LoginValues) => {
            dispatch(loginUser(values, history, setLoading))
          }}
        >
          {(props) => {
            const { isValid, values, errors, setFieldValue } = props
            const disabled = !isValid || !values.agentCode || !values.password
            return (
              <FormValue>
                <FormContainer>
                  <Label>บัญชีผู้ใช้งาน (อีเมล/รหัสตัวแทน)</Label>
                  <FieldValue
                    name="agentCode"
                    type="text"
                    placeholder="โปรดระบุ"
                    validate={validateUsername}
                    isError={isNavbarError || errors.agentCode}
                    onChange={(e) => {
                      setFieldValue('agentCode', e.target.value)
                      setIsResp({ email: e.target.value })
                      clearError()
                    }}
                  />
                  <ErrorMessage render={Error} name="agentCode" />
                </FormContainer>
                <FormContainer>
                  <Label>
                    รหัสผ่าน
                    <ForgetPassword
                      style={{ display: 'flex' }}
                      className="small"
                      onClick={() => onForgotPasswordClick()}
                    >
                      ลืมรหัสผ่าน?
                    </ForgetPassword>
                  </Label>
                  <FieldValue
                    name="password"
                    type="password"
                    placeholder="โปรดระบุ"
                    validate={validatePassword}
                    isNavbarError={isNavbarError || errors.password}
                    component={PasswordField}
                    onChange={() => clearError()}
                  />
                  <ErrorMessage render={Error} name="password" />
                </FormContainer>
                {isLoading ? (
                  <Loading>loading ....</Loading>
                ) : (
                  <SubmitButton type="submit" disabled={disabled}>
                    เข้าสู่ระบบ
                  </SubmitButton>
                )}
                <AnotherSignIn>
                  <div className="suggest">
                    <hr className="line" />
                    หรือ
                    <hr className="line" />
                  </div>
                  <GoogleLogin
                    clientId={`${process.env.REACT_APP_APPID_GOOGLE}`}
                    render={(renderProps) => (
                      <button
                        className="google secondary"
                        type="button"
                        onClick={renderProps.onClick}
                      >
                        <div className="icon">
                          <IconSignIn name="google" />
                        </div>
                        เข้าสู่ระบบด้วย Google
                      </button>
                    )}
                    onSuccess={(res) => {
                      let values: { email: string; tokenId: string } = {
                        email: get(res, 'profileObj.email', ''),
                        tokenId: get(res, 'tokenId', ''),
                      }
                      setIsResp(values)
                      dispatch(loginUserBySocial('google', values, history))
                    }}
                    onFailure={(err) => console.log('onFailure : ', err)}
                  />
                  <FacebookLogin
                    appId={`${process.env.REACT_APP_APPID_FACEBOOK}`}
                    autoLoad={false}
                    callback={(res) => {
                      if (res.userID) {
                        let values: { email: string; accessToken: string } = {
                          email: get(res, 'email', ''),
                          accessToken: get(res, 'accessToken', ''),
                        }
                        setIsResp(values)
                        dispatch(loginUserBySocial('facebook', values, history))
                      }
                    }}
                    fields="email"
                    render={(renderProps) => (
                      <button
                        className="facebook secondary"
                        type="button"
                        onClick={renderProps.onClick}
                      >
                        <div className="icon">
                          <IconSignIn name="facebook-signin" />
                        </div>
                        เข้าสู่ระบบด้วย Facebook
                      </button>
                    )}
                  />
                </AnotherSignIn>
              </FormValue>
            )
          }}
        </Formik>
      </Container>
    </>
  )
}

export default Login
