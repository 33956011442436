import React, { useState, FC, useCallback, useRef, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'routes'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import 'moment/locale/th'
import DetailItem from './NotificationItem/DetailItem'
import {
  updateRead,
  updateCheck,
  getNotification,
  initializeNotifications,
} from 'store/notificationSlice'
import authToken from 'utils/session'
import HistoryType from 'types/HistoryType'
import { RootState } from 'store/rootReducer'
import { NotificationType } from 'types/NotificationType'

type NotificationProp = {}

const MainContainer = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  background: var(--agt-background-color-3);
  border-radius: 50%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
`
const NotificationIconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const NotificationRedDot = styled.div`
  position: absolute;
  top: 7px;
  left: 18px;
  width: 12px;
  height: 12px;
  background: var(--agt-error-color);
  border: 2px solid var(--agt-background-color-3);
  border-radius: 50%;
`
const NotificationListContainerArrowTop = styled.div`
  position: absolute;
  background: #ffffff;
  border-radius: 4px 0px 0px 0px;
  transform: rotate(45deg);
  width: 16.97px;
  height: 16.97px;
  top: 41px;
  right: 9px;
  z-index: 6;
  border: 1px solid var(--agt-secondary-color-1);
  border-bottom: 0px;
  border-right: 0px;
`
const NotificationListContainer = styled.div`
  position: absolute;
  top: 49px;
  right: -25px;
  min-width: 346px;
  width: auto;
  height: fit-content;
  background: #ffffff;
  border: 1px solid var(--agt-secondary-color-1);
  border-radius: 4px;
  padding: 24.5px 0px;
  z-index: 5;
  max-height: 619px;
  overflow-y: auto;
`
const AlertMessageContainer = styled.div`
  display: flex;
  margin-left: 16px;
  margin-right: 27px;
  margin-bottom: 9.5px;
`
const DateMessageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 27px;
`
const AlertMessage = styled.div`
  color: var(--agt-secondary-color-2);
  font-size: 17px;
`
const NotificationListMainContainer = styled.div``
const SeeAllContainer = styled.div`
  margin-top: 8.5px;
  width: 100%;
  display: flex;
  justify-content: center;
`
const SeeAllText = styled.div`
  text-decoration-line: underline;
  color: var(--agt-primary-color-1);
  cursor: pointer;
`
const DontHaveNotiText = styled.div`
  text-align: center;
  color: var(--agt-secondary-color-2);
  margin-top: 24.5px;
  justify-content: center;
`

const setMaxframe = (length) => {
  return 5 - length > 0 ? 5 - length : 0
}
const getTask = (notifications) => {
  let currentDayNotificatons: NotificationType[] = []
  let latedNotificatons: NotificationType[] = []
  let isRead = true
  let alertAt = '1920-01-01'
  let idTask = 0
  let actionId = 0

  notifications.forEach((item) => {
    if (item.actionType === 'task') {
      idTask = item.id ? item.id : 0
      if (moment(item?.info?.eventStartDate).isSame(new Date(), 'day')) {
        currentDayNotificatons.push(item)
        if (moment(item?.alertAt).isAfter(alertAt)) {
          alertAt = item?.alertAt
          actionId = item?.actionId
        }
      } else if (moment(item?.info?.eventStartDate).isBefore(new Date(), 'day')) {
        latedNotificatons.push(item)
        if (moment(item?.alertAt).isAfter(alertAt)) {
          alertAt = item?.alertAt
          actionId = item?.actionId
        }
      }
      if (!item.isRead) {
        isRead = item.isRead
      }
    }
  })
  const task = {
    id: idTask,
    actionType: 'task',
    info: null,
    isRead: isRead,
    isChecked: null,
    contactName: 'contactName',
    alertAt: alertAt,
    actionId: actionId,
    currentDayTask: currentDayNotificatons.length,
    latedTask: latedNotificatons.length,
  }
  return task
}

const Notification: FC<NotificationProp> = (props) => {
  const [open, setOpen] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLHeadingElement>(null)
  const [status] = useState<boolean>(!authToken.isAuthTokenPresent())

  let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const notifications = useSelector((state: RootState) => state.notifications.notifications) || []

  useEffect(() => {
    !status && dispatch(initializeNotifications())
    !status && dispatch(getNotification())
    const interval = !status
      ? setInterval(() => {
          dispatch(getNotification())
        }, 60000)
      : null
    if (interval) {
      return () => clearInterval(interval)
    }
  }, [status, dispatch])

  const setOpenToConverse = useCallback(
    async (notification) => {
      let notificatonIds: number[] = []
      notifications.forEach((item) => {
        if (
          !item.isRead &&
          item.id &&
          (notification.id === item.id ||
            (notification.actionType === 'task' && item.actionType === 'task'))
        ) {
          notificatonIds.push(item.id)
        }
      })
      if (notificatonIds.length > 0) dispatch(updateRead(notificatonIds))
    },
    [notifications, dispatch],
  )
  const setOpenChecked = useCallback(async () => {
    setOpen(!open)
    let notificatonIds: number[] = []
    notifications.forEach((item) => {
      if (!item.isChecked && item.id) {
        notificatonIds.push(item.id)
      }
    })
    if (notificatonIds.length > 0) dispatch(updateCheck(notificatonIds))
  }, [notifications, open, dispatch])

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const goToNotificationPage = () => {
    setOpen(!open)
    history.push('/notifications')
  }
  const notificationLated = useMemo(() => {
    let newNotificatons: NotificationType[] = []
    const task = getTask(notifications)
    notifications.forEach((item) => {
      if (moment(item?.alertAt).isBefore(new Date(), 'day')) {
        if (item.actionType !== 'task') {
          newNotificatons.push(item)
        } else if (task && item.id === task.id) {
          newNotificatons.push(task)
        }
      }
    })

    newNotificatons = newNotificatons.sort(
      (a, b) => new Date(b.alertAt).getTime() - new Date(a.alertAt).getTime(),
    )
    return newNotificatons
  }, [notifications])

  const notificationToday = useMemo(() => {
    let newNotificatons: NotificationType[] = []
    const task = getTask(notifications)
    notifications.forEach((item) => {
      if (moment(item?.alertAt).isSame(new Date(), 'day')) {
        if (item.actionType !== 'task') {
          newNotificatons.push(item)
        } else if (task && item.id === task.id) {
          newNotificatons.push(task)
        }
      }
    })
    newNotificatons = newNotificatons.sort(
      (a, b) => new Date(b.alertAt).getTime() - new Date(a.alertAt).getTime(),
    )
    return newNotificatons
  }, [notifications])

  const haveIsChecked = useMemo(() => {
    let haveIsChecked = false
    notifications.forEach((item) => {
      if (!item.isChecked) {
        haveIsChecked = true
      }
    })
    return haveIsChecked
  }, [notifications])

  const onItemClick = (actionType = '', actionId = 0) => {
    if (actionType === 'opportunity') {
      setOpen(false)
    } else {
      setOpen(false)
    }
  }
  return (
    <>
      <MainContainer ref={wrapperRef}>
        <NotificationIconContainer onClick={setOpenChecked}>
          {haveIsChecked && !open && <NotificationRedDot />}
          <FontAwesomeIcon icon={faBell} color="white" />
        </NotificationIconContainer>
        {open && (
          <NotificationListMainContainer>
            <NotificationListContainerArrowTop />
            <NotificationListContainer>
              <AlertMessageContainer>
                <AlertMessage>การแจ้งเตือน</AlertMessage>
              </AlertMessageContainer>
              {notificationToday.length > 0 || notificationLated.length > 0 ? (
                <>
                  {notificationToday.length > 0 && (
                    <>
                      <DateMessageContainer>
                        <AlertMessage>ใหม่</AlertMessage>
                      </DateMessageContainer>
                      {notificationToday.slice(0, 5).map((item, index) => (
                        <DetailItem
                          {...item}
                          key={index}
                          setOpenToConverse={setOpenToConverse}
                          callback={onItemClick}
                        />
                      ))}
                    </>
                  )}
                  {notificationLated.length > 0 && notificationToday.length < 5 && (
                    <>
                      <AlertMessageContainer>
                        <AlertMessage>ก่อนหน้านี้</AlertMessage>
                      </AlertMessageContainer>
                      {notificationLated
                        .slice(0, setMaxframe(notificationToday.length))
                        .map((item, index) => (
                          <DetailItem
                            {...item}
                            key={index}
                            setOpenToConverse={setOpenToConverse}
                            callback={onItemClick}
                          />
                        ))}
                    </>
                  )}
                  <SeeAllContainer>
                    <SeeAllText onClick={goToNotificationPage}>ดูทั้งหมด</SeeAllText>
                  </SeeAllContainer>
                </>
              ) : (
                <DontHaveNotiText>ยังไม่มีการแจ้งเตือน</DontHaveNotiText>
              )}
            </NotificationListContainer>
          </NotificationListMainContainer>
        )}
      </MainContainer>
    </>
  )
}

export default Notification
