import styled from '@emotion/styled'
import React, { FC } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import Task from './Task'
import { currency } from 'utils/helper'
import { useHistory } from 'routes'
import HistoryType from 'types/HistoryType'
import { ColumnPropsType } from 'types/OpportunityType'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(25% - 16px);
  overflow: hidden;

  border-radius: 4px;
  background-color: var(--agt-background-color-1);
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  padding: 0 16px;

  color: #ffffff;
  background-color: var(--agt-primary-color-1);
`
const TaskList = styled.div`
  flex-grow: 1;
  min-height: 100px;
  padding: 20px 12px;
  margin-bottom: 20px;
  height: 476px;
  overflow-y: auto;
  background-color: #eff1f5;
  transition: background-color 0.2s ease;
`
const MoneyValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: var(--agt-disable-color-1);
`
const CreateButton = styled.button`
  width: 100px;
  height: 36px;
`

const Column: FC<ColumnPropsType> = (props) => {
  const { currentAgent, setOpportunities } = props
  const history: HistoryType = useHistory()

  let stateRejected = 0
  if (props.column.pipelineStateName === 'Implementation') {
    props.column.opportunities.map((value) => value.state === 'rejected' && stateRejected++)
  }

  const totalPremium = props.tasks.reduce((prev, curr) => {
    const sumPremium = curr.orders.reduce((prevPremium, currOrder) => {
      return prevPremium + parseFloat(currOrder.premium)
    }, 0)
    return prev + sumPremium
  }, 0)

  return (
    <Container>
      <Header>
        <div>{props.column.pipelineStateNameTh}</div> <div>{props.tasks.length}</div>
      </Header>
      <MoneyValue>
        <CreateButton
          className="secondary"
          style={{ borderRadius: '5px' }}
          onClick={() => {
            history.push('/opportunities/new')
          }}
          disabled={currentAgent?.statusTh === 'ระงับ'}
        >
          + สร้าง
        </CreateButton>
        <b>{totalPremium !== 0 ? `${currency(totalPremium, 2, '฿ ')}` : '-'}</b>
      </MoneyValue>
      <Droppable droppableId={props.column.pipelineStateId.toString()} type="TASK">
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {props.tasks.map((task, index) => (
              <Task
                key={task.id}
                task={task}
                index={index}
                comlumnId={props.column.pipelineStateId}
                pipelineStateName={props.column.pipelineStateName}
                setOpportunities={setOpportunities}
                isChangeColor={props.column.pipelineStateName === 'Implementation'}
              />
            ))}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </Container>
  )
}
export default Column
