import React, { FC, useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import api from 'api'
import Icon from 'components/Icon'
import ButtonLink from 'components/ButtonLink'
import { Link } from 'routes'
import { ContactType } from 'types/ContactType'
import { UserProps } from 'types/AppType'

type TaskRendererType = {
  header: string
  type: string
  data: TaskType[]
  forceExpand: boolean
  currentAgent?: UserProps | null
}
type TaskType = {
  id: number
  name: string
  dueDate: string | Date
  priorityLevel: number
  description?: string
}
type DetailType = {
  isMore: boolean
  isHistory: boolean
}
type DetailRowType = {
  isHistory: boolean
}
type TasksType = {
  contactId?: number | null
  contactData?: ContactType | null
  currentAgent: UserProps | null
}
type DataType = {
  tasksCurrentDay: Array<TaskType>
  tasksCurrentWeek: Array<TaskType>
  tasksIncoming: Array<TaskType>
  tasksLated: Array<TaskType>
  tasksNextWeek: Array<TaskType>
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 288px;
  max-width: 288px;
  background: var(--agt-background-color-2);
`
const Header = styled.div`
  width: 100%;
  height: 76px;
  background: var(--agt-tqm-pantone-1);
  display: flex;
  flex-direction: row;
  .label {
    width: 73.6%;
    color: white;
    text-align: center;
    height: 100%;
    border-right: 2px solid #003d73;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .icon {
    width: 26.4%;
    height: 100%;
    padding-top: 20px;
    padding-left: 18px;
  }
`
const Loading = styled.div`
  padding: 40px 20px;
  text-align: center;
`
const TodoContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`
const NotingText = styled.div`
  width: 100%;
  margin-top: 40px;
  text-align: center;
  color: var(--agt-secondary-text-color);
`
const IconStyle = styled(Icon)`
  width: 17px;
  height: 17px;
  margin-bottom: 10px;
`
const CustomBtnLink = styled(ButtonLink)`
  button {
    width: fit-content;
    padding: 0px 40px;
    margin-bottom: 40px;
  }
  @media (max-width: 1024px) {
    button {
      font-size: 13px;
    }
  }
`
const TaskContainer = styled.div`
  border-bottom: 1px solid white;
`
const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background-color: var(--agt-background-color-1);
  cursor: pointer;
`
const TaskHeaderHist = styled.div`
  width: 100%;
  height: 48px;
  padding: 11px 16px;
  font-weight: bold;
  color: var(--agt-primary-color-1);
  background-color: var(--agt-white-color);
`
const Detail = styled.ul<DetailType>`
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: white;
  > li:last-of-type {
    border-bottom: none;
  }
  height: ${(props) => (props.isMore && props.isHistory ? '750px' : props.isMore ? '500px' : '')};
  overflow-y: ${(props) => (props.isMore ? 'scroll' : '')};
`
const DetailRow = styled.li<DetailRowType>`
  display: flex;
  flex-direction: row;
  padding: ${(props) => (props.isHistory ? '12px' : '16px')};
  padding-left: ${(props) => (props.isHistory ? '16px' : '')};
  border-bottom: ${(props) => (props.isHistory ? '' : '1px solid var(--agt-background-color-2)')};
  position: relative;
  height: 125px;
`
const TopicContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .name {
    width: 208px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const TopicDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .overdue {
    font-size: 13px;
  }
`
const Clock = styled(Icon)`
  width: 17px;
  height: 17px;
  margin-right: 5px;
  margin-bottom: 5px;
  svg {
    width: 14px;
    height: 14px;
  }
`
const StarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 85px;
  max-width: 85px;
`
const CheckboxHistory = styled.div`
  margin-right: 15px;
  z-index: 1;
`
const VerticalLine = styled.div`
  position: absolute;
  top: 26px;
  left: 26px;
  border-left: 2px dashed var(--agt-secondary-color-2);
  height: 100%;
`
const CheckboxContainer = styled.div`
  margin-right: 15px;
  .disabledCheckbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--agt-secondary-color-1);
    background-color: var(--agt-disable-color-1);
    margin-top: 3px;
    cursor: not-allowed;
  }
`
const CheckboxWrapper = styled.div`
  padding-top: 6px;
  padding-left: 6px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  :hover {
    background: var(--agt-background-color-1);
  }
  .round {
    position: relative;
  }
  .round label {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
  }
  .round label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 4px;
    width: 9px;
    height: 4px;
    opacity: 0;
    border: 2px solid var(--agt-primary-text-color);
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }
  .round input[type='checkbox'] {
    visibility: hidden;
  }
  .round input[type='checkbox']:checked + label {
    background-color: white;
    border-color: var(--agt-primary-text-color);
  }
  .round input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
`
const LinkTab = styled(Link)`
  text-decoration: none;
`
const Description = styled.div`
  width: 208px;
  min-height: 25.6px;
  font-size: 13px;
  color: var(--agt-secondary-text-color);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`

const toggleStatus = async (id: number, callback: () => void) => {
  if (id === undefined) {
    alert('Undefind task id')
  }
  try {
    await api.patch(`/tasks/${id}/toggle_status`, {})
    callback()
  } catch (err) {
    if (err && err.response) {
      alert(err.response)
    }
    throw err
  }
}

const TaskRenderer: FC<TaskRendererType> = (props) => {
  const { header, type, data, forceExpand = false, currentAgent } = props
  const [isExpanded, setExpand] = useState<boolean>(forceExpand)
  const [tasks, setTasks] = useState<TaskType[]>(data)
  const toggleExpand = () => setExpand(!isExpanded)
  const showMax = type === 'history' ? 6 : 4
  return (
    <TaskContainer>
      {type === 'history' ? (
        <TaskHeaderHist>
          <span>{header}</span>
        </TaskHeaderHist>
      ) : (
        <TaskHeader onClick={toggleExpand}>
          <span
            style={
              header === 'เกินกำหนด' && tasks.length > 0 ? { color: 'var(--agt-error-color)' } : {}
            }
          >
            {header} ({tasks.length})
          </span>
          <IconStyle name={isExpanded ? 'vector_top' : 'vector_bottom'} />
        </TaskHeader>
      )}
      {isExpanded && (
        <Detail isMore={tasks.length > showMax} isHistory={type === 'history'}>
          {tasks.map((d: TaskType, index: number) => {
            return (
              <DetailRow key={index} isHistory={type === 'history'}>
                {type === 'history' ? (
                  <>
                    <CheckboxHistory>
                      <IconStyle name="check" />
                    </CheckboxHistory>
                    {index < tasks.length - 1 && <VerticalLine />}
                  </>
                ) : (
                  <CheckboxContainer>
                    {currentAgent?.statusTh === 'ระงับ' ? (
                      <div className="disabledCheckbox" />
                    ) : (
                      <CheckboxWrapper>
                        <div className="round">
                          <input
                            type="checkbox"
                            id={`checkoux_task_${d.id}`}
                            onChange={() =>
                              toggleStatus(d.id, () => {
                                setTimeout(function () {
                                  setTasks([])
                                  setTasks(
                                    tasks.filter(function (obj) {
                                      return obj.id !== d.id
                                    }),
                                  )
                                }, 500)
                              })
                            }
                          />
                          <label htmlFor={`checkoux_task_${d.id}`}></label>
                        </div>
                      </CheckboxWrapper>
                    )}
                  </CheckboxContainer>
                )}
                <TopicContainer>
                  <LinkTab to={`/tasks/${d.id}`} style={{ color: 'var(--agt-primary-text-color)' }}>
                    <div className="name">{d.name}</div>
                  </LinkTab>
                  {d.description ? <Description>{d.description}</Description> : <br />}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <TopicDate className={type === 'overdue' ? 'error' : 'success'}>
                      <Clock
                        name="clock"
                        color={
                          type === 'overdue'
                            ? 'var(--agt-error-color)'
                            : 'var(--agt-secondary-color-2)'
                        }
                      />
                      <label
                        className="overdue"
                        style={
                          type === 'overdue'
                            ? { color: 'var(--agt-error-color)' }
                            : { color: 'var(--agt-secondary-color-2)' }
                        }
                      >
                        {new Date(d.dueDate).toLocaleDateString('th-TH')}
                      </label>
                    </TopicDate>
                    <StarContainer>
                      {Array.from(Array(d.priorityLevel).keys()).map((s, i) => (
                        <IconStyle name="star" key={i} color="black" />
                      ))}
                    </StarContainer>
                  </div>
                </TopicContainer>
              </DetailRow>
            )
          })}
        </Detail>
      )}
    </TaskContainer>
  )
}

const Tasks: FC<TasksType> = (props) => {
  const { contactId, contactData, currentAgent } = props
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isHistory, setHistory] = useState<boolean>(false)
  const [data, setData] = useState<DataType>({
    tasksCurrentDay: [],
    tasksCurrentWeek: [],
    tasksIncoming: [],
    tasksLated: [],
    tasksNextWeek: [],
  })
  const {
    tasksCurrentDay = [],
    tasksCurrentWeek = [],
    tasksIncoming = [],
    tasksLated = [],
    tasksNextWeek = [],
  } = data

  const [tasksHistory, setTasksData] = useState<Array<TaskType>>([])

  const getTasks = useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get(`/tasks`, { contactId })
      const { tasks = {} } = response.data.data
      setData(tasks)
    } catch (error) {
      console.error(error.response || error)
    } finally {
      setLoading(false)
    }
  }, [contactId])

  const getHistoryTasks = useCallback(async (contactId: number) => {
    try {
      setLoading(true)
      const response = await api.get(`/tasks/log`, { contactId: contactId })
      const { tasks = {} } = response.data.data
      setTasksData(tasks)
    } catch (error) {
      console.error(error.response || error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (isHistory && contactId) {
      getHistoryTasks(contactId)
    } else if (contactId) {
      getTasks()
    }
  }, [contactId, isHistory, setHistory, getTasks, getHistoryTasks])

  const tasksCurrentDayExpand = tasksCurrentDay.length > 0
  const tasksLatedExpand = tasksLated.length > 0 && !tasksCurrentDayExpand
  const tasksCurrentWeekExpand =
    tasksCurrentWeek.length > 0 && !tasksCurrentDayExpand && !tasksLatedExpand
  const tasksNextWeekExpand =
    tasksNextWeek.length > 0 &&
    !tasksCurrentWeekExpand &&
    !tasksLatedExpand &&
    !tasksCurrentDayExpand
  const tasksIncomingExpand =
    tasksIncoming.length > 0 &&
    !tasksNextWeekExpand &&
    !tasksCurrentWeekExpand &&
    !tasksLatedExpand &&
    !tasksCurrentDayExpand

  return (
    <Container id="contact-id__task-sidebar">
      <Header>
        <h4
          className="label"
          style={isHistory ? { cursor: 'pointer' } : {}}
          onClick={() => {
            if (isHistory) {
              setHistory(!isHistory)
            }
          }}
        >
          งานที่ต้องทำ
        </h4>
        <div
          className="icon"
          style={isHistory ? { background: '#003d73' } : { cursor: 'pointer' }}
          onClick={() => {
            if (!isHistory) {
              setHistory(!isHistory)
            }
          }}
        >
          <Icon name="history" />
        </div>
      </Header>
      {isLoading ? (
        <Loading>loading ....</Loading>
      ) : (
        <>
          {isHistory ? (
            <TodoContainer>
              {tasksHistory.length === 0 ? (
                <NotingText>ยังไม่มีประวัติงานที่ต้องทำ</NotingText>
              ) : (
                <TaskRenderer
                  header="ประวัติงานที่ทำเสร็จแล้ว"
                  type="history"
                  data={tasksHistory}
                  forceExpand={true}
                />
              )}
            </TodoContainer>
          ) : (
            <TodoContainer>
              {tasksLated.length === 0 &&
                tasksCurrentDay.length === 0 &&
                tasksCurrentWeek.length === 0 &&
                tasksNextWeek.length === 0 &&
                tasksIncoming.length === 0 && <NotingText>ยังไม่มีงานที่ต้องทำ</NotingText>}
              {tasksLated.length > 0 && (
                <TaskRenderer
                  header="เกินกำหนด"
                  type="overdue"
                  data={tasksLated}
                  forceExpand={tasksLatedExpand}
                  currentAgent={currentAgent}
                />
              )}
              {tasksCurrentDay.length > 0 && (
                <TaskRenderer
                  header="วันนี้"
                  type="today"
                  data={tasksCurrentDay}
                  forceExpand={tasksCurrentDayExpand}
                  currentAgent={currentAgent}
                />
              )}
              {tasksCurrentWeek.length > 0 && (
                <TaskRenderer
                  header="สัปดาห์นี้"
                  type="incoming"
                  data={tasksCurrentWeek}
                  forceExpand={tasksCurrentWeekExpand}
                  currentAgent={currentAgent}
                />
              )}
              {tasksNextWeek.length > 0 && (
                <TaskRenderer
                  header="สัปดาห์หน้า"
                  type="incoming"
                  data={tasksNextWeek}
                  forceExpand={tasksNextWeekExpand}
                  currentAgent={currentAgent}
                />
              )}
              {tasksIncoming.length > 0 && (
                <TaskRenderer
                  header="สัปดาห์กำลังมาถึง"
                  type="incoming"
                  data={tasksIncoming}
                  forceExpand={tasksIncomingExpand}
                  currentAgent={currentAgent}
                />
              )}
            </TodoContainer>
          )}
        </>
      )}
      <CustomBtnLink
        link={`/tasks/new/${contactId}`}
        label="สร้างงานที่ต้องทำ"
        disabled={contactData?.status === 'รอยินยอม' || currentAgent?.statusTh === 'ระงับ'}
      />
    </Container>
  )
}
export default Tasks
