import React, { FC, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'routes'
import Modal from 'components/Modal'
import Icon from 'components/Icon'
import Avatar from 'components/Avatar'
import Api from 'api'
import { dateFormat, phoneNumberFormat } from 'utils/helper'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'

export type TaskType = {
  id: number
  name: string
  dueDate: string
  reminderTime: string
  priorityLevel: number | null
  contactId: number | null
  contactName: string | null
  contactAvatar: string
  phoneNumber?: string
  email?: string
  status?: string
  description?: string
  occupation?: string
  contactStatus?: string | null
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 120px;
  padding: 10px 0;
  border-bottom: 1px solid var(--agt-background-color-2);
`
const ValuesName = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 366px;
  padding: 0 15px 0 24px;
  border-right: 1px solid var(--agt-background-color-2);
  @media only screen and (max-width: 1120px) {
    min-width: 300px;
  }
`
const CheckboxContainer = styled.div`
  margin-right: 18px;
  .disabledCheckbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--agt-secondary-color-1);
    background-color: var(--agt-disable-color-1);
    margin-top: 6px;
    cursor: not-allowed;
  }
`
const CheckboxWrapper = styled.div`
  padding-top: 6px;
  padding-left: 6px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  :hover {
    background: var(--agt-background-color-1);
  }
  .round {
    position: relative;
  }
  .round label {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
  }
  .round label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 4px;
    width: 9px;
    height: 4px;
    opacity: 0;
    border: 2px solid var(--agt-primary-text-color);
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }
  .round input[type='checkbox'] {
    visibility: hidden;
  }
  .round input[type='checkbox']:checked + label {
    background-color: white;
    border-color: var(--agt-primary-text-color);
  }
  .round input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
`
const NameDisplay = styled.div`
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .name {
    width: 276px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media only screen and (max-width: 1120px) {
    .name {
      width: 210px;
    }
  }
`
const Description = styled.div`
  width: 276px;
  font-size: 13px;
  color: var(--agt-secondary-text-color);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 1120px) {
    width: 210px;
  }
`
const TimeDisplay = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const IconStyle = styled(Icon)`
  width: 21px;
  height: 21px;
  align-items: center;
`
const ClockStyle = styled(Icon)`
  margin-right: 4px;
  div {
    display: flex;
    align-items: center;
    svg {
      width: 14px;
      height: 20px;
    }
  }
`
const CustomButton = styled.button`
  right: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
  path {
    fill: var(--agt-primary-color-1);
  }
  :hover {
    path {
      fill: var(--agt-dark-sky-color);
    }
  }
`
const IconButton = styled(Icon)`
  margin-top: 4px;
`
const DueDateDisplay = styled.small`
  color: ${(props) => props.color};
  font-size: 12px;
`
const StarDisplay = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 65px;
  margin-left: auto;
`
const ValuesDisplay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 40px;
  padding-top: 16px;
  padding-right: 60px;
`
const AvatarContainer = styled.div`
  width: 48px;
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  height: 100%;
  padding: 0 0 10px 14px;
  small {
    color: var(--agt-primary-text-color-1);
    margin-bottom: 2px;
  }
  > small:first-of-type {
  }
  .contactName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 2px;
  }
  .telephone {
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 230px;
  }
  @media only screen and (max-width: 1024px) {
    width: 180px;
    max-width: unset;
  }
`
const LinkTab = styled(Link)`
  align-items: center;
  text-decoration: none;
  color: var(--agt-primary-color-1);
`
const EditDisplay = styled.div`
  padding-top: 14px;
  display: flex;
  > button:last-of-type {
    margin-left: 18px;
  }
  .disabledLink {
    pointer-events: none;
  }
  .disabledLink svg path {
    fill: var(--agt-secondary-color-1);
  }
`
const DetailLink = styled(Link)`
  width: fit-content;
  margin-top: auto;
  white-space: nowrap;
`
const DeleteModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0 48px 0;
  width: 352px;
  background: var(--agt-white-color);
  border-radius: 4px;
`

const Task: FC<TaskType> = (props) => {
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [deleteIsClicked, setStateDeleteClicked] = useState<boolean>(false)

  const toggleStatus = useCallback(async () => {
    if (props.id === undefined) {
      alert('Undefind task id')
    }
    try {
      await Api.patch(`/tasks/${props.id}/toggle_status`, {})
    } catch (err) {
      if (err && err.response) {
        alert(err.response)
      }
      throw err
    }
  }, [props.id])

  const deleteTask = useCallback(async () => {
    if (props.id === undefined) {
      alert('Undefind task id')
    } else {
      try {
        await Api.delete(`/tasks/${props.id}`, {})
        window.location.reload(false)
      } catch (err) {
        if (err && err.response) {
          alert(err.response)
        }
        throw err
      }
    }
  }, [props.id])

  return (
    <Container>
      <Modal
        isOpen={deleteIsClicked}
        onConfirm={() => deleteTask()}
        onCancel={() => setStateDeleteClicked(false)}
      >
        <DeleteModal>
          <p>ยืนยันการลบงานที่ต้องทำ</p>
          <p>{props?.name}</p>
        </DeleteModal>
      </Modal>
      <ValuesName>
        <CheckboxContainer>
          {currentAgent?.statusTh === 'ระงับ' ? (
            <div className="disabledCheckbox" />
          ) : (
            <CheckboxWrapper>
              <div className="round">
                <input type="checkbox" id={`checkoux_task_${props.id}`} onChange={toggleStatus} />
                <label htmlFor={`checkoux_task_${props.id}`}></label>
              </div>
            </CheckboxWrapper>
          )}
        </CheckboxContainer>
        <NameDisplay>
          <LinkTab to={`/tasks/${props.id}`} style={{ color: 'var(--agt-primary-text-color)' }}>
            <div className="name">{props.name}</div>
          </LinkTab>
          {props.description ? <Description>{props.description}</Description> : <br />}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <TimeDisplay>
              <ClockStyle
                name="calendarDatePicker"
                color={props.status === 'expire' ? 'red' : 'var(--agt-secondary-color-2)'}
              />
              <DueDateDisplay
                color={props.status === 'expire' ? 'red' : 'var(--agt-secondary-color-2)'}
              >
                {dateFormat(props.dueDate)}
              </DueDateDisplay>
            </TimeDisplay>
            <StarDisplay>
              {[...Array(props.priorityLevel)].map((_, i) => (
                <IconStyle key={i} name="star" color={'var(--agt-primary-text-color)'} />
              ))}
            </StarDisplay>
          </div>
        </NameDisplay>
      </ValuesName>
      <ValuesDisplay>
        <div style={{ display: 'flex' }}>
          <AvatarContainer>
            {props.contactId && (
              <Avatar type="circle" width="48px" height="48px" avatar={props.contactAvatar} />
            )}
          </AvatarContainer>
          <InfoContainer>
            <LinkTab to={`/contacts/${props.contactId}`}>
              <div className="contactName">{props.contactName}</div>
            </LinkTab>
            <small className="">{props.phoneNumber && phoneNumberFormat(props.phoneNumber)}</small>
            <small className="">{props.occupation && props.occupation}</small>
          </InfoContainer>
        </div>
        <div>
          {props.status === 'complete' ? (
            <DetailLink to={`/tasks/${props.id}`}>
              <div style={{ paddingTop: '20px' }}>รายละเอียด</div>
            </DetailLink>
          ) : (
            <EditDisplay>
              <Link
                to={{
                  pathname: `/tasks/${props.id}`,
                  state: {
                    edit: true,
                  },
                }}
                className={currentAgent?.statusTh === 'ระงับ' ? 'disabledLink' : ''}
              >
                <CustomButton
                  type="button"
                  className="secondary"
                  style={
                    props.contactStatus === 'รอยินยอม' ? { display: 'none' } : { right: '98px' }
                  }
                  disabled={currentAgent?.statusTh === 'ระงับ'}
                >
                  <IconButton name="pencil_icon" />
                </CustomButton>
              </Link>
              <CustomButton
                type="button"
                className={
                  currentAgent?.statusTh === 'ระงับ' ? 'disabledLink secondary' : 'secondary'
                }
                onClick={() => setStateDeleteClicked(true)}
                disabled={currentAgent?.statusTh === 'ระงับ'}
              >
                <IconButton name="bin" />
              </CustomButton>
            </EditDisplay>
          )}
        </div>
      </ValuesDisplay>
    </Container>
  )
}

export default Task
