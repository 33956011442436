import React, { FC, useState, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import ButtonLink from 'components/ButtonLink'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { getContacts, getOptions, getWaitConsent, patchStatus } from 'store/contactSlice'
import { RootState } from 'store/rootReducer'
import Avatar from 'components/Avatar'
import { dateFormat, dayOfWeek, phoneNumberFormat } from 'utils/helper'
import Select from 'react-select'
import Icon from 'components/Icon'
import { FilterValues, initialSearchValues, statusOptions } from './index.utils'
import WebTour from './WebTour'

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-radius: 0px;
  .isLoading {
    text-align: center;
  }
`
const SelectCustom = styled.div`
  margin: 24px 0px;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  .total {
    color: var(--agt-primary-color-1);
    margin-right: 16px;
  }
  .buttomLink {
    margin-right: 16px;
  }
  .buttomLink button {
    padding: 0;
  }
  .consentBtn {
    width: 240px;
    padding: 0 12px;
    background: var(--agt-white-color);
    border: 1px solid var(--agt-primary-color-1);
    display: flex;
    align-items: center;
  }
  .consentBtn label {
    margin-left: 6px;
    color: var(--agt-primary-color-1);
    cursor: pointer;
  }
  .consentBtn.onClick {
    background: var(--agt-light-sky-color);
  }
  .consentBtn.onClick label {
    color: var(--agt-primary-color-1);
  }
  .consentBtn:hover {
    svg path {
      fill: var(--agt-dark-sky-color);
    }
  }
`
const SearchCustom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 106px;
  padding: 0 46px;
  background: var(--agt-background-color-4);
`
const Header = styled.div`
  display: flex;
  column-gap: 24px;
  h3 {
    color: var(--agt-primary-color-1);
  }
  .hint {
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    font-size: 17px;
    color: var(--agt-primary-color-1);
  }
`
const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`
const ConsentCustom = styled(Icon)`
  width: 32px;
  height: 32px;
  &:hover {
    svg path {
      fill: var(--agt-dark-sky-color);
    }
  }
`
const InputCustom = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`
const Input = styled.input`
  width: 448px;
  border-radius: 5px 0px 0px 5px !important;
`
const SearchButton = styled.button`
  height: 48px;
  width: 97px;
  padding: 0px;
  color: var(--agt-primary-text-color);
  background: var(--agt-background-color-2);
  border: 1px solid #a0a8bc;
  border-radius: 0px 5px 5px 0px;
  &:hover {
    cursor: pointer;
    background: #d8d9e0;
    border: 1px solid #7d828e;
  }
`
const FirstColumn = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
  padding-top: 10px;
  height: 88px;
`
const NameAndOccupation = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 14px;
  .name,
  .occupation {
    width: 165px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 2px;
  }
`
const ResponsiveDiv = styled.div`
  .status {
    width: 175px;
  }
  .opportunity {
    padding-top: 12px;
    width: 180px;
    padding-right: 16px;
  }
  .event,
  .task {
    padding-top: 12px;
    width: 180px;
    padding-right: 16px;
  }
  @media (max-width: 1180px) {
    .status {
      width: 155px;
    }
    .opportunity,
    .event,
    .task {
      width: 155px;
      padding-right: 8px;
    }
  }
`
const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '10px 16px',
  }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    background: 'var( --agt-white-color)',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '200px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'white',
    position: 'absolute',
    zIndex: '2',
  }),
}
const Selector = styled(Select)`
  div[class$='ValueContainer'] {
    padding: 2px 16px;
  }
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

const SearchComponent = ({ setIsShowHint, setTextData, setSearchValue, searchValue, textData }) => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchValue({
        ...searchValue,
        page: 1,
        dataSearch: textData,
      })
    }
  }
  return (
    <SearchCustom>
      <Header>
        <h3>รายชื่อลูกค้า</h3>
        <div className="hint" onClick={() => setIsShowHint(true)}>
          <HintIcon name="hint" />
          <span>คำแนะนำ</span>
        </div>
      </Header>
      <InputCustom>
        <Input
          type="text"
          placeholder="ชื่อ, เบอร์โทร, สถานะลูกค้า, โอกาสในการขาย, งานถัดไป"
          onChange={(event) => setTextData(event.target.value)}
          onKeyPress={(event) => handleKeyPress(event)}
        />
        <SearchButton
          type="button"
          onClick={() =>
            setSearchValue({
              ...searchValue,
              page: 1,
              dataSearch: textData,
            })
          }
        >
          ค้นหา
        </SearchButton>
      </InputCustom>
    </SearchCustom>
  )
}

const SelectComponent = ({ paging, currentAgent, isAgree, setIsAgree }) => {
  return (
    <SelectCustom>
      <div className="total">ทั้งหมด {paging?.totalCount || 0} ชื่อ</div>
      <ButtonLink
        id="contact__add-contact"
        link="/contacts/new"
        label="+ สร้างรายชื่อลูกค้า"
        className="buttomLink"
        disabled={currentAgent?.statusTh === 'ระงับ'}
      />
      <button
        id="contact__view-consent"
        className={isAgree ? 'consentBtn secondary onClick' : 'consentBtn secondary'}
        type="button"
        onClick={() => {
          setIsAgree(!isAgree)
        }}
      >
        <ConsentCustom name="agree" color={'var(--agt-primary-color-1)'} />
        เฉพาะลูกค้าที่รอการยินยอม
      </button>
    </SelectCustom>
  )
}

const ContactInfo: FC = (props) => {
  const dispatch = useDispatch()
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const { contacts, paging, options, initialized } = useSelector(
    (state: RootState) => state.contacts,
  )
  const [isShowHint, setIsShowHint] = useState<boolean>(false)
  const [isAgree, setIsAgree] = useState<boolean>(false)
  const [textData, setTextData] = useState<string>('')
  const [searchValue, setSearchValue] = useState<FilterValues>(initialSearchValues)

  useEffect(() => {
    dispatch(getOptions())
  }, [dispatch])

  useEffect(() => {
    dispatch(getContacts(searchValue))
  }, [dispatch, searchValue])

  useEffect(() => {
    if (isAgree) {
      dispatch(getWaitConsent())
    } else {
      dispatch(getContacts(initialSearchValues))
    }
  }, [dispatch, isAgree])

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (storage['contacts']['contacts'] === false) {
        window.localStorage.setItem(
          'webtour',
          JSON.stringify({
            ...storage,
            contacts: { ...storage.contacts, contacts: true },
          }),
        )
        setTimeout(() => {
          setIsShowHint(true)
        }, 1000)
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: true, create: false, contactById: false },
          tasks: { tasks: false, create: false },
          calendar: { calendar: false, create: false },
          opportunities: {
            opportunities: false,
            create: false,
            opportunityById: false,
            completed: false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [setIsShowHint])

  const columns = useMemo(
    () => [
      {
        Header: 'occupation',
        accessor: 'occupation',
        canSort: false,
      },
      {
        Header: 'id',
        accessor: 'id',
        canSort: false,
      },
      {
        Header: 'ชื่อลูกค้า',
        accessor: 'name',
        canSort: true,
        Cell: ({ row }: any) => {
          return (
            <FirstColumn>
              <Avatar avatar={row.original['avatar']} type="circle" />
              <NameAndOccupation>
                <Link to={`/contacts/${row.original['id']}`} className="ghost">
                  <div className="name">{row.original['name']}</div>
                </Link>
                <small
                  className="occupation"
                  style={
                    row.original['occupation']
                      ? { color: 'var(--agt-primary-text-color)' }
                      : { color: 'var(--agt-secondary-color-1)' }
                  }
                >
                  {row.original['occupation'] ? row.original['occupation'] : 'ไม่ระบุอาชีพ'}
                </small>
                <small>{phoneNumberFormat(row.original['phoneNumber'])}</small>
              </NameAndOccupation>
            </FirstColumn>
          )
        },
      },
      {
        Header: 'สถานะลูกค้า',
        accessor: 'status',
        canSort: false,
        Cell: ({ row }) => {
          let defaultSeletor = (options.statusOptions || statusOptions).find(
            (statusOption) => statusOption.value === row.values.status,
          )
          if (defaultSeletor) {
          } else {
            defaultSeletor = { value: 'รอเสนองาน', label: 'รอเสนองาน' }
          }
          return (
            <ResponsiveDiv>
              <div className="status">
                <Selector
                  value={defaultSeletor}
                  onChange={(option) => {
                    dispatch(patchStatus(row.original.id, { status: option.value }, searchValue))
                  }}
                  options={(options.statusOptions || statusOptions).filter(
                    (statusOption) => statusOption.value !== 'รอยินยอม',
                  )}
                  styles={customSelectStyles}
                  placeholder="โปรดระบุ"
                  isDisabled={
                    defaultSeletor.value === 'รอยินยอม' || currentAgent?.statusTh === 'ระงับ'
                  }
                />
              </div>
            </ResponsiveDiv>
          )
        },
      },
      {
        Header: 'โอกาสในการขาย',
        accessor: 'opportunity',
        canSort: true,
        Cell: ({ row }: any) => (
          <ResponsiveDiv>
            <div className="opportunity">
              {row.values['opportunity'] ? (
                <div className="column">
                  <small className="lineclamp" style={{ fontWeight: 'bold' }}>
                    {row.values['opportunity'].name ? row.values['opportunity'].name : '-'}
                  </small>
                  {row.values['opportunity'].otherCount > 0 && (
                    <small style={{ color: 'var(--agt-secondary-color-1)' }}>
                      +{row.values['opportunity'].otherCount}
                    </small>
                  )}
                </div>
              ) : (
                <span
                  style={{
                    color: 'var(--agt-primary-text-color-1)',
                  }}
                >
                  -
                </span>
              )}
            </div>
          </ResponsiveDiv>
        ),
      },
      {
        Header: 'นัดหมายถัดไป',
        accessor: 'event',
        canSort: false,
        Cell: ({ row }: any) => (
          <ResponsiveDiv>
            <div className="event">
              <div className="column">
                <small className="linenowrap" style={{ fontWeight: 'bold' }}>
                  {row.original['event'].name ? row.original['event'].name : '-'}
                </small>
                {row.original['event'].startDate && (
                  <div className="row" style={{ alignItems: 'center' }}>
                    <small>
                      {`${dayOfWeek(row.original['event'].startDate)} ${dateFormat(
                        row.original['event'].startDate,
                      )}`}
                    </small>
                    <div
                      style={{
                        background: `${row.original['event'].colorCode}`,
                        borderRadius: '4px',
                        width: '20px',
                        height: '13px',
                        marginLeft: '8px',
                      }}
                    />
                  </div>
                )}
                {row.values['event'].otherCount > 0 && (
                  <small style={{ color: 'var(--agt-secondary-color-1)' }}>
                    +{row.values['event'].otherCount}
                  </small>
                )}
              </div>
            </div>
          </ResponsiveDiv>
        ),
      },
      {
        Header: 'งานถัดไป',
        accessor: 'task',
        canSort: false,
        Cell: ({ row }: any) => {
          return (
            <ResponsiveDiv>
              <div className="task">
                {row.values['task'].name ? (
                  <div className="column">
                    <small
                      className="linenowrap"
                      style={{
                        color: 'var(--agt-primary-color-text-1)',
                        fontWeight: 'bold',
                      }}
                    >
                      {row.values['task'].name}
                    </small>
                    <small
                      className="lineclamp"
                      style={
                        row.values['task'].description
                          ? {
                              color: 'var(--agt-primary-text-color-1)',
                            }
                          : { color: 'var(--agt-secondary-color-1)' }
                      }
                    >
                      {row.values['task'].description
                        ? row.values['task'].description
                        : 'ไม่ระบุรายละเอียด'}
                    </small>
                  </div>
                ) : (
                  <span
                    style={{
                      color: 'var(--agt-primary-text-color-1)',
                    }}
                  >
                    -
                  </span>
                )}
              </div>
            </ResponsiveDiv>
          )
        },
      },
      {
        Header: 'วันที่ได้ข้อมูล',
        accessor: 'createdAt',
        canSort: true,
        Cell: ({ row }: any) => dateFormat(row.values['createdAt']),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, searchValue, options],
  )

  return (
    <Container>
      <WebTour visible={isShowHint} setVisible={setIsShowHint} />
      <SearchComponent
        setIsShowHint={setIsShowHint}
        setTextData={setTextData}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        textData={textData}
      />
      <SelectComponent
        paging={paging}
        currentAgent={currentAgent}
        isAgree={isAgree}
        setIsAgree={setIsAgree}
      />
      {initialized ? (
        <p className="isLoading">loading...</p>
      ) : (
        <Table
          columns={columns}
          data={contacts || []}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          paging={paging}
        />
      )}
    </Container>
  )
}

export default ContactInfo
