import Modal from 'react-modal'
import styled from '@emotion/styled'
import React, { FC } from 'react'
import Icon from './Icon'

export const ModalCustomStyle = {
  content: {
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minHeight: '10rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'hidden',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '1000',
  },
}
const CloseButtonDiv = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  border: none;
  outline: none;
  width: 100%;
  padding: 24px;

  > div {
    cursor: pointer;
  }
`
type ChoiceProps = {
  hiddenButton?: boolean
}
const ChoiceButton = styled.div<ChoiceProps>`
  display: ${(props) => `${props.hiddenButton ? 'none' : 'flex'}`};
  width: 100%;
`
const SubmitButton = styled.button`
  width: 100%;
  height: 56px;

  background-color: var(--agt-primary-color-1);
  border-radius: 0;
`
const CancelButton = styled.button`
  width: 100%;
  height: 56px;

  background-color: white;
  color: var(--agt-primary-text-color);
  border-top: 1px solid var(--agt-secondary-color-1);
  border-radius: 0;
  :hover {
    background-color: white;
    color: var(--agt-primary-text-color);
  }
`
const Content = styled.div`
  flex: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

type ModalProps = {
  isOpen: boolean
  onCancel: Function
  onConfirm: Function
  hiddenButton?: boolean
  hiddenClose?: boolean
  className?: string
  confirmText?: string
}
const ModalContainer: FC<ModalProps> = (props) => {
  const { hiddenButton = false, hiddenClose = false } = props
  return (
    <Modal className={props.className} style={ModalCustomStyle} id="modal" isOpen={props.isOpen}>
      {!hiddenClose && (
        <CloseButtonDiv>
          <div onClick={() => props.onCancel()}>
            <Icon name="close_button" />
          </div>
        </CloseButtonDiv>
      )}
      <Content>{props.children}</Content>
      <ChoiceButton hiddenButton={hiddenButton}>
        <SubmitButton onClick={() => props.onConfirm()}>
          {props.confirmText || 'ยืนยัน'}
        </SubmitButton>
        <CancelButton onClick={() => props.onCancel()}>ยกเลิก</CancelButton>
      </ChoiceButton>
    </Modal>
  )
}
export default ModalContainer
