import React, { FC, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'

import Avatar from 'components/Avatar'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'

import { getProfile, getProileOptions } from 'store/profileSlice'
import UserType, { OptionsType } from 'types/RegisterType'
// import authorize from 'utils/authorize'
import { dateFormat } from 'utils/helper'

type ProfilePageType = {}

const Container = styled.div`
  width: 100%;
  padding: 16px 0 0;
  .editBtn {
    width: fit-content;
    margin: 0 auto;
  }
  .table {
    display: grid;
    grid-template-columns: 30% 40% auto;
  }
  .table.header {
    margin-bottom: 6px;
  }
  .table.detail {
    padding-bottom: 24px;
  }
  .table label {
    width: 90%;
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  .table.detail:last-of-type {
    padding-bottom: 0px;
  }
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const Body = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  padding: 30px 0;
`
const CustomAvatar = styled(Avatar)`
  margin: 0 auto 24px;
`
const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  grid-gap: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--agt-secondary-color-2);
  div.row {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  div.row-flex {
    display: flex;
    flex-direction: row;
    grid-gap: 24px;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: 10px;
    label {
      flex: 1;
    }
    div {
      display: flex;
      align-items: center;
      flex: 1;
    }
    .button-show {
      border-bottom: none;
    }
    .license-code {
      border-bottom: 1px solid var(--agt-secondary-color-2);
    }
  }
`
const Label = styled.label`
  margin-bottom: 8px;
  font-weight: bold;
`
const Value = styled.div`
  padding-bottom: 4px;
  border-bottom: 1px solid var(--agt-secondary-color-2);
`

const DisplayValue = ({ className, title, values }) => {
  return (
    <div className={className}>
      <Label>{title}</Label>
      <Value>{values}</Value>
    </div>
  )
}

const ProfilePage: FC<ProfilePageType> = (props) => {
  const dispatch = useDispatch()
  const profile: UserType = useSelector((state: RootState) => state.profile.profile)
  const options: OptionsType = useSelector((state: RootState) => state.profile.options)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const breadCrumbArr: Array<LinkValues> = [{ label: 'ข้อมูลส่วนตัว' }]

  useEffect(() => {
    dispatch(getProfile())
    dispatch(getProileOptions())
  }, [dispatch])

  function stringAddress(address) {
    return (
      address.housingNo +
      ' ' +
      'หมู่ ' +
      address.villageNo +
      ' ' +
      'หมู่บ้าน/อาคาร ' +
      address.building +
      ' ' +
      'ซอย/ตรอก ' +
      address.lane +
      ' ' +
      'ถนน ' +
      address.road +
      '' +
      address.subDistrict +
      ' ' +
      address.district +
      ' ' +
      address.province +
      ' ' +
      address.postcode
    )
  }

  return (
    <Container>
      <Header>ข้อมูลส่วนตัว</Header>
      <BreadCrumb links={breadCrumbArr} />
      <Body>
        <CustomAvatar width="120px" height="120px" type="circle" avatar={profile.avatar} />
        <InfoContainer>
          <DisplayValue
            className="row"
            title={'ชื่อ'}
            values={`${profile?.prefixName || ''} ${profile.firstName} ${profile.lastName}`}
          />
          <DisplayValue
            className=""
            title={'เพศ'}
            values={profile.gender ? (profile.gender === 'M' ? 'ชาย' : 'หญิง') : '-'}
          />
          <DisplayValue
            className=""
            title={'วันเกิด'}
            values={profile.birthdate ? dateFormat(profile.birthdate) : '-'}
          />
          <DisplayValue className="" title={'เลขประจำตัวประชาชน'} values={profile.idCard || '-'} />
          <DisplayValue className="" title={'เบอร์โทรศัพท์'} values={profile.phoneNumber || '-'} />
          <DisplayValue className="" title={'อีเมล'} values={profile.email || '-'} />
          <DisplayValue
            className="row"
            title={'ที่อยู่ตามบัตรประชาชน'}
            values={profile.idCardAddress ? stringAddress(profile.idCardAddress) : '-'}
          />
          <DisplayValue
            className="row"
            title={'ที่อยู่ในการส่งเอกสาร'}
            values={profile.currentAddress ? stringAddress(profile.currentAddress) : '-'}
          />
          <DisplayValue
            className=""
            title={'บริษัทประกันชีวิตต้นสังกัด'}
            values={
              profile.insuranceCompanyName
                ? options.insuranceCompanyNameOptions?.find(
                    (insuranceCompanyNameOption) =>
                      insuranceCompanyNameOption.value === profile.insuranceCompanyName,
                  )?.label
                : '-'
            }
          />
          <div />
          <div className="row">
            <div className="table header">
              <b>เลขที่ใบอนุญาต</b>
              <b>ประเภทใบอนุญาต</b>
              <b>วันหมดอายุ</b>
            </div>
            {profile.agentLicenses?.map((license, index) => (
              <div className="table detail" key={index}>
                <label>{license?.licenseCode || '0144546465646'}</label>
                <label>{license?.licenseType || 'ตัวแทนประกันวินาศภัย'}</label>
                <label>{license?.expiredAt ? dateFormat(license?.expiredAt) : '04/03/2567'}</label>
              </div>
            ))}
          </div>
        </InfoContainer>
      </Body>
      <div className="editBtn">
        <Link to="/profile/edit" className="ghost">
          <button type="button" className="secondary" disabled={currentAgent?.statusTh === 'ระงับ'}>
            แก้ไขข้อมูล
          </button>
        </Link>
      </div>
    </Container>
  )
}

export default ProfilePage
