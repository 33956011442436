import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Avatar from 'components/Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Icon from 'components/Icon'
import { currency } from 'utils/helper'
// import Thaiwiwat from '../../../../assets/images/thaiwiwat.png'
import Prb from '../../../../assets/images/Prb.png'
import { actOptions, prbInsurance as prbInsuranceType } from 'store/actSlice'

const Act = styled.div`
  width: 32%;
  border-right: 1px solid var(--agt-secondary-color-1);
  .avatar {
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    color: var(--agt-primary-color-1);
  }
  .avatar b:nth-child(3) {
    font-size: 24px;
  }
  .detail > div:first-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 40px;
    background-color: var(--agt-background-color-2);
  }
  /* .detail > div:first-of-type div:last-of-type > span {
    transform: rotate(90deg);
  } */
  .detail ul {
    padding: 18px 40px;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  .detail ul li {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

const ChevronDirect = styled.div<{ spin: boolean }>`
  > span {
    transform-origin: center;
    transform: ${(props) => (props.spin ? 'rotate(90deg)' : 'rotate(270deg)')};
    transition: all 0.5s ease;
  }
`

const ActAside: FC<{
  prbCarType: string
  prbCode: string
  companyCode: string
  prbInsurance: prbInsuranceType | null
  options: actOptions | null
}> = (props) => {
  const { prbInsurance } = props
  const [firstDetail, setFirstDetail] = useState<boolean>(true)
  const [secondDetail, setSecondDetail] = useState<boolean>(true)

  return (
    <Act>
      <section className="avatar">
        <Avatar avatar={Prb} width="120px" height="120px" type="circle" />
        {/* <b>
          พ.ร.บ.{prbCarType || ''} {prbCode ? `(${prbCode})` : ''}
        </b> */}
        {/* <b>
          ฿{' '}
          {prbCarType === 'เก๋ง'
            ? '645.21'
            : prbCarType === 'กระบะ'
            ? '967.28'
            : prbCarType === 'รถตู้'
            ? '1,182.35'
            : '0.00'}
        </b> */}
        {/* {companyCode && options && options.prbInsuranceCompanyOptions && (
          <b>
            {(options?.prbInsuranceCompanyOptions || []).find(
              (option) => option.value === companyCode,
            )?.label || ''}
          </b>
        )} */}
        <b>
          พ.ร.บ.{prbInsurance?.prbCarType} ({prbInsurance?.prbCode})
        </b>
        <b>฿ {prbInsurance?.netAmount}</b>
        <b>{prbInsurance?.companyName}</b>
      </section>
      <section className="detail">
        <div className="">
          <div>รายละเอียด</div>
          <ChevronDirect spin={firstDetail} onClick={() => setFirstDetail(!firstDetail)}>
            <Icon name="vector_right_black" />
          </ChevronDirect>
        </div>
        {firstDetail && (
          <ul>
            <li>
              <b>จำนวนอู่</b>
              <div>-{/* {currency(1700, 0, '')} */}</div>
            </li>
            <li>
              <b>ทุนประกัน</b>
              <div>-{/* {currency(1700, 0, '')} */}</div>
            </li>
            <li>
              <b>ตรวจสภาพ</b>
              <FontAwesomeIcon icon={faTimes} />
            </li>
          </ul>
        )}
      </section>
      <section className="detail">
        <div className="">
          <div>ความคุ้มครอง (พ.ร.บ.)</div>
          <ChevronDirect spin={secondDetail} onClick={() => setSecondDetail(!secondDetail)}>
            <Icon name="vector_right_black" />
          </ChevronDirect>
        </div>
        {secondDetail && (
          <ul>
            <li>
              <b>ค่ารักษาพยาบาล</b>
              <div>{currency(30000, 0)}</div>
            </li>
            <li>
              <b>
                สูญเสียอวัยวะหรือ
                <br />
                ทุพพลภาพ
              </b>
              <div>{currency(35000, 0)}</div>
            </li>
          </ul>
        )}
      </section>
    </Act>
  )
}

export default ActAside
