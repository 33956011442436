import React, { FC, useEffect, useMemo } from 'react'
import Modal from 'react-modal'
import styled from '@emotion/styled'
import Icon from './Icon'
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik'
import { PrefixComponent } from 'pages/contacts/components/ContactForm'
import { getOptions, updateShortContact } from 'store/contactSlice'
import subDays from 'date-fns/subDays'
import Gender from 'components/Inputs/Gender'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'
import get from 'lodash/get'
import { filterObjByArray } from 'utils/helper'
import { has } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { ContactType } from 'types/ContactType'

type ModalProps = {
  isOpen: boolean
  onCancel: Function
  onConfirm: Function
  contactData: ContactType
}

const ModalCustomStyle = {
  content: {
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minHeight: '10rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'unset',
    borderRadius: '4px',
    outline: 'none',
    padding: '32px',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '2',
  },
}
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  p {
    color: var(--agt-primary-color-1);
  }
`
const CloseButton = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
  cursor: pointer;
  &:hover {
    svg {
      path {
        fill: #393d43;
      }
    }
  }
`
const FormContact = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 736px;
  padding-top: 24px;
  border-top: 1px solid var(--agt-secondary-color-1);
  .groupField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .groupField .groupRow {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .groupField .groupRow .inputField {
    width: 254px;
  }
`
const SubmitButton = styled.button`
  width: 160px;
  height: 48px;
  align-self: flex-end;
`
const StyledGender = styled(Gender)`
  width: 290px;
  height: 48px;
`
const DatePickerField = styled(DatePicker)`
  width: 360px;
  height: 48px;
`
const Error = styled.div`
  color: var(--agt-error-color);
  font-size: 13px;
  display: flex;
`
const today = new Date()
const yesterday = subDays(today, 1)

const validator = (message) => (value) => {
  let error
  if (!value) {
    error = message || 'กรุณาระบุ'
  }
  return error
}
const ContactForm = (props) => {
  const { values, isSubmiting = false, forOpps = false } = props
  const dispatch = useDispatch()
  const options = useSelector((state: RootState) => state.contacts.options)
  const { setFieldValue } = useFormikContext()
  useEffect(() => {
    dispatch(getOptions())
  }, [dispatch])
  return (
    <FormContact onSubmit={props.handleSubmit}>
      <div className="groupField">
        <div className="groupRow">
          <div>คำนำหน้าชื่อ</div>
          <PrefixComponent name="prefixName" options={options.prefixNameOptions} />
          <Field hidden name="prefixName" validate={validator('กรุณาระบุคำนำหน้าชื่อ')} />
          <ErrorMessage component={Error} name="prefixName" />
        </div>
        <div className="groupRow">
          <div>ชื่อจริง</div>
          <Field
            name="firstName"
            className="inputField"
            placeholder="โปรดระบุ"
            validate={validator('กรุณาระบุชื่อ')}
          />
          <ErrorMessage component={Error} name="firstName" />
        </div>
        <div className="groupRow">
          <div>นามสกุล</div>
          <Field
            name="lastName"
            className="inputField"
            placeholder="โปรดระบุ"
            validate={validator('กรุณาระบุนามสกุล')}
          />
          <ErrorMessage component={Error} name="lastName" />
        </div>
      </div>
      <div className="groupField">
        <div className="groupRow">
          <div>เพศ</div>
          <StyledGender name="gender" />
          <Field hidden name="gender" validate={validator('กรุณาระบุเพศ')} />
          <ErrorMessage component={Error} name="gender" />
        </div>
        <div className="groupRow">
          <div>วันเกิด</div>
          <DatePickerField
            value={values.birthdate || ''}
            maxDate={yesterday}
            showSelector
            onChange={(value) => setFieldValue('birthdate', value)}
          />
          <Field hidden name="birthdate" validate={validator('กรุณาระบุวันเกิด')} />
          <ErrorMessage component={Error} name="birthdate" />
        </div>
      </div>
      <SubmitButton type="submit" disabled={isSubmiting}>
        {isSubmiting ? 'กำลังดำเนินการ' : forOpps ? 'บันทึกและถัดไป' : 'บันทึก'}
      </SubmitButton>
    </FormContact>
  )
}
const NewContact = (props) => {
  const dispatch = useDispatch()
  const { contactData = {}, forOpps = false, isOpps, isClose, getContactByIdAgain } = props
  const id: string = useMemo(() => get(contactData, 'id', ''), [contactData])
  const contactForUpdate = useMemo(
    () =>
      filterObjByArray(contactData, [
        'id',
        'firstName',
        'lastName',
        'gender',
        'birthdate',
        'prefixName',
        'nickname',
        'phoneNumber',
      ]),
    [contactData],
  )
  return (
    <Formik
      initialValues={contactForUpdate}
      enableReinitialize
      onSubmit={(values) => {
        const modifiedValues = {
          ...values,
          birthdate: has(values, 'birthdate') ? get(values, 'birthdate').toString() : null,
        }
        if (forOpps) {
          dispatch(updateShortContact(id, modifiedValues))
          setTimeout(() => {
            getContactByIdAgain()
            isClose()
          }, 1500)
          isOpps()
        } else {
          dispatch(updateShortContact(id, modifiedValues))
          setTimeout(() => {
            document.location.reload()
          }, 1500)
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <ContactForm
          handleSubmit={handleSubmit}
          values={contactForUpdate}
          isSubmiting={isSubmitting}
          forOpps={forOpps}
        />
      )}
    </Formik>
  )
}

export const ContactForOpportunity = (props) => {
  const { isOpen, onCancel, setModalOpen, contactData, getContactByIdAgain } = props
  return (
    <Modal style={ModalCustomStyle} id="modal" isOpen={isOpen}>
      <Header>
        <h4 style={{ color: 'var(--agt-primary-color-1)' }}>
          กรอกข้อมูลผู้มุ่งหวังเพื่อดำเนินการต่อ
        </h4>
        <CloseButton onClick={() => onCancel(false)}>
          <Icon name="close_button" />
        </CloseButton>
      </Header>
      <NewContact
        contactData={contactData}
        forOpps={true}
        isClose={() => onCancel(false)}
        isOpps={() => setModalOpen(true)}
        getContactByIdAgain={getContactByIdAgain}
      />
    </Modal>
  )
}

const ContactModal: FC<ModalProps> = (props) => {
  const { isOpen, onCancel, contactData } = props
  return (
    <Modal style={ModalCustomStyle} id="modal" isOpen={isOpen}>
      <Header>
        <p>กรอกข้อมูลเพิ่มเติมเพื่อดำเนินงานต่อ</p>
        <CloseButton onClick={() => onCancel(false)}>
          <Icon name="close_button" />
        </CloseButton>
      </Header>
      <NewContact contactData={contactData} />
    </Modal>
  )
}

export default ContactModal
