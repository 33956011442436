import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { ErrorMessage, Field as FormikField, Form, Formik } from 'formik'
import { useHistory } from 'routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'

import { changePassword } from 'store/profileSlice'
import HistoryType from 'types/HistoryType'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { passwordValidator } from 'utils/helper'
import Icon1 from 'components/Icon'
import validation from './form.validation'

type ResetPasswordPageType = {
  oldPassword: string
  newPassword: string
  newPasswordConfirmation: string
}
// type ErrorBarType = {
//   hidden?: boolean
// }
type FieldValueProps = {
  isError?: boolean
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 17px;
`
const Header = styled.h3`
  margin: 16px 0 10px;
`
// const ErrorBar = styled.div<ErrorBarType>`
//   position: absolute;
//   z-index: 1;
//   top: 80px;
//   left: 0;

//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: ${(props) => (props.hidden ? '0px' : '50px')};

//   color: white;
//   background: var(--agt-error-color);
//   transition: height 0.5s;
// `
// const FormContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   padding: 50px 0;
// `
const FormValue = styled(Form)`
  position: relative;
  margin: 40px auto;
  width: 100%;
  max-width: 352px;
`
const SubmitButton = styled.button`
  width: 100%;
  margin-bottom: 24px;
`
const FieldContainer = styled.div`
  margin: 0 0 32px;
`
const Label = styled.div`
  margin: 0 0 8px;
`
const InputContainer = styled.div`
  position: relative;
`
const FieldValue = styled(FormikField)<FieldValueProps>`
  width: 352px;
  padding: 0 40px 0 16px !important;
  border: ${(props) =>
    props.isError
      ? '1px solid var(--agt-error-color)'
      : '1px solid var(--agt-secondary-color-1)'} !important;
`
const Icon = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;

  cursor: pointer;
`
const Error = styled.div`
  color: var(--agt-error-color);
`
const ValidatorContainer = styled.div`
  position: absolute;
  top: 34px;
  left: calc(100% + 50px);

  width: fit-content;
  padding: 18px 30px;

  border-radius: 4px;
  border: 1px solid var(--agt-background-color-1);
`
const Title = styled.div`
  margin-bottom: 8px;
`
const Row = styled.div`
  display: flex;
  align-items: center;
`
const CheckIcon = styled(Icon1)`
  height: 20px;
  margin-right: 10px;

  path {
    fill: var(--agt-secondary-text-color);
  }

  &.success {
    path {
      fill: var(--agt-success-color);
    }
  }
`

const Field = (props) => {
  const { label, field, form } = props
  const { touched, errors } = form

  const [showPassword, setShowPassword] = useState<boolean>(false)

  const togglePassword = () => setShowPassword(!showPassword)
  return (
    <FieldContainer>
      <Label>{label}</Label>
      <InputContainer>
        <FieldValue
          {...field}
          type={showPassword ? 'text' : 'password'}
          placeholder="โปรดระบุ"
          isError={
            Object.keys(touched).find((key) => key === field.name) &&
            Object.keys(errors).find((key) => key === field.name)
          }
        />
        <Icon onClick={() => togglePassword()}>
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            color="#ACB1BF"
            style={{ fontSize: '18px' }}
          />
        </Icon>
      </InputContainer>
      <ErrorMessage component={Error} name={field.name} />
    </FieldContainer>
  )
}

const ResetPasswordPage: FC = (props) => {
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/setting', label: 'ตั้งค่าบัญชี' },
    { label: 'เปลี่ยนรหัสผ่านใหม่' },
  ]
  const history: HistoryType = useHistory()

  const dispatch = useDispatch()

  return (
    <Container>
      <Header>เปลี่ยนรหัสผ่านใหม่</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />

      <Formik
        initialValues={{ oldPassword: '', newPassword: '', newPasswordConfirmation: '' }}
        validationSchema={validation}
        onSubmit={(values: ResetPasswordPageType) => {
          dispatch(changePassword(values, () => history.push('/setting')))
        }}
      >
        {(props) => {
          const { handleSubmit, isValid, values, touched } = props
          const { hasLowercase, hasUppercase, hasDigit } = passwordValidator(values.newPassword)
          return (
            <FormValue id="123" onSubmit={handleSubmit}>
              <FormikField name="oldPassword" label="รหัสผ่านเดิม" component={Field} />
              <FormikField name="newPassword" label="รหัสผ่านใหม่" component={Field} />
              <FormikField
                name="newPasswordConfirmation"
                label="ยืนยันรหัสผ่านใหม่"
                component={Field}
              />
              <SubmitButton
                type="submit"
                disabled={!isValid || !touched.newPassword || !touched.newPasswordConfirmation}
              >
                ยืนยันการเปลี่ยนรหัสผ่านใหม่
              </SubmitButton>

              <ValidatorContainer>
                <Title>รหัสผ่านขั้นต่ำ 8 ตัวอักษร ต้องประกอบด้วย</Title>
                <Row>
                  <CheckIcon name="check-circle" className={hasUppercase ? 'success' : ''} />
                  A-Z ตัวพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร
                </Row>
                <Row>
                  <CheckIcon name="check-circle" className={hasLowercase ? 'success' : ''} />
                  a-z ตัวพิมพ์เล็กอย่างน้อย 1 ตัวอักษร
                </Row>
                <Row>
                  <CheckIcon name="check-circle" className={hasDigit ? 'success' : ''} />
                  0-9 อย่างน้อย 1 ตัวอักษร
                </Row>
              </ValidatorContainer>
            </FormValue>
          )
        }}
      </Formik>
    </Container>
  )
}

// const ResetPasswordPage: FC = (props) => {
//   const breadCrumbArr: Array<LinkValues> = [
//     { path: '/setting', label: 'ตั้งค่าบัญชี' },
//     { label: 'เปลี่ยนรหัสผ่านใหม่' },
//   ]
//   const [errorMessage, setErrorMessage] = useState<string>('')
//   const [isLoading, setLoading] = useState<boolean>(false)
//   return (
//     <Container>
//       <Header>เปลี่ยนรหัสผ่านใหม่</Header>
//       <BreadCrumb links={breadCrumbArr} hasBackToRoot />

//       <ErrorBar hidden={!errorMessage}>
//         <FontAwesomeIcon icon={faTimes} style={{ marginRight: '12px' }} />
//         <span>{errorMessage}</span>
//       </ErrorBar>

//       <FormContainer>
//         <Formik
//           initialValues={{
//             oldPassword: '',
//             newPassword: '',
//             newPasswordConfirmation: '',
//           }}
//           validationSchema={validation}
//           onSubmit={(values: ResetPasswordPageType) => {
//             // setupPassword(values, () => setSetupSuccess(true))
//           }}
//         >
//           {(props) => {
//             const { isValid, values, touched } = props
//             return (
//               <FormValue>
//                 <FormikField name="oldPassword" label="รหัสผ่านเดิม" component={Field} />
//                 <FormikField name="newPassword" label="รหัสผ่านใหม่" component={Field} />
//                 <FormikField
//                   name="newPasswordConfirmation"
//                   label="ยืนยันรหัสผ่านใหม่"
//                   component={Field}
//                 />
//                 <SubmitButton
//                   type="submit"
//                   disabled={
//                     !isValid ||
//                     !touched.newPassword ||
//                     !touched.newPasswordConfirmation ||
//                     isLoading
//                   }
//                 >
//                   ยืนยันการเปลี่ยนรหัสผ่านใหม่
//                 </SubmitButton>
//                 <Validator
//                   values={{
//                     password: values.newPassword,
//                     passwordConfirmation: values.newPasswordConfirmation,
//                   }}
//                 />
//               </FormValue>
//             )
//           }}
//         </Formik>
//       </FormContainer>
//     </Container>
//   )
// }

export default ResetPasswordPage
