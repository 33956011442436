import { InsurancePortType } from 'types/InsurancePortType'

const initialValues: InsurancePortType = {
  ownerName: '',
  ownerGender: '',
  ownerAge: 0,
  insurancePattern: 'personal',
  insuranceType: '',
  policyType: '',
  insuranceCompanyName: '',
  name: '',
  policyNo: '',
  startPolicyAt: '',
  endPolicyAt: '',
  sumInsured: '',
  premium: '',
  paymentType: '',
  yieldToMaturityRate: '',
  payPeriodType: '',
  payPeriodValue: 0,
  remark: '',
  policyDocumentId: '',
  specialFields: {
    premiumAllowance: 0,
    residualValue: 0,
    rateOfReturn: 0,
    adDd: 0,
    pd: 0,
    ma: 0,
    mc: 0,
    bb: 0,
    me: 0,
    ph: 0,
    nd: 0,
    pp: 0,
    fcg: 0,
    mlb: 0,
    hu: 0,
  },
  insuranceRiders: [{ riderType: '', compensation: 0, premium: 0, peroidYearNumber: 0 }],
  purpose: '',
  state: '',
}

export default initialValues
