import React, { FC, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { NotificationType } from 'types/NotificationType'
import { useHistory } from 'routes'
import HistoryType from 'types/HistoryType'
import moment from 'moment'
import { dateFormat } from 'utils/helper'
import Icon from 'components/Icon'

interface CustomNotificationType extends NotificationType {
  setOpenToConverse: Function
  subActionType?: string
}
type MainContainerProps = {
  isRead: boolean
}

const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 72px;
  background: ${(props) => (props.isRead ? '#fffff' : ' #eff1f5')};
  padding-left: 46px;
  padding-right: 56px;
  padding-top: 10px;
  cursor: pointer;
  border-bottom: 1px solid #e6e8ec;
`
const DetailContainer = styled.div`
  display: flex;
`
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 8px;
  .detail {
  }
  .detail b {
    margin-right: 5px;
  }
`
const ExpandeDetail = styled.div`
  display: flex;
  justify-content: space-between;
`
const OpportunityText = styled.div`
  margin-right: 5px;
`
const TimeText = styled.div`
  font-size: 17px;
  white-space: nowrap;
  text-align: right;
  color: #7d828e;
`

const NotificationItem: FC<CustomNotificationType> = (props) => {
  const {
    id,
    contactId = '',
    actionId = '',
    actionName = '',
    actionType = '',
    subActionType = '',
    latedTask = 0,
    currentDayTask = 0,
    setOpenToConverse,
    info,
  } = props
  const eventStartDate = info?.eventStartDate || ''
  let history: HistoryType = useHistory()

  const textType = useMemo(() => {
    switch (actionType) {
      case 'event':
        return 'นัดหมาย'
      case 'task':
        return 'งานที่ต้องทำ'
      case 'document':
        return 'ตรวจสอบเอกสาร'
      case 'insurance_port':
        return 'คำสั่งซื้อ'
      default:
        return 'โอกาสในการขาย'
    }
  }, [actionType])

  const eventDate = useMemo(() => {
    const dateToday = moment().format('DD-MM-YYYY')
    let initial = eventStartDate
    let final = moment(dateToday, 'DD-MM-YYYY')
    let subDay = final.diff(initial, 'days')
    if (subDay > -6 && subDay < 6) {
      return `${moment(eventStartDate).calendar()} น.`
    } else {
      return `${
        moment(eventStartDate).format('DD/MM/') +
        (parseInt(moment(eventStartDate).format('YYYY')) + 543)
      } ${moment(eventStartDate).locale('en').format('H:mm') + ' น.'}`
    }
  }, [eventStartDate])

  const actionTypeIcon = useMemo(() => {
    switch (actionType) {
      case 'opportunity':
        return <Icon name="NotiOpportunity" />
      case 'document':
        return <Icon name="NotiDocument" />
      case 'task':
        return <Icon name="NotiTask" />
      case 'insurance_port':
        return <Icon name="NotiOrder" />
      default:
        return <Icon name="NotiEvent" />
    }
  }, [actionType])

  const onClickItem = useCallback(() => {
    setOpenToConverse({ id, actionType })
    switch (actionType) {
      case 'event':
        history.push(`/calendar/${actionId}`)
        window.location.reload()
        break
      case 'task':
        history.push(`/tasks`)
        break
      case 'document':
        history.push(`/profile/documents`)
        break
      case 'opportunity':
        if (['won', 'lose'].includes(info?.opportunityStatus || '')) {
          history.push(`/opportunities/?viewpoint=completed`)
        } else if (subActionType === 'unassign_order') {
        } else {
          history.push(`/opportunities/${actionId}`)
        }
        break
      case 'insurance_port':
        history.push(`/contacts/${contactId}/insurance_portfolio/${actionId}`)
        break
      default:
        break
    }
  }, [setOpenToConverse, id, actionType, actionId, info, contactId, subActionType, history])

  return (
    <MainContainer isRead={props.isRead || false} onClick={onClickItem}>
      <DetailContainer>
        {actionTypeIcon}
        <ListContainer>
          <div className="detail">
            <b>{textType}</b>
            {actionType === 'task' && currentDayTask > 0 ? (
              <u>{`วันนี้คุณมี ${currentDayTask} งานที่ต้องทำ`}</u>
            ) : actionType === 'document' ? (
              <u>{actionName}</u>
            ) : actionType === 'insurance_port' ? (
              <>
                {info?.orderNo && <label className="textType">{` #${info?.orderNo} `}</label>}
                <label className="contactName">
                  {actionName}
                  {props?.contactName && (
                    <>
                      {` ของ `}
                      <u>{props.contactName}</u>
                    </>
                  )}
                </label>
              </>
            ) : actionType === 'opportunity' && subActionType === 'assign_order' ? (
              <label className="">{actionName}</label>
            ) : (
              <label>
                {actionName}
                {['event', 'document', 'opportunity', 'insurance_port'].includes(
                  actionType || '',
                ) &&
                  props?.contactName && (
                    <>
                      {` : `}
                      <u>{props.contactName}</u>
                    </>
                  )}
              </label>
            )}
          </div>
          <ExpandeDetail>
            {actionType === 'opportunity' && (
              <>
                {subActionType === 'assign_order' ? (
                  <div>
                    <label>{`มีคำสั่งซื้อ ${info?.orderNo} มาใหม่จากผู้แนะนำ `}</label>
                    <b>{info?.orderStatus}</b>
                  </div>
                ) : (
                  <>
                    {!['สถานะ', 'Action Code'].includes(info?.orderStatus || '') ? (
                      <OpportunityText>
                        {`มีการอัปเดตสถานะเป็น `}
                        <b>{info?.orderStatus}</b>
                      </OpportunityText>
                    ) : (
                      <OpportunityText>
                        {`มีการอัปเดต `}
                        <b>{info?.orderStatus}</b>
                        {` ของคำสั่งซื้อ #${info?.orderNo}`}
                      </OpportunityText>
                    )}
                  </>
                )}
              </>
            )}
            {actionType === 'event' && eventDate}
            {actionType === 'document' && (
              <>
                {subActionType === 'update_document_file' ? (
                  <label>มีอัปเดตโปรดตรวจสอบรายละเอียดเพื่อดำเนินการต่อ</label>
                ) : (
                  <label>
                    {`ของท่านกำลังจะหมดอายุวันที่ `}
                    <b>{info?.expired ? dateFormat(info?.expired) : dateFormat(new Date())}</b>
                    {` โปรดตระเตรียมการต่ออายุล่วงหน้าก่อนบัญชีของท่านจะถูกระงับ `}
                  </label>
                )}
              </>
            )}
            {actionType === 'task' && latedTask > 0 && (
              <label>{`มี ${latedTask} งานที่เกินกำหนด`}</label>
            )}
            {actionType === 'insurance_port' && (
              <>
                {subActionType === 'expired' && info?.expired && (
                  <div>
                    <b>{info.massage}</b>
                    <label>{` ในวันที่ ${dateFormat(info.expired)} `}</label>
                  </div>
                )}
                {subActionType === 'payment' && info?.paymentDate && (
                  <div>
                    <b>{info.massage}</b>
                    <label>{` ในวันที่ ${dateFormat(info.paymentDate)} `}</label>
                  </div>
                )}
              </>
            )}
            <DetailContainer style={{ justifyContent: 'flex-end' }}>
              <TimeText>{moment(props.alertAt).fromNow()}</TimeText>
            </DetailContainer>
          </ExpandeDetail>
        </ListContainer>
      </DetailContainer>
    </MainContainer>
  )
}

export default NotificationItem
