import React, { Fragment } from 'react'
import { dateFormat } from 'utils/helper'
import { Field as FormikField, useField, FieldArray, useFormikContext } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { OptionType } from 'types/RegisterType'
import UploadFile from './UploadFile'
import {
  HoverInfo,
  CustomIcon,
  FieldContainer,
  Error,
  Selector,
  LicenseDelete,
  customSelectStyles,
} from './AffiliateLicense.styles'

const FieldInput = (props) => {
  const { name, placeholder, onChange, className = '', ...rest } = props
  const [field, meta] = useField(name)
  return (
    <FieldContainer>
      <FormikField
        name={name}
        className={className}
        placeholder={placeholder}
        {...rest}
        onChange={(e) => {
          field.onChange(e)
          if (onChange) {
            onChange(e.target.value)
          }
        }}
        style={meta.touched && meta.error ? { borderColor: 'var(--agt-error-color)' } : {}}
      />
      {meta.touched && meta.error && <Error className="small error">{meta.error}</Error>}
    </FieldContainer>
  )
}

const UploadFileLicenses = ({ title = '', status = '', small = true, selectType = [''] }) => {
  const [, , helpers] = useField(title)
  const { setValue } = helpers
  const onChange = (value) => {
    setValue(value)
  }
  return (
    <UploadFile
      title={title}
      status={status}
      onChange={onChange}
      small={small}
      selectType={selectType}
    />
  )
}

const OptionsComponent = (props) => {
  const { options, className = '', isDisabled } = props
  const [, meta, helpers] = useField(props.field.name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <Selector
      value={defaultSeletor ? defaultSeletor : ''}
      className={className}
      onChange={(option: OptionType) => {
        setValue(option.value)
      }}
      options={options}
      styles={customSelectStyles}
      placeholder="โปรดระบุ"
      isDisabled={isDisabled}
    />
  )
}

const AffiliateLicense = (props) => {
  const { setInfoFile, infoFile } = props
  const { setFieldValue } = useFormikContext()
  const { agentLicenses = [], isApproved = null, licenseTypeOptions = [] } = props
  return (
    <Fragment>
      <HoverInfo className="affiliate">
        <CustomIcon className="note_docs" name="note_docs" />
        <label className="">เลขที่ใบอนุญาต</label>
        <CustomIcon className="infoI" name="I" color={'var(--agt-primary-color-1)'} />
        <div className="infoThird suggest">รองรับไฟล์ PDF, JPG, JPEG, PNG</div>{' '}
      </HoverInfo>
      <div
        className="subbody"
        style={{ alignItems: 'start', color: 'var(--agt-secondary-color-1)' }}
      >
        <div style={{ paddingLeft: 16 }}>เลขที่</div>
        <div>วันที่อัปเดต</div>
        <div>คำแนะนำ</div>
      </div>
      <FieldArray
        name="agentLicenses"
        render={(arrayHelpers) => (
          <>
            {agentLicenses.map((license, index: number) => {
              return (
                <div key={index} className="agentLicenses subbody" style={{ alignItems: 'start' }}>
                  <div className="upload" style={{ position: 'relative', alignItems: 'start' }}>
                    <FieldInput
                      name={`agentLicenses[${index}].licenseCode`}
                      className="licenseCode"
                      placeholder="โปรดระบุ"
                      disabled={license?.status === 'processing' || license?.status === 'approved'}
                      maxLength="10"
                      onChange={(value) => {
                        if (value) {
                          const chars = [...value]
                          const modified = chars
                            .filter((element) => /^[0-9]*$/.test(element))
                            .join('')
                          setFieldValue(`agentLicenses[${index}].licenseCode`, modified)
                        }
                      }}
                    />
                    <FormikField
                      name={`agentLicenses[${index}].licenseType`}
                      className="licenseType"
                      component={OptionsComponent}
                      options={licenseTypeOptions}
                      isDisabled={license.status !== 'new'}
                    />
                    <UploadFileLicenses
                      title={`agentLicenses[${index}].licenseImageId`}
                      status={license.status}
                      selectType={['PDF', 'JPG', 'JPEG', 'PNG']}
                    />
                    {['rejected', 'new'].includes(license?.status) && (
                      <LicenseDelete onClick={() => arrayHelpers.remove(index)}>
                        <FontAwesomeIcon icon={faTimes} color="var(--agt-primary-text-color)" />
                      </LicenseDelete>
                    )}
                  </div>
                  <small>{license.updatedAt ? dateFormat(license.updatedAt) : '-'}</small>
                  <div className="" style={{ fontSize: 13 }}>
                    {license.description ? license.description : '-'}
                  </div>
                </div>
              )
            })}
            {
              // isApproved !== 'approved' && agentLicenses.length < 5 &&
              isApproved !== 'approved' && (
                <div className="addLicense">
                  <button
                    type="button"
                    className="secondary"
                    onClick={() =>
                      arrayHelpers.push({
                        licenseCode: '',
                        licenseImageId: '',
                        licenseType: '',
                        status: 'new',
                      })
                    }
                  >
                    + เพิ่มใบอนุญาต
                  </button>
                </div>
              )
            }
          </>
        )}
      />
    </Fragment>
  )
}

export default AffiliateLicense
