import React, { useState, useEffect, Fragment, useCallback } from 'react'
import styled from '@emotion/styled'

import { Formik } from 'formik'
import { Link } from 'routes'
// import Api from 'api'
import { get } from 'lodash'

import Consent from './Consent'
// import ResgisterPolicy from './Policy'
import Form from 'components/Form/GuestForm'
import Icon from 'components/Icon'
import validate from './validate'

import SetStateType from 'types/SetStateType'

import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useDispatch } from 'react-redux'
import { checkAccount, createUser } from 'store/appSlice'

type termsAndConditionsType = {
  agreementType: string
  beginDate: string
  consentBy: string
  contentDisplay: string
  id: number | string
  name: string
  status: string
  updateBy: string
  updatedAt: string
  version: string
}
// type agreementsType = {
//   id: number
//   consents: Array<consentsType> | null
// }
type RegisterPageType = {
  className?: string
  onSuccess: () => void
  setEmail: SetStateType<string>
  termsAndConditions: termsAndConditionsType | null
  pdpa: termsAndConditionsType | null
  agreement: Array<{
    id: string
    consents: Array<{
      name: string
      key: string
      forcedConsent: boolean
    }>
  }> | null
  mockupTerm?: any
  mockupPDPA?: any
}
type consentsType = {
  name: string
  key: string
  forceConsent: boolean
  value: boolean
}
// type userType = {
//   firstName: string
//   lastName: string
//   phoneNumber: string
//   email: string
//   type: string
//   facebook?: {}
//   google?: {}
//   agreements: Array<agreementsType>
// }

const Container = styled.div`
  width: 100%;
  /* max-width: 736px; */
  padding: 50px 0;
  .header {
    width: 100%;
    height: 36px;
    margin-bottom: 46px;
    display: flex;
    justify-content: center;
  }
  .header .headerRegister {
    width: 48%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .primary {
    }
    .secondary {
      color: var(--agt-secondary-color-1) !important;
      text-decoration: none;
    }
    .center {
      border-left: 1px solid var(--agt-secondary-color-1);
      height: 100%;
    }
  }
  .header .headerWithSocial {
    display: flex;
    align-items: center;
  }
  .submit {
    display: block;
    width: 352px;
    margin: 25px auto 0;
  }
`
const IconSignIn = styled(Icon)`
  margin-top: 10px;
  margin-right: 10px;
`
const CustomIcon = styled(Icon)`
  div {
    width: 28px;
    height: 28px;
    margin-right: 24px;
    svg {
      width: 28px;
      height: 28px;
    }
  }
`
const ContainerSignIn = styled.div`
  width: 352px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 24px;
  .suggest {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  .suggest .line {
    border: 0px;
    border-top: 1px solid var(--agt-secondary-color-1);
    height: 1px;
    width: 150px;
  }
  .google {
    width: 100%;
    height: 48px;
    margin-bottom: 24px;
    border: 1px solid var(--agt-primary-color-1);
    border-radius: 5px;
    background: var(--agt-white-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .label {
      color: var(--agt-primary-color-1);
    }
    :disabled {
      border: 1px solid var(--agt-disable-color-1);
      background: var(--agt-white-color);
      cursor: no-drop;
      .label {
        color: var(--agt-disable-color-2);
      }
      .icon {
        opacity: 0.5;
      }
    }
  }
  .facebook {
    width: 100%;
    height: 48px;
    margin-bottom: 24px;
    border: 1px solid var(--agt-primary-color-1);
    border-radius: 5px;
    background: var(--agt-white-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .label {
      color: var(--agt-primary-color-1);
    }
    :disabled {
      border: 1px solid var(--agt-disable-color-1);
      background: var(--agt-white-color);
      cursor: no-drop;
      .label {
        color: var(--agt-disable-color-2);
      }
      .icon {
        opacity: 0.5;
      }
    }
  }
`

// const duplicateEmail = async (
//   values: { type: string; email: string; token: string },
//   setForm: Function,
// ) => {
//   try {
//     await Api.get('/auth/check_account', values)
//     setForm()
//   } catch (err) {
//     alert('มีบัญชีผู้ใช้อยู่ในระบบแล้ว')
//   }
// }

const SignInWithSocial = (props) => {
  const { setIsResp, setIsSocial, waitForCheckAccount } = props
  return (
    <ContainerSignIn>
      <div className="suggest">
        <hr className="line" />
        หรือ
        <hr className="line" />
      </div>
      <GoogleLogin
        clientId={`${process.env.REACT_APP_APPID_GOOGLE}`}
        render={(renderProps) => (
          <button className="google secondary" type="button" onClick={renderProps.onClick}>
            <IconSignIn name="google" />
            ลงทะเบียนและผูกบัญชีด้วย Google
          </button>
        )}
        onSuccess={(res) => {
          // duplicateEmail(
          //   {
          //     type: 'google',
          //     email: get(res, 'profileObj.email', ''),
          //     token: get(res, 'tokenId', ''),
          //   },
          //   () => {
          //     setIsResp(res)
          //     setIsSocial(1)
          //   },
          // )
          waitForCheckAccount(
            {
              type: 'google',
              email: get(res, 'profileObj.email', ''),
              token: get(res, 'tokenId', ''),
            },
            () => {
              setIsResp(res)
              setIsSocial(1)
            },
          )
        }}
        onFailure={(err) => console.log('onFailure : ', err)}
      />
      <FacebookLogin
        appId={`${process.env.REACT_APP_APPID_FACEBOOK}`}
        autoLoad={false}
        callback={(res) => {
          if (res.userID) {
            // duplicateEmail(
            //   {
            //     type: 'facebook',
            //     email: get(res, 'email', ''),
            //     token: get(res, 'accessToken', ''),
            //   },
            //   () => {
            //     setIsResp(res)
            //     setIsSocial(2)
            //   },
            // )
            waitForCheckAccount(
              {
                type: 'facebook',
                email: get(res, 'email', ''),
                token: get(res, 'accessToken', ''),
              },
              () => {
                setIsResp(res)
                setIsSocial(2)
              },
            )
          }
        }}
        fields="name, email"
        render={(renderProps) => (
          <button className="facebook secondary" type="button" onClick={renderProps.onClick}>
            <IconSignIn name="facebook-signin" />
            ลงทะเบียนและผูกบัญชีด้วย Facebook
          </button>
        )}
      />
    </ContainerSignIn>
  )
}

const RegisterForm = (props) => {
  const {
    values,
    touched,
    isValid,
    submitForm,
    setFieldValue,
    isSocial,
    setIsSocial,
    termsAndConditions,
    pdpa,
    waitForCheckAccount,
  } = props
  const [isResp, setIsResp] = useState<any | null>({})
  const [isCheckedTerm, setIsCheckedTerm] = useState<boolean>(false)
  const [isCheckedPDPA, setIsCheckedPDPA] = useState<boolean>(false)

  useEffect(() => {
    if (isSocial === 1) {
      setFieldValue('type', 'google')
      setFieldValue('google', { email: isResp.profileObj.email, tokenId: isResp.tokenId })
      if (values.firstName === '') {
        setFieldValue('firstName', isResp.profileObj.givenName)
      }
      if (values.lastName === '') {
        setFieldValue('lastName', isResp.profileObj.familyName)
      }
      if (values.email === '') {
        setFieldValue('email', isResp.profileObj.email)
      }
    } else if (isSocial === 2) {
      setFieldValue('type', 'facebook')
      setFieldValue('facebook', { email: isResp.email, accessToken: isResp.accessToken })
      if (values.firstName === '') {
        setFieldValue('firstName', isResp.name.trim().split(' ')[0])
      }
      if (values.lastName === '') {
        setFieldValue('lastName', isResp.name.trim().split(' ')[1])
      }
      if (values.email === '') {
        setFieldValue('email', isResp.email)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocial])
  return (
    <Fragment>
      <Form />
      <Consent
        consent={termsAndConditions}
        type="terms"
        values={values.terms}
        isChecked={isCheckedTerm}
        setIsChecked={setIsCheckedTerm}
      />
      <Consent
        consent={pdpa}
        type="pdpa"
        values={values.pdpa}
        isChecked={isCheckedPDPA}
        setIsChecked={setIsCheckedPDPA}
      />
      <button
        className="submit"
        type="submit"
        disabled={!isValid || Object.keys(touched).length === 0 || !isCheckedTerm || !isCheckedPDPA}
        onClick={submitForm}
      >
        ยืนยันข้อมูล
      </button>
      {!isSocial && (
        <SignInWithSocial
          isSocial={isSocial}
          setIsResp={setIsResp}
          setIsSocial={setIsSocial}
          waitForCheckAccount={waitForCheckAccount}
        />
      )}
    </Fragment>
  )
}

const RegisterPage = ({
  className = '',
  onSuccess,
  setEmail,
  termsAndConditions,
  pdpa,
  agreement,
  mockupTerm,
  mockupPDPA,
}: RegisterPageType) => {
  const dispatch = useDispatch()
  const [isSocial, setIsSocial] = useState<number>(0)
  const waitForCheckAccount = useCallback(
    (values, cb) => {
      dispatch(checkAccount(values, cb))
    },
    [dispatch],
  )

  return (
    <Container className={className}>
      <h4 className="header">
        {isSocial === 1 ? (
          <div className="headerWithSocial">
            <CustomIcon name="google" />
            <label>ลงทะเบียนและผูกบัญชีด้วย Google</label>
          </div>
        ) : isSocial === 2 ? (
          <div className="headerWithSocial">
            <CustomIcon name="facebook-signin" />
            <label>ลงทะเบียนและผูกบัญชีด้วย Facebook</label>
          </div>
        ) : (
          <div className="headerRegister">
            <Link to="/login" className="secondary">
              เข้าสู่ระบบ
            </Link>
            <div className="center" />
            <div className="primary">ลงทะเบียน</div>
          </div>
        )}
      </h4>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          type: '',
          facebook: {},
          google: {},
          terms: mockupTerm,
          pdpa: mockupPDPA,
          agreements: agreement,
        }}
        onSubmit={(values) => {
          let newAgreements: Array<{ id: string; consents: [] }> = []
          let newTerms = values?.terms ? { ...values.terms } : {}
          let newPDPA = values?.pdpa ? { ...values.pdpa } : {}
          if (values.terms) newAgreements.push(newTerms)
          if (values.pdpa) newAgreements.push(newPDPA)
          let modifiedValues = { ...values, agreements: newAgreements }
          delete modifiedValues.pdpa
          delete modifiedValues.terms
          dispatch(createUser(modifiedValues, onSuccess, setEmail))
        }}
        validate={validate}
      >
        {(props) => {
          return (
            <RegisterForm
              {...props}
              isSocial={isSocial}
              setIsSocial={setIsSocial}
              termsAndConditions={termsAndConditions}
              pdpa={pdpa}
              waitForCheckAccount={waitForCheckAccount}
            />
          )
        }}
      </Formik>
    </Container>
  )
}

export default RegisterPage
