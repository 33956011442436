import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Pages from 'pages'
import { useLocation } from 'routes'
import Layout from 'components/Layout'
import { RootState } from 'store/rootReducer'
import { fetchCurrentAgent } from 'store/appSlice'
import { go2Top } from 'utils/helper'
import SwiperCore, { Pagination, Autoplay } from 'swiper'

import FirstTimeModal from 'components/FirstTimeModal'
import NewConsentModal from 'components/NewConsentModal'

function App() {
  const dispatch = useDispatch()
  const location = useLocation()

  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)

  useEffect(() => {
    dispatch(fetchCurrentAgent())
  }, [dispatch])

  useEffect(() => {
    go2Top()
  }, [location.pathname])

  useEffect(() => {
    console.log('NODE_ENV => ', process.env.NODE_ENV)
    console.log('API_BASE_URL => ', process.env.REACT_APP_AGENT_TOOLS_API_HOST)
    console.log('TQM_LIFE_URL => ', process.env.REACT_APP_TQM_LIFE)
    console.log('APPID_FACEBOOK => ', process.env.REACT_APP_APPID_FACEBOOK)
    console.log('APPID_GOOGLE => ', process.env.REACT_APP_APPID_GOOGLE)
  }, [])

  SwiperCore.use([Autoplay, Pagination])

  return (
    <>
      <Layout appName="Agent Tools" currentAgent={currentAgent}>
        <Pages />
      </Layout>
      <FirstTimeModal currentAgent={currentAgent} />
      <NewConsentModal currentAgent={currentAgent} />
    </>
  )
}

export default App
