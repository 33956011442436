import React, { FC } from 'react'
import styled from '@emotion/styled'
import { Link, useParams } from 'routes'
import { financial } from 'pages/insurance_portfolio/root/Summary'
import Avatar from 'components/Avatar'
import Icon from 'components/Icon'
import { InsurancePortType } from 'types/InsurancePortType'

type InsurancePortCardType = {
  port: InsurancePortType
}

const LinkName = styled(Link)`
  text-decoration: none;
  color: var(--agt-primary-text-color);
  :hover {
    color: var(--agt-primary-text-color);
  }
`
const LinkOrderNo = styled(Link)`
  font-size: 13px;
`
const Card = styled.div`
  width: 544px;
  height: 190px;
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid var(--agt-secondary-color-1);
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`
const ContainerTop = styled.div`
  display: flex;
  flex-direction: row;
`
const ContainerBottom = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 18px;
  padding-right: 18px;
`
const AvartarContainer = styled.div`
  width: 80px;
  padding: 16px;
`
const Detial = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const InfoLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 14px 0;
`
const InfoRight = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 14px 0;
`
const Row = styled.div`
  margin-bottom: 4px;
`
const StateComplete = styled.div`
  width: 135px;
  height: 26px;
  font-size: 13px;
  color: var(--agt-white-color);
  background: var(--agt-tqm-pantone-1);
  border-radius: 20px;
  text-align: center;
`
const StateExpired = styled.div`
  width: 135px;
  height: 26px;
  font-size: 13px;
  color: var(--agt-primary-text-color);
  background: var(--agt-background-color-2);
  border-radius: 20px;
  text-align: center;
`
const StateRejected = styled.div`
  width: 135px;
  height: 26px;
  font-size: 13px;
  color: var(--agt-white-color);
  background: var(--agt-error-color);
  border-radius: 20px;
  text-align: center;
`
const InfoLeftBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 14px 26px;
  width: 60%;
  border-top: 1px solid var(--agt-secondary-color-1);
`
const InfoRightBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 14px 0;
  width: 40%;
  border-top: 1px solid var(--agt-secondary-color-1);
`
const IconStyles = styled(Icon)`
  width: 22px;
  height: 22px;
  margin-right: 13px;
`

function formatChangeDate(myDate: string) {
  var today = new Date(myDate)
  var dd = String(today.getDate())
  var mm = ''
  switch (today.getMonth() + 1) {
    case 1:
      mm = 'ม.ค.'
      break
    case 2:
      mm = 'ก.พ.'
      break
    case 3:
      mm = 'มี.ค.'
      break
    case 4:
      mm = 'เม.ย.'
      break
    case 5:
      mm = 'พ.ค.'
      break
    case 6:
      mm = 'มิ.ย.'
      break
    case 7:
      mm = 'ก.ค.'
      break
    case 8:
      mm = 'ส.ค.'
      break
    case 9:
      mm = 'ก.ย.'
      break
    case 10:
      mm = 'ต.ค.'
      break
    case 11:
      mm = 'พ.ย.'
      break
    case 12:
      mm = 'ธ.ค'
      break
    default:
      mm = String(today.getMonth() + 1).padStart(2, '0')
  }
  var yyyy = today.getFullYear() + 543
  return dd + ' ' + mm + ' ' + yyyy
}

const InsurancePortCard: FC<InsurancePortCardType> = ({ port }) => {
  let { id }: { id: string } = useParams()

  return (
    <Card>
      <ContainerTop>
        <AvartarContainer>
          <Avatar avatar={port?.logoUrl || '-'} width="48px" height="48px" type="circle" />
        </AvartarContainer>
        <Detial>
          <InfoLeft>
            <Row>
              <LinkName to={`/contacts/${id}/insurance_portfolio/${port.id}`}>
                <b>{port?.name || '-'}</b>
              </LinkName>
            </Row>
            <Row>{port?.ownerName || '-'}</Row>
          </InfoLeft>
          <InfoRight>
            <Row>
              {port.state === 'completed' && (
                <StateComplete>
                  <label>กรมธรรม์มีผลบังคับใช้</label>
                </StateComplete>
              )}
              {port.state === 'rejected' && (
                <StateRejected>
                  <label>กรมธรรม์ถูกยกเลิก</label>
                </StateRejected>
              )}
              {port.state === 'expired' && (
                <StateExpired>
                  <label>กรมธรรม์หมดอายุ</label>
                </StateExpired>
              )}
            </Row>
            {port.createdSource === 'tqm_life' && port.orderNo && (
              <Row>
                <LinkOrderNo to={`/contacts/${id}/insurance_portfolio/${port.id}/${port.orderNo}`}>
                  รหัสคำสั่งซื้อ #{port.orderNo}
                </LinkOrderNo>
              </Row>
            )}
          </InfoRight>
        </Detial>
      </ContainerTop>
      <ContainerBottom>
        <InfoLeftBottom>
          <Row>
            <IconStyles name="policyNo" />
            {port?.policyNo || '-'}
          </Row>
          <Row>
            <IconStyles name="endPolicyAt" />
            {port?.endPolicyAt ? formatChangeDate(port.endPolicyAt) : 'ไม่ได้ระบุวันครบกำหนดสัญญา'}
          </Row>
        </InfoLeftBottom>
        <InfoRightBottom>
          <Row>
            <IconStyles name="sumInsured" />
            ทุน {port?.sumInsured ? financial(port.sumInsured) : 0}
          </Row>
          <Row>
            <IconStyles name="insuranceRiders" />
            อนุสัญญา {port?.insuranceRiders ? port.insuranceRiders.length : 0}
          </Row>
        </InfoRightBottom>
      </ContainerBottom>
    </Card>
  )
}
export default InsurancePortCard
