import React, { FC, useState, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { Link } from 'routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import Icon from 'components/Icon'
import BannerImage from 'assets/images/register_header_banner.jpg'
import StepImage from 'assets/images/recruitment_step.png'

type ArrowType = {
  activated: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
`
const HeaderTopic = styled.h2`
  margin-bottom: 40px;

  color: white;
  text-align: center;
`
const ImageContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1120px;
  height: 250px;
  margin: 0 auto;
  overflow: hidden;
`
const Image = styled.img`
  position: absolute;
  z-index: -2;

  width: 100%;
  height: 100%;

  object-fit: cover;
`
const RegisterButton = styled.button`
  display: block;
  width: 352px;
  margin: 0 auto;

  font-weight: bold;
`
const StickyHeader = styled(ImageContainer)`
  position: fixed;
  z-index: 99;
  top: 0;

  height: 0;

  background-color: rgba(204, 220, 235, 0.7);
  backdrop-filter: blur(1px);
  transition: height 0.5s;

  &.is-sticky {
    height: 58px;
  }
`
const Header = () => {
  const [isSticky, setIsSticky] = useState<boolean>(false)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)

  const link: string = useMemo(() => {
    if (
      ['ปฏิเสธชั่วคราว (1 ปี)', 'ปฏิเสธถาวร (Blacklist)', 'เก็บประวัติการสมัคร'].includes(
        currentAgent?.agentStatus || '',
      )
    ) {
      return 'form'
    } else if (currentAgent?.recruitsStatus && currentAgent?.recruitsStatus?.length > 0) {
      return 'download'
    } else {
      return 'form'
    }
    // eslint-disable-next-line
  }, [currentAgent, currentAgent?.agentStatus, currentAgent?.recruitsStatus])

  useEffect(() => {
    function tracking() {
      setIsSticky(window.scrollY > 330)
    }
    window.addEventListener('scroll', tracking)
    return () => window.removeEventListener('scroll', tracking)
  }, [])

  return (
    <>
      <ImageContainer>
        <Image src={BannerImage} />
        <HeaderTopic>สมัครเป็นตัวแทนกับ Friday Business</HeaderTopic>
        <Link to={currentAgent ? `/profile/recruitment/${link}` : '/login'} className="ghost">
          <RegisterButton>สมัครเป็นตัวแทน</RegisterButton>
        </Link>
      </ImageContainer>
      <StickyHeader className={isSticky ? 'is-sticky' : ''}>
        <Link to={currentAgent ? `/profile/recruitment/${link}` : '/login'} className="ghost">
          <RegisterButton>สมัครเป็นตัวแทน</RegisterButton>
        </Link>
      </StickyHeader>
    </>
  )
}

const Privilege = styled.div`
  width: 100%;
  padding: 40px 0;
`
const Topic = styled.h4`
  margin-bottom: 40px;

  text-align: center;
  color: var(--agt-primary-color-1);
`
const PrivilegeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const PrivilegeCard = styled.div`
  position: relative;
  width: 100%;
  max-width: 352px;
  padding: 15px;
  padding-bottom: 24px;
  height: 290px;
  border: 1px solid var(--agt-background-color-1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const PrivilegeTopic = styled.div`
  margin-bottom: 19px;
`
const PrivilegeIcon = styled(Icon)`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 62px;
  height: 62px;
  > div {
    > div {
      > svg {
        width: 62px;
        height: 62px;
      }
    }
  }
`
const Step = styled.img`
  width: 100%;
  height: 357px;
  object-fit: cover;
`
const Why = styled.div`
  width: 100%;
  padding: 40px 96px 60px;

  text-align: center;
`
const WhyTopic = styled(Topic)`
  margin-bottom: 23px;
`
const WhyHr = styled.div`
  width: 160px;
  height: 4px;
  margin: 26px auto 0;

  background-color: var(--agt-primary-color-1);
`
const QnA = styled.div`
  width: 100%;
  padding: 24px 96px 40px;

  background-color: var(--agt-background-color-3);
`
const QnATopic = styled(Topic)`
  margin-bottom: 24px;
`
const QnAContainer = styled.div`
  margin-bottom: 12px;

  background-color: white;
`
const QnAQuestion = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  padding: 20px 80px 20px 60px;
  font-weight: bold;
  cursor: pointer;
`
const Arrow = styled(FontAwesomeIcon)<ArrowType>`
  position: absolute;
  right: 40px;

  font-size: 20px;
  transform-origin: center;
  transform: ${(props) => (props.activated ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.5s;
`
const QnAAnswer = styled.div`
  padding: 20px 80px 20px 60px;
  border-top: 1px solid var(--agt-background-color-1);

  white-space: pre-line;
`
const QnARenderer = (props) => {
  const [activated, setActive] = useState<boolean>(false)
  const toggle = () => setActive(!activated)
  return (
    <QnAContainer>
      <QnAQuestion onClick={() => toggle()}>
        {props.question}
        <Arrow activated={activated} icon={faChevronUp} color="var(--agt-primary-color-1)" />
      </QnAQuestion>
      {activated && <QnAAnswer>{props.answer}</QnAAnswer>}
    </QnAContainer>
  )
}
const Landing: FC = () => {
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const QUESTION = [
    {
      question: `ใช้ระยะเวลานานเท่าไหร่ในการสมัครและขึ้นทะเบียนเป็นตัวแทนกับ Friday Business`,
      answer: `ไม่เกิน 30 วันหลังจากที่ตัวแทนส่งเอกสารไปยังสาขาใกล้บ้านได้ครบถ้วนเรียบร้อย ทั้งนี้ ตัวแทนจะได้รับ User Account ในการเข้าใช้งาน เพื่อทดลองใช้งานระบบก่อนการอนุมัติตัวแทนได้ในบางฟีเจอร์`,
    },
    {
      question: `จะได้รับส่วนแบ่งรายได้จากการแนะนำผลิตภัณฑ์ของ Friday Business เป็นสัดส่วนเท่าไหร่`,
      answer: `ดัมพ์มิวสิคแจ๊กเก็ต แพทเทิร์นแฮมเบอร์เกอร์กระดี๊กระด๊าไคลแม็กซ์เมคอัพ อิสรชนโค้กแรงผลักชินบัญชร รีโมต`,
    },
  ]
  return (
    <Container>
      <Header />
      <Privilege>
        <Topic>สิทธิประโยชน์ในการเป็นตัวแทนกับ Friday Business</Topic>
        <PrivilegeContainer>
          <PrivilegeCard>
            <div>
              <PrivilegeIcon name="agt_register_product" />
              <PrivilegeTopic>
                <b>
                  ผลิตภัณฑ์มากมายจากเครือข่าย
                  <br />
                  พันธมิตรบริษัทประกัน
                </b>
              </PrivilegeTopic>
              <div className="agt-small">
                ขยายความสามารถในการให้บริการด้านผลิตภัณฑ์ของคุณ ทั้งประกันชีวิต ประกันสุขภาพ
                ประกันรถยนต์ ประกันอุบัติเหตุ ฯลฯ
                จากผลิตภัณฑ์ของบริษัทประกันชีวิตและประกันวินาศภัยกว่า 40 ค่าย
                เพื่อให้บริการกับลูกค้าของคุณได้ครบวงจร โดยไม่ต้องยกเลิกใบอนุญาตเดิม
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <a href="https://www.noon.in.th/smart/InsuranceCategory">
                <button className="primary" type="button" style={{ width: 261 }}>
                  ดูผลิตภัณฑ์
                </button>
              </a>
            </div>
          </PrivilegeCard>
          <PrivilegeCard>
            <div>
              <PrivilegeIcon name="agt_register_tools" />
              <PrivilegeTopic>
                <b>
                  เครื่องมือช่วยทำงานให้เต็ม
                  <br />
                  ประสิทธิภาพ
                </b>
              </PrivilegeTopic>
              <div className="agt-small">
                เพียบพร้อมด้วยหลากเครื่องมือบริหารความสัมพันธ์กับลูกค้า
                ที่จะทำให้คุณไม่พลาดทุกโอกาสในการปิดการขาย ติดตามผลงาน และการบริการได้ครบทุกองศา
                รวมถึงเครื่องมือวางแผนการเงิน และการประกันภัยให้ลูกค้า และยังมีทีมงาน Friday
                Business ใกล้บ้านคุณ ที่จะคอยช่วยเหลือ การปิดการขายทำให้การทำงานของคุณ
                ให้ง่ายขึ้นไปอีกขั้น
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link to={currentAgent ? '/home' : '/login'}>
                <button className="primary" type="button" style={{ width: 261 }}>
                  ทดลองใช้งาน 30 วัน
                </button>
              </Link>
            </div>
          </PrivilegeCard>
          <PrivilegeCard>
            <div>
              <PrivilegeIcon name="agt_register_income" />
              <PrivilegeTopic>
                <b>
                  ช่องทางในการสร้างรายได้
                  <br />
                  มากขึ้น
                </b>
              </PrivilegeTopic>
              <div className="agt-small">
                รับส่วนแบ่งรายได้จากการแนะนำผลิตภัณฑ์ของ Friday Business ให้กับลูกค้า
                พร้อมโอกาสในการสร้างทีม ขยายเครือข่ายทีมงานของคุณ
                ไปพร้อมกับการฝึกอบรมและพัฒนาตัวแทนโดยทีมผู้เชี่ยวชาญหลากหลายแขนงจาก Friday Business
              </div>
            </div>
          </PrivilegeCard>
        </PrivilegeContainer>
      </Privilege>
      <Step src={StepImage} />
      <Why>
        <WhyTopic>ทำไมต้องเป็น Friday Business ?</WhyTopic>
        <div>
          Friday Business โบรกเกอร์ประกันภัยชั้นนำของไทย ด้วยประสบการณ์ที่มีมายาวนานกว่า 60 ปี
          ในการให้บริการครบวงจร
          <br />
          ด้วยความชำนาญทั้งในเรื่องประกันวินาศภัย ประกันชีวิต และการเงิน
          มีผลิตภัณฑ์ประกันภัยให้เลือกจากพันธมิตรประกันภัยกว่า 40 แห่ง
          <br />
          รวมถึงสาขาทั่วประเทศกว่า 100
          และพร้อมทีมงานที่พร้อมช่วยเหลือการทำงานของตัวแทนได้อย่างเต็มประสิทธิภาพ
          <br />
          และเทคโนโลยีที่จะช่วยยกระดับความสามารถในการให้บริการในฐานะที่ปรึกษาการเงินและการประกันภัยของคุณได้อย่างเต็มรูปแบบ
        </div>
        <WhyHr />
      </Why>
      <QnA>
        <QnATopic>คำถามที่พบบ่อย</QnATopic>
        {QUESTION.map((q, index) => (
          <QnARenderer key={index} {...q} />
        ))}
      </QnA>
    </Container>
  )
}

export default Landing
