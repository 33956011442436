import React, { FC, useEffect } from 'react'
import styled from '@emotion/styled'
import { useHistory, useParams } from 'routes'
// import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

// import { getNewOpportunityById } from 'store/opportunitiesSlice'
import { SearchStateType } from 'types/TravelInsuranceType'
import HistoryType from 'types/HistoryType'
// import { carUsage } from '../utils'
import { currency } from 'utils/helper'
import ComparingCard from './ComparingCard'
import Prb from 'assets/images/Prb.png'
import ChooseCard from './ChooseCard'

type SearchType = {
  id: string
}
type ComparingType = {
  setIsCompare: Function
  setCompareData: Function
  data: Array<SearchStateType>
  location: string
}

const Container = styled.section`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
`
const BackWrapper = styled.div`
  width: fit-content;
  cursor: pointer;
`
const CustomIcon = styled(FontAwesomeIcon)`
  color: var(--agt-primary-text-color);
  margin-right: 18px;
`
const Remark = styled.p`
  text-align: right;
`
const ProductContainer = styled.div`
  padding: 1rem 0;
  width: 100%;
  overflow: auto;
`
const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px repeat(3, 284px);
  grid-template-rows: 1fr;
  column-gap: 3px;
  width: 100%;
  padding-inline: 40px;
`
const BuyButton = styled.button`
  margin: 0.5rem auto 1rem;
`
const CollapseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 40px;
  background-color: var(--agt-background-color-2);
`
const DetailWrapper = styled.div`
  display: grid;
  grid-template-columns: 25% repeat(3, 25%);
  padding: 1rem 40px;
  row-gap: 1rem;
  label {
    text-align: right;
  }
`
const Label = styled.p`
  font-weight: bold;
`

const ComparingTravel: FC<ComparingType> = ({ setIsCompare, setCompareData, data, location }) => {
  let { id } = useParams<SearchType>()
  const history: HistoryType = useHistory()

  return (
    <Container>
      <div className="header">
        <BackWrapper onClick={() => setIsCompare(false)}>
          <CustomIcon icon={faChevronLeft} className="fa-lg" />
          ย้อนกลับ
        </BackWrapper>
        <Remark>เปรียบเทียบสูงสุด 3 รายการ</Remark>
      </div>
      <ProductContainer>
        <ProductWrapper>
          <div />
          {data[0] ? (
            <ComparingCard
              logo={data[0]?.companyLogoUrl || Prb}
              onClose={() => {
                setCompareData([...data.filter((d) => d.productId !== data[0].productId)])
                data.length < 2 && setIsCompare(false)
              }}
              insuranceName={`คุ้มครองบุคคล ที่มีอายุ ระหว่าง ${data[0].minAge} ถึง ${data[0].maxAge} ปี`}
              companyName={data[0]?.companyName}
              netAmount={data[0]?.netAmount}
            />
          ) : (
            <ChooseCard onClick={() => setIsCompare(false)} />
          )}
          {data[1] ? (
            <ComparingCard
              logo={data[1]?.companyLogoUrl || Prb}
              onClose={() =>
                setCompareData([...data.filter((d) => d.productId !== data[1].productId)])
              }
              insuranceName={`คุ้มครองบุคคล ที่มีอายุ ระหว่าง ${data[1].minAge} ถึง ${data[1].maxAge} ปี`}
              companyName={data[1]?.companyName}
              netAmount={data[1]?.netAmount}
            />
          ) : (
            <ChooseCard onClick={() => setIsCompare(false)} />
          )}
          {data[2] ? (
            <ComparingCard
              logo={data[2]?.companyLogoUrl || Prb}
              onClose={() =>
                setCompareData([...data.filter((d) => d.productId !== data[2].productId)])
              }
              insuranceName={`คุ้มครองบุคคล ที่มีอายุ ระหว่าง ${data[2].minAge} ถึง ${data[2].maxAge} ปี`}
              companyName={data[2]?.companyName}
              netAmount={data[2]?.netAmount}
            />
          ) : (
            <ChooseCard onClick={() => setIsCompare(false)} />
          )}
        </ProductWrapper>
        <ProductWrapper style={{ marginBottom: '1rem' }}>
          <div />
          {data[0] ? (
            <BuyButton
              onClick={() =>
                history.push({
                  pathname: `/opportunities/${id}/catalog/travel/insurance_product/droplead/${data[0]?.productId}`,
                  search: `${location}`,
                })
              }
              disabled
            >
              ซื้อเลย
            </BuyButton>
          ) : (
            <div />
          )}
          {data[1] ? (
            <BuyButton
              onClick={() =>
                history.push({
                  pathname: `/opportunities/${id}/catalog/travel/insurance_product/droplead/${data[1]?.productId}`,
                  search: `${location}`,
                })
              }
              disabled
            >
              ซื้อเลย
            </BuyButton>
          ) : (
            <div />
          )}
          {data[2] ? (
            <BuyButton
              onClick={() =>
                history.push({
                  pathname: `/opportunities/${id}/catalog/travel/insurance_product/droplead/${data[2]?.productId}`,
                  search: `${location}`,
                })
              }
              disabled
            >
              ซื้อเลย
            </BuyButton>
          ) : (
            <div />
          )}
        </ProductWrapper>
        <CollapseHeader>
          <p>ความคุ้มครองค่ารักษาพยาบาล</p>
        </CollapseHeader>
        <DetailWrapper>
          <Label>
            การเสียชีวิต การสูญเสียอวัยวะ สายตา หรือ ทุพพลภาพ ถาวรสิ้นเชิง เนื่องจากอุบัติเหตุ
          </Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA01, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA01, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA01, 0, '') || '-')}</label>
          <Label>ค่ารักษาพยาบาลระหว่างการเดินทาง</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA02, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA02, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA02, 0, '') || '-')}</label>
          <Label>ค่ารักษาพยาบาลต่อเนื่องหลังจาก กลับถึงประเทศไทย</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA03, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA03, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA03, 0, '') || '-')}</label>
          <Label>การรับการรักษาพยาบาลในประเทศไทย</Label>
          <label>
            {data[0]?.coverDetail.travelA53 === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}
          </label>
          <label>
            {data[1]?.coverDetail.travelA53 === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}
          </label>
          <label>
            {data[2]?.coverDetail.travelA53 === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}
          </label>
          <Label>การเคลื่อนย้าย เพื่อการรักษาพยาบาลฉุกเฉิน</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA04, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA04, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA04, 0, '') || '-')}</label>
          <Label>เงินชดเชยรายวันระหว่างรักษาตัวใน โรงพยาบาล ในฐานะผู้ป่วยใน</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA13, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA13, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA13, 0, '') || '-')}</label>
          <Label>ค่าศัลยกรรมอันเนื่องมาจากอุบัติเหตุ</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA42, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA42, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA42, 0, '') || '-')}</label>
        </DetailWrapper>
        <CollapseHeader>
          <p>ความคุ้มครองสัมภาระและทรัพย์สิน</p>
        </CollapseHeader>
        <DetailWrapper>
          <Label>
            การสูญหายหรือเสียหายของกระเป๋าเดินทาง และ / หรือ ทรัพย์สินส่วนตัวภายใน กระเป๋าเดินทาง
          </Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA09, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA09, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA09, 0, '') || '-')}</label>
          <Label>การล่าช้าของกระเป๋าเดินทาง</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA10, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA10, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA10, 0, '') || '-')}</label>
          <Label>การสูญหายหรือเสียหายของเงินสดและ เช็คเดินทาง</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA14, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA14, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA14, 0, '') || '-')}</label>
          <Label>การสูญหายหรือเสียหายของเอกสาร ที่เกี่ยวข้องกับการเดินทาง</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA15, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA15, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA15, 0, '') || '-')}</label>
          <Label>ความสูญเสียหรือความเสียหาย ของคอมพิวเตอร์โน้ตบุ๊ค</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA19, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA19, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA19, 0, '') || '-')}</label>
        </DetailWrapper>
        <CollapseHeader>
          <p>ความคุ้มครองในการเดินทาง</p>
        </CollapseHeader>
        <DetailWrapper>
          <Label>การยกเลิกการเดินทาง</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA06, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA06, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA06, 0, '') || '-')}</label>
          <Label>ความล่าช้าในการเดินทาง</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA08, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA08, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA08, 0, '') || '-')}</label>
          <Label>การพลาดการต่อเครื่องบิน</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA16, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA16, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA16, 0, '') || '-')}</label>
          <Label>การจี้เครื่องบิน</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA20, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA20, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA20, 0, '') || '-')}</label>
          <Label>การลดจำนวนวันเดินทาง</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA07, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA07, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA07, 0, '') || '-')}</label>
          <Label>การล่าช้าของระบบขนส่งมวลชน</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA55, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA55, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA55, 0, '') || '-')}</label>
        </DetailWrapper>
        <CollapseHeader>
          <p>ความคุ้มครองเพิ่มเติม</p>
        </CollapseHeader>
        <DetailWrapper>
          <Label>ค่าใช้จ่ายในการส่งศพ อัฐิกลับประเทศ</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA05, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA05, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA05, 0, '') || '-')}</label>
          <Label>ความรับผิดชอบกฎหมายต่อบุคคลภายนอก</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA11, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA11, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA11, 0, '') || '-')}</label>
          <Label>ชดเชยค่าโทรศัพท์ในกรณีฉุกเฉิน</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA24, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA24, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA24, 0, '') || '-')}</label>
          <Label>การเดินทางเพื่อเยี่ยมผู้ป่วยที่โรงพยาบาล</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA12, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA12, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA12, 0, '') || '-')}</label>
          <Label>รางวัลพิเศษสำหรับ โฮล-อิน-วัน</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA17, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA17, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA17, 0, '') || '-')}</label>
          <Label>ความเสียหายส่วนแรกสำหรับรถเช่า</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA18, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA18, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA18, 0, '') || '-')}</label>
          <Label>การไปเยี่ยมผู้เอาประกันภัย</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA21, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA21, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA21, 0, '') || '-')}</label>
          <Label>การส่งผู้เยาว์เพื่อเดินทางกลับประเทศ</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA22, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA22, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA22, 0, '') || '-')}</label>
          <Label>ผลประโยชน์การขยายระยะเวลาโดยอัตโนมัติ</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA23, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA23, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA23, 0, '') || '-')}</label>
          <Label>ผลประโยชน์ความรับผิดต่อบัตรเครดิต</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA25, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA25, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA25, 0, '') || '-')}</label>
          <Label>ผลประโยชน์การสูญเสียหรือความเสียหาย ของทรัพย์สินภายในบ้าน</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA26, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA26, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA26, 0, '') || '-')}</label>
          <Label>การบริการให้ความช่วยเหลือในการเดินทาง</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA31, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA31, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA31, 0, '') || '-')}</label>
          <Label>
            โฮล-อิน-วัน / การสูญหายหรือความเสียหาย ของอุปกรณ์กอล์ฟ / การชดเชยค่า ธรรมเนียมสนามกอล์ฟ
          </Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA43, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA43, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA43, 0, '') || '-')}</label>
          <Label>การค้นหา การช่วยชีวิต และการกู้ภัย</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA54, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA54, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA54, 0, '') || '-')}</label>
          <Label>การสูญหายของหนังสือเดินทาง</Label>
          <label>{data[0] && (currency(data[0]?.coverDetail.travelA56, 0, '') || '-')}</label>
          <label>{data[1] && (currency(data[1]?.coverDetail.travelA56, 0, '') || '-')}</label>
          <label>{data[2] && (currency(data[2]?.coverDetail.travelA56, 0, '') || '-')}</label>
        </DetailWrapper>
      </ProductContainer>
    </Container>
  )
}

export default ComparingTravel
