import styled from '@emotion/styled'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SetStateType from 'types/SetStateType'
import Icon from 'components/Icon'
import Select from 'react-select'
import { getTqmOfficeAddresses } from 'store/profileSlice'

type BranchProps = {
  branchName: string
  addressFirst: string
  addressSecond: string
  subDistrictName: string
  districtName: string
  provinceName: string
  postalCode: string
  phoneNumber: string
  openDay: string
}
type SortSelector = {
  label: string
  value: string
}
type SelectorProps = {
  sortBy: SortSelector
  setSorting: SetStateType<SortSelector>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const BranchContainer = styled.div`
  display: flex;
  margin-top: 26px;
  flex-direction: column;
  /* justify-content: center; */
  width: 100%;
  padding: 0 96px;
  height: 100%;
  margin-bottom: 40px;
`
const Branch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 60px;
  .Header {
    font-size: 24px;
    font-weight: bold;
    color: var(--agt-primary-color-1);
    align-self: center;
    margin-right: 24px;
  }
  .Searching {
    width: 54px;
    height: 48px;
    background: var(--agt-background-color-2);
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 0px 4px 4px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const OfficeBranch = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  color: var(--agt-primary-color-1);
  height: 500px;
  overflow: auto;
  @media (max-height: 1024px) {
    height: 700px;
  }
`
const BranchContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 324px;
  height: fit-content;
  color: var(--agt-primary-color-1);
  margin-bottom: 30px;
`
const BranchName = styled.div`
  font-weight: bold;
  font-size: 17px;
`
const BranchDetail = styled.div`
  margin-left: 20px;
  font-size: 13px;
  color: var(--agt-primary-text-color);
  .address {
    height: 55px;
  }
`
const Input = styled.input`
  width: 333px;
  border-radius: 0px 0px 0px 0px !important;
`
const IconSearch = styled(Icon)`
  width: 25px;
  height: 25px;
  color: var(--agt-primary-color-1);
`
const IconDownload = styled(Icon)`
  width: 26px;
  height: 26px;
`
const IconMapPin = styled(Icon)`
  width: 14px;
  height: 24px;
  margin-right: 7px;
  color: var(--agt-primary-color-1);
`
const IconStyle = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-right: 7px;
  color: var(--agt-primary-color-1);
`
const Selector = styled(Select)``

const customSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px 0px 0px 4px',
    width: '153px',
    background: 'var(--agt-background-color-2)',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    paddingLeft: '6px',
    fontSize: '17px',
    fontWeight: '400',
  }),
  menuList: () => ({
    maxHeight: '275px',
    overflowY: 'auto',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '172px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: '#ffffff',
    position: 'absolute',
    zIndex: '2',
    fontSize: '17px',
    fontWeight: '400',
    color: ' var(--agt-primary-text-color)',
  }),
}
const SelectorComponent = ({ sortBy, setSorting }: SelectorProps) => {
  return (
    <div>
      <Selector
        value={sortBy}
        styles={customSelectStyles}
        onChange={(option: any) => {
          setSorting(option)
        }}
        options={searchOptions}
      />
    </div>
  )
}

const searchOptions = [
  { label: 'ค้นหาทั้งหมด', value: 'ทุกจังหวัด ทุกสาขา' },
  { label: 'จังหวัด', value: 'จังหวัด' },
  { label: 'สาขา', value: 'สาขา' },
]

const Branches: FC = () => {
  const dispatch = useDispatch()
  const [branch, setBranch] = useState<Array<BranchProps>>([])
  const [sortBy, setSorting] = useState<SortSelector>(searchOptions[0])
  const [search, setDataSearch] = useState<string>('')
  let branchFilter = branch.filter((key) =>
    sortBy.label === 'ค้นหาทั้งหมด'
      ? new RegExp(search).test(key.addressSecond) || new RegExp(search).test(key.branchName)
      : (sortBy.label === 'สาขา' && new RegExp(search).test(key.branchName)) ||
        (sortBy.label === 'จังหวัด' && new RegExp(search).test(key.addressSecond)),
  )
  useEffect(() => {
    dispatch(
      getTqmOfficeAddresses((branch) => {
        setBranch(branch)
      }),
    )
  }, [dispatch])

  return (
    <Container>
      <BranchContainer>
        <Branch>
          <div className="Header">สาขา Friday Business</div>
          <SelectorComponent sortBy={sortBy} setSorting={setSorting} />
          <Input
            type="text"
            placeholder={sortBy.value}
            onChange={(event) => setDataSearch(event.target.value)}
          />
          <div className="Searching">
            <IconSearch name="search" />
          </div>
        </Branch>
        <OfficeBranch>
          {branchFilter.map((v, key) => (
            <BranchContent key={key}>
              <BranchName>
                <IconMapPin name="map_pin" color={'#00529a'} />
                {v.branchName}
              </BranchName>
              <BranchDetail>
                <div className="address">
                  {v.addressFirst}
                  {v.addressSecond && <p>{v.addressSecond}</p>}
                </div>

                <div>
                  <IconStyle name="clock_white" />
                  {v.openDay ? v.openDay : 'จ-ศ (ยกเว้นวันหยุดนักขัตฤกษ์) 9.00-15.00 น.'}
                </div>
                <div>
                  <IconStyle name="phone_icon" />
                  {v.phoneNumber}
                </div>
              </BranchDetail>
            </BranchContent>
          ))}
        </OfficeBranch>
      </BranchContainer>
    </Container>
  )
}

export default Branches
