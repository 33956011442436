import React, { FC, FormEvent, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { ErrorMessage, Form, Field, useField, useFormikContext, FieldArray, getIn } from 'formik'
import { faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router'
import subDays from 'date-fns/subDays'
import isEqual from 'lodash/isEqual'
import Select from 'react-select'
import isNaN from 'lodash/isNaN'
import get from 'lodash/get'
import has from 'lodash/has'
import moment from 'moment'
import Api from 'api'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import Gender from 'components/Inputs/Gender'
import InsuranceModal from 'components/InsuranceModal'
import { DatePickerComponent as DatePicker, getYearsOptions } from 'components/DatePicker'
import SetStateType from 'types/SetStateType'
import { RootState } from 'store/rootReducer'
import { getContactById } from 'store/contactSlice'
import { InsurancePortType } from 'types/InsurancePortType'
import { financial } from 'pages/insurance_portfolio/root/Summary'
import { getFullNameInsuranceType } from 'pages/insurance_portfolio/_id/InsurancePort'

type useParamsType = { id: string }
type Riders = {
  id?: number | null
  riderType: string
  compensation: number
  premium: number
  peroidYearNumber: number
  _destroy?: boolean
}
type InsurancePortFormType = {
  values?: InsurancePortType | undefined
  handleSubmit?: ((event: FormEvent<HTMLFormElement>) => void) | undefined
  errors?: any
  touched?: any
  isSubmitting?: boolean
}
type insuranceType = {
  life: Array<optionsType>
  health: Array<optionsType>
  motor: Array<optionsType>
}
type policyType = {
  personal: Array<optionsType>
  others: Array<optionsType>
}
type purposeOptionsType = {
  personal: Array<optionsType>
  others: Array<optionsType>
}
type optionsType = {
  value: string
  label: string
}
type OptionPropsType = {
  name: string
  options: Array<optionsType>
  isDisable: boolean
  onChange?: (value) => void
}
type imageResponse = {
  id?: number
  url?: string
}
type ImageComponentType = {
  isDisabled?: boolean
  isEdit?: boolean
  forOnce?: SetStateType<boolean>
}
const FormValue = styled(Form)`
  display: flex;
  flex-direction: column;
`
const Loading = styled.div`
  padding: 40px 20px;
  text-align: center;
`
export const CreateNormalForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
  margin-bottom: 24px;
  .Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .Row .Group {
    display: flex;
    flex-direction: column;
  }
  .Row .Group .Topic {
    color: var(--agt-primary-text-color);
    margin-bottom: 12px;
  }
  .Row .Group .Topic span {
    color: var(--agt-error-color);
  }
  .Row .Group .Topic.disabled {
    color: var(--agt-disable-color-1);
  }
  .normal-box {
    width: 356px;
    height: 48px;
  }
  .medium-box {
    width: 398px;
    height: 48px;
  }
  .extra-box {
    width: 736px;
    padding: 16px;
    border: 1px solid var(--agt-secondary-color-1);
    height: 96px !important;
    resize: none;
  }
  .normal-box.fix,
  .medium-box.fix {
    border: 1px solid var(--agt-disable-color-1);
    background: var(--agt-background-color-1);
    ::placeholder {
      color: var(--agt-disable-color-1);
    }
  }
  .normal-box.disabled,
  .medium-box.disabled {
    border: 1px solid var(--agt-disable-color-1);
    background: var(--agt-background-color-1);
    ::placeholder {
      color: var(--agt-disable-color-1);
    }
  }
  .normal-box.errors,
  .medium-box.errors {
    border: 1px solid var(--agt-error-color);
  }
  .MoreDetailLayer {
    border-bottom: 1px solid #d2d4db;
  }
  .MoreDetailLayer .Topic {
    font-weight: bold;
    color: var(--agt-primary-text-color);
    margin-bottom: 8px !important;
  }
  .MoreDetailLayer .Normal {
    width: 356px;
  }
  .MoreDetailLayer .Large {
    width: 736px;
  }
  .ImageButton {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`
const IconStyle = styled(Icon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`
const PencilIcon = styled(IconStyle)`
  position: absolute;
  bottom: 20px;
  right: 75px;
`
const TrashIcon = styled(IconStyle)`
  position: absolute;
  bottom: 20px;
  right: 35px;
`
export const CreateAddForm = styled.div`
  .Header {
    color: var(--agt-primary-text-color);
    margin-bottom: 24px;
  }
  .Body {
    width: 736px;
    height: 160px;
    padding: 16px 28px;
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 4px;
    margin-bottom: 24px;
    position: relative;
    .Text {
      margin-bottom: 8px;
    }
    .Text.primary {
      font-weight: bold;
    }
  }
`
export const ButtonComponents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const Error = styled.div`
  font-size: 13px;
  color: var(--agt-error-color);
`
const AddImage = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 36px;
  .ContainerBefore {
    width: 173px;
    height: 48px;
    background: var(--agt-background-color-1);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    color: var(--agt-secondary-text-color);
    padding-left: 9px;
  }
  .ContainerBefore .Right {
    align-self: center;
  }
  .ContainerBefore .Left {
    height: 100%;
    margin-right: 5px;
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .ContainerAfter {
    width: 173px;
    height: 48px;
    background: #ffffff;
    border: 1px solid var(--agt-success-color);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 2px;
  }
  .ContainerAfter .LeftColumn {
    width: 58px;
    height: 42px;
    background: var(--agt-success-color);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .ContainerAfter .LeftColumn .Font {
    font-size: 13px;
    color: #ffffff;
  }
  .ContainerAfter .MiddleColumn {
    width: 57px;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ContainerAfter .RightColumn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  button {
    width: 137px;
    margin-right: 14px;
    margin-left: 14px;
  }
`
const StyleGender = styled(Gender)`
  width: 356px;
  height: 48px;
`
const Selector = styled(Select)`
  width: 356px;
  height: 48px;
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Container {
    height: auto;
    width: 840px;
  }
  .Container .Header {
    margin: 24px 36px;
  }
  .Container .Body {
    border-top: 1px solid var(--agt-secondary-color-1);
    padding-left: 52px;
    padding-right: 52px;
    padding-top: 36px;
  }
  .Container .Body .Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .Container .Body .Row .LeftColumn {
    display: flex;
    flex-direction: column;
    width: 356px;
  }
  .Container .Body .Row .RightColumn {
    display: flex;
    flex-direction: column;
    width: 356px;
  }
  .Container .Body .Row .Topic {
    color: var(--agt-primary-text-color);
    margin-bottom: 4px;
  }
  .Container .Body .Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 24px;
  }
  .Warning,
  .Deleted {
    padding: 48px 98.5px;
  }
`
const SelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '354px',
    outline: 'none',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '46px',
  }),
  menuList: () => ({
    maxHeight: '100px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '356px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
}
const SelectErrorsStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '354px',
    outline: 'none',
    border: '1px solid var(--agt-error-color)',
    height: '46px',
  }),
  menuList: () => ({
    maxHeight: '100px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '356px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
}
const SelectDisabledStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '356px',
    outline: 'none',
    border: '1px solid var(--agt-disable-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '100px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '356px',
    border: '1px solid var(--agt-disable-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  placeholder: () => ({
    color: 'var(--agt-disable-color-1)',
  }),
}
export const DatePickerField = styled(DatePicker)`
  border-radius: 4px;
  height: 48px;
  width: 356px;
`
export const validator = (message) => (value) => {
  let error
  if (!value) {
    error = message || 'กรุณาระบุ'
  } else if (parseFloat(value) === 0) {
    error = message || 'กรุณาระบุ'
  }
  return error
}
export const getOptions = async (
  setGenderOptions: SetStateType<Array<optionsType>>,
  setInsurancePatternOptions: SetStateType<Array<optionsType>>,
  setInsuranceTypeOptions: SetStateType<insuranceType>,
  setPayPeriodTypeOptions: SetStateType<Array<optionsType>>,
  setPaymentTypeOptions: SetStateType<Array<optionsType>>,
  setPolicyTypeOptions: SetStateType<policyType>,
  setInsuranceCompanyNameOptions: SetStateType<Array<optionsType>>,
  setRiderTypeOptions: SetStateType<Array<optionsType>>,
  setPurposeOptions: SetStateType<purposeOptionsType>,
  setStateOptions: SetStateType<Array<optionsType>>,
  setLoading: SetStateType<boolean>,
  path: string,
) => {
  try {
    setLoading(true)
    const response = await Api.get(path)
    setGenderOptions(response.data.data.genderOptions)
    setInsurancePatternOptions(response.data.data.insurancePatternOptions)
    setInsuranceTypeOptions(response.data.data.insuranceTypeOptions)
    setPayPeriodTypeOptions(response.data.data.payPeriodTypeOptions)
    setPaymentTypeOptions(response.data.data.paymentTypeOptions)
    setPolicyTypeOptions(response.data.data.policyTypeOptions)
    setRiderTypeOptions(response.data.data.riderTypeOptions)
    setInsuranceCompanyNameOptions(response.data.data.insuranceCompanyNameOptions)
    setPurposeOptions(response.data.data.purposeOptions)
    setStateOptions(response.data.data.stateOptions)
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    setLoading(false)
  }
}
const postImageAttachment = async (image: File) => {
  try {
    let isFormData = true
    let postImageApi: string = '/attachments/'
    const response = await Api.post(postImageApi, { image }, { isFormData: isFormData })
    return response.data.data.attachment
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}
export const ImageComponent = (props: ImageComponentType) => {
  const { isDisabled, isEdit, forOnce } = props
  const [field] = useField('policyDocumentId')
  const [fieldFullPath] = useField('policyDocumentFullPath')
  const [previewFile, setFile] = useState<imageResponse>({ id: 0, url: '' })
  const { setFieldValue } = useFormikContext()
  const [typeOfUrl, setTypeOfUrl] = useState<string>('')
  const onDrop = async (acceptedFiles) => {
    let lengthOfUrl: number
    const attachment = await postImageAttachment(acceptedFiles[0])
    setFile(attachment)
    setFieldValue('policyDocumentId', attachment.id)
    lengthOfUrl = attachment.url.split('.').length
    setTypeOfUrl(attachment.url.split('.')[lengthOfUrl - 1].toUpperCase())
  }
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const clearFile = () => {
    setFile({ id: 0, url: '' })
    setFieldValue('policyDocumentId', '')
    if (isEdit && forOnce) {
      forOnce(false)
    }
  }
  useEffect(() => {
    if (isEdit && fieldFullPath.value) {
      let lengthImage: number = 0
      lengthImage = fieldFullPath.value.split('.').length
      setTypeOfUrl(fieldFullPath.value.split('.')[lengthImage - 1].toUpperCase())
    }
  }, [isEdit, fieldFullPath.value])
  return (
    <>
      {isEdit && fieldFullPath.value ? (
        <AddImage>
          <div {...getRootProps({ className: 'dropzone' })}>
            <button type="button" disabled={true}>
              อัปโหลด
            </button>
            <input {...getInputProps()} />
          </div>
          <div className="ContainerAfter">
            <div className="LeftColumn">
              <div className="Font">{typeOfUrl}</div>
            </div>
            <div className="MiddleColumn">
              <small title={fieldFullPath.value}>{fieldFullPath.value}</small>
            </div>
            <div className="RightColumn" onClick={clearFile}>
              <FontAwesomeIcon icon={faTimes} color="#000000" style={{ fontSize: '20px' }} />
            </div>
          </div>
        </AddImage>
      ) : (
        <AddImage>
          {field.value === '' || !field.value ? (
            <div {...getRootProps({ className: 'dropzone' })}>
              <button type="button" disabled={isDisabled}>
                อัปโหลด
              </button>
              <input {...getInputProps()} />
            </div>
          ) : (
            <div {...getRootProps({ className: 'dropzone' })}>
              <button disabled>อัปโหลด</button>
              <input {...getInputProps()} />
            </div>
          )}
          {field.value === '' || !field.value ? (
            <div className="ContainerBefore">
              <div className="Left">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  color="var(--agt-secondary-text-color)"
                  style={{ fontSize: '14px' }}
                />
              </div>
              <small className="Right">อัปโหลดภาพกรมธรรม์ รองรับไฟล์ .jpg .png .pdf</small>
            </div>
          ) : (
            <div className="ContainerAfter">
              <div className="LeftColumn">
                <div className="Font">{typeOfUrl}</div>
              </div>
              <div className="MiddleColumn">
                <small>{previewFile.url}</small>
              </div>
              <div className="RightColumn" onClick={clearFile}>
                <FontAwesomeIcon icon={faTimes} color="#000000" style={{ fontSize: '20px' }} />
              </div>
            </div>
          )}
        </AddImage>
      )}
    </>
  )
}
export const OptionsComponent = ({
  name,
  options,
  isDisable,
  onChange = () => {},
}: OptionPropsType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <Selector
      value={defaultSeletor ? defaultSeletor : ''}
      onChange={(option: optionsType) => {
        setValue(option.value)
        if (onChange) {
          onChange(option.value)
        }
      }}
      options={options}
      styles={
        isDisable
          ? SelectDisabledStyles
          : meta.error && meta.touched
          ? SelectErrorsStyles
          : SelectStyles
      }
      isDisabled={isDisable}
      placeholder="โปรดระบุ"
    />
  )
}
export const CustomSelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  forChangePattern = (value: any) => {},
  ...props
}) => {
  const { options, isDisabled = false } = props
  const isSelected = options.find((select) => select.value === field.value)
  return (
    <>
      <Selector
        name={field.name}
        {...field}
        {...props}
        options={options}
        value={isSelected ? isSelected : ''}
        onChange={(option: { value: string; label: string }) => {
          setFieldValue(field.name, option.value)
          if (forChangePattern) {
            forChangePattern(option.value)
          }
        }}
        styles={errors[field.name] && touched[field.name] ? SelectErrorsStyles : SelectStyles}
        placeholder="โปรดระบุ"
        isDisabled={isDisabled}
      />
      <ErrorMessage component={ErrorMassageStyle} name={field.name} />
    </>
  )
}
export function getStateFromEndPolicyAt(date: any) {
  let state = ''
  let today = new Date()
  let yesterday = moment(new Date(subDays(today, 1)), 'MM/DD/YYYY')
  let myDate = moment(new Date(date), 'MM/DD/YYYY')
  let diffDays = myDate.diff(yesterday, 'days')
  let diffHours = myDate.diff(yesterday, 'hours')
  if (diffDays > 0) {
    // pass
  } else {
    if (diffHours < 0) {
      state = 'expired'
    }
  }
  return state
}
export function getMinDate(date: any) {
  let validateDate = subDays(date, -1)
  return new Date(validateDate)
}
export function getMaxDate(date: any) {
  let validateDate = subDays(date, 1)
  return new Date(validateDate)
}
const ErrorMassageStyle = styled.div`
  display: flex;
  align-self: right;
  font-size: 13px;
  color: var(--agt-error-color);
  width: 100%;
`
function getStyles(errors, fieldName) {
  if (getIn(errors, fieldName)) {
    return {
      border: '1px solid var(--agt-error-color)',
    }
  }
}
export const CustomCurrencyComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { min = 0, max = 9999999 } = props
  return (
    <>
      <NumberFormat
        isNumericString={true}
        thousandSeparator={true}
        placeholder="โปรดระบุ"
        {...field}
        {...props}
        onChange={(e) => {
          setFieldValue(field.name, e.target.value.replace(/(,|-)/g, ''))
        }}
        decimalScale={2}
        style={touched[field.name] && errors[field.name] && getStyles(errors, field.name)}
        isAllowed={(values) => {
          const { value, floatValue } = values
          if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true
          }
          if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
              return false
            }
          }
          if (floatValue >= min && floatValue <= max) {
            return true
          }
        }}
      />
      <ErrorMessage component={ErrorMassageStyle} name={field.name} />
    </>
  )
}
export const CurrencyModal = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { min = 0, max = 10000000 } = props
  return (
    <NumberFormat
      isNumericString={true}
      thousandSeparator={true}
      placeholder="โปรดระบุ"
      {...field}
      {...props}
      onChange={(e) => {
        setFieldValue(field.name, e.target.value.replace(/(,|-)/g, ''))
      }}
      decimalScale={2}
      isAllowed={(values) => {
        const { value, floatValue } = values
        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
          return true
        }
        if (value.charAt(0) === '0') {
          if (value.charAt(1) && value.charAt(1) !== '.') {
            return false
          }
        }
        if (floatValue >= min && floatValue <= max) {
          return true
        }
      }}
    />
  )
}
export const YearsModal = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { min = 0, max = 99 } = props
  return (
    <NumberFormat
      isNumericString={true}
      thousandSeparator={true}
      placeholder="โปรดระบุ"
      {...field}
      {...props}
      onChange={(e) => {
        setFieldValue(field.name, e.target.value.replace(/(,|-)/g, ''))
      }}
      decimalScale={0}
      isAllowed={(values) => {
        const { value, floatValue } = values
        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
          return true
        }
        if (value.charAt(0) === '0') {
          if (value.charAt(1) && value.charAt(1) !== '.') {
            return false
          }
        }
        if (floatValue >= min && floatValue <= max) {
          return true
        }
      }}
    />
  )
}
export const CustomPercentComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { min = 0, max = 100 } = props
  return (
    <>
      <NumberFormat
        isNumericString={true}
        thousandSeparator={true}
        placeholder="โปรดระบุ"
        {...field}
        {...props}
        decimalScale={2}
        style={touched[field.name] && errors[field.name] && getStyles(errors, field.name)}
        isAllowed={(values) => {
          const { value, floatValue } = values
          if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true
          }
          if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
              return false
            }
          }
          if (floatValue >= min && floatValue <= max) {
            return true
          }
        }}
      />
      <ErrorMessage component={ErrorMassageStyle} name={field.name} />
    </>
  )
}
export const CustomSpecialFieldComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { min = 0, max = 9999999 } = props
  let name: string = field.name.split('.')
  let getTouched = getIn(touched.specialFields, name[1])
  let getErrors = getIn(errors.specialFields, name[1])

  return (
    <>
      <NumberFormat
        isNumericString={true}
        thousandSeparator={true}
        placeholder="โปรดระบุ"
        {...field}
        {...props}
        onChange={(e) => {
          setFieldValue(field.name, e.target.value.replace(/(,|-)/g, ''))
        }}
        decimalScale={2}
        style={getTouched && getErrors && getStyles(errors, field.name)}
        isAllowed={(values) => {
          const { value, floatValue } = values
          if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true
          }
          if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
              return false
            }
          }
          if (floatValue >= min && floatValue <= max) {
            return true
          }
        }}
      />
      <ErrorMessage component={ErrorMassageStyle} name={field.name} />
    </>
  )
}
export const CustomSpecialFieldPercentComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { min = 0, max = 100 } = props
  let name: string = field.name.split('.')
  let getTouched = getIn(touched.specialFields, name[1])
  let getErrors = getIn(errors.specialFields, name[1])
  return (
    <>
      <NumberFormat
        isNumericString={true}
        thousandSeparator={true}
        placeholder="โปรดระบุ"
        {...field}
        {...props}
        decimalScale={2}
        style={getTouched && getErrors && getStyles(errors, field.name)}
        isAllowed={(values) => {
          const { value, floatValue } = values
          if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true
          }
          if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
              return false
            }
          }
          if (floatValue >= min && floatValue <= max) {
            return true
          }
        }}
      />
      <ErrorMessage component={ErrorMassageStyle} name={field.name} />
    </>
  )
}
export function checkStateModal(object: any) {
  let flag = true
  for (var property in object) {
    let value = `${object[property]}`
    if (property === 'riderType') {
      if (value === '') {
        flag = false
        break
      }
    } else {
      if (value === '') {
        flag = false
        break
      } else {
        let data_array = value.toString().split(',')
        let new_data = data_array.join('')
        let amount = 0
        amount = parseFloat(new_data)
        if (amount === 0) {
          flag = false
          break
        }
      }
    }
  }
  return flag
}
const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
function getNaNCurrency(obj, key) {
  let amount = 0
  amount = parseFloat(get(obj, key))
  if (key === 'compensation') {
    if (isNaN(amount)) {
      return true
    } else {
      return false
    }
  } else {
    if (isNaN(amount) || amount <= 0) {
      return true
    } else {
      return false
    }
  }
}
function myValidate(obj, isEdit = false, index = 0) {
  if (isEdit) {
    if (
      isEqual(get(obj[index], 'riderType'), '') ||
      getNaNCurrency(obj[index], 'compensation') ||
      getNaNCurrency(obj[index], 'premium') ||
      getNaNCurrency(obj[index], 'peroidYearNumber')
    ) {
      return true
    } else {
      return false
    }
  } else {
    let len = obj.length - 1
    if (
      isEqual(get(obj[len], 'riderType'), '') ||
      getNaNCurrency(obj[len], 'compensation') ||
      getNaNCurrency(obj[len], 'premium') ||
      getNaNCurrency(obj[len], 'peroidYearNumber')
    ) {
      return true
    } else {
      return false
    }
  }
}
function getMoreInsurance(array) {
  let fact = false
  if (array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      if (get(array[i], 'riderType') !== '' && !has(array[i], '_destroy')) {
        fact = true
        break
      }
    }
  } else {
    fact = false
  }
  return fact
}

const InsurancePortForm: FC<InsurancePortFormType> = (props) => {
  const { values = {}, errors = {}, touched = {} } = props
  const { id } = useParams<useParamsType>()
  const formik = useFormikContext()
  const { setFieldValue } = formik
  const options = `/contacts/${id}/insurance_ports/options`
  const [loading, setLoading] = useState<boolean>(false)
  const [isAddInsurance, setIsAddInsurance] = useState<boolean>(false)
  const [isRemove, setIsRemove] = useState<number>(-1)
  const [, setGenderOptions] = useState<Array<optionsType>>([])
  const [insurancePatternOptions, setInsurancePatternOptions] = useState<Array<optionsType>>([])
  const [insuranceTypeOptions, setInsuranceTypeOptions] = useState<insuranceType>({
    life: [],
    health: [],
    motor: [],
  })
  const [payPeriodTypeOptions, setPayPeriodTypeOptions] = useState<Array<optionsType>>([])
  const [paymentTypeOptions, setPaymentTypeOptions] = useState<Array<optionsType>>([])
  const [policyTypeOptions, setPolicyTypeOptions] = useState<policyType>({
    personal: [],
    others: [],
  })
  const [insuranceCompanyNameOptions, setInsuranceCompanyNameOptions] = useState<
    Array<optionsType>
  >([])
  const [riderTypeOptions, setRiderTypeOptions] = useState<Array<optionsType>>([])
  const [purposeOptions, setPurposeOptions] = useState<purposeOptionsType>({
    personal: [],
    others: [],
  })
  const [stateOptions, setStateOptions] = useState<Array<optionsType>>([])
  const [stateImage, setStateImage] = useState<boolean>(true)
  const dispatch = useDispatch()
  const contact = useSelector((state: RootState) => state.contacts.contact)

  const [waitingModal, setWaitingModal] = useState(false)
  const [isChange, setIsChange] = useState(false)
  const initialValue = [
    {
      riderType: '',
      compensation: 0,
      premium: 0,
      peroidYearNumber: 0,
    },
  ]

  const [prevArray, setPrevArray] = useState(initialValue)
  const [indexField, setIndexField] = useState(0)

  useEffect(() => {
    dispatch(getContactById(id))
    getOptions(
      setGenderOptions,
      setInsurancePatternOptions,
      setInsuranceTypeOptions,
      setPayPeriodTypeOptions,
      setPaymentTypeOptions,
      setPolicyTypeOptions,
      setInsuranceCompanyNameOptions,
      setRiderTypeOptions,
      setPurposeOptions,
      setStateOptions,
      setLoading,
      options,
    )
  }, [dispatch, id, options])

  useEffect(() => {
    if (contact) {
      const birthdate =
        contact.birthdate !== null ? moment(contact.birthdate).format('DD-MM-YYYY') : null
      if (birthdate) {
        const dateToday = moment().format('DD-MM-YYYY')
        let initial = moment(`${birthdate}`, 'DD-MM-YYYY')
        let final = moment(`${dateToday}`, 'DD-MM-YYYY')
        values.ownerAge = final.diff(initial, 'years')
      }
      if (values.ownerName === '') {
        values.ownerName = contact.name !== null ? contact.name : ''
      }
      if (values.ownerGender === '') {
        values.ownerGender = contact.gender !== null ? contact.gender : ''
      }
    }
  }, [contact, values])

  return (
    <>
      {loading ? (
        <Loading>Loading...</Loading>
      ) : (
        <FormValue onSubmit={props.handleSubmit}>
          <InsuranceModal
            isOpen={isAddInsurance}
            onConfirm={() => {}}
            onCancel={() => {
              if (values.insuranceRiders) {
                setIsAddInsurance(false)
                setWaitingModal(false)
                values.insuranceRiders = prevArray
              }
            }}
          >
            <ModalContent>
              <div className="Container">
                <h3 className="Header">เพิ่มอนุสัญญา</h3>
                <FieldArray name="insuranceRiders">
                  {({ push }) => (
                    <>
                      {values.insuranceRiders &&
                        values.insuranceRiders.length > 0 &&
                        values.insuranceRiders.map((friend, index) => {
                          let len = values.insuranceRiders ? values.insuranceRiders.length - 1 : 0
                          if (index !== len) {
                            return null
                          }
                          return (
                            <div className="Body">
                              <div className="Row">
                                <div className="LeftColumn">
                                  <div className="Topic">ประเภทอนุสัญญา</div>
                                  <Field
                                    name={`insuranceRiders.${index}.riderType`}
                                    component={CustomSelectComponent}
                                    options={riderTypeOptions}
                                    validate={validator('กรุณาระบุประเภทอนุสัญญา')}
                                  />
                                </div>
                                <div className="RightColumn">
                                  <div className="Topic">เงินชดเชยต่อวัน</div>
                                  <Field
                                    name={`insuranceRiders.${index}.compensation`}
                                    component={CurrencyModal}
                                  />
                                </div>
                              </div>
                              <div className="Row">
                                <div className="LeftColumn">
                                  <div className="Topic">เบี้ยประกันต่อปี</div>
                                  <Field
                                    name={`insuranceRiders.${index}.premium`}
                                    component={CurrencyModal}
                                  />
                                </div>
                                <div className="RightColumn">
                                  <div className="Topic">
                                    จำนวนปีที่ต้องชำระเบี้ยประกันตามกรมธรรม์
                                  </div>
                                  <Field
                                    name={`insuranceRiders.${index}.peroidYearNumber`}
                                    component={YearsModal}
                                  />
                                </div>
                              </div>
                              <div className="Button">
                                <button
                                  className="primary"
                                  onClick={() => {
                                    if (values.insuranceRiders) {
                                      let setArr = values.insuranceRiders
                                      setArr.push({
                                        riderType: '',
                                        compensation: 0,
                                        premium: 0,
                                        peroidYearNumber: 0,
                                      })
                                      setPrevArray(setArr)
                                      push({
                                        riderType: '',
                                        compensation: 0,
                                        premium: 0,
                                        peroidYearNumber: 0,
                                      })
                                      setIsAddInsurance(false)
                                      setWaitingModal(false)
                                    }
                                  }}
                                  disabled={myValidate(values.insuranceRiders)}
                                >
                                  บันทึก
                                </button>
                              </div>
                            </div>
                          )
                        })}
                    </>
                  )}
                </FieldArray>
              </div>
            </ModalContent>
          </InsuranceModal>
          <InsuranceModal
            isOpen={isChange}
            onConfirm={() => {}}
            onCancel={() => {
              if (values.insuranceRiders) {
                setIsChange(false)
                setWaitingModal(false)
                values.insuranceRiders = prevArray
              }
            }}
          >
            <ModalContent>
              <div className="Container">
                <h3 className="Header">แก้ไขอนุสัญญา</h3>
                <div className="Body">
                  <div className="Row">
                    <div className="LeftColumn">
                      <div className="Topic">ประเภทอนุสัญญา</div>
                      <Field
                        name={`insuranceRiders.${indexField}.riderType`}
                        component={CustomSelectComponent}
                        options={riderTypeOptions}
                        validate={validator('กรุณาระบุประเภทอนุสัญญา')}
                      />
                    </div>
                    <div className="RightColumn">
                      <div className="Topic">เงินชดเชยต่อวัน</div>
                      <Field
                        name={`insuranceRiders.${indexField}.compensation`}
                        component={CurrencyModal}
                      />
                    </div>
                  </div>
                  <div className="Row">
                    <div className="LeftColumn">
                      <div className="Topic">เบี้ยประกันต่อปี</div>
                      <Field
                        name={`insuranceRiders.${indexField}.premium`}
                        component={CurrencyModal}
                      />
                    </div>
                    <div className="RightColumn">
                      <div className="Topic">จำนวนปีที่ต้องชำระเบี้ยประกันตามกรมธรรม์</div>
                      <Field
                        name={`insuranceRiders.${indexField}.peroidYearNumber`}
                        component={YearsModal}
                      />
                    </div>
                  </div>
                  <div className="Button">
                    <button
                      className="primary"
                      onClick={() => {
                        if (values.insuranceRiders) {
                          let setArr = values.insuranceRiders
                          setPrevArray(setArr)
                          setIsChange(false)
                          setWaitingModal(false)
                        }
                      }}
                      disabled={myValidate(values.insuranceRiders, true, indexField)}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </ModalContent>
          </InsuranceModal>
          <CreateNormalForm>
            <div className="Row">
              <div className="Group">
                <div className="Topic">
                  เจ้าของกรมธรรม์ <span>*</span>
                </div>
                <Field
                  className="medium-box fix"
                  name="ownerName"
                  type="string"
                  placeholder="โปรดระบุ"
                  disabled
                />
              </div>
              <div className="Group">
                <ImageComponent
                  isDisabled={values.createdSource === 'tqm_life'}
                  isEdit={stateImage}
                  forOnce={setStateImage}
                />
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="Topic">
                  เพศ <span>*</span>
                </div>
                <StyleGender name="ownerGender" disabled={true} />
              </div>
              <div className="Group">
                <div className="Topic">
                  อายุ <span>*</span>
                </div>
                <Field
                  className="normal-box fix"
                  name="ownerAge"
                  placeholder="โปรดระบุ"
                  value={values.ownerAge}
                  disabled
                />
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="Topic">
                  รูปแบบการประกันภัย <span>*</span>
                </div>
                <Field
                  className="normal-box"
                  name="insurancePattern"
                  component={CustomSelectComponent}
                  validate={validator('กรุณาระบุรูปแบบประกันภัย')}
                  options={insurancePatternOptions}
                  forChangePattern={(value) => {
                    if (value !== values.insurancePattern) {
                      setFieldValue('policyType', '')
                      setFieldValue('insuranceType', '')
                      setFieldValue('purpose', '')
                      setFieldValue('yieldToMaturityRate', '')
                    }
                  }}
                  isDisabled={values.createdSource === 'tqm_life'}
                />
              </div>
              <div className="Group">
                <div className="Topic">
                  ประเภทประกัน <span>*</span>
                </div>
                <Field
                  className="normal-box"
                  name="policyType"
                  component={CustomSelectComponent}
                  validate={validator('กรุณาระบุประเภทประกันภัย')}
                  options={policyTypeOptions[`${values.insurancePattern}`] || []}
                  isDisabled={values.createdSource === 'tqm_life'}
                />
              </div>
            </div>
            <div className="Row">
              {(values.insurancePattern === 'others' && values.policyType === 'home') ||
              (values.insurancePattern === 'personal' && values.policyType === 'accident') ? (
                <div className="Group">
                  <div className="Topic disabled">
                    ประเภทกรมธรรม์ <span>*</span>
                  </div>
                  <Field
                    className="normal-box disabled"
                    name="insuranceType"
                    placeholder="โปรดระบุ"
                    disabled
                  />
                </div>
              ) : (
                <div className="Group">
                  <div className="Topic">
                    ประเภทกรมธรรม์ <span>*</span>
                  </div>
                  <Field
                    className="normal-box"
                    name="insuranceType"
                    component={CustomSelectComponent}
                    validate={validator('กรุณาระบุประเภทกรมธรรม์')}
                    options={insuranceTypeOptions[`${values.policyType}`] || []}
                    isDisabled={values.createdSource === 'tqm_life'}
                  />
                </div>
              )}
              <div className="Group">
                <div className="Topic">
                  บริษัทประกัน <span>*</span>
                </div>
                <Field
                  className="normal-box"
                  name="insuranceCompanyName"
                  component={CustomSelectComponent}
                  validate={validator('กรุณาระบุบริษัทประกัน')}
                  options={insuranceCompanyNameOptions}
                  isDisabled={values.createdSource === 'tqm_life'}
                />
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="Topic">
                  แบบประกัน <span>*</span>
                </div>
                <Field
                  className={errors.name && touched.name ? 'normal-box errors' : 'normal-box'}
                  name="name"
                  type="string"
                  placeholder="กรอกชื่อแบบประกัน"
                  validate={validator('กรุณาระบุชื่อแบบประกัน')}
                  disabled={values.createdSource === 'tqm_life'}
                />
                <ErrorMessage component={Error} name="name" />
              </div>
              <div className="Group">
                <div className="Topic">
                  หมายเลขกรมธรรม์ <span>*</span>
                </div>
                <Field
                  className={
                    errors.policyNo && touched.policyNo ? 'normal-box errors' : 'normal-box'
                  }
                  name="policyNo"
                  type="string"
                  placeholder="โปรดระบุ"
                  validate={validator('กรุณาระบุหมายเลขกรมธรรม์')}
                  disabled={values.createdSource === 'tqm_life'}
                />
                <ErrorMessage component={Error} name="policyNo" />
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="Topic">
                  วันที่เริ่มทำสัญญา <span>*</span>
                </div>
                <DatePickerField
                  name="startPolicyAt"
                  value={values.startPolicyAt || ''}
                  showSelector={true}
                  maxDate={getMaxDate(values.endPolicyAt)}
                  disabled={values.state === 'rejected' || values.createdSource === 'tqm_life'}
                  isErrors={errors.startPolicyAt && touched.startPolicyAt}
                  onChange={(value) => setFieldValue('startPolicyAt', value)}
                />
                <Field
                  hidden={true}
                  name="startPolicyAt"
                  validate={validator('กรุณาระบุวันที่เริ่มทำสัญญา')}
                  placeholder="โปรดระบุ"
                />
                <ErrorMessage component={Error} name="startPolicyAt" />
              </div>
              <div className="Group">
                <div className="Topic">
                  วันที่ครบกำหนดสัญญา (คุ้มครองถึง) <span>*</span>
                </div>
                <DatePickerField
                  name="endPolicyAt"
                  value={values.endPolicyAt || ''}
                  showSelector={true}
                  minDate={getMinDate(values.startPolicyAt)}
                  yearOptions={getYearsOptions(null, 'desc', 50)}
                  disabled={values.state === 'rejected' || values.createdSource === 'tqm_life'}
                  isErrors={errors.endPolicyAt && touched.endPolicyAt}
                  onChange={(value) => {
                    setFieldValue('endPolicyAt', value)
                    setFieldValue('state', getStateFromEndPolicyAt(value))
                  }}
                />
                {values.createdSource !== 'tqm_life' && (
                  <>
                    <Field
                      hidden={true}
                      name="endPolicyAt"
                      validate={validator('กรุณาระบุวันที่ครบกำหนดสัญญา')}
                      placeholder="โปรดระบุ"
                    />
                    <ErrorMessage component={Error} name="endPolicyAt" />
                  </>
                )}
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="Topic">
                  สถานะกรมธรรม์ <span>*</span>
                </div>
                {values.state === 'expired' ? (
                  <input
                    className="normal-box disabled"
                    placeholder="โปรดระบุ"
                    value="กรมธรรม์หมดอายุ"
                    disabled
                  />
                ) : (
                  <Field
                    className="normal-box"
                    name="state"
                    component={CustomSelectComponent}
                    validate={validator('กรุณาระบุสถานะกรมธรรม์')}
                    options={stateOptions}
                    isDisabled={values.createdSource === 'tqm_life'}
                  />
                )}
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="Topic">รายละเอียดเพิ่มเติม</div>
                <Field
                  className="extra-box"
                  component="textarea"
                  name="remark"
                  type="textarea"
                  placeholder="รายละเอียดเพิ่มเติม"
                />
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="Topic">
                  ทุนประกัน (บาท) <span>*</span>
                </div>
                <Field
                  className="normal-box"
                  name="sumInsured"
                  component={CustomCurrencyComponent}
                  validate={validator('กรุณาระบุทุนประกัน')}
                  disabled={values.createdSource === 'tqm_life'}
                />
              </div>
              <div className="Group">
                <div className="Topic">
                  {values.insurancePattern === 'personal'
                    ? 'เบี้ยประกันต่อปี (บาท)'
                    : 'เบี้ยประกันภัยสุทธิต่อปี (บาท)'}{' '}
                  <span>*</span>
                </div>
                <Field
                  className="normal-box"
                  name="premium"
                  component={CustomCurrencyComponent}
                  validate={validator('กรุณาระบุเบี้ยประกัน')}
                  disabled={values.createdSource === 'tqm_life'}
                />
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="Topic">
                  รูปแบบการจ่าย <span>*</span>
                </div>
                <Field
                  className="normal-box"
                  name="paymentType"
                  component={CustomSelectComponent}
                  validate={validator('กรุณาระบุรูปแบบการจ่าย')}
                  options={paymentTypeOptions}
                  isDisabled={values.createdSource === 'tqm_life'}
                />
              </div>
              {values.policyType === 'life' ? (
                <>
                  {values.insuranceType === 'term' || values.insuranceType === '' ? (
                    <div className="Group">
                      <div className="Topic disabled">เงินคืนเมื่อครบสัญญา (%)</div>
                      <Field
                        className="normal-box disabled"
                        name="yieldToMaturityRate"
                        component={CustomPercentComponent}
                        disabled
                      />
                    </div>
                  ) : (
                    <div className="Group">
                      <div className="Topic">เงินคืนเมื่อครบสัญญา (%)</div>
                      <Field
                        className="normal-box"
                        name="yieldToMaturityRate"
                        component={CustomPercentComponent}
                        validate={validator('กรุณาระบุเงินคืนเมื่อครบสัญญา')}
                        disabled={values.createdSource === 'tqm_life'}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="Group">
                  <div className="Topic disabled">เงินคืนเมื่อครบสัญญา (%)</div>
                  <Field
                    className="normal-box disabled"
                    name="yieldToMaturityRate"
                    component={CustomPercentComponent}
                    disabled
                  />
                </div>
              )}
            </div>
            <div className="Row">
              <div className="Group">
                <div className="Topic">
                  วิธีนับระยะเวลาจ่ายเบี้ย <span>*</span>
                </div>
                <Field
                  className="normal-box"
                  name="payPeriodType"
                  component={CustomSelectComponent}
                  validate={validator('กรุณาระบุวิธีนับระยะเวลาจ่ายเบี้ย')}
                  options={payPeriodTypeOptions}
                  isDisabled={values.createdSource === 'tqm_life'}
                />
              </div>
              {values.payPeriodType === '' ? (
                <div className="Group">
                  <div className="Topic disabled">จำนวนนับระยะเวลา *</div>
                  <input className="normal-box disabled" placeholder="โปรดระบุ" disabled />
                </div>
              ) : (
                <div className="Group">
                  <div className="Topic">
                    {values.payPeriodType === 'year' ? 'จำนวนปีที่จ่ายเบี้ย' : 'จ่ายถึงอายุ'}{' '}
                    <span>*</span>
                  </div>
                  <Field
                    className="normal-box"
                    name="payPeriodValue"
                    component={CustomPercentComponent}
                    validate={validator('กรุณาระบุจำนวนปีที่จ่าย')}
                    onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
                    disabled={values.createdSource === 'tqm_life'}
                  />
                </div>
              )}
            </div>
            {values.policyType === 'life' && values.insuranceType === 'investment' && (
              <>
                <div className="Row">
                  <div className="Group">
                    <div className="Topic">
                      วัตถุประสงค์ของกรมธรรม์ <span>*</span>
                    </div>
                    <br />
                    <Field
                      className="normal-box"
                      name="purpose"
                      component={CustomSelectComponent}
                      validate={validator('กรุณาระบุวัตถุประสงค์ของกรมธรรม์')}
                      options={purposeOptions[`${values.insurancePattern}`] || []}
                    />
                  </div>
                  <div className="Group">
                    <div className="Topic">
                      เบี้ยประกันของ unit link เฉพาะส่วนที่ใช้ลดหย่อน
                      <br />
                      ภาษีได้
                    </div>
                    <Field
                      className="normal-box"
                      name="specialFields.premiumAllowance"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                </div>
                <div className="Row">
                  <div className="Group">
                    <div className="Topic">คาดการณ์มูลค่าคงเหลือ ณ ปีสุดท้าย</div>
                    <Field
                      className="normal-box"
                      name="specialFields.residualValue"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                  <div className="Group">
                    <div className="Topic">อัตราผลตอบแทนคาดหวัง</div>
                    <Field
                      className="normal-box"
                      name="specialFields.rateOfReturn"
                      component={CustomSpecialFieldPercentComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                </div>
              </>
            )}
            {values.insurancePattern === 'others' && values.policyType === 'motor' && (
              <>
                <div className="Row">
                  <div className="Group">
                    <div className="Topic">เงินชดเชยกรณีเสียชีวิต สูญเสียอวัยวะ และสายตา</div>
                    <Field
                      className="normal-box"
                      name="specialFields.adDd"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                  <div className="Group">
                    <div className="Topic">เงินชดเชยกรณีทุพพลภาพถาวรสิ้นเชิง</div>
                    <Field
                      className="normal-box"
                      name="specialFields.pd"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                </div>
                <div className="Row">
                  <div className="Group">
                    <div className="Topic">เงินชดเชยกรณีถูกฆาตกรรมหรือลอบทำร้าย</div>
                    <br></br>
                    <Field
                      className="normal-box"
                      name="specialFields.ma"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                  <div className="Group">
                    <div className="Topic">
                      เงินชดเชยกรณีเสียชีวิตจากการขับขี่
                      <br />
                      หรือโดยสารรถจักรยานต์ยนต์
                    </div>
                    <Field
                      className="normal-box"
                      name="specialFields.mc"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                </div>
                <div className="Row">
                  <div className="Group">
                    <div className="Topic">เงินชดเชยกรณีกระดูกแตกหัก</div>
                    <Field
                      className="normal-box"
                      name="specialFields.bb"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                  <div className="Group">
                    <div className="Topic">เงินชดเชยค่ารักษาพยาบาล</div>
                    <Field
                      className="normal-box"
                      name="specialFields.me"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                </div>
                <div className="Row">
                  <div className="Group">
                    <div className="Topic">วงเงินคุ้มครองอุบัติเหตุในวันหยุดนักขัตฤกษ์</div>
                    <Field
                      className="normal-box"
                      name="specialFields.ph"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                  <div className="Group">
                    <div className="Topic">วงเงินคุ้มครองอุบัติเหตุอันเกิดจากภัยธรรมชาติ</div>
                    <Field
                      className="normal-box"
                      name="specialFields.nd"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                </div>
                <div className="Row">
                  <div className="Group">
                    <div className="Topic">
                      วงเงินคุ้มครองอุบัติเหตุขณะเดินทางโดยรถยนต์
                      <br />
                      ส่วนบุคคล
                    </div>
                    <Field
                      className="normal-box"
                      name="specialFields.pp"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                  <div className="Group">
                    <div className="Topic">ค่าเลี้ยงดูบุคคลในครอบครัวเป็นรายเดือน</div>
                    <br></br>
                    <Field
                      className="normal-box"
                      name="specialFields.fcg"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                </div>
                <div className="Row">
                  <div className="Group">
                    <div className="Topic">ค่าเลี้ยงดูสำหรับการดำรงชีพเป็นรายเดือน</div>
                    <Field
                      className="normal-box"
                      name="specialFields.mlb"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                  <div className="Group">
                    <div className="Topic">ค่าชดเชยรายวันกรณีนอนโรงพยาบาล</div>
                    <Field
                      className="normal-box"
                      name="specialFields.hu"
                      component={CustomSpecialFieldComponent}
                      validate={validator('กรุณาระบุจำนวน')}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                </div>
              </>
            )}
            {values.insurancePattern === 'personal' && values.insuranceType !== 'investment' && (
              <div className="Row">
                <div className="Group">
                  <div className="Topic">
                    วัตถุประสงค์ของกรมธรรม์ <span>*</span>
                  </div>
                  <Field
                    className="normal-box"
                    name="purpose"
                    component={CustomSelectComponent}
                    validate={validator('กรุณาระบุวัตถุประสงค์ของกรมธรรม์')}
                    options={purposeOptions[`${values.insurancePattern}`] || []}
                  />
                </div>
              </div>
            )}
          </CreateNormalForm>
          {!waitingModal &&
            values.insurancePattern === 'personal' &&
            values.policyType === 'life' &&
            getMoreInsurance(values.insuranceRiders) && (
              <CreateAddForm>
                <div className="Header" style={{ fontWeight: 'bold' }}>
                  อนุสัญญา
                </div>
                <FieldArray name="insuranceRiders">
                  {({ push, remove, replace }) => {
                    return (
                      <>
                        <div>
                          {values.insuranceRiders &&
                            values.insuranceRiders.length > 0 &&
                            values.insuranceRiders.map((value, index) => {
                              if (get(value, 'riderType') === '') {
                                return null
                              }
                              if (has(value, '_destroy') && value._destroy) {
                                return null
                              }
                              return (
                                <>
                                  <div className="Body" key={index}>
                                    <p className="Text primary">
                                      {getFullNameInsuranceType(value.riderType)}
                                    </p>
                                    <p className="Text">
                                      เบี้ยประกันต่อปี : {financial(value.premium + '')}
                                    </p>
                                    <p className="Text">
                                      จำนวนปีที่ต้องชำระเบี้ยประกันภัยตามกรมธรรม์ :{' '}
                                      {value.peroidYearNumber}
                                    </p>
                                    <p className="Text">
                                      เงินชดเชยต่อวัน : {financial(value.compensation + '')}
                                    </p>
                                    <div
                                      onClick={() => {
                                        if (values.insuranceRiders) {
                                          setPrevArray(values.insuranceRiders)
                                          setIsChange(true)
                                          setWaitingModal(true)
                                          setIndexField(index)
                                        }
                                      }}
                                      style={
                                        values.createdSource === 'tqm_life'
                                          ? { display: 'none' }
                                          : {}
                                      }
                                    >
                                      <PencilIcon name="edit" />
                                    </div>
                                    <div
                                      onClick={() => {
                                        setIsRemove(index)
                                      }}
                                      style={
                                        values.createdSource === 'tqm_life'
                                          ? { display: 'none' }
                                          : {}
                                      }
                                    >
                                      <TrashIcon name="bin" />
                                    </div>
                                  </div>
                                  <Modal
                                    isOpen={isRemove === index}
                                    onConfirm={() => {
                                      if (has(value, 'id')) {
                                        replace(index, {
                                          id: value.id,
                                          riderType: value.riderType,
                                          premium: value.premium,
                                          peroidYearNumber: value.peroidYearNumber,
                                          compensation: value.compensation,
                                          _destroy: true,
                                        })
                                        let arr = values.insuranceRiders
                                          ? values.insuranceRiders
                                          : initialValue
                                        arr.splice(index, 1)
                                        setPrevArray(arr)
                                      } else {
                                        remove(index)
                                        let arr = values.insuranceRiders
                                          ? values.insuranceRiders
                                          : initialValue
                                        arr.splice(index, 1)
                                        setPrevArray(arr)
                                      }
                                      setIsRemove(-1)
                                    }}
                                    onCancel={() => setIsRemove(-1)}
                                  >
                                    <ModalContent>
                                      <div className="Warning">ยืนยันการลบอนุสัญญา</div>
                                    </ModalContent>
                                  </Modal>
                                </>
                              )
                            })}
                        </div>
                      </>
                    )
                  }}
                </FieldArray>
              </CreateAddForm>
            )}
          <ButtonComponents>
            {values.insurancePattern === 'personal' && values.policyType === 'life' && (
              <button
                className="secondary"
                type="button"
                onClick={() => {
                  if (values.insuranceRiders) {
                    let len = values.insuranceRiders.length
                    let obj = values.insuranceRiders[len - 1]
                    if (isEqual(get(obj, 'riderType'), '')) {
                    } else {
                      values.insuranceRiders.push({
                        riderType: '',
                        compensation: 0,
                        premium: 0,
                        peroidYearNumber: 0,
                      })
                      setPrevArray(values.insuranceRiders)
                    }
                    setIsAddInsurance(true)
                    setWaitingModal(true)
                  }
                }}
                style={{ marginRight: '24px' }}
                disabled={values.createdSource === 'tqm_life'}
              >
                + เพิ่มอนุสัญญา
              </button>
            )}
            <button className="primary" type="submit" disabled={props.isSubmitting}>
              {props.isSubmitting ? 'กำลังดำเนินการ' : 'บันทึก'}
            </button>
          </ButtonComponents>
        </FormValue>
      )}
    </>
  )
}

export default InsurancePortForm
