import React, { FC, FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { ErrorMessage, Field, Form, useFormikContext } from 'formik'
import styled from '@emotion/styled'
import { addDays, addYears } from 'date-fns'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'

import CustomAddress from 'components/Address'
import Icon from 'components/Icon'
import { OptionStateType } from 'types/MotorInsuranceType'
import HistoryType from 'types/HistoryType'
import { OptionType } from 'types/GeneralType'
import { createOrder } from 'store/motorInsuranceSlice'

import vehicleChassis from './../../../../../../assets/images/vehicle_chassis.png'
import {
  CheckboxComponent,
  FieldComponent,
  RadioComponent,
  SelectorComponent,
  DatePickerComponent,
} from './components/inputs'

const GroupAddress = ({ name, disabled }) => {
  return (
    <>
      <div className="row_group">
        <FieldGroup>
          <label>
            เลขที่ <Validate>*</Validate>
          </label>
          <FieldComponent name={`${name}.housingNo`} disabled={disabled} />
        </FieldGroup>
        <FieldGroup>
          <label>หมู่</label>
          <FieldComponent name={`${name}.villageNo`} disabled={disabled} />
        </FieldGroup>
      </div>
      <div className="row_group">
        <FieldGroup className="extra">
          <label>หมู่บ้าน/อาคาร</label>
          <FieldComponent name={`${name}.building`} disabled={disabled} />
        </FieldGroup>
      </div>
      <div className="row_group">
        <FieldGroup>
          <label>ซอย/ตรอก</label>
          <FieldComponent name={`${name}.lane`} disabled={disabled} />
        </FieldGroup>
        <FieldGroup>
          <label>ถนน</label>
          <FieldComponent name={`${name}.road`} disabled={disabled} />
        </FieldGroup>
      </div>
      <StyledCustomAddress>
        <CustomAddress addressType={name} validate disabled={disabled} />
      </StyledCustomAddress>
    </>
  )
}

const DropleadForm: FC<DropleadProps> = (props) => {
  const { values, handleSubmit, options, id, data } = props
  const { setFieldValue } = useFormikContext()
  const dispatch = useDispatch()
  const history: HistoryType = useHistory()
  const [isSuggest, setIsSuggest] = useState(false)
  const newCompanyOptions = options?.insuranceCompanyOptions.filter(
    (option) => option.value !== null,
  )
  const today = new Date()
  const tomorrow = addDays(today, 1)
  const inThreeDays = addDays(today, 3)

  useEffect(() => {
    if (values?.address?.policyDeliveryAddress === 'เหมือนที่อยู่บนกรมธรรม์') {
      setFieldValue(
        'order.motorDetails.policyDeliveryAddress',
        values.order.motorDetails.policyAddress,
      )
    }
    if (values?.address?.receiptAddress === 'เหมือนที่อยู่บนกรมธรรม์') {
      setFieldValue('order.motorDetails.receiptAddress', values.order.motorDetails.policyAddress)
    }
  }, [
    values?.address?.policyDeliveryAddress,
    values?.address?.receiptAddress,
    values.order.motorDetails.policyAddress,
  ])

  return (
    <FormValue onSubmit={handleSubmit}>
      <label className="header">กรอกข้อมูลผู้เอาประกันภัย</label>
      <FieldContainer>
        <div className="row_group name">
          <FieldGroup className="prefixName">
            <label>คำนำหน้าชื่อ</label>
            <FieldComponent name="prefixName" disabled />
          </FieldGroup>
          <FieldGroup className="firstName">
            <label>ชื่อจริง</label>
            <FieldComponent name="firstName" disabled />
          </FieldGroup>
          <FieldGroup className="lastName">
            <label>นามสกุล</label>
            <FieldComponent name="lastName" disabled />
          </FieldGroup>
        </div>
        <div className="row_group">
          <FieldGroup>
            <label>
              วันเกิด <Validate>*</Validate>
            </label>

            <DatePickerComponent name="birthdate" value={values?.birthdate || ''} disabled />
          </FieldGroup>
          <FieldGroup>
            <label>
              เลขบัตรประจำตัวประชาชน <Validate>*</Validate>
            </label>
            <FieldComponent
              name="idCard"
              onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
              disabled={!isEmpty(options?.contact?.idCard)}
            />
          </FieldGroup>
        </div>
        <div className="row_group">
          <FieldGroup>
            <label>
              อีเมล <Validate>*</Validate>
            </label>
            <FieldComponent name="email" type="email" placeholder="โปรดระบุ" />
          </FieldGroup>
          <FieldGroup>
            <label>
              เบอร์โทรศัพท์ <Validate>*</Validate>
            </label>
            <FieldComponent name="phoneNumber" maxLength={10} />
          </FieldGroup>
        </div>
        <div className="row_group">
          <FieldGroup className="extra">
            <label>ประกันเดิม</label>
            <SelectorComponent
              name="order.motorDetails.oldCompanyName"
              options={newCompanyOptions || []}
              disable={false}
            />
          </FieldGroup>
        </div>

        {/* ================================== 
            ============== ที่อยู่ ============== 
            ================================== */}

        <div className="topic">ที่อยู่บนกรมธรรม์</div>
        <div className="row_group">
          <FieldGroup>
            <label>ใช้ข้อมูลที่อยู่จาก</label>
            <SelectorComponent
              name="address.policyAddress"
              options={
                options?.addressOptions.filter((o) => o.value !== 'เหมือนที่อยู่บนกรมธรรม์') || []
              }
              cb={(option) => {
                const value = (option as OptionType).value
                if (!isNull(value)) {
                  const newValues = Object.keys(value).reduce((accumulator, key) => {
                    if (value[key] === null) {
                      return { ...accumulator, [key]: '' }
                    } else {
                      return { ...accumulator, [key]: value[key] }
                    }
                  }, {})
                  setFieldValue('order.motorDetails.policyAddress', newValues)
                }
              }}
            />
          </FieldGroup>
        </div>
        <GroupAddress
          name={'order.motorDetails.policyAddress'}
          disabled={!isNull(values?.address?.policyAddress)}
        />

        <div className="topic">ที่อยู่จัดส่งกรมธรรม์</div>
        <div className="row_group">
          <FieldGroup>
            <label>ใช้ข้อมูลที่อยู่จาก</label>
            <SelectorComponent
              name="address.policyDeliveryAddress"
              options={options?.addressOptions || []}
              cb={(option) => {
                const value = (option as OptionType).value
                if (!isNull(value)) {
                  const newValues = Object.keys(value).reduce((accumulator, key) => {
                    if (value[key] === null) {
                      return { ...accumulator, [key]: '' }
                    } else {
                      return { ...accumulator, [key]: value[key] }
                    }
                  }, {})
                  setFieldValue('order.motorDetails.policyDeliveryAddress', newValues)
                }
              }}
            />
          </FieldGroup>
        </div>
        <GroupAddress
          name={'order.motorDetails.policyDeliveryAddress'}
          disabled={!isNull(values?.address?.policyDeliveryAddress)}
        />

        <div className="topic">ที่อยู่ใบเสร็จรับเงิน</div>
        <div className="row_group">
          <FieldGroup>
            <label>ใช้ข้อมูลที่อยู่จาก</label>
            <SelectorComponent
              name="address.receiptAddress"
              options={options?.addressOptions || []}
              cb={(option) => {
                const value = (option as OptionType).value
                if (!isNull(value)) {
                  const newValues = Object.keys(value).reduce((accumulator, key) => {
                    if (value[key] === null) {
                      return { ...accumulator, [key]: '' }
                    } else {
                      return { ...accumulator, [key]: value[key] }
                    }
                  }, {})
                  setFieldValue('order.motorDetails.receiptAddress', newValues)
                }
              }}
            />
          </FieldGroup>
        </div>
        <GroupAddress
          name={'order.motorDetails.receiptAddress'}
          disabled={!isNull(values?.address?.receiptAddress)}
        />

        {/* ================================= 
            ======== รายละเอียดกรมธรรม์ ======== 
            ================================= */}

        <div className="topic">รายละเอียดกรมธรรม์</div>
        <div className="row_group">
          <RadioContainer>
            <label>
              สถานที่ตรวจสภาพรถยนต์ <Validate>*</Validate>
            </label>
            <ErrorMessage component={Error} name="order.motorDetails.inspectionLocation" />
            <div className="row_radio_group">
              <RadioComponent
                name="order.motorDetails.inspectionLocation"
                value="policy"
                text="ใช้ที่อยู่กรมธรรม์"
                checked={values.order.motorDetails.inspectionLocation === 'policy'}
              />
              <RadioComponent
                name="order.motorDetails.inspectionLocation"
                value="receipt"
                text="ใช้ที่อยู่ใบเสร็จรับเงิน"
                checked={values.order.motorDetails.inspectionLocation === 'receipt'}
              />
              <RadioComponent
                name="order.motorDetails.inspectionLocation"
                value="policy_delivery"
                text="ใช้ที่อยู่การจัดส่งกรมธรรม์"
                checked={values.order.motorDetails.inspectionLocation === 'policy_delivery'}
              />
            </div>
          </RadioContainer>
        </div>
        <div className="row_group">
          <FieldGroup>
            <label>
              วันที่ตรวจสภาพรถยนต์ <Validate>*</Validate>
            </label>
            <DatePickerComponent
              name="order.motorDetails.vehicleInspectionDate"
              value={values?.order?.motorDetails?.vehicleInspectionDate || ''}
              minDate={tomorrow}
              maxDate={inThreeDays}
            />
          </FieldGroup>
        </div>
        <div className="row_group">
          <RadioContainer>
            <label>
              รูปแบบกรมธรรม์ <Validate>*</Validate>
            </label>
            <ErrorMessage component={Error} name="order.motorDetails.policyForm" />
            <div className="row_radio_group">
              <RadioComponent
                name="order.motorDetails.policyForm"
                value="online"
                text="กรมธรรม์ออนไลน์"
                checked={values.order.motorDetails.policyForm === 'online'}
              />
              <RadioComponent
                name="order.motorDetails.policyForm"
                value="original"
                text="กรมธรรม์ฉบับจริง"
                checked={values.order.motorDetails.policyForm === 'original'}
              />
            </div>
          </RadioContainer>
        </div>
        <div className="row_group">
          <FieldGroup>
            <label>
              เริ่มการคุ้มครอง <Validate>*</Validate>
            </label>
            <DatePickerComponent
              name="order.motorDetails.startPolicyAt"
              value={values?.order?.motorDetails?.startPolicyAt || ''}
              minDate={tomorrow}
              cb={(value) => {
                setFieldValue('order.motorDetails.endPolicyAt', addYears(new Date(value), 1))
              }}
            />
          </FieldGroup>
          <FieldGroup>
            <label>
              สิ้นสุดการคุ้มครอง <Validate>*</Validate>
            </label>
            <DatePickerComponent
              name="order.motorDetails.endPolicyAt"
              value={values?.order?.motorDetails?.endPolicyAt || ''}
              disabled
            />
          </FieldGroup>
        </div>
        <div className="row_group">
          <FieldGroup>
            <CheckboxComponent
              name="order.motorDetails.insuranceRenewalNotification"
              checked={values.order.motorDetails.insuranceRenewalNotification}
              cb={(isChecked) => {
                setFieldValue('order.motorDetails.insuranceRenewalNotification', isChecked)
              }}
              text="แจ้งเตือนการต่อประกัน"
            />
          </FieldGroup>
        </div>

        {/* +++++++ รอ key พ.ร.บ. +++++++ */}
        {/* <div className="row_group">
          <RadioContainer>
            <label>
              ต้องการ พ.ร.บ. <Validate>*</Validate>
            </label>
            <ErrorMessage component={Error} name="order.motorDetails.prb" />
            <div className="row_radio_group">
              <RadioComponent
                name="prb"
                value="yes"
                text="ใช่"
                // checked={values.order.motorDetails.prb === 'yes'}
              />
              <RadioComponent
                name="prb"
                value="no"
                text="ไม่ใช่"
                // checked={values.order.motorDetails.prb === 'no'}
              />
            </div>
          </RadioContainer>
        </div>
        {values.prb === 'yes' && (
          <div className="row_group">
            <FieldGroup>
              <label>
                เริ่มการคุ้มครอง <Validate>*</Validate>
              </label>
              <DatePickerComponent name="" value={''} minDate={tomorrow} />
            </FieldGroup>
            <FieldGroup>
              <label>
                สิ้นสุดการคุ้มครอง <Validate>*</Validate>
              </label>
              <DatePickerComponent name="" value={''} cb={(value) => {}} disabled />
            </FieldGroup>
          </div>
        )} */}

        {/* ================================== 
            ========= รายละเอียดรถยนต์ ========= 
            ================================== */}

        <div className="topic">รายละเอียดรถยนต์</div>
        <div className="row_group">
          <FieldGroup className="extra">
            <label>
              ชื่อ <Validate>*</Validate>
            </label>
            <FieldComponent
              name="vehicleDetails.name"
              disabled={!isEmpty(values.vehicleDetails.id)}
            />
          </FieldGroup>
        </div>
        <div className="row_group">
          <FieldGroup>
            <label>
              ปีรถยนต์ <Validate>*</Validate>
            </label>
            <SelectorComponent
              name="vehicleDetails.year"
              options={options?.carYearOptions || []}
              disable={true}
            />
            <ErrorMessage component={Error} name="vehicleDetails.year" />
          </FieldGroup>
          <FieldGroup>
            <label>
              ยี่ห้อรถยนต์ <Validate>*</Validate>
            </label>
            <SelectorComponent
              name="vehicleDetails.makeCode"
              options={options?.makeCodeOptions || []}
              disable={true}
            />
            <ErrorMessage component={Error} name="vehicleDetails.makeCode" />
          </FieldGroup>
        </div>
        <div className="row_group">
          <FieldGroup>
            <label>
              รุ่น <Validate>*</Validate>
            </label>
            <SelectorComponent
              name="vehicleDetails.familyCode"
              options={options?.familyCodeOptions || []}
              disable={true}
            />
            <ErrorMessage component={Error} name="vehicleDetails.familyCode" />
          </FieldGroup>
          <FieldGroup>
            <label>
              รุ่นย่อย <Validate>*</Validate>
            </label>
            <SelectorComponent
              name="vehicleDetails.vehicleSubModel"
              options={options?.carSubModelOptions || []}
              disable={true}
            />
            <ErrorMessage component={Error} name="vehicleDetails.vehicleSubModel" />
          </FieldGroup>
        </div>
        <div className="row_group">
          <FieldGroup>
            <label>
              เลขทะเบียนรถยนต์ (ตัวอย่าง 1กก8888) <Validate>*</Validate>
            </label>
            <FieldComponent
              name="vehicleDetails.vehicleLicense.value"
              disabled={values.vehicleDetails.redLicencePlate || !data.vehicleLicense.canEdit}
            />
          </FieldGroup>
          <FieldGroup>
            <label>
              จังหวัดที่จดทะเบียน <Validate>*</Validate>
            </label>
            <SelectorComponent
              name="vehicleDetails.vehicleProvince.value"
              options={options?.provinceOptions || []}
              disable={!data.vehicleProvince.canEdit}
            />
            <ErrorMessage component={Error} name="vehicleDetails.vehicleProvince.value" />
          </FieldGroup>
        </div>
        <div className="row_group">
          <FieldGroup>
            <label>
              เลขตัวถังรถ <Validate>*</Validate>
            </label>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '100%' }}>
                <FieldComponent
                  name="vehicleDetails.vehicleChassis.value"
                  styles={{ width: '100%' }}
                  disabled={!data.vehicleChassis.canEdit}
                />
              </div>
              <div className="icon" onClick={() => setIsSuggest(!isSuggest)}>
                <Icon name="I" color={'var(--agt-primary-color-1)'} />
              </div>
              <div
                className="suggest"
                style={
                  isSuggest
                    ? {
                        position: 'absolute',
                        bottom: '60px',
                        width: 'fit-content',
                        display: 'block',
                      }
                    : { display: 'none', width: '0px' }
                }
              >
                <div className="label">
                  <div>
                    <img alt="Vehicle Chassis" src={vehicleChassis} />
                  </div>
                </div>
              </div>
            </div>
          </FieldGroup>
          <FieldGroup>
            <div className="red_license_checkbox">
              <CheckboxComponent
                name="vehicleDetails.redLicencePlate"
                checked={values.vehicleDetails.redLicencePlate}
                cb={(isChecked) => {
                  setFieldValue('vehicleDetails.redLicencePlate', isChecked)
                  isChecked
                    ? setFieldValue('vehicleDetails.vehicleLicense.value', 'ป้ายแดง')
                    : setFieldValue('vehicleDetails.vehicleLicense.value', '')
                }}
                text="เป็นรถป้ายแดง"
                disabled={!data.vehicleLicense.canEdit}
              />
            </div>
          </FieldGroup>
        </div>
      </FieldContainer>
      <SubmitContainer>
        <button
          type="button"
          onClick={() => {
            const modifiedValues = {
              ...values,
              type: 'update',
              vehicleDetails: {
                ...values.vehicleDetails,
                id: values.vehicleDetails.id,
                expiryDate: values.vehicleDetails.expiryDate,
                carUsage: values.vehicleDetails.carUsage,
                cc: values.vehicleDetails.cc,
                vehicleKey: values.vehicleDetails.vehicleKey,
                vehicleLicense: values.vehicleDetails.vehicleLicense.value,
                vehicleProvince: values.vehicleDetails.vehicleProvince.value,
                vehicleChassis: values.vehicleDetails.vehicleChassis.value,
              },
              order: {
                ...values.order,
                name: `${values.order.motorDetails.productDetails.companyName} ชั้น ${values.order.motorDetails.insureLevel}`,
                motorDetails: {
                  ...values.order.motorDetails,
                  insureLevel: values.order.motorDetails.insureLevel,
                  productDetails: { ...values.order.motorDetails.productDetails },
                },
              },
            }

            delete modifiedValues.order.motorDetails.vehicleInspectionDate
            delete modifiedValues.order.motorDetails.startPolicyAt
            delete modifiedValues.order.motorDetails.endPolicyAt

            dispatch(
              createOrder(id, { ...modifiedValues }, () => history.push(`/opportunities/${id}/`)),
            )
          }}
        >
          บันทึก
        </button>
        <button type="submit" className="secondary">
          แจ้งแนะนำ
        </button>
      </SubmitContainer>
    </FormValue>
  )
}

export default DropleadForm

type DropleadProps = {
  values: any
  handleSubmit: ((event: FormEvent<HTMLFormElement>) => void) | undefined
  options?: OptionStateType | null
  id: number | string
  data: any
}

const FormValue = styled(Form)`
  display: flex;
  flex-direction: column;
  font-size: 17px;

  width: 800px;

  .header {
    font-weight: 700;
    padding: 40px 18px 52px;
  }

  .icon {
    padding-left: 10px;
    padding-top: 12px;
  }
  .suggest {
    padding: 13px 10px;
    border-radius: 5px;
    background: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    .label {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      div {
        line-height: 16px;
      }
    }
    @media (max-width: 1024px) {
      padding: 13px 5px;
    }
  }
`
const FieldContainer = styled.div`
  padding-left: 31px;

  .topic {
    font-weight: 700;
    margin-bottom: 24px;
  }

  .row_group {
    display: flex;
    flex-flow: row wrap;
    gap: 32px;
    margin-bottom: 24px;
  }

  .name {
    .prefixName {
      width: 162px;
    }
    .firstName,
    .lastName {
      width: 255px;
    }

    @media (max-width: 1196px) {
      .prefixName,
      .firstName,
      .lastName {
        width: 352px;
      }
    }
  }
`
const FieldGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 352px;

  & > label {
    padding-bottom: 4px;
  }

  input.specialField {
    width: 352px;
  }

  &.extra {
    width: 100%;
    @media (max-width: 1196px) {
      width: 352px;
    }
  }

  .red_license_checkbox {
    display: flex;
    height: 100%;
    align-items: center;
    align-self: self-end;
    padding-top: 25px;
    @media (max-width: 1196px) {
      align-self: unset;
      padding-top: unset;
    }
  }
`
const Validate = styled.span`
  color: var(--agt-error-color);
`
const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  button {
    width: 164px;
  }
`
const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;

  .row_radio_group {
    display: flex;
    gap: 52px;

    @media (max-width: 1196px) {
      flex-direction: column;
      gap: 0;
    }
  }
`
const Error = styled.div`
  color: var(--agt-error-color);
  font-size: 13px;
`
const StyledCustomAddress = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 1196px) {
    flex-direction: column;
  }
`
