import React, { useMemo, useState } from 'react'
import { dateFormat } from 'utils/helper'
import { useField } from 'formik'
import Modal from 'components/Modal'
import UploadFile from './UploadFile'
import AffiliateLicense from './AffiliateLicense'
import isEmpty from 'lodash/isEmpty'
import {
  Container,
  CustomIcon,
  HoverInfo,
  ContentModal,
  ClockIcon,
  MessageInfo,
} from './DocumentForm.styles'

const Rows = (props) => {
  const { nameArray = '', index = null, data, selectType = [] } = props
  const { name, updatedAt, description } = data
  let typeDocument: string = ''
  if (nameArray === 'agentDocuments') {
    typeDocument = 'documentImageId'
  } else if (nameArray === 'agentImportantDocuments') {
    typeDocument = 'importantDocumentImageId'
  } else {
    typeDocument = 'documentImageId'
  }
  const fieldName = `${nameArray}.${index}.${typeDocument}`
  const [, , helpers] = useField(fieldName)
  const { setValue } = helpers
  const onChange = (value) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value)
    }
  }
  return (
    <div className="body">
      <div className="upload">
        <div className="title">
          <CustomIcon className="note_docs" name="note_docs" />
          <div className="lineclamp">{name}</div>
        </div>
        <UploadFile title={name} {...data} onChange={onChange} selectType={selectType} />
      </div>
      <small>{updatedAt ? dateFormat(updatedAt) : '-'}</small>
      <div className="" style={{ fontSize: 13 }}>
        {description ? description : '-'}
      </div>
    </div>
  )
}

const DocumentsForm = (props) => {
  const { values = {}, options = {}, handleSubmit, dirty, errors, setFieldValue } = props
  const { licenseTypeOptions = [] } = options
  const [infoFile, setInfoFile] = useState<number>(0)
  const [isModal, setIsModal] = useState<string>('')
  const [arrayBrokers, setArrayBrokers] = useState<Array<string> | []>([])

  const closeModal = () => {
    setIsModal('')
  }

  const AffiliateStatus = useMemo(() => {
    if (values?.recruitsStatus?.affiliate?.recruitStatus) {
      return values?.recruitsStatus?.affiliate?.recruitStatus
    }
    return ''
  }, [values?.recruitsStatus?.affiliate?.recruitStatus])

  const LifeBrokerStatus = useMemo(() => {
    if (values?.recruitsStatus?.lifeBroker?.recruitStatus) {
      return values?.recruitsStatus?.lifeBroker?.recruitStatus
    }
    return ''
  }, [values?.recruitsStatus?.lifeBroker?.recruitStatus])

  const NonLifeBrokerStatus = useMemo(() => {
    if (values?.recruitsStatus?.nonLifeBroker?.recruitStatus) {
      return values?.recruitsStatus?.nonLifeBroker?.recruitStatus
    }
    return ''
  }, [values?.recruitsStatus?.nonLifeBroker?.recruitStatus])

  return (
    <Container onSubmit={handleSubmit}>
      {['submit', 'lifeBroker', 'nonLifeBroker'].includes(isModal) && (
        <Modal
          isOpen={!!isModal}
          onConfirm={() => {
            if (isModal === 'submit') {
              handleSubmit()
            } else if (isModal === 'lifeBroker' || isModal === 'nonLifeBroker') {
              setFieldValue('agentLicenses', [
                ...values.agentLicenses,
                {
                  licenseCode: '',
                  licenseImageId: '',
                  licenseType:
                    isModal === 'lifeBroker' ? 'นายหน้าประกันชีวิต' : 'นายหน้าประกันวินาศภัย',
                  status: '',
                },
              ])
              setFieldValue('recruits', [
                ...values?.recruits,
                isModal === 'lifeBroker'
                  ? 'life_broker'
                  : isModal === 'nonLifeBroker'
                  ? 'non_life_broker'
                  : '',
              ])
              closeModal()
              setArrayBrokers([
                ...arrayBrokers,
                isModal === 'lifeBroker' ? 'addLifeBroker' : 'addNonLifeBroker',
              ])
            }
          }}
          onCancel={closeModal}
        >
          {isModal === 'submit' ? (
            <ContentModal>
              <p>หลังจากยืนยันแล้วจะไม่สามารถแก้ไขได้</p>
              <p>คุณยืนยันการส่งเอกสารทั้งหมดใช่หรือไม่?</p>
            </ContentModal>
          ) : isModal === 'lifeBroker' || isModal === 'nonLifeBroker' ? (
            <ContentModal>
              คุณต้องการสมัครเป็น
              <b>
                {isModal === 'lifeBroker' ? 'นายหน้าประกันชีวิต' : 'นายหน้าประกันวินาศภัย'}
              </b>{' '}
              Friday Business ใช่หรือไม่?
              <br />
              <br />
              กรุณาเตรียม
              <br />
              <li>
                ใบอนุญาต{isModal === 'lifeBroker' ? 'นายหน้าประกันชีวิต' : 'นายหน้าประกันวินาศภัย'}
              </li>
              เพื่ออัปโหลดเพิ่มในส่วนเอกสารเลขที่ใบอนุญาต
              <br />
              <br />
              <small className="suggestion">
                (ระหว่างขั้นตอนการสมัคร คุณยังสามารถแนะนำผลิตภัณฑ์ได้ตามปกติ)
              </small>
            </ContentModal>
          ) : null}
        </Modal>
      )}
      <section className="recruitment">
        <div className="affiliateRecruit">
          <b>สมัครผู้แนะนำ</b>
          {(['processing', 'success'].includes(LifeBrokerStatus) ||
            ['processing', 'success'].includes(NonLifeBrokerStatus)) &&
          ['', 'failed', null].includes(AffiliateStatus) ? (
            <button type="button" className="affiliateStatus" disabled>
              สมัครผู้แนะนำ
            </button>
          ) : ['success'].includes(AffiliateStatus) ? (
            <div className="affiliateStatus success">
              <CustomIcon name="correct-outline" color={'var(--agt-white-color)'} />
              สมัครผู้แนะนำเสร็จแล้ว
            </div>
          ) : ['processing'].includes(AffiliateStatus) ? (
            <div className="affiliateStatus process">
              <ClockIcon name="clock_white" color={'var(--agt-white-color)'} />
              อยู่ในขั้นตอนสมัครผู้แนะนำ
            </div>
          ) : ['failed'].includes(LifeBrokerStatus) ? (
            <>
              <button type="button" className="affiliateStatus" disabled>
                สมัครผู้แนะนำ
              </button>
              {values?.recruitsStatus?.affiliate?.nextRecruitmentedAt && (
                <small>
                  {`สมัครได้อีกครั้งวันที่ : `}
                  {dateFormat(values?.recruitsStatus?.affiliate?.nextRecruitmentedAt)}
                </small>
              )}
            </>
          ) : null}
        </div>
        <div className="brokerRecruit">
          <div className="nextRecruitment">
            <b>สมัครนายหน้า</b>
          </div>
          <div className="groupRecruit">
            <HoverInfo className="broker">
              {['success', 'failed', '', null].includes(AffiliateStatus) &&
              ['', null].includes(LifeBrokerStatus) &&
              ['success', 'failed', '', null].includes(NonLifeBrokerStatus) ? (
                <button
                  type="button"
                  className="brokerStatus"
                  onClick={() => setIsModal('lifeBroker')}
                  disabled={arrayBrokers.some((broker) => broker === 'addLifeBroker')}
                >
                  สมัครนายหน้าประกันชีวิต
                </button>
              ) : (['processing'].includes(AffiliateStatus) ||
                  ['processing'].includes(NonLifeBrokerStatus)) &&
                ['', null].includes(LifeBrokerStatus) ? (
                <button type="button" className="brokerStatus" disabled>
                  สมัครนายหน้าประกันชีวิต
                </button>
              ) : ['success'].includes(LifeBrokerStatus) ? (
                <div className="brokerStatus success">
                  <CustomIcon name="correct-outline" color={'var(--agt-white-color)'} />
                  สมัครนายหน้าประกันชีวิตผ่านแล้ว
                </div>
              ) : ['processing'].includes(LifeBrokerStatus) ? (
                <div className="brokerStatus process">
                  <ClockIcon name="clock_white" color={'var(--agt-white-color)'} />
                  อยู่ในขั้นตอนสมัครนายหน้า
                </div>
              ) : ['failed'].includes(LifeBrokerStatus) ? (
                <>
                  <button type="button" className="brokerStatus" disabled>
                    สมัครนายหน้าประกันชีวิต
                  </button>
                  {values?.recruitsStatus?.lifeBroker?.nextRecruitmentedAt && (
                    <small>
                      {`สมัครได้อีกครั้งวันที่ : `}
                      {dateFormat(values?.recruitsStatus?.lifeBroker?.nextRecruitmentedAt)}
                    </small>
                  )}
                </>
              ) : null}
              <CustomIcon className="infoI" name="I" color={'var(--agt-primary-color-1)'} />
              <div className="notice lifeBroker suggest">
                <b>สมัครนายหน้าประกันชีวิต</b>
                <br />
                เพียงแนบใบอนุญาตนายหน้าประกันชีวิต
                <br />
                เพิ่มเติมเพื่อยื่นความประสงค์ในการสมัคร
                <br />
                โดยคุณจะยังสามารถแนะนำผลิตภัณฑ์
                <br />
                ในหมวดประกันวินาศภัยได้ดังเดิม
              </div>
            </HoverInfo>
            <HoverInfo className="broker">
              {['success', 'failed', '', null].includes(AffiliateStatus) &&
              ['success', 'failed', '', null].includes(LifeBrokerStatus) &&
              ['', null].includes(NonLifeBrokerStatus) ? (
                <button
                  type="button"
                  className="brokerStatus"
                  onClick={() => setIsModal('nonLifeBroker')}
                  // disabled={arrayBrokers.some((broker) => broker === 'addNonLifeBroker')}
                  disabled
                >
                  สมัครนายหน้าวินาศภัย
                </button>
              ) : (['processing'].includes(AffiliateStatus) ||
                  ['processing'].includes(LifeBrokerStatus)) &&
                ['', null].includes(NonLifeBrokerStatus) ? (
                <button type="button" className="brokerStatus" disabled>
                  สมัครนายหน้าวินาศภัย
                </button>
              ) : ['success'].includes(NonLifeBrokerStatus) ? (
                <div className="brokerStatus success">
                  <CustomIcon name="correct-outline" color={'var(--agt-white-color)'} />
                  สมัครนายหน้าวินาศภัยผ่านแล้ว
                </div>
              ) : ['processing'].includes(NonLifeBrokerStatus) ? (
                <div className="brokerStatus process">
                  <ClockIcon name="clock_white" color={'var(--agt-white-color)'} />
                  อยู่ในขั้นตอนสมัครนายหน้า
                </div>
              ) : ['failed'].includes(NonLifeBrokerStatus) ? (
                <>
                  <button type="button" className="brokerStatus" disabled>
                    สมัครนายหน้าวินาศภัย
                  </button>
                  {values?.recruitsStatus?.nonLifeBroker?.nextRecruitmentedAt && (
                    <small>
                      {`สมัครได้อีกครั้งวันที่ : `}
                      {dateFormat(values?.recruitsStatus?.nonLifeBroker?.nextRecruitmentedAt)}
                    </small>
                  )}
                </>
              ) : null}
              <CustomIcon className="infoI" name="I" color={'var(--agt-primary-color-1)'} />
              <div className="notice nonLifeBroker suggest">
                <b>สมัครนายหน้าประกันวินาศภัย</b>
                <br />
                เปิดให้สมัครนายหน้าประกันวินาศภัยเร็วๆนี้
              </div>
            </HoverInfo>
          </div>
        </div>
      </section>
      <section className="message">
        {values.isApproved !== 'processing' ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MessageInfo>
              <div
                className="explanation submit"
                style={values?.explain ? {} : { visibility: 'hidden' }}
              >
                <CustomIcon name="note_docs" color="var(--agt-white-color)" />
                <div>
                  <b>ชี้แจงสำคัญ : </b>
                  <label>{values?.explain || 'คำชี้แจงเอกสาร เช่น ลบเอกสาร'}</label>
                </div>
              </div>
            </MessageInfo>
          </div>
        ) : (
          <MessageInfo>
            <div className="explanation" style={values?.explain ? {} : { visibility: 'hidden' }}>
              <CustomIcon name="note_docs" color="var(--agt-white-color)" />
              <div>
                <b>ชี้แจงสำคัญ : </b>
                <label>{values?.explain || 'คำชี้แจงเอกสาร เช่น ลบเอกสาร'}</label>
              </div>
            </div>
          </MessageInfo>
        )}
        {values.isApproved !== 'approved' && (
          <button
            type="button"
            onClick={() => setIsModal('submit')}
            disabled={!(dirty && isEmpty(errors))}
          >
            ยืนยันเอกสาร
          </button>
        )}
      </section>
      <section className="content">
        <HoverInfo className="header">
          <label>เอกสารการสมัคร</label>
          <CustomIcon className="infoI" name="I" color={'var(--agt-primary-color-1)'} />
          <div className="info suggest" style={{ left: 177 }}>
            รองรับไฟล์ PDF เท่านั้น
          </div>
        </HoverInfo>
        <div className="subtopic">
          <div style={{ paddingLeft: 16 }}>เอกสาร</div>
          <div>วันที่อัปเดต</div>
          <div>คำแนะนำ</div>
        </div>
        {(values.agentDocuments || []).map((agentDocument, index) => {
          return (
            <Rows
              index={index}
              nameArray="agentDocuments"
              data={agentDocument}
              selectType={['PDF']}
            />
          )
        })}
      </section>
      <section className="content">
        <HoverInfo className="header">
          <label>สำเนาเอกสารสำคัญ</label>
          <CustomIcon className="infoI" name="I" color={'var(--agt-primary-color-1)'} />
          <div className="info suggest" style={{ left: 196 }}>
            รองรับไฟล์ PDF, JPG, JPEG, PNG
          </div>
        </HoverInfo>
        {(values.agentImportantDocuments || []).map((agentImportantDocument, index) => {
          return (
            <Rows
              index={index}
              nameArray="agentImportantDocuments"
              data={agentImportantDocument}
              selectType={['PDF', 'JPG', 'JPEG', 'PNG']}
            />
          )
        })}
      </section>
      <section className="content">
        <AffiliateLicense
          agentLicenses={values.agentLicenses}
          isApproved={values.isApproved}
          licenseTypeOptions={licenseTypeOptions}
          setInfoFile={setInfoFile}
          infoFile={infoFile}
        />
      </section>
    </Container>
  )
}

export default DocumentsForm
