import React, { FC } from 'react'
import { ProtectedRoute, Switch } from 'routes'

import Contacts from 'pages/contacts/root'
import ContactsNew from 'pages/contacts/new'
import ContactsDetail from 'pages/contacts/_id'
import ContactsEdit from 'pages/contacts/edit'
import InsurancePortfolio from 'pages/insurance_portfolio'
import Planning from 'pages/planning'

const Pages: FC = (props) => (
  <Switch>
    <ProtectedRoute path="/contacts" component={Contacts} exact />
    <ProtectedRoute path="/contacts/new" component={ContactsNew} exact />
    <ProtectedRoute path="/contacts/:id" component={ContactsDetail} exact />
    <ProtectedRoute path="/contacts/:id/edit" component={ContactsEdit} exact />
    <ProtectedRoute path="/contacts/:id/insurance_portfolio" component={InsurancePortfolio} />
    <ProtectedRoute path="/contacts/:id/planning" component={Planning} />
  </Switch>
)

export default Pages
