import React from 'react'
import { ErrorMessage, Field, useField, useFormikContext } from 'formik'
import styled from '@emotion/styled'
import Select from 'react-select'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'

type SelectorComponentType = {
  name: string
  options: Array<any>
  disable?: boolean
  cb?: Function
}
type RadioComponentType = {
  name: string
  value: string
  text: string
  checked?: boolean
}

const Selector = styled(Select)`
  border-radius: 4px;
  align-self: center;

  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1fhf3k1-control {
    height: 48px;
  }
`
const CheckboxGroup = styled.div`
  display: flex;
  gap: 16px;
  input[type='checkbox'],
  label {
    cursor: pointer;
  }
`
const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  input[type='radio'],
  label {
    cursor: pointer;
  }
`
const Error = styled.div`
  color: var(--agt-error-color);
  font-size: 13px;
`
const customStyleError = {
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-error-color)',
    height: '48px',
  }),
}

export const SelectorComponent = ({
  name,
  options,
  disable = false,
  cb = () => {},
}: SelectorComponentType) => {
  const [, meta, helpers] = useField(name)
  const { value, error, touched } = meta
  const { setValue } = helpers
  const defaultSelector = options.find((select) => select.value === value)

  return (
    <div>
      <Selector
        value={defaultSelector}
        styles={touched && error ? customStyleError : ''}
        onChange={(option: any) => {
          const value = (option as any).value
          setValue(value)
          cb(option)
        }}
        placeholder="กรุณาเลือก"
        options={options}
        isDisabled={disable}
      />
    </div>
  )
}

export const FieldComponent = (props) => {
  const { name, styles } = props
  const [, meta, helpers] = useField(name)
  const { error, touched } = meta
  return (
    <>
      <Field
        name={name}
        type="string"
        style={
          error && touched
            ? { ...styles, border: '1px solid var(--agt-error-color)' }
            : { ...styles }
        }
        placeholder="โปรดระบุ"
        {...props}
      />
      <ErrorMessage component={Error} name={name} />
    </>
  )
}

export const RadioComponent = ({ name, value, text, checked }: RadioComponentType) => {
  const { setFieldValue } = useFormikContext()
  return (
    <RadioGroup>
      <input
        type="radio"
        name={name}
        value={value}
        id={`${name}-${value}`}
        onChange={(e) => setFieldValue(name, e.target.value)}
        checked={checked}
      />
      <label htmlFor={`${name}-${value}`}>{text}</label>
    </RadioGroup>
  )
}

export const CheckboxComponent = ({ name, checked = false, cb, text, disabled = false }) => {
  return (
    <CheckboxGroup className="checkbox_wrapper">
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        onChange={(e) => {
          cb(e.target.checked)
        }}
        disabled={disabled}
      />
      <label htmlFor={name}>{text}</label>
    </CheckboxGroup>
  )
}

export const DatePickerComponent = (props) => {
  const { name, value, cb = () => {}, minDate, maxDate, disabled } = props
  const [, meta, helpers] = useField(name)
  const { error, touched } = meta
  const { setFieldValue } = useFormikContext()

  return (
    <>
      <DatePicker
        value={value || ''}
        name={name}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(value) => {
          setFieldValue(name, value)
          cb(value)
        }}
        isErrors={touched && error}
        disabled={disabled}
      />
      <ErrorMessage component={Error} name={name} />
    </>
  )
}
