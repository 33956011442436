import React, { FC, useState, useEffect, useCallback } from 'react'
import { useHistory } from 'routes'
import moment from 'moment'

import session from 'utils/session'
import { UserProps } from 'types/AppType'
import HistoryType from 'types/HistoryType'
import FirstTimeTour from 'pages/FirstTimeTour'

type FirstTimeModalType = {
  currentAgent?: UserProps | null
}

const FirstTimeModal: FC<FirstTimeModalType> = (props) => {
  const { currentAgent } = props
  const [initialized, setInitial] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [remainingDays, setRemainingDays] = useState<number>(0)

  let history: HistoryType = useHistory()

  const logout = useCallback(async () => {
    session.removeAuthToken()
    session.removeAuthTqmLifeToken()
    localStorage.removeItem('isFirstSignIn')
    history.push('/login')
    window.location.reload()
  }, [history])

  useEffect(() => {
    const isFirstTimeVisible = !localStorage.getItem('isFirstSignIn')
    const today = moment(new Date(), 'MM/DD/YYYY')
    const expiredDate = moment(new Date(currentAgent?.expiredAt || ''), 'MM/DD/YYYY')
    const diffDays = expiredDate.diff(today, 'days') + 1

    if (
      session.isAuthTokenPresent() &&
      currentAgent?.agentType === 'guest' &&
      currentAgent?.isFirstSignIn &&
      isFirstTimeVisible &&
      diffDays > 0
    ) {
      setInitial(true)
      setVisible(true)
      setRemainingDays(diffDays)
    }
  }, [setInitial, currentAgent])

  if (initialized) {
    return (
      <FirstTimeTour
        visible={visible}
        setVisible={setVisible}
        currentAgent={currentAgent || null}
        remainingDays={remainingDays}
        logout={logout}
      />
    )
  }

  return null
}

export default FirstTimeModal
