import React, { FC } from 'react'
import styled from '@emotion/styled'

import Tasks from './Tasks'
import LandingNewsCard from 'components/Card/LandingNewsCard'
import LandingPremiumCard from 'components/Card/LandingPremiumCard'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 381px auto;
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  width: 100%;
  padding: 32px 0;

  .agt-landing-tasks-card__container {
    grid-area: 1 / 1 / 3 / 2;
  }
  .agt-landing-premium-card__container {
    grid-area: 1 / 2 / 2 / 3;
  }
  .agt-landing-news-card__container {
    grid-area: 2 / 2 / 3 / 3;
  }
`

const Landing: FC = () => {
  return (
    <Container>
      <Tasks />
      <LandingPremiumCard />
      <LandingNewsCard />
    </Container>
  )
}
export default Landing
