import React, { FC } from 'react'
import { Route, Switch } from 'routes'
import Consents from './Consents'

const Pages: FC = (props) => (
  <Switch>
    <Route path="/consent" component={Consents} />
  </Switch>
)

export default Pages
