import React, { FC, useEffect, useState } from 'react'
import Modal from 'react-modal'
import styled from '@emotion/styled'
import Icon from 'components/Icon'
import { useDispatch, useSelector } from 'react-redux'
import {
  getConsentDetail,
  getConsentURL,
  setConsentDetail,
  setConsentURL,
} from 'store/consentsSlice'
import { sendConsent } from 'store/contactSlice'
import { ContactType } from 'types/ContactType'
import { RootState } from 'store/rootReducer'
import { isEmail } from 'utils/helper'
import { initialContact } from 'store/contactSlice.utils'

type ModalProps = {
  isOpen: boolean
  onConfirm: Function
  onCancel: Function
  onLater: Function
  values: ContactType | null
}

const ModalCustomStyle = {
  content: {
    width: '100%',
    maxWidth: '510px',
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minHeight: '10rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'unset',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '1000',
  },
}
const CloseBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  border: none;
  outline: none;
  width: 100%;
  padding: 24px 32px 16px 0;
  > div {
    cursor: pointer;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px 24px 32px;
  b {
    margin-bottom: 8px;
  }
  .contactBy {
    margin-bottom: 24px;
  }
  .contactBy span {
    color: var(--agt-primary-color-1);
    font-weight: bold;
  }
  .share {
    margin-bottom: 8px;
  }
  .copyURL {
    width: 100%;
    max-width: 445px;
    height: 58px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  .copyURL .url {
    width: 100%;
    max-width: 345px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ccdceb;
    border-radius: 8px 0 0 8px;
  }
  .copyURL .url .text {
    width: 100%;
    max-width: 266px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 16px;
    text-decoration: none;
  }
  .copyURL .paste {
    width: 100%;
    max-width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
  }
  .email {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    > label {
      margin-bottom: 8px;
    }
    > input {
      width: 100%;
    }
    margin-bottom: 8px;
  }
  small {
    color: var(--agt-secondary-color-1);
  }
`
const ChoiceBtn = styled.div`
  display: flex;
  width: 100%;
  .submitBtn {
    width: 100%;
    height: 56px;
    background-color: var(--agt-primary-color-1);
    border-radius: 0;
    &:disabled {
      color: var(--agt-disable-color-2);
      background-color: var(--agt-disable-color-1);
    }
  }
  .laterBtn {
    width: 100%;
    height: 56px;
    background-color: white;
    color: var(--agt-primary-text-color);
    border-top: 1px solid var(--agt-secondary-color-1);
    border-radius: 0;
    &:hover {
      background-color: white;
      color: var(--agt-primary-text-color);
    }
  }
`

const ConsentModal: FC<ModalProps> = (props) => {
  const { isOpen, onConfirm, onLater, onCancel, values } = props
  const [email, setEmail] = useState<string>('')
  const [isWaitUrl, setIsWaitUrl] = useState<boolean>(true)
  const dispatch = useDispatch()
  const consentUrl = useSelector((state: RootState) => state.consents?.consentUrl || null)
  const contact = useSelector((state: RootState) => state.consents?.contact)

  useEffect(
    () => {
      if (values?.id && isWaitUrl) {
        setIsWaitUrl(false)
        dispatch(getConsentURL(values?.id))
      }
    }, // eslint-disable-next-line
    [dispatch, values?.id, isWaitUrl],
  )

  useEffect(() => {
    if (values?.id && consentUrl) {
      let searchConsentUrl = consentUrl.split('?')[1].split('&')
      let keyValues = { agreement_id: '', contact_id: '', jwt_contact_token: '' }
      searchConsentUrl.forEach((keySearch) => {
        let val = keySearch.split('=')
        keyValues[val[0]] = val[1]
      })

      dispatch(getConsentDetail(keyValues))
    }
    //eslint-disable-next-line
  }, [dispatch, consentUrl, values?.id])

  useEffect(() => {
    if (contact?.email) setEmail(contact.email)
  }, [contact])

  useEffect(() => {
    return () => {
      dispatch(setConsentURL(''))
      dispatch(setConsentDetail({ agreement: null, contact: initialContact }))
    }
  }, [])

  return (
    <Modal style={ModalCustomStyle} id="modal" isOpen={isOpen}>
      <CloseBtn>
        <div onClick={() => onCancel()}>
          <Icon name="close_button" />
        </div>
      </CloseBtn>
      <Content>
        <b>การขอความยินยอม</b>
        <label>เราได้ทำหนังสือการยินยอมให้เปิดเผยข้อมูลตาม พ.ร.บ.คอมฯ</label>
        <label className="contactBy">
          ฉบับล่าสุดของ&nbsp;<span>{contact?.name || 'โรแลนด์ แบงส์'}</span>&nbsp;เรียบร้อยแล้ว
        </label>
        <label className="share">คัดลอกลิงก์เพื่อแชร์ให้กับลูกค้าของคุณ</label>
        <div className="copyURL">
          <div className="url">
            <Icon name="chainLink" />
            <a
              className="text"
              href={consentUrl || '/consents'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {consentUrl || ''}
            </a>
          </div>
          <div
            className="paste"
            onClick={() => {
              navigator.clipboard.writeText(consentUrl || '')
              alert('คัดลอกลิงค์เพื่อแชร์ให้กับลูกค้าเรียบร้อยแล้ว')
            }}
          >
            คัดลอก
          </div>
        </div>
        <div className="email">
          <label>หรือระบุที่อยู่อีเมลลูกค้า เพื่อส่งขอความยินยอม</label>
          <input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            placeholder="กรุณาระบุอีเมลลูกค้า"
          />
        </div>
        <small>แนะนำให้ระบุอีเมลลูกค้า</small>
        <small>เพื่อประโยชน์ในการขอความยินยอมฉบับใหม่ในอนาคต</small>
      </Content>
      <ChoiceBtn>
        <button
          className="submitBtn"
          onClick={() => {
            dispatch(
              sendConsent(values?.id || '', email, () => {
                onConfirm()
              }),
            )
          }}
          disabled={!isEmail(email)}
        >
          ส่งขอความยินยอม
        </button>
        <button className="laterBtn" onClick={() => onLater()}>
          ไว้ทีหลัง
        </button>
      </ChoiceBtn>
    </Modal>
  )
}
export default ConsentModal
