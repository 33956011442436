import React, { FC, useState, Fragment, useEffect } from 'react'
import styled from '@emotion/styled'
import { Field } from 'formik'
import Modal from 'react-modal'
import Banner from '../../../assets/images/friday_biz.png'
import { ModalCustomStyle } from 'components/Modal'
import SetStateType from 'types/SetStateType'

const testConsents = [
  { name: 'ท่านจะยอมรับหรือไม่ว่าไม่ชอบสลิ่ม', forcedConsent: true, key: 'afb4ee5d3f' },
  { name: 'ท่านจะยอมรับหรือไม่ว่าไม่ชอบสลิ่ม2', forcedConsent: true, key: 'afb4ee5d3f' },
  { name: 'ท่านจะยอมรับหรือไม่ว่าไม่ชอบสลิ่ม3', forcedConsent: false, key: 'afb4ee5d3f' },
  { name: 'ท่านจะยอมรับหรือไม่ว่าไม่ชอบสลิ่ม4', forcedConsent: true, key: 'afb4ee5d3f' },
]

type ConsentType = {
  consent?: any
  values?: any
  type: string
  isChecked: boolean
  setIsChecked: SetStateType<boolean>
}

const CustomModal = styled.div`
  width: 100%;
  min-width: 736px;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 34px;
  border-bottom: 1px solid var(--agt-background-color-2);
`
const Image = styled.img`
  width: 230px;
  margin-bottom: -8px;
  object-fit: cover;
`
const Content = styled.div`
  width: 100%;
  padding: 34px 96px 44px;
  display: flex;
  flex-direction: column;
  .name {
    display: flex;
    margin-bottom: 24px;
    font-weight: bold;
    width: fit-content;
    color: var(--agt-primary-color-1);
    /* border-bottom: 4px solid var(--agt-primary-color-1); */
  }
  .consents {
    width: 100%;
    height: 100%;
    max-height: 300px;
    white-space: pre-wrap;
    overflow-y: auto;
    margin-bottom: 44px;
  }
  .checked {
    margin-bottom: 48px;
  }
  .checked .listChecked {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    label {
      text-align: center;
    }
    input {
      margin-top: 4px;
      margin-right: 16px;
    }
  }
  .checked .listChecked .agtPlatform {
    color: var(--agt-primary-color-1);
    text-align: center;
  }
  .submit {
    align-self: center;
  }
`
const CheckedBox = styled.div`
  width: fit-content;
  margin: 14px auto 0px;
  color: var(--agt-primary-color-1);
  input {
    margin-right: 10px;
  }
  u {
    cursor: pointer;
  }
`

const Consent: FC<ConsentType> = (props) => {
  const { consent, type, values, isChecked, setIsChecked } = props
  const [activated, setActivated] = useState<string>('')
  const [isRead, setIsRead] = useState<boolean>(false)

  useEffect(() => {
    if (consent && consent.consents && values) {
      let checkedArrays = true
      consent.consents.forEach((array, index) => {
        if (values.consents[index]) {
          if (array.forcedConsent && !values.consents[index].value) {
            checkedArrays = false
            return null
          }
        } else if (array.forcedConsent) {
          checkedArrays = false
          return null
        }
      })
      setIsChecked(checkedArrays)
    }
  }, [setIsChecked, consent, values])

  return (
    <Fragment>
      <Modal isOpen={!!activated} style={ModalCustomStyle}>
        <CustomModal>
          <Header>
            <Image alt="agentProtal" src={Banner} />
          </Header>
          <Content>
            <div className="name">
              {consent?.name || 'นโยบายการเก็บรวบรวมและเปิดเผยข้อมูลส่วนบุคคล'}
            </div>
            <p
              className="consents"
              dangerouslySetInnerHTML={{
                __html: consent?.detail || 'ยังไม่มีการระบุเนื้อหาใดๆ',
              }}
            />
            <div className="checked">
              {(consent?.consents || testConsents).map((consent, index: number) => {
                return (
                  <div className="listChecked">
                    <Field name={`${type}.consents.${index}.value`} type="checkbox" />
                    <div className="agtPlatform">
                      <label>{consent.name}</label>
                    </div>
                  </div>
                )
              })}
            </div>
            <button
              type="button"
              className="submit"
              onClick={() => {
                setActivated('')
                setIsRead(true)
              }}
              disabled={!isChecked}
            >
              ยืนยันข้อมูล
            </button>
          </Content>
        </CustomModal>
      </Modal>
      <CheckedBox>
        <input type="checkbox" checked={isChecked && isRead} />
        {type === 'terms' ? (
          <label>
            ข้าพเจ้าได้อ่านและยอมรับ&nbsp;
            <u
              onClick={() => {
                if (consent) {
                  setActivated('condition')
                } else {
                  alert('เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง')
                }
              }}
            >
              เงื่อนไขและข้อตกลง การลงทะเบียนเข้าใช้งาน Agent Tool Platform
            </u>
            &nbsp;ตามที่ได้ระบุไว้
          </label>
        ) : (
          <label>
            ข้าพเจ้าได้อ่านและยอมรับ&nbsp;
            <u
              onClick={() => {
                if (consent) {
                  setActivated('condition')
                } else {
                  alert('เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง')
                }
              }}
            >
              นโยบายการเก็บรวบรวมและเปิดเผยข้อมูล การลงทะเบียนเข้าใช้งาน Agent Tool Platform
            </u>
            &nbsp;ตามที่ระบุไว้
          </label>
        )}
      </CheckedBox>
    </Fragment>
  )
}

export default Consent
