import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'routes'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Formik } from 'formik'
import queryString from 'querystring'

import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import Loader from 'components/BackgroundLoading'
import HistoryType from 'types/HistoryType'
import { MotorType, SearchStateType } from 'types/MotorInsuranceType'
import { RootState } from 'store/rootReducer'
import { getMotorOptions, getSearch } from 'store/motorInsuranceSlice'
import DropleadForm from './form'
import DropleadSchema from './form/form.validate'
import InsuranceDetail from './InsuranceDetail'

const DropleadProduct: FC = () => {
  let { id, matrix_id } = useParams<ParamsType>()
  const history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const location = useLocation().search
  const jsonStr = queryString.parse(location.replace('?', ''))
  const { motorInsurances, loading, options } = useSelector((state: RootState) => state.motor)
  const [insurancesById, setInsurancesById] = useState<SearchStateType>()

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: 'โอกาสในการขาย' },
    { path: `/opportunities/${id}/catalog`, label: 'เลือกหมวดประกัน' },
    { path: `/opportunities/${id}/catalog/motor`, label: 'ประกันรถยนต์' },
    {
      path: `/opportunities/${id}/catalog/motor/insurance_product${location}`,
      label: 'แสดงแบบประกัน',
    },
    { label: 'ข้อมูลผู้เอาประกันภัย' },
  ]

  useEffect(() => {
    dispatch(getSearch(id, jsonStr))
    dispatch(
      getMotorOptions(id, {
        year: jsonStr.year,
        makeCode: jsonStr.makeCode,
        familyCode: jsonStr.familyCode,
      }),
    )
  }, [dispatch, id])

  const vehicleName = useMemo(() => {
    const vehicleOptions = get(options, 'vehicleOptions', [])
    if (vehicleOptions && vehicleOptions.length > 0)
      return vehicleOptions.find((d) => d.value == jsonStr.vehicleId)?.label || ''
  }, [options])

  useEffect(() => {
    setInsurancesById(motorInsurances.find((d) => d.matrixId === matrix_id))
  }, [matrix_id, jsonStr, id])

  const insuranceData = useMemo(
    () => ({
      companyLogoUrl: insurancesById?.companyLogoUrl || '',
      companyName: insurancesById?.companyName || '',
      netAmount: insurancesById?.netAmount || '',
      carUsage: insurancesById?.carUsage || '',
      garage: insurancesById?.garage || '',
      garageTotal: insurancesById?.garageTotal || '',
      cover: insurancesById?.cover || '',
      carCheck: insurancesById?.carCheck || '',
      terrorism: insurancesById?.terrorism || '',
      coverFlooding: insurancesById?.coverFlooding || '',
      coverDeduct: insurancesById?.coverDeduct || '',
      cover3Rd: insurancesById?.cover3Rd || '',
      cover3RdTime: insurancesById?.cover3RdTime || '',
      cover3RdAsset: insurancesById?.cover3RdAsset || '',
      coverLostFire: insurancesById?.coverLostFire || '',
      coverAcc: insurancesById?.coverAcc || '',
      coverAccNum: insurancesById?.coverAccNum || '',
      coverAccPass: insurancesById?.coverAccPass || '',
      coverAccPassNum: insurancesById?.coverAccPassNum || '',
      coverLegal: insurancesById?.coverLegal || '',
      insureLevel: jsonStr.insureLevel.toString() || '',
    }),
    [insurancesById, jsonStr],
  )

  const vehicleData = useMemo(
    () => ({
      name: vehicleName,
      vehicleLicense: { canEdit: true },
      vehicleProvince: { canEdit: true },
      vehicleChassis: { canEdit: true },
    }),
    [vehicleName],
  )

  return (
    <Container>
      <Header>ประกันรถยนต์</Header>
      <BreadCrumbStyle links={breadCrumbArr} hasBackToRoot iframe />
      {(loading.options || loading.search) && <Loader />}
      <DropleadContainer>
        <InsuranceDetail data={insuranceData} />

        <Formik
          enableReinitialize
          initialValues={{
            ...defaultProps,
            prefixName: options?.contact?.prefixName,
            firstName: options?.contact?.firstName,
            lastName: options?.contact?.lastName,
            birthdate: options?.contact?.birthdate,
            idCard: options?.contact?.idCard,
            email: options?.contact?.email,
            phoneNumber: options?.contact?.phoneNumber,
            vehicleDetails: {
              ...defaultProps.vehicleDetails,
              id: jsonStr.vehicleId.toString(),
              name: vehicleName,
              year: jsonStr.year.toString(),
              cc: jsonStr.cc.toString(),
              expiryDate: jsonStr.expiryDate.toString(),
              carUsage: jsonStr.carUsage.toString(),
              makeCode: jsonStr.makeCode.toString(),
              familyCode: jsonStr.familyCode.toString(),
              vehicleSubModel: jsonStr.vehicleSubModel.toString(),
              vehicleKey: jsonStr.vehicleKey.toString(),
            },
            order: {
              ...defaultProps.order,
              motorDetails: {
                ...defaultProps.order.motorDetails,
                insureLevel: jsonStr.insureLevel.toString(),
                productDetails: { ...insurancesById },
              },
            },
          }}
          validationSchema={DropleadSchema}
          onSubmit={(values) => {
            const modifiedValues = {
              ...values,
              type: 'droplead',
              vehicleDetails: {
                ...values.vehicleDetails,
                id: jsonStr.vehicleId.toString(),
                expiryDate: jsonStr.expiryDate.toString(),
                cc: jsonStr.cc.toString(),
                carUsage: jsonStr.carUsage.toString(),
                vehicleLicense: values.vehicleDetails.vehicleLicense.value,
                vehicleProvince: values.vehicleDetails.vehicleProvince.value,
                vehicleChassis: values.vehicleDetails.vehicleChassis.value,
              },
              order: {
                ...values.order,
                name: `${insurancesById?.companyName} ชั้น ${jsonStr.insureLevel}`,
                motorDetails: {
                  ...values.order.motorDetails,
                  productDetails: { ...insurancesById },
                },
              },
            }
            // console.log('modifiedValues', modifiedValues)
            // dispatch(
            //   createOrder(id, { ...modifiedValues }, () => history.push(`/opportunities/${id}/`)),
            // )
          }}
        >
          {(props) => (
            <DropleadForm
              handleSubmit={props.handleSubmit}
              values={props.values}
              options={options}
              data={vehicleData}
              id={id}
            />
          )}
        </Formik>
      </DropleadContainer>
    </Container>
  )
}
export default DropleadProduct

const defaultProps: MotorType = {
  prefixName: null,
  firstName: null,
  lastName: null,
  birthdate: null,
  idCard: null,
  email: null,
  phoneNumber: null,
  type: null,
  insureLevel: null,
  vehicleDetails: {
    id: null,
    name: null,
    year: 0,
    vehicleSubModel: null,
    redLicencePlate: false,
    vehicleProvince: { value: null, canEdit: null },
    vehicleLicense: { value: null, canEdit: null },
    vehicleChassis: { value: null, canEdit: null },
    expiryDate: null,
    cc: null,
    carUsage: null,
    makeCode: null,
    familyCode: null,
    vehicleKey: null,
  },
  order: {
    orderId: null,
    motorDetails: {
      inspectionLocation: null,
      vehicleInspectionDate: null,
      insuranceRenewalNotification: false,
      policyForm: null,
      oldCompanyName: null,
      policyAddress: {
        housingNo: null,
        villageNo: null,
        building: null,
        lane: null,
        road: null,
        district: null,
        postcode: null,
        province: null,
        subDistrict: null,
      },
      policyDeliveryAddress: {
        housingNo: null,
        villageNo: null,
        building: null,
        lane: null,
        road: null,
        district: null,
        postcode: null,
        province: null,
        subDistrict: null,
      },
      receiptAddress: {
        housingNo: null,
        villageNo: null,
        building: null,
        lane: null,
        road: null,
        district: null,
        postcode: null,
        province: null,
        subDistrict: null,
      },
      startPolicyAt: null,
      endPolicyAt: null,
      productDetails: {
        productId: null,
        promotionCode: null,
        netAmount: null,
        amount: null,
        vat: null,
        duty: null,
        companyCode: null,
        companyName: null,
        level: null,
        cover: null,
        carUsage: null,
        garage: null,
        garageTotal: null,
        coverDeduct: null,
        showDeductNoPaid: null,
        terrorism: null,
        coverFlooding: null,
        carCheck: null,
        cover3Rd: null,
        cover3RdTime: null,
        cover3RdAsset: null,
        coverLostFire: null,
        coverAcc: null,
        coverAccNum: null,
        coverAccPass: null,
        coverAccPassNum: null,
        coverLegal: null,
        coverMed: null,
        promotionCodeSupplier: null,
        completeOnline: null,
        salepiority: null,
        takephoto: null,
        carGroup: null,
        coverPeriod: null,
        startPolicyDate: null,
        premiumType: null,
        matrixId: null,
      },
    },
  },
}
type ParamsType = {
  id: string
  matrix_id: string
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
  font-size: 17px;
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const BreadCrumbStyle = styled(BreadCrumb)`
  border-bottom: 1px solid var(--agt-secondary-color-1);
  padding-bottom: 16px;
`
const DropleadContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-around;
`
