import React, { FC, useEffect } from 'react'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import { useParams, useHistory } from 'routes'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import CreateForm from 'pages/insurance_portfolio/components/InsurancePortForm'
import HistoryType from 'types/HistoryType'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { createInsurance, getContactById } from 'store/contactSlice'
import initialValues from './newInsurance.utils'

const Container = styled.div`
  justify-content: space-around;
  align-items: center;
  width: 100%;
`
const CustomForm = styled.div`
  display: flex;
  justify-content: center;
`
const Header = styled.h3`
  margin: 16px 0 10px;
`
const SubHeader = styled.h4`
  padding-left: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const CreatePortfolio: FC = (props) => {
  let { id }: { id: string } = useParams()
  let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const contact = useSelector((state: RootState) => state.contacts.contact)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'ข้อมูลติดต่อ' },
    { path: `/contacts/${id}`, label: contact?.name || 'รายละเอียดข้อมูลติดต่อ' },
    { path: `/contacts/${id}/insurance_portfolio`, label: 'สรุปกรมธรรม์' },
    { label: 'เพิ่มข้อมูลประกันที่ถืออยู่' },
  ]
  useEffect(() => {
    dispatch(getContactById(id))
  }, [id, dispatch])

  return (
    <Container>
      <Header>เพิ่มข้อมูลประกัน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <SubHeader>ข้อมูลกรมธรรม์</SubHeader>
      <CustomForm>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            dispatch(
              createInsurance(id, values, () => {
                history.push(`/contacts/${id}/insurance_portfolio`)
              }),
            )
          }}
        >
          {(props) => {
            return (
              <CreateForm
                handleSubmit={props.handleSubmit}
                values={props.values}
                errors={props.errors}
                touched={props.touched}
                isSubmitting={props.isSubmitting}
              />
            )
          }}
        </Formik>
      </CustomForm>
    </Container>
  )
}
export default CreatePortfolio
