import React, { FC, useMemo } from 'react'
import styled from '@emotion/styled'
import { get } from 'lodash'

import Mask from './Mask'
import Header from './Header'
import Footer from 'components/Layout/Footer'
import Tabs from 'components/Layout/Tabs'
import { UserProps } from 'types/AppType'

type LayoutProps = {
  appName?: string
  currentAgent?: UserProps | null
}

const Container = styled.div`
  width: 100%;
  max-width: 1194px;
  margin: 0 auto;
  padding: 0 37px;
`
const BodyContainer = styled.div``
const Body = styled.main`
  width: 100% !important;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 112px);
  margin: auto;
`

const Layout: FC<LayoutProps> = ({ currentAgent, children }) => {
  const TabsSlide = useMemo(() => {
    let role: string = get(currentAgent, 'agentType', '')
    if (['guest', 'affiliate', 'tqm_staff', 'broker'].includes(role)) return <Tabs />
    else return null
  }, [currentAgent])

  return (
    <>
      <Mask />
      <Container>
        <BodyContainer>
          <Header currentAgent={currentAgent} />
          {TabsSlide}
          <Body>{children}</Body>
        </BodyContainer>
      </Container>
      <Footer />
    </>
  )
}

const defaultProps: LayoutProps = {
  appName: 'Agent Tools',
  currentAgent: null,
}

Header.defaultProps = defaultProps

export default Layout
