import React, { FC, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { useParams, useHistory } from 'routes'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import ImageModal from 'components/ImageModal'
import HistoryType from 'types/HistoryType'
import { InsurancePortPageType } from 'types/InsurancePortType'
import { financial } from 'pages/insurance_portfolio/root/Summary'
import {
  ModalContent,
  CreateAddForm,
  ButtonComponents,
  CreateNormalForm,
} from 'pages/insurance_portfolio/components/InsurancePortForm'

import {
  POLICY_TYPE,
  INSURANCE_TYPE,
  INSURANCE_STATE,
  INSURANCE_PURPOSE,
  INSURANCE_PATTERN,
} from 'utils/constants'
import { RootState } from 'store/rootReducer'
import { useDispatch, useSelector } from 'react-redux'
import { deleteInsurance } from 'store/contactSlice'

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .GetImage {
    max-height: 840px;
    max-width: 620px;
  }
`
const ShieldIcon = styled(Icon)`
  margin-top: 7px;
  svg {
    width: 17px;
    height: 20px;
  }
`
const getFullNamePurpose = (shortNamePurpose: string) => {
  const text = INSURANCE_PURPOSE[shortNamePurpose]
  return text || 'ไม่ขอระบุวัตถุประสงค์ของกรมธรรม์'
}
export const getFullNameState = (shortNameState: string) => {
  const text = INSURANCE_STATE[shortNameState]
  return text || 'กรมธรรม์ถูกยกเลิก'
}
export const getFullNameInsurancePattern = (shortNameInsurancePattern: string) => {
  const text = INSURANCE_PATTERN[shortNameInsurancePattern]
  return text || 'ไม่ต้องระบุประเภทประกัน'
}
export const getFullNamePolicyType = (shortNamePolicyType: string) => {
  const text = POLICY_TYPE[shortNamePolicyType]
  return text || 'ไม่ต้องระบุประเภทประกัน'
}
export const getFullNameInsuranceType = (shortNameInsuranceType: string) => {
  const text = INSURANCE_TYPE[shortNameInsuranceType]
  return text || 'ไม่ต้องระบุประเภทกรมธรรม์'
}
function getUrlExtension(url) {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}

const InsurancePortPage: FC<InsurancePortPageType> = (props) => {
  const { instead = {}, onEdit = () => {} } = props
  let { id, insuranceId }: { id: string; insuranceId: string } = useParams()
  let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [isImageFile, setIsImageFile] = useState<boolean>(false)

  const document = useMemo(() => {
    if (instead.policyDocumentFullPath) {
      const type = getUrlExtension(instead.policyDocumentFullPath)
      if (type === 'pdf') {
        return (
          <div style={{ width: '800px', height: 'calc(100vh - 100px)' }}>
            <embed src={instead.policyDocumentFullPath} width="800px" height="100%" />
          </div>
        )
      }
      return (
        <img src={instead.policyDocumentFullPath} alt="policyDocumentPath" className="GetImage" />
      )
    }
    return <div />
  }, [instead.policyDocumentFullPath])

  return (
    <div>
      <Modal
        isOpen={isDelete}
        onConfirm={() => {
          setIsDelete(false)
          dispatch(
            deleteInsurance(id, insuranceId, () => {
              history.push(`/contacts/${id}/insurance_portfolio`)
            }),
          )
        }}
        onCancel={() => setIsDelete(false)}
      >
        <ModalContent>
          <div className="Deleted">ยืนยันการลบข้อมูลกรมธรรม์</div>
        </ModalContent>
      </Modal>
      <ImageModal isOpen={isImageFile} onConfirm={() => {}} onCancel={() => setIsImageFile(false)}>
        <ModalContainer>{document}</ModalContainer>
      </ImageModal>
      <CreateNormalForm>
        {instead.createdSource === 'tqm_life' ? (
          <div className="Row">
            <div className="Group">
              <div className="MoreDetailLayer">
                <div className="Topic">เจ้าของกรมธรรม์</div>
                <div className="Normal">{instead.ownerName}</div>
              </div>
            </div>
            <div className="Row" style={{ width: 356, margin: 0, alignItems: 'flex-end' }}>
              <div className="Group" style={{ width: 158, borderBottom: '1px solid #d2d4db' }}>
                <b className="Topic" style={{ marginBottom: 8 }}>
                  รหัสคำสั่งซื้อ
                </b>
                <div className="Normal">{instead.tqmOrderId}</div>
              </div>
              <Link to={`/contacts/${id}/insurance_portfolio/${instead.id}/${instead.orderNo}`}>
                <button type="button" style={{ width: 176 }}>
                  รายละเอียดคำสั่งซื้อ
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="Row">
            <div className="Group">
              <div className="MoreDetailLayer">
                <div className="Topic">เจ้าของกรมธรรม์</div>
                <div className="Large">{instead.ownerName}</div>
              </div>
            </div>
          </div>
        )}
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">เพศ</div>
              <div className="Normal">{instead.ownerGender === 'M' ? 'ชาย' : 'หญิง'}</div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">อายุ</div>
              <div className="Normal">{instead.ownerAge}</div>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">รูปแบบการประกันภัย</div>
              <div className="Normal">
                {getFullNameInsurancePattern(instead.insurancePattern || '')}
              </div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">ประเภทประกัน</div>
              <div className="Normal">{getFullNamePolicyType(instead.policyType || '')}</div>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">ประเภทกรมธรรม์</div>
              <div className="Normal">{getFullNameInsuranceType(instead.insuranceType || '')}</div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">บริษัทประกัน</div>
              <div className="Normal">{instead.insuranceCompanyName}</div>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">แบบประกัน</div>
              <div className="Normal">{instead.name}</div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">หมายเลขกรมธรรม์</div>
              <div className="Normal">{instead.policyNo}</div>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">วันที่เริ่มทำสัญญา</div>
              <div className="Normal">
                {new Date(instead.startPolicyAt || '').toLocaleDateString('th-TH')}
              </div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">วันที่ครบกำหนดสัญญา</div>
              <div className="Normal">
                {instead.endPolicyAt
                  ? new Date(instead.endPolicyAt || '').toLocaleDateString('th-TH')
                  : 'ไม่ได้ระบุวันครบกำหนดสัญญา'}
              </div>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">สถานะกรมธรรม์</div>
              <div className="Normal">
                {instead.state ? getFullNameState(instead.state) : 'ไม่ต้องระบุสถานะกรมธรรม์'}
              </div>
            </div>
          </div>
        </div>
        {instead.remark && instead.remark.length > 0 && (
          <div className="Row">
            <div className="Group">
              <div className="MoreDetailLayer">
                <div className="Topic">รายละเอียดเพิ่มเติม</div>
                <div className="Large">{instead.remark}</div>
              </div>
            </div>
          </div>
        )}
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">ทุนประกัน (บาท)</div>
              <div className="Normal">{instead.sumInsured && financial(instead.sumInsured)}</div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">เบี้ยประกัน (ต่อปี)</div>
              <div className="Normal">{instead.premium && financial(instead.premium)}</div>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">รูปแบบการจ่าย</div>
              <div className="Normal">
                {instead.paymentType === '1' && 'รายเดือน'}
                {instead.paymentType === '3' && 'ราย 3 เดือน'}
                {instead.paymentType === '6' && 'ราย 6 เดือน'}
                {instead.paymentType === '12' && 'ทุก 1 ปี'}
              </div>
            </div>
          </div>
          {instead.yieldToMaturityRate && (
            <div className="Group">
              <div className="MoreDetailLayer">
                <div className="Topic">เงินคืนเมื่อครบสัญญา (%)</div>
                <div className="Normal">{instead.yieldToMaturityRate}</div>
              </div>
            </div>
          )}
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">วิธีนับเวลาจ่ายเบี้ย</div>
              <div className="Normal">
                {instead.payPeriodType === 'year' ? 'จำนวนปีที่จ่ายเบี้ย' : 'จ่ายถึงอายุ'}
              </div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">
                {instead.payPeriodType === 'year' ? 'จำนวนปีที่จ่ายเบี้ย' : 'จ่ายถึงอายุ'}
              </div>
              <div className="Normal">{instead.payPeriodValue}</div>
            </div>
          </div>
        </div>
        {instead.policyType === 'life' && instead.insuranceType === 'investment' && (
          <>
            <div className="Row">
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">วัตถุประสงค์ของกรมธรรม์</div>
                  <br />
                  <div className="Normal">{getFullNamePurpose(instead.purpose || '')}</div>
                </div>
              </div>
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">
                    เบี้ยประกันของ unit link เฉพาะส่วนที่ใช้ลดหย่อน
                    <br />
                    ภาษีได้
                  </div>
                  <div className="Normal">
                    {instead.specialFields?.premiumAllowance &&
                      financial(instead.specialFields?.premiumAllowance.toString())}
                  </div>
                </div>
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">คาดการณ์มูลค่าคงเหลือ ณ ปีสุดท้าย</div>
                  <div className="Normal">
                    {instead.specialFields?.residualValue &&
                      financial(instead.specialFields?.residualValue.toString())}
                  </div>
                </div>
              </div>
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">อัตราผลตอบแทนคาดหวัง</div>
                  <div className="Normal">{instead.specialFields?.rateOfReturn}</div>
                </div>
              </div>
            </div>
          </>
        )}
        {instead.insurancePattern === 'others' && instead.policyType === 'motor' && (
          <>
            <div className="Row">
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">เงินชดเชยกรณีเสียชีวิต สูญเสียอวัยวะ และสายตา</div>
                  <div className="Normal">
                    {instead.specialFields?.adDd &&
                      financial(instead.specialFields?.adDd.toString())}
                  </div>
                </div>
              </div>
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">เงินชดเชยกรณีทุพพลภาพถาวรสิ้นเชิง</div>
                  <div className="Normal">
                    {instead.specialFields?.pd && financial(instead.specialFields?.pd.toString())}
                  </div>
                </div>
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">เงินชดเชยกรณีถูกฆาตกรรมหรือลอบทำร้าย</div>
                  <br></br>
                  <div className="Normal">
                    {instead.specialFields?.ma && financial(instead.specialFields?.ma.toString())}
                  </div>
                </div>
              </div>
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">
                    เงินชดเชยกรณีเสียชีวิตจากการขับขี่
                    <br />
                    หรือโดยสารรถจักรยานต์ยนต์
                  </div>
                  <div className="Normal">
                    {instead.specialFields?.mc && financial(instead.specialFields?.mc.toString())}
                  </div>
                </div>
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">เงินชดเชยกรณีกระดูกแตกหัก</div>
                  <div className="Normal">
                    {instead.specialFields?.bb && financial(instead.specialFields?.bb.toString())}
                  </div>
                </div>
              </div>
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">เงินชดเชยค่ารักษาพยาบาล</div>
                  <div className="Normal">
                    {instead.specialFields?.me && financial(instead.specialFields?.me.toString())}
                  </div>
                </div>
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">วงเงินคุ้มครองอุบัติเหตุในวันหยุดนักขัตฤกษ์</div>
                  <div className="Normal">
                    {instead.specialFields?.ph && financial(instead.specialFields?.ph.toString())}
                  </div>
                </div>
              </div>
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">วงเงินคุ้มครองอุบัติเหตุอันเกิดจากภัยธรรมชาติ</div>
                  <div className="Normal">
                    {instead.specialFields?.nd && financial(instead.specialFields?.nd.toString())}
                  </div>
                </div>
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">
                    วงเงินคุ้มครองอุบัติเหตุขณะเดินทางโดยรถยนต์
                    <br />
                    ส่วนบุคคล
                  </div>
                  <div className="Normal">
                    {instead.specialFields?.pp && financial(instead.specialFields?.pp.toString())}
                  </div>
                </div>
              </div>
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">ค่าเลี้ยงดูบุคคลในครอบครัวเป็นรายเดือน</div>
                  <br></br>
                  <div className="Normal">
                    {instead.specialFields?.fcg && financial(instead.specialFields?.fcg.toString())}
                  </div>
                </div>
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">ค่าเลี้ยงดูสำหรับการดำรงชีพเป็นรายเดือน</div>
                  <div className="Normal">
                    {instead.specialFields?.mlb && financial(instead.specialFields?.mlb.toString())}
                  </div>
                </div>
              </div>
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">ค่าชดเชยรายวันกรณีนอนโรงพยาบาล</div>
                  <div className="Normal">
                    {instead.specialFields?.hu && financial(instead.specialFields?.hu.toString())}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {instead.insuranceType !== 'investment' && (
          <div className="Row">
            <div className="Group">
              <div className="MoreDetailLayer">
                <div className="Topic">วัตถุประสงค์ของกรมธรรม์</div>
                <div className="Normal">{getFullNamePurpose(instead.purpose || '')}</div>
              </div>
            </div>
          </div>
        )}
        <button
          className="ImageButton"
          type="button"
          onClick={() => setIsImageFile(true)}
          disabled={!instead.policyDocumentFullPath}
        >
          <ShieldIcon
            color={instead.policyDocumentFullPath ? '#ffffff' : '#b5b6bf'}
            name="Vector"
            className="EditIcon"
          />
          <div style={{ marginLeft: '14px' }}>ดูหน้ากรมธรรม์</div>
        </button>
      </CreateNormalForm>
      {instead.insuranceRiders && instead.insuranceRiders.length > 0 && (
        <CreateAddForm>
          <div className="Header" style={{ fontWeight: 'bold' }}>
            อนุสัญญา
          </div>
          {instead.insuranceRiders.map((value, index) => {
            return (
              <div className="Body" key={index}>
                <p className="Text primary">{getFullNameInsuranceType(value.riderType)}</p>
                <p className="Text">เบี้ยประกันต่อปี : {financial(value.premium + '')}</p>
                <p className="Text">
                  จำนวนปีที่ต้องชำระเบี้ยประกันภัยตามกรมธรรม์ : {value.peroidYearNumber}
                </p>
                <p className="Text">เงินชดเชยต่อวัน : {financial(value.compensation + '')}</p>
              </div>
            )
          })}
        </CreateAddForm>
      )}
      <ButtonComponents>
        <button
          className="secondary"
          type="button"
          style={{ marginRight: '24px' }}
          onClick={() => setIsDelete(true)}
          disabled={instead?.createdSource === 'tqm_life' || currentAgent?.statusTh === 'ระงับ'}
        >
          ลบ
        </button>
        <button
          className="secondary"
          type="button"
          onClick={() => onEdit()}
          disabled={currentAgent?.statusTh === 'ระงับ'}
        >
          แก้ไข
        </button>
      </ButtonComponents>
    </div>
  )
}

export default InsurancePortPage
