import React, { FC, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import ProfilePage from './ProfilePage'
import { RootState } from 'store/rootReducer'
import { getContactById, getOptions } from 'store/contactSlice'
import WebTour from './WebTour'
import WebTourNonConsent from './WebTourNonConsent'

const Container = styled.div`
  width: 100%;
  .isLoading {
    text-align: center;
  }
`

const ContactDetail: FC = () => {
  let { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { contact, consentCurrentVersion, lastConsentHistory, initialized } = useSelector(
    (state: RootState) => state.contacts,
  )
  const [isShowHint, setIsShowHint] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getContactById(id))
    dispatch(getOptions())
  }, [dispatch, id])

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (storage['contacts']['contactById'] === false) {
        window.localStorage.setItem(
          'webtour',
          JSON.stringify({
            ...storage,
            contacts: { ...storage.contacts, contactById: true },
          }),
        )
        setTimeout(() => {
          setIsShowHint(true)
        }, 1000)
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: false, create: false, contactById: true },
          tasks: { tasks: false, create: false },
          calendar: { calendar: false, create: false },
          opportunities: {
            opportunities: false,
            create: false,
            opportunityById: false,
            completed: false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [setIsShowHint])

  return (
    <Container>
      {contact?.status === 'รอยินยอม' ? (
        <WebTourNonConsent visible={isShowHint} setVisible={setIsShowHint} />
      ) : (
        <WebTour visible={isShowHint} setVisible={setIsShowHint} />
      )}
      {initialized ? (
        <p className="isLoading">loading...</p>
      ) : (
        <ProfilePage
          contact={contact}
          consentCurrentVersion={consentCurrentVersion}
          lastConsentHistory={lastConsentHistory}
          getContactByIdAgain={() => {
            dispatch(getContactById(id))
          }}
          setIsShowHint={setIsShowHint}
        />
      )}
    </Container>
  )
}
export default ContactDetail
