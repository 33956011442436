import React, { FC, useState, useEffect, useMemo } from 'react'
import { useLocation } from 'routes'
import get from 'lodash/get'

import SuccessPage from 'pages/register/guest/Success'
import RegisterPage from 'pages/register/guest/GuestForm'
import { go2Top } from 'utils/helper'
import { useDispatch, useSelector } from 'react-redux'
import { getConsentRegister, getConsentById } from 'store/appSlice'
import { RootState } from 'store/rootReducer'

const Form: FC = (props) => {
  const location = useLocation()
  const { state: stateLocate } = location
  const [state, setState] = useState<'success' | 'registering'>('registering')
  const [email, setEmail] = useState<string>('')
  const dispatch = useDispatch()
  const pdpa = useSelector((state: RootState) => state.app.pdpa)
  const termsAndConditions = useSelector((state: RootState) => state.app.termsAndConditions)
  const pdpaAgreement = useSelector((state: RootState) => state.app.pdpaAgreement)
  const termAgreement = useSelector((state: RootState) => state.app.termAgreement)

  useEffect(() => {
    go2Top()
  }, [state])

  useEffect(() => {
    dispatch(getConsentRegister())
  }, [dispatch])

  useEffect(() => {
    if (pdpa) dispatch(getConsentById(pdpa.id, 'pdpa'))
  }, [dispatch, pdpa])

  useEffect(() => {
    if (termsAndConditions) dispatch(getConsentById(termsAndConditions.id, 'term'))
  }, [dispatch, termsAndConditions])

  useEffect(() => {
    if (stateLocate) {
      setEmail(get(stateLocate, 'resendEmail'))
      setState('success')
    }
  }, [stateLocate])

  const modifiedAgreement = useMemo(() => {
    if (pdpaAgreement && termAgreement) {
      let modifiedConsents: Array<{
        name: string
        key: string
        value: boolean
        forcedConsent: boolean
      }> = []
      pdpaAgreement.consents?.forEach((consent) => {
        let newConsent = { ...consent, value: false }
        modifiedConsents.push(newConsent)
      })
      let pdpaModified = {
        id: pdpaAgreement.id,
        consents: modifiedConsents.length > 0 ? modifiedConsents : [],
      }
      modifiedConsents = []
      termAgreement.consents?.forEach((consent) => {
        let newConsent = { ...consent, value: false }
        modifiedConsents.push(newConsent)
      })
      let termModified = {
        id: termAgreement.id,
        consents: modifiedConsents.length > 0 ? modifiedConsents : [],
      }
      let array: Array<{
        id: string
        consents: Array<{
          name: string
          key: string
          forcedConsent: boolean
          value: boolean
        }>
      }> = []
      array.push(pdpaModified)
      array.push(termModified)
      return array
    } else return null
  }, [pdpaAgreement, termAgreement])

  const mockupTerm = useMemo(() => {
    if (termAgreement) {
      let modifiedConsents: Array<{
        name: string
        key: string
        value: boolean
        forcedConsent: boolean
      }> = []
      termAgreement.consents?.forEach((consent) => {
        let newConsent = { ...consent, value: false }
        modifiedConsents.push(newConsent)
      })
      let termModified = {
        id: termAgreement.id,
        consents: modifiedConsents.length > 0 ? modifiedConsents : [],
      }
      return termModified
    } else return null
  }, [termAgreement])

  const mockupPDPA = useMemo(() => {
    if (pdpaAgreement) {
      let modifiedConsents: Array<{
        name: string
        key: string
        value: boolean
        forcedConsent: boolean
      }> = []
      pdpaAgreement.consents?.forEach((consent) => {
        let newConsent = { ...consent, value: false }
        modifiedConsents.push(newConsent)
      })
      let pdpaModified = {
        id: pdpaAgreement.id,
        consents: modifiedConsents.length > 0 ? modifiedConsents : [],
      }
      return pdpaModified
    } else return null
  }, [pdpaAgreement])

  if (state === 'success') {
    return <SuccessPage email={email} resend={get(stateLocate, 'callApi', 'N') === 'Y'} />
  }

  return (
    <RegisterPage
      onSuccess={() => setState('success')}
      setEmail={setEmail}
      termsAndConditions={termAgreement}
      pdpa={pdpaAgreement}
      agreement={modifiedAgreement}
      mockupTerm={mockupTerm}
      mockupPDPA={mockupPDPA}
    />
  )
}

export default Form
