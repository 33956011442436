import React, { FC } from 'react'
import { ProtectedRoute, Switch } from 'routes'
import Opportunities from 'pages/opportunities/root'
import OpportunityCard from 'pages/opportunities/root/OpportunityCard'
import SearchProduct from 'pages/opportunities/tqm-life/search-product'
import OrderDetail from 'pages/opportunities/tqm-life/order-detail'
import CatalogProduct from 'pages/opportunities/catalog'
import MotorProduct from 'pages/opportunities/catalog/motor/index'
import DropleadProduct from 'pages/opportunities/catalog/motor/droplead/index'
import EditDropleadProduct from 'pages/opportunities/catalog/motor/droplead/edit'
import InsuranceProduct from 'pages/opportunities/catalog/motor/insurance_product/index'
import ActProduct from 'pages/opportunities/catalog/act'
import NewAct from 'pages/opportunities/catalog/act/NewAct'
import EditAct from 'pages/opportunities/catalog/act/EditAct'
import DetailAct from 'pages/opportunities/catalog/act/DetailAct'
import TravelProduct from 'pages/opportunities/catalog/travel'
import TravelInsuranceProduct from 'pages/opportunities/catalog/travel/insurance_product'
import DropleadTravelProduct from 'pages/opportunities/catalog/travel/droplead'
import EditDropleadTravelProduct from 'pages/opportunities/catalog/travel/droplead/edit'
import DetailDropleadTravelProduct from 'pages/opportunities/catalog/travel/droplead/detail'
const Pages: FC = (props) => (
  <Switch>
    <ProtectedRoute
      path="/opportunities/:id/motor/droplead/edit/:order_id"
      component={EditDropleadProduct}
    />

    <ProtectedRoute
      path="/opportunities/:id/motor/droplead/:order_id"
      component={EditDropleadProduct}
    />
    <ProtectedRoute
      path="/opportunities/:id/catalog/motor/insurance_product/droplead/:matrix_id"
      component={DropleadProduct}
    />
    <ProtectedRoute
      path="/opportunities/:id/catalog/motor/insurance_product"
      component={InsuranceProduct}
    />
    <ProtectedRoute path="/opportunities/:id/catalog/motor" component={MotorProduct} />
    <ProtectedRoute path="/opportunities/:id/catalog/act/form/:orderNo" component={EditAct} />
    <ProtectedRoute path="/opportunities/:id/catalog/act/form" component={NewAct} />
    <ProtectedRoute path="/opportunities/:id/catalog/act/:orderNo" component={DetailAct} />
    <ProtectedRoute path="/opportunities/:id/catalog/act" component={ActProduct} />
    <ProtectedRoute
      path="/opportunities/:id/travel/droplead/edit/:order_id"
      component={EditDropleadTravelProduct}
    />
    <ProtectedRoute
      path="/opportunities/:id/travel/droplead/:order_id"
      component={DetailDropleadTravelProduct}
    />
    <ProtectedRoute
      path="/opportunities/:id/catalog/travel/insurance_product/droplead/:matrix_id"
      component={DropleadTravelProduct}
    />
    <ProtectedRoute
      path="/opportunities/:id/catalog/travel/insurance_product"
      component={TravelInsuranceProduct}
    />
    <ProtectedRoute path="/opportunities/:id/catalog/travel" component={TravelProduct} />
    <ProtectedRoute path="/opportunities/:id/catalog" component={CatalogProduct} />
    <ProtectedRoute
      path="/opportunities/:id/search-product/:contact_id"
      component={SearchProduct}
    />
    <ProtectedRoute
      path="/opportunities/:id/order-detail/:orderNo/:documentInfo"
      component={OrderDetail}
    />
    <ProtectedRoute path="/opportunities/:id/order-detail/:orderNo/" component={OrderDetail} />
    <ProtectedRoute path="/opportunities/new" component={OpportunityCard} />
    <ProtectedRoute path="/opportunities/:id" component={OpportunityCard} />
    <ProtectedRoute path="/opportunities" component={Opportunities} />
  </Switch>
)

export default Pages
