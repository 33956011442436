import { ContactType } from 'types/ContactType'
import { MotorStateType, OrderType, VehicleDetailsType } from 'types/MotorInsuranceType'
import { initialAddress } from './utils'

export const initialContact: ContactType = {
  id: 0,
  age: 0,
  avatar: '',
  email: '',
  name: '',
  gender: '',
  nickname: '',
  firstName: '',
  lastName: '',
  birthdate: '',
  maritalStatus: '',
  prefixName: '',
  idCard: '',
  phoneNumber: '',
  occupation: '',
  entertainment: {},
  health: {},
  travel: {},
  food: {},
  position: '',
  company: '',
  facebook: '',
  lineId: '',
  canDeleted: true,
  homeAddress: initialAddress,
  currentAddress: initialAddress,
  officeAddress: initialAddress,
  idCardAddress: initialAddress,
  isSameHomeAddress: false,
  isSameCurrentAddress: false,
  isSameIdCardAddress: false,
  updatedAt: '',
  createdAt: '',
}
export const initialOrder: OrderType = {
  id: null,
  name: null,
  companyName: null,
  companyLogoUrl: null,
  orderNo: null,
  status: null,
  premium: null,
  draftingParams: null,
  salesman: null,
  affiliate: null,
  motorInsurance: {
    inspectionLocation: null,
    insuranceRenewalNotification: null,
    oldCompanyName: null,
    policyForm: null,
    insureLevel: null,
    policyAddress: null,
    policyDeliveryAddress: null,
    receiptAddress: null,
    productDetails: {
      productId: null,
      promotionCode: null,
      netAmount: null,
      amount: null,
      vat: null,
      duty: null,
      companyCode: null,
      companyName: null,
      level: null,
      cover: null,
      carUsage: null,
      garage: null,
      garageTotal: null,
      coverDeduct: null,
      showDeductNoPaid: null,
      terrorism: null,
      coverFlooding: null,
      carCheck: null,
      cover3Rd: null,
      cover3RdTime: null,
      cover3RdAsset: null,
      coverLostFire: null,
      coverAcc: null,
      coverAccNum: null,
      coverAccPass: null,
      coverAccPassNum: null,
      coverLegal: null,
      coverMed: null,
      promotionCodeSupplier: null,
      completeOnline: null,
      salepiority: null,
      takephoto: null,
      carGroup: null,
      coverPeriod: null,
      startPolicyDate: null,
      premiumType: null,
      matrixId: null,
    },
    startPolicyAt: null,
    endPolicyAt: null,
    vehicleInspectionDate: null,
  },
  lastUpdatedAt: null,
  submittedAt: null,
}

export const initialVehicle: VehicleDetailsType = {
  id: null,
  name: null,
  year: null,
  vehicleSubModel: null,
  redLicencePlate: null,
  vehicleProvince: { value: null, canEdit: null },
  vehicleLicense: { value: null, canEdit: null },
  vehicleChassis: { value: null, canEdit: null },
  expiryDate: null,
  cc: null,
  carUsage: null,
  makeCode: null,
  familyCode: null,
  vehicleKey: null,
}

export const initialState: MotorStateType = {
  initialized: false,
  error: null,
  options: {
    contact: initialContact,
    vehicleOptions: [],
    insureLevelOptions: [],
    carYearOptions: [],
    makeCodeOptions: [],
    familyCodeOptions: [],
    carSubModelOptions: [],
    expiryDateOptions: [],
    insuranceCompanyOptions: [],
    coverAmountOptions: [],
    provinceOptions: [],
    addressOptions: [],
  },
  motorInsurances: [],
  order: { contact: initialContact, order: initialOrder, vehicle: initialVehicle },
  loading: {
    options: false,
    search: false,
    order: false,
  },
}
