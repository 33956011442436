import isEmpty from 'lodash/isEmpty'

const DEFAULT_VALUES = {
  firstName: null,
  lastName: null,
  gender: null,
  nickname: null,
  phoneNumber: null,
  idCard: null,
  idCardImageId: null,
  idCardImageStatus: null,
  email: null,
  agentLicenses: [
    {
      licenseCode: '',
      licenseImageId: '',
      status: '',
      licenseType: 'ตัวแทนประกันชีวิต',
    },
  ],
  idCardAddress: {
    housingNo: null,
    villageNo: null,
    building: null,
    lane: null,
    road: null,
    district: null,
    postcode: null,
    province: null,
    subDistrict: null,
  },
  currentAddress: {
    housingNo: null,
    villageNo: null,
    building: null,
    lane: null,
    road: null,
    district: null,
    postcode: null,
    province: null,
    subDistrict: null,
  },
  avatar: null,
  insuranceCompanyName: null,
  isOTP: '',
}

function customizer(objValue, srcValue) {
  if (isEmpty(objValue)) {
    return srcValue
  }
  return objValue
}

export { DEFAULT_VALUES, customizer }
