import styled from '@emotion/styled'
import React, { FC, useState, useEffect, useMemo, Fragment } from 'react'
import { useParams, useHistory, useLocation } from 'routes'
import { Formik } from 'formik'
import moment from 'moment'

import Modal from 'components/Modal'
import TaskForm from '../components/TaskForm'
import StarRating from 'components/StarRating'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'

import HistoryType from 'types/HistoryType'
import { currency, dateFormat, phoneNumberFormat } from 'utils/helper'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTask, getTaskById, updateTask } from 'store/taskSlice'
import { OpportunityType } from 'types/TaskType'
import Icon from 'components/Icon'
import Avatar from 'components/Avatar'
import { get } from 'lodash'
import { RootState } from 'store/rootReducer'

type TaskType = {
  id: number | null
  name: string
  dueDate: string | null
  priorityLevel: number | null
  contactId: number | null
  status: string | null
  description: string
  taskDocuments: File[]
  contactName?: string
  contactPhoneNumber?: string
  contactEmail?: string
  opportunity?: OpportunityType | null
  contactStatus?: string | null
}

moment().locale('th')

const Container = styled.div`
  width: 100%;
  padding: 16px 0;
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const ContentContainer = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  padding-top: 40px;
`
const GroupComponents = styled.div`
  border-bottom: 1px solid #a0a8bc;
`
const DetailComponents = styled.div`
  margin: 16px 0;
`
const BagIcon = styled(Icon)`
  width: 23px;
  height: 23px;
  svg {
    width: 23px;
    height: 23px;
  }
`
const NoteIcon = styled(Icon)`
  cursor: pointer;
  width: 18px;
  height: 24px;
  svg {
    width: 18px;
    height: 24px;
  }
`
const Detail = styled.div`
  display: flex;
  margin: 16px 0;
  .dueDate {
    margin-left: 18px;
    margin-right: 24px;
  }
  .time {
    margin-right: 8px;
  }
  .remainTime {
    margin-left: 40px;
  }
  .OpportunityCard {
    width: 352px;
    b {
      color: var(--agt-primary-color-1);
    }
    .title {
      background: var(--agt-background-color-3);
      height: 48px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 14px;
      padding-right: 6px;
    }
    .body {
      height: 386px;
      border: 1px solid var(--agt-secondary-color-1);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .body {
      height: 386px;
      border: 1px solid var(--agt-secondary-color-1);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .body .card {
      width: 244px;
      max-height: 278px;
      border: 1px solid var(--agt-secondary-color-1);
      border-radius: 4px;
      padding: 4px;
      padding-top: 8px;
    }
    .body .card .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
      padding-left: 2px;
      margin-bottom: 6px;
    }
    .body .card .content {
      display: flex;
      flex-direction: column;
    }
    .body .card .content .avatar {
      display: flex;
      align-items: center;
      padding-bottom: 6px;
      border-bottom: 1px solid var(--agt-secondary-color-1);
      label {
        color: var(--agt-primary-color-1);
        margin-left: 8px;
      }
    }
    .body .card .content .orders {
      display: flex;
      flex-direction: column;
    }
    .body .card .content .orders .premium {
      display: flex;
      align-items: flex-start;
      padding: 12px;
      padding-bottom: 10px;
      b {
        margin-left: 8px;
        color: var(--agt-primary-color-1);
      }
    }
    .body .card .content .orders .order {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--agt-secondary-color-1);
      padding: 0 4px;
    }
    .body .card .content .orders .order .status {
      display: flex;
      justify-content: space-between;
      color: var(--agt-secondary-color-1);
    }
    .body .card .content .updatedAt {
      margin: 6px;
      align-self: end;
      color: var(--agt-secondary-color-1);
    }
    .body .card .footer {
      width: 100%;
      height: 32px;
      color: var(--agt-primary-color-1);
      background: var(--agt-light-sky-color);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      small {
        margin-left: 8px;
      }
    }
  }
`
const ButtonComponents = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`
const DeleteModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0 48px 0;
  width: 352px;
  /* height: 164px; */
  background: #ffffff;
  border-radius: 4px;
`
const StarDisplay = styled(StarRating)`
  margin-left: 32px;
`

const defaultTask: TaskType = {
  id: null,
  name: '',
  dueDate: null,
  priorityLevel: null,
  contactId: null,
  status: null,
  taskDocuments: [],
  description: '',
  opportunity: null,
}

const OpportunityCard = (props) => {
  const { values, premium = 0 } = props
  return (
    <div
      className="OpportunityCard"
      style={{ position: 'absolute', left: 385, top: 0, display: 'none' }}
    >
      <div
        className="title"
        style={
          values.opportunity
            ? {
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                paddingRight: 16,
                position: 'relative',
              }
            : {}
        }
      >
        <b>โอกาสในการขาย</b>
      </div>
      {values.opportunity && (
        <div className="body">
          <div className="card">
            <div className="header">
              <div>{values.opportunity?.name || ''}</div>
              <Icon name="dot_option" />
            </div>
            <div className="content">
              <div className="avatar">
                <Avatar type="circle" width="32px" height="32px" avatar="" />
                <label>{values.opportunity?.contactName || ''}</label>
              </div>
              {values.opportunity?.orders && values.opportunity.orders?.length > 0 && (
                <div className="orders">
                  <div className="premium">
                    <BagIcon name="bag" />
                    <b>
                      ฿&nbsp;
                      {currency(premium, 0, '')}
                    </b>
                  </div>
                  {values.opportunity.orders.map((order, index) => {
                    if (index < 2)
                      return (
                        <div className="order">
                          <small>{order.name}</small>
                          <div className="status">
                            <small>{order.status}</small>
                            <small># {order.orderNo}</small>
                          </div>
                        </div>
                      )
                    else return null
                  })}
                </div>
              )}
              <small className="updatedAt">
                วันที่อัปเดต {dateFormat(values.opportunity?.updatedAt || new Date())}
              </small>
            </div>
            <div className="footer">
              <NoteIcon name="note_opps" color={'var(--agt-primary-color-1)'} />
              <small>ดูรายละเอียด</small>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const TaskDetail: FC = (props) => {
  const location = useLocation()
  const history: HistoryType = useHistory()
  let { id = '' } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [task, setTask] = useState<TaskType>(defaultTask)
  const [deleteIsClicked, setStateDeleteClicked] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(location.state ? !!get(location.state, 'edit') : false)

  const dueDate = task && task.dueDate !== null ? moment(task.dueDate).format('DD/MM/YYYY') : null
  const initialValue: TaskType = {
    contactId: task?.contactId,
    dueDate: task?.dueDate,
    id: task.id,
    name: task?.name,
    priorityLevel: task?.priorityLevel,
    status: task?.status,
    description: task?.description,
    taskDocuments: task?.taskDocuments,
    opportunity: task?.opportunity,
  }
  const premium = useMemo(() => {
    if (initialValue.opportunity?.orders) {
      let price = 0
      initialValue.opportunity.orders.forEach((order, index) => {
        price += parseFloat(order.premium)
      })
      return price
    } else return 0
  }, [initialValue])

  const breadCrumbArr: Array<LinkValues> = [
    { path: '/tasks', label: 'งานที่ต้องทำ' },
    { label: 'แก้ไขงานที่ต้องทำ' },
  ]
  const detailBreadCrumbArr: Array<LinkValues> = [
    { path: '/tasks', label: 'งานที่ต้องทำ' },
    { label: 'รายละเอียดงาน' },
  ]

  useEffect(() => {
    dispatch(getTaskById(id, setTask, () => history.push('/tasks')))
  }, [dispatch, id, history])

  return (
    <Container>
      {edit && dueDate ? (
        <Fragment>
          <Header>แก้ไขงานที่ต้องทำ</Header>
          <BreadCrumb links={breadCrumbArr} hasBackToRoot />
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            onSubmit={(values: TaskType) => {
              dispatch(updateTask(id, values, () => history.push('/tasks')))
            }}
          >
            {(props) => (
              <TaskForm
                values={props.values}
                handleSubmit={props.handleSubmit}
                isSubmitting={props.isSubmitting}
              />
            )}
          </Formik>
        </Fragment>
      ) : (
        <Fragment>
          <Header>รายละเอียดงาน</Header>
          <BreadCrumb links={detailBreadCrumbArr} hasBackToRoot />
          <ContentContainer>
            <Modal
              isOpen={deleteIsClicked}
              onConfirm={() => dispatch(deleteTask(id, () => history.push('/tasks')))}
              onCancel={() => setStateDeleteClicked(false)}
            >
              <DeleteModal>
                <p>ยืนยันการลบงานที่ต้องทำ</p>
                <p>{task?.name || ''}</p>
              </DeleteModal>
            </Modal>
            <Detail
              style={{
                width: '352px',
                position: 'relative',
              }}
            >
              <b>{task.name}</b>
              <OpportunityCard values={initialValue} premium={premium} />
            </Detail>
            <GroupComponents
            // style={{ width: '352px' }}
            >
              <Detail>บุคคลที่เกี่ยวข้อง : {task.contactName}</Detail>
              <Detail>
                เบอร์โทรศัพท์ :{' '}
                {task.contactPhoneNumber ? phoneNumberFormat(task.contactPhoneNumber) : '-'}
              </Detail>
              <Detail>อีเมล : {task?.contactEmail || ''}</Detail>
            </GroupComponents>
            <GroupComponents
            // style={{ width: '352px' }}
            >
              <Detail>
                <label>ครบกำหนด&nbsp;วันที่</label>
                <div className="dueDate">
                  {moment(task.dueDate).format('DD MMM ') +
                    (parseInt(moment(task.dueDate).format('YYYY')) + 543)}
                </div>
                {/* <label className="time">เวลา</label>
                <label>22.00 น.</label> */}
              </Detail>
              {/* <Detail>
                <label>การแจ้งเตือน</label>
                <div className="remainTime">ก่อน 1 ชั่วโมง</div>
              </Detail> */}
              <Detail>
                ระดับความสำคัญ
                <StarDisplay star={3} value={task.priorityLevel || 0} disabled />
              </Detail>
            </GroupComponents>
            <GroupComponents>
              <DetailComponents>รายละเอียด</DetailComponents>
              <DetailComponents style={{ whiteSpace: 'pre-line' }}>
                {task?.description || '-'}
              </DetailComponents>
            </GroupComponents>
            <ButtonComponents>
              <button
                className="secondary"
                type="button"
                onClick={() => setStateDeleteClicked(true)}
                style={{ marginRight: '24px' }}
                disabled={task.contactStatus === 'รอยินยอม' || currentAgent?.statusTh === 'ระงับ'}
              >
                ลบ
              </button>
              <button
                className="secondary"
                type="button"
                onClick={() => setEdit(true)}
                disabled={task.contactStatus === 'รอยินยอม' || currentAgent?.statusTh === 'ระงับ'}
              >
                แก้ไข
              </button>
            </ButtonComponents>
          </ContentContainer>
        </Fragment>
      )}
    </Container>
  )
}
export default TaskDetail
