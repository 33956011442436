import React, { useState, useEffect, FC, Fragment, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Doughnut } from 'react-chartjs-2'
import styled from '@emotion/styled'
import { Link } from 'routes'
import { useDispatch } from 'react-redux'
import { RootState } from 'store/rootReducer'
import authorize, { UnauthorizedAction } from 'utils/authorize'

import Icon from 'components/Icon'
import EmptyDoughnut from 'assets/images/no_data_doughnut.png'

import { LandingPremiumCardType } from 'types/DashboardType'
import { getdashboard } from 'store/profileSlice'
import {
  getThisMonthTh,
  getThisQuarterShortTh,
  getThisYearTh,
} from 'components/Inputs/DatePicker/DatePicker.utils'
import { currency } from 'utils/helper'

type ColorType = {
  color: string
}

const chart = {
  a: [
    { key: 'Life', value: '10000' },
    { key: 'health', value: '10000' },
    { key: 'Car', value: '10000' },
    { key: 'Fire', value: '10000' },
  ],

  b: [
    { key: 'Life', value: '20000' },
    { key: 'health', value: '40000' },
    { key: 'Car', value: '30000' },
    { key: 'Fire', value: '50000' },
  ],

  c: [
    { key: 'Life', value: '10000' },
    { key: 'health', value: '50000' },
    { key: 'Car', value: '20000' },
    { key: 'Fire', value: '52200' },
  ],
}
const Nochart = {
  a: [
    { key: 'Life', value: '20000' },
    { key: 'health', value: '10000' },
  ],
}

const dataA = {
  datasets: [
    {
      data: chart.a.map((key) => key.value),

      backgroundColor: ['#00529A', '#45DAAE', '#4881FF', '#7E5FFF'],
      hoverBorderColor: '#7E5FFF',
      hoverBorderWidth: 1,
      borderWidth: 0,
    },
  ],
}

const Container = styled.div`
  width: 100%;

  .dashboard {
    width: 100%;
    height: 128px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--agt-primary-color-1);
    border-radius: 8px;
    cursor: pointer;
    .lable {
      color: var(--agt-white-color);
    }
  }
  .disabledDashboard {
    width: 100%;
    height: 128px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--agt-disable-color-1);
    border-radius: 8px;
    cursor: no-drop;
    .lable {
      color: var(--agt-secondary-text-color);
    }
  }
`
const DashBoardDiv = styled.div`
  width: 100%;
  height: 381px;
  background: #ffffff;
  border: 1px solid #e6e8ec;
  border-radius: 8px;

  .LinkNextPage {
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: auto;
    justify-content: flex-end;
    padding: 0 1rem;
  }
  .LinkText {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    padding-right: 13.62px;
    /* identical to box height */

    text-align: center;
  }
`
const TopDashBoard = styled.div`
  height: 179px;
  width: 100%;
  display: flex;
  flex-direction: row;
`
const LeftDashBoardDiv = styled.div`
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
`
const RightDashBoardDiv = styled.div`
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: row;
`
const TitterDashBoard = styled.div`
  width: 122px;
  height: 66px;
  margin-top: 8px;
  margin-left: 16px;
  margin-bottom: 8px;
  .Title {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    text-align: left;
    color: #00529a;
  }
`
const MiniTitterDashBoard = styled.div`
  width: 64px;
  height: 20px;
  margin-left: 16px;
  .MiniTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #00529a;
  }
`
const HeaderMonthDiv = styled.div`
  width: 56px;
  height: 36px;
  margin: 0 auto 0.5rem;
  text-align: center;
  .MonthTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    color: #7d828e;
  }
`
const HeaderQuarterDiv = styled.div`
  width: 60px;
  height: 36px;
  margin: 0 auto 0.5rem;
  text-align: center;
  .QuarterTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    color: #7d828e;
  }
`
const HeaderYearDiv = styled.div`
  width: 28px;
  height: 36px;
  margin: 0 auto 0.5rem;
  text-align: center;
  .YearTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    color: #7d828e;
  }
`
const ChartDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  padding: 1rem;
`
const ChartMainMonth = styled.div`
  width: 100%;
  height: 110px;
  justify-content: center;
`
const ChartMainQuarter = styled.div`
  width: 100%;
  height: 110px;

  justify-content: center;
`
const ChartMainYear = styled.div`
  width: 100%;
  height: 110px;

  justify-content: center;
`
const BottomDashBoard = styled.div`
  width: 100%;
  height: 179px;
`
const ColumnData = styled.div<ColorType>`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;

  border-bottom: 1px solid #e6e8ec;
  .Header {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 26px;
    /* identical to box height */
    margin-left: 13px;
    margin-top: 7px;
    margin-bottom: 7px;
    width: 20%;
    color: ${(props) => props.color && props.color};
  }
  .ValueLife {
    width: 33.3%;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: center;
  }
  .ValueHealth {
    width: 33.3%;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: center;
  }
  .ValueCar {
    width: 33.3%;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: center;
  }
  .ValueFire {
    width: 33.3%;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: center;
  }
  .Values {
    width: 33.3%;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: center;
  }
`

const IconStyle = styled(Icon)`
  width: 16px;
  height: 13px;
  margin-top: 8px;
  margin-left: 20px;
  margin-right: 8px;
  margin-bottom: 8px;
`

const DoughnutMonthChart = ({ rawData }) => {
  const [formatData, setFormatData] = useState(dataA)

  useEffect(() => {
    if (rawData) {
      let checkData = rawData.every((x) => x.value === 0)

      if (checkData) {
        let data = {
          datasets: [
            {
              data: Nochart.a.map((key) => key.value),

              backgroundColor: ['#F9F6F6', '#ECECEC'],
              hoverBorderColor: '#FFFFFF',
              hoverBorderWidth: 1,
              borderWidth: 0,
            },
          ],
        }
        setFormatData(data)
      } else {
        let data = {
          datasets: [
            {
              data: rawData.map((key) => key.value),

              backgroundColor: ['#00529A', '#45DAAE', '#4881FF', '#7E5FFF'],
              hoverBorderColor: '#7E5FFF',
              hoverBorderWidth: 1,
              borderWidth: 0,
            },
          ],
        }
        setFormatData(data)
      }
    }
  }, [rawData])

  return (
    <ChartMainMonth>
      <Doughnut
        data={formatData}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </ChartMainMonth>
  )
}
const DoughnutQuarterChart = ({ rawData }) => {
  const [formatData, setFormatData] = useState(dataA)

  useEffect(() => {
    if (rawData) {
      let checkData = rawData.every((x) => x.value === 0)

      if (checkData) {
        let data = {
          datasets: [
            {
              data: Nochart.a.map((key) => key.value),

              backgroundColor: ['#F9F6F6', '#ECECEC'],
              hoverBorderColor: '#FFFFFF',
              hoverBorderWidth: 1,
              borderWidth: 0,
            },
          ],
        }
        setFormatData(data)
      } else {
        let data = {
          datasets: [
            {
              data: rawData.map((key) => key.value),

              backgroundColor: ['#00529A', '#45DAAE', '#4881FF', '#7E5FFF'],
              hoverBorderColor: '#7E5FFF',
              hoverBorderWidth: 1,
              borderWidth: 0,
            },
          ],
        }
        setFormatData(data)
      }
    }
  }, [rawData])

  return (
    <ChartMainQuarter>
      <Doughnut
        data={formatData}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </ChartMainQuarter>
  )
}
const DoughnutYearChart = ({ rawData }) => {
  const [formatData, setFormatData] = useState(dataA)

  useEffect(() => {
    if (rawData) {
      let checkData = rawData.every((x) => x.value === 0)

      if (checkData) {
        let data = {
          datasets: [
            {
              data: Nochart.a.map((key) => key.value),

              backgroundColor: ['#F9F6F6', '#ECECEC'],
              hoverBorderColor: '#FFFFFF',
              hoverBorderWidth: 1,
              borderWidth: 0,
            },
          ],
        }
        setFormatData(data)
      } else {
        let data = {
          datasets: [
            {
              data: rawData.map((key) => key.value),

              backgroundColor: ['#00529A', '#45DAAE', '#4881FF', '#7E5FFF'],
              hoverBorderColor: '#7E5FFF',
              hoverBorderWidth: 1,
              borderWidth: 0,
            },
          ],
        }
        setFormatData(data)
      }
    }
  }, [rawData])

  return (
    <ChartMainYear>
      <Doughnut
        data={formatData}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </ChartMainYear>
  )
}

const DoughnutNoChartImage = styled.img`
  width: 100%;
`
const DoughnutNoChart = () => {
  return <DoughnutNoChartImage src={EmptyDoughnut} alt="for no data" />
}
const DashboardNoValue = () => {
  return (
    <Fragment>
      <TopDashBoard>
        <LeftDashBoardDiv>
          <TitterDashBoard>
            <p className="Title">ภาพรวมเบี้ยประกัน</p>
          </TitterDashBoard>
          <MiniTitterDashBoard>
            <p className="MiniTitle">ยอดที่ขายได้</p>
          </MiniTitterDashBoard>
        </LeftDashBoardDiv>
        <RightDashBoardDiv>
          <ChartDiv>
            <HeaderMonthDiv>
              <p className="MonthTitle">รายเดือน</p>
            </HeaderMonthDiv>
            <DoughnutNoChart />
          </ChartDiv>
          <ChartDiv>
            <HeaderQuarterDiv>
              <p className="QuarterTitle">รายไตรมาส</p>
            </HeaderQuarterDiv>
            <DoughnutNoChart />
          </ChartDiv>
          <ChartDiv>
            <HeaderYearDiv>
              <p className="YearTitle">รายปี</p>
            </HeaderYearDiv>
            <DoughnutNoChart />
          </ChartDiv>
        </RightDashBoardDiv>
      </TopDashBoard>
      <BottomDashBoard>
        <ColumnData color={'#00529A'}>
          <IconStyle name="Life" />
          <div className="Header">ชีวิต</div>
          <div className="ValueLife">-</div>
          <div className="Values">-</div>
          <div className="Values">-</div>
        </ColumnData>
        <ColumnData color={'#45DAAE'}>
          <IconStyle name="health" />
          <div className="Header">สุขภาพ</div>
          <div className="ValueHealth">-</div>
          <div className="Values">-</div>
          <div className="Values">-</div>
        </ColumnData>
        <ColumnData color={'#3ABAF8'}>
          <IconStyle name="car" />
          <div className="Header">รถยนต์</div>
          <div className="ValueCar">-</div>
          <div className="Values">-</div>
          <div className="Values">-</div>
        </ColumnData>
        <ColumnData color={'#7E5FFF'} style={{ border: 'unset' }}>
          <IconStyle name="Fire" />
          <div className="Header">อัคคีภัย</div>
          <div className="ValueFire">-</div>
          <div className="Values">-</div>
          <div className="Values">-</div>
        </ColumnData>
        <div className="LinkNextPage">
          <Link to="/dashboard" className="LinkText">
            ดูเพิ่มเติม
          </Link>

          <Link to="/dashboard" className="LinkText">
            <Icon name="Arrow_dashboard" />
          </Link>
        </div>
      </BottomDashBoard>
    </Fragment>
  )
}

const LandingPremiumCard: FC<LandingPremiumCardType> = (props) => {
  const { disabled } = props
  const dispatch = useDispatch()
  const chartData = useSelector((state: RootState) => state.profile.dashboard)

  useEffect(() => {
    dispatch(getdashboard())
  }, [dispatch])

  const thisMonth = useMemo(() => {
    return getThisMonthTh()
  }, [])

  const thisQuarter = useMemo(() => {
    return getThisQuarterShortTh()
  }, [])

  const thisYear = useMemo(() => {
    return getThisYearTh()
  }, [])

  const formatValue = useCallback((input) => {
    if (!input) return '-'
    return `${currency(input, 0, '')} ฿`
  }, [])

  const isNoDataArray = useMemo(() => {
    return Object.values(chartData).map((chart) => chart.every((c) => c.value === 0))
  }, [chartData])

  return (
    <Container className="agt-landing-premium-card__container">
      <DashBoardDiv>
        {disabled ? (
          <DashboardNoValue />
        ) : (
          <Fragment>
            <TopDashBoard>
              <LeftDashBoardDiv>
                <TitterDashBoard>
                  <p className="Title">ภาพรวมเบี้ยประกัน</p>
                </TitterDashBoard>
                <MiniTitterDashBoard>
                  <p className="MiniTitle">ยอดที่ขายได้</p>
                </MiniTitterDashBoard>
              </LeftDashBoardDiv>
              <RightDashBoardDiv>
                <ChartDiv>
                  <HeaderMonthDiv>
                    <p className="MonthTitle">รายเดือน{thisMonth}</p>
                  </HeaderMonthDiv>
                  {isNoDataArray[0] ? (
                    <DoughnutNoChart />
                  ) : (
                    <DoughnutMonthChart rawData={chartData.month} />
                  )}
                </ChartDiv>
                <ChartDiv>
                  <HeaderQuarterDiv>
                    <p className="QuarterTitle">รายไตรมาส {thisQuarter}</p>
                  </HeaderQuarterDiv>
                  {isNoDataArray[1] ? (
                    <DoughnutNoChart />
                  ) : (
                    <DoughnutQuarterChart rawData={chartData.quarter} />
                  )}
                </ChartDiv>
                <ChartDiv>
                  <HeaderYearDiv>
                    <p className="YearTitle">รายปี {thisYear}</p>
                  </HeaderYearDiv>
                  {isNoDataArray[2] ? (
                    <DoughnutNoChart />
                  ) : (
                    <DoughnutYearChart rawData={chartData.year} />
                  )}
                </ChartDiv>
              </RightDashBoardDiv>
            </TopDashBoard>
            <BottomDashBoard>
              <ColumnData color={'#00529A'}>
                <IconStyle name="Life" />
                <div className="Header">ชีวิต</div>
                <div className="ValueLife">{formatValue(chartData?.month[0]?.value)}</div>
                <div className="Values">{formatValue(chartData?.quarter[0]?.value)}</div>
                <div className="Values">{formatValue(chartData?.year[0]?.value)}</div>
              </ColumnData>
              <ColumnData color={'#45DAAE'}>
                <IconStyle name="health" />
                <div className="Header">สุขภาพ</div>
                <div className="ValueHealth">{formatValue(chartData?.month[1]?.value)}</div>
                <div className="Values">
                  {chartData?.quarter[1]?.value
                    ? `${currency(chartData?.quarter[1]?.value, 0, '')} ฿`
                    : '-'}
                </div>
                <div className="Values">{formatValue(chartData?.year[1]?.value)}</div>
              </ColumnData>
              <ColumnData color={'#3ABAF8'}>
                <IconStyle name="car" />
                <div className="Header">รถยนต์</div>
                <div className="ValueCar">{formatValue(chartData?.month[2]?.value)}</div>
                <div className="Values">{formatValue(chartData?.quarter[2]?.value)}</div>
                <div className="Values">{formatValue(chartData?.year[2]?.value)}</div>
              </ColumnData>
              <ColumnData color={'#7E5FFF'} style={{ border: 'unset' }}>
                <IconStyle name="Fire" />
                <div className="Header">อัคคีภัย</div>
                <div className="ValueFire">{formatValue(chartData?.month[3]?.value)}</div>
                <div className="Values">{formatValue(chartData?.quarter[3]?.value)}</div>
                <div className="Values">{formatValue(chartData?.year[3]?.value)}</div>
              </ColumnData>
              <div className="LinkNextPage">
                <Link to="/dashboard" className="LinkText">
                  ดูเพิ่มเติม
                </Link>

                <Link to="/dashboard" className="LinkText">
                  <Icon name="Arrow_dashboard" />
                </Link>
              </div>
            </BottomDashBoard>
          </Fragment>
        )}
      </DashBoardDiv>
    </Container>
  )
}

export default authorize(LandingPremiumCard, {
  roles: ['tqm_staff', 'affiliate', 'broker'],
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
})
