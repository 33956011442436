import React, { FC, useEffect } from 'react'
import styled from '@emotion/styled'
import Icon from 'components/Icon'
import { useFormikContext, Field, useField, Form } from 'formik'
import Select from 'react-select'
import { DatePickerComponent } from 'components/DatePicker'
import { addDays, addYears } from 'date-fns'
import SetStateType from 'types/SetStateType'
import {
  initialValuesType,
  OptionByTypeTaStateType,
  OptionStateType,
} from 'types/TravelInsuranceType'

type searchByTypeTaType = {
  travelTypeCountry: string
  travelType: string
}

type TravelFormType = {
  values: initialValuesType
  searchByTypeTa: searchByTypeTaType
  options: OptionStateType | null
  optionsByTypeTa: OptionByTypeTaStateType | null
  handleSubmit: React.FormEventHandler<HTMLFormElement>
  setSearchByTypeTa: SetStateType<searchByTypeTaType>
}

const today = new Date()
const yesterday = addDays(today, -1)
const nextYear = addYears(yesterday, 1)
const fixedOptions = {
  coutries: [
    { value: '999', label: 'ประเทศไทย' },
    { value: '2', label: 'ประเทศญี่ปุ่น' },
  ],
  travelPassenger: [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
  ],
  province: [
    { value: '001', label: 'กรุงเทพ' },
    { value: '002', label: 'ขอนแก่น' },
    { value: '003', label: 'นครศรีธรรมราช' },
  ],
}

const Container = styled(Form)`
  width: 100%;
  max-width: 736px;
  margin: 44px auto 40px;
  display: flex;
  flex-direction: column;
  .topic {
    text-align: center;
    margin: 0px 0 40px;
  }
  .domestic,
  .international {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 32px;
    margin-bottom: 24px;
  }
  .domestic .selector,
  .international .selector,
  .domestic .datepicker,
  .international .datepicker {
    width: 100%;
    max-width: 352px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .domestic .selector label span,
  .international .selector label span,
  .domestic .datepicker label span,
  .international .datepicker label span {
    color: var(--agt-error-color);
  }
  .domestic .travelType {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .travelSelector {
    display: flex;
    gap: 24px;
  }
  .domestic .travelType label span {
    color: var(--agt-error-color);
  }
  .international .roundType {
    width: 100%;
    display: flex;
  }
  .international .roundType .item {
    width: 50%;
    height: 48px;
    background: #ffffff;
    border: 1px solid #a0a8bc;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .international .roundType .item.left {
    border-radius: 4px 0px 0px 4px;
  }
  .international .roundType .item.right {
    border-radius: 0px 4px 4px 0px;
  }
  .international .roundType .item.active {
    background: #cae6ff;
    border: 1px solid #00529a;
    color: #00529a;
  }
  button {
    align-self: center;
  }
`

const FlightSelecter = styled.section`
  width: 100%;
  max-width: 344px;
  display: flex;
  column-gap: 24px;
  margin: 0 auto 48px;
  label {
    width: 100%;
    padding: 24px 6px;
    border: 2px solid var(--agt-secondary-text-color);
    color: var(--agt-secondary-text-color);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  label input {
    display: none;
  }
  label.active {
    color: var(--agt-primary-color-1);
    font-weight: bold;
    border: 2px solid var(--agt-primary-color-1);
  }
  label > span:first-of-type {
    height: 70px;
  }
  label > span:last-of-type {
    margin-top: 18px;
  }
  label .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: var(--agt-white-text-color);
    border: 2px solid var(--agt-secondary-color-2);
    border-radius: 50%;
    /* margin-top: 18px; */
  }
  label:hover {
    cursor: pointer;
  }
  label input:checked ~ .checkmark {
    border: 2px solid var(--agt-primary-color-1);
  }
  label .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  label input:checked ~ .checkmark:after {
    display: block;
  }
  label .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--agt-primary-color-1);
  }
`

const customSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}

const Selector = styled(Select)`
  height: 48px;
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

const OtherComponent = styled.div`
  &.travelType {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    > label span {
      color: var(--agt-error-color);
    }
    > div:last-child {
      display: flex;
      column-gap: 52px;
    }
    > div:last-child label {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }
    > div:last-child label:hover {
      cursor: pointer;
    }
    > div:last-child label .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid var(--agt-secondary-color-2);
      border-radius: 50%;
    }
    > div:last-child label input {
      display: none;
    }
    > div:last-child label input:checked ~ .checkmark {
      border: 2px solid var(--agt-primary-color-1);
    }
    > div:last-child label .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    > div:last-child label input:checked ~ .checkmark:after {
      display: block;
    }
    > div:last-child label .checkmark:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--agt-primary-color-1);
    }
  }
`

const DatePicker = (props) => {
  const { field, label, className, disabled = false, minDate, cb = () => {} } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <DatePickerComponent
        value={value}
        showSelector
        onChange={(e: string) => {
          setValue(e)
          if (cb) cb(e)
        }}
        minDate={minDate}
        disabled={disabled}
      />
    </div>
  )
}

const TravelType = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <div>
        <label htmlFor="policy__1" onClick={() => setValue('plane')}>
          <input id="policy__1" type="radio" name={name} checked={value === 'plane'} />
          <span className="checkmark" />
          เครื่องบิน
        </label>
        <label htmlFor="policy__2" onClick={() => setValue('etc')}>
          <input id="policy__2" type="radio" name={name} checked={value === 'etc'} />
          <span className="checkmark" />
          ยานพาหนะอื่นๆ
        </label>
      </div>
    </OtherComponent>
  )
}

const TravelForm: FC<TravelFormType> = (props) => {
  const {
    values,
    searchByTypeTa,
    options,
    optionsByTypeTa,
    handleSubmit,
    setSearchByTypeTa,
  } = props
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    setFieldValue('src', '')
    setFieldValue('dsc', '')
    setFieldValue('vehicleType', 'plane')
    if (values.travelTypeCountry === 'IN') {
      setFieldValue('countryId', 999)
      setFieldValue('travelDateFrom', today)
      setFieldValue('travelDateTo', addDays(today, 1))
    } else if (values.travelTypeCountry === 'OUT') {
      setFieldValue('countryId', '')
      setFieldValue('travelDateFrom', today)
      setFieldValue('travelDateTo', nextYear)
    }
  }, [values.travelTypeCountry])

  useEffect(() => {
    if (values.travelTypeCountry === 'OUT') {
      setFieldValue('countryId', '')
    }
  }, [values.travelType])

  useEffect(() => {
    if (values.travelTypeCountry === 'IN') {
      setFieldValue('travelDateTo', addDays(values.travelDateFrom, 1))
    } else if (values.travelTypeCountry === 'OUT') {
      setFieldValue('travelDateTo', addYears(addDays(values.travelDateFrom, -1), 1))
    }
  }, [values.travelDateFrom])

  return (
    <Container onSubmit={handleSubmit}>
      <p className="topic">ประกันเดินทาง</p>
      <FlightSelecter>
        <label
          className={values.travelTypeCountry === 'IN' ? 'active' : ''}
          htmlFor="radio__1"
          onClick={() => {
            setFieldValue('travelType', 'D')
            setFieldValue('travelTypeCountry', 'IN')
            setSearchByTypeTa({ ...searchByTypeTa, travelType: 'D', travelTypeCountry: 'IN' })
          }}
        >
          <Icon
            name="domestic"
            color={
              values.travelTypeCountry === 'IN'
                ? 'var(--agt-primary-color-1)'
                : 'var(--agt-secondary-color-1)'
            }
          />
          <input
            id="radio__1"
            type="radio"
            name="radio"
            checked={values.travelTypeCountry === 'IN'}
          />
          เดินทางในประเทศ
          <span className="checkmark"></span>
        </label>
        <label
          className={values.travelTypeCountry === 'OUT' ? 'active' : ''}
          htmlFor="radio__2"
          onClick={() => {
            setFieldValue('travelType', 'D')
            setFieldValue('travelTypeCountry', 'OUT')
            setSearchByTypeTa({ ...searchByTypeTa, travelType: 'D', travelTypeCountry: 'OUT' })
          }}
        >
          <Icon
            name="international"
            color={
              values.travelTypeCountry === 'OUT'
                ? 'var(--agt-primary-color-1)'
                : 'var(--agt-secondary-color-1)'
            }
          />
          <input
            id="radio__2"
            type="radio"
            name="radio"
            checked={values.travelTypeCountry === 'OUT'}
          />
          เดินทางนอกประเทศ
          <span className="checkmark"></span>
        </label>
      </FlightSelecter>
      {values.travelTypeCountry === 'IN' && (
        <section className="domestic">
          <div className="selector">
            <label>เดินทางในประเทศ</label>
            <Selector
              value={(optionsByTypeTa?.taCountries || []).find(
                (option) => option.value === values.countryId,
              )}
              onChange={(option: { label: string; value: string }) => {
                setFieldValue('countryId', option.value)
              }}
              options={optionsByTypeTa?.taCountries || []}
              styles={customSelectStyles}
              placeholder="โปรดระบุ/เลือก"
              isDisabled={true}
            />
          </div>
          <div className="selector">
            <label>
              จำนวนผู้เดินทาง <span>*</span>
            </label>
            <Selector
              value={(fixedOptions?.travelPassenger || []).find(
                (option) => option.value === values.travelPassenger,
              )}
              onChange={(option: { label: string; value: string }) => {
                setFieldValue('travelPassenger', option.value)
              }}
              options={fixedOptions?.travelPassenger || []}
              styles={customSelectStyles}
              placeholder="เลือก"
              isDisabled={false}
            />
          </div>
          <Field
            label="ประเภทการเดินทาง"
            name="vehicleType"
            className="travelType"
            component={TravelType}
          />
          <div className="selector">
            <label>
              จังหวัดต้นทาง <span>*</span>
            </label>
            <Selector
              value={(options?.provinceOptions || []).find((option) => option.value === values.src)}
              onChange={(option: { label: string; value: string }) => {
                setFieldValue('src', option.value)
              }}
              options={options?.provinceOptions || []}
              styles={customSelectStyles}
              placeholder="เลือก"
              isDisabled={false}
            />
          </div>
          <div className="selector">
            <label>
              จังหวัดปลายทาง <span>*</span>
            </label>
            <Selector
              value={(options?.provinceOptions || []).find((option) => option.value === values.dsc)}
              onChange={(option: { label: string; value: string }) => {
                setFieldValue('dsc', option.value)
              }}
              options={options?.provinceOptions || []}
              styles={customSelectStyles}
              placeholder="เลือก"
              isDisabled={false}
            />
          </div>
          <Field
            label="วันที่ออกเดินทาง"
            name="travelDateFrom"
            className="datepicker"
            component={DatePicker}
            placeholder="โปรดระบุ"
          />
          <Field
            label="วันที่เดินทางกลับ"
            name="travelDateTo"
            className="datepicker"
            component={DatePicker}
            placeholder="โปรดระบุ"
            minDate={addDays(values.travelDateFrom, 1)}
          />
        </section>
      )}
      {values.travelTypeCountry === 'OUT' && (
        <section className="international">
          <div className="roundType">
            <div
              className={values.travelType === 'D' ? 'item left active' : 'item left'}
              onClick={() => {
                setFieldValue('travelType', 'D')
                setSearchByTypeTa({ ...searchByTypeTa, travelType: 'D' })
              }}
            >
              รายเที่ยว
            </div>
            <div
              className={values.travelType === 'Y' ? 'item right active' : 'item right'}
              onClick={() => {
                setFieldValue('travelType', 'Y')
                setSearchByTypeTa({ ...searchByTypeTa, travelType: 'Y' })
              }}
            >
              รายปี
            </div>
          </div>
          <div className="selector">
            <label>
              ประเทศปลายทาง <span>*</span>
            </label>
            <Selector
              value={
                (optionsByTypeTa?.taCountries || []).find(
                  (option) => option.value === values.countryId,
                ) || ''
              }
              onChange={(option: { label: string; value: string }) => {
                setFieldValue('countryId', option.value)
              }}
              options={optionsByTypeTa?.taCountries || []}
              styles={customSelectStyles}
              placeholder="โปรดระบุ/เลือก"
              isDisabled={false}
            />
          </div>
          <div className="selector">
            <label>
              จำนวนผู้เดินทาง <span>*</span>
            </label>
            <Selector
              value={(fixedOptions?.travelPassenger || []).find(
                (option) => option.value === values.travelPassenger,
              )}
              onChange={(option: { label: string; value: string }) => {
                setFieldValue('travelPassenger', option.value)
              }}
              options={fixedOptions?.travelPassenger || []}
              styles={customSelectStyles}
              placeholder="เลือก"
              isDisabled={false}
            />
          </div>
          <Field
            label="วันที่ออกเดินทาง"
            name="travelDateFrom"
            className="datepicker"
            component={DatePicker}
            placeholder="โปรดระบุ"
            cb={(e) => setFieldValue('travelDateTo', addYears(addDays(e, -1), 1))}
          />
          <Field
            label="วันที่เดินทางกลับ"
            name="travelDateTo"
            className="datepicker"
            component={DatePicker}
            placeholder="โปรดระบุ"
            disabled
          />
        </section>
      )}
      <button
        type="submit"
        disabled={
          (values.travelTypeCountry === 'IN' && !(values.src && values.dsc)) ||
          (values.travelTypeCountry === 'OUT' && !values.countryId)
        }
      >
        ค้นหาแบบประกัน
      </button>
    </Container>
  )
}

export default TravelForm
