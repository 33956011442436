import React, { FC, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { NotificationType } from 'types/NotificationType'
import moment from 'moment'
import Icon from 'components/Icon'
import HistoryType from 'types/HistoryType'
import { useHistory } from 'routes'
import { dateFormat } from 'utils/helper'

interface CustomNotificationType extends NotificationType {
  setOpenToConverse: Function
  callback?: Function
  subActionType?: string
}
type MainContainerProps = {
  isRead: boolean
  actionType: string
}

const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 380px;
  background: ${(props) => (props.isRead ? 'white' : ' #eff1f5')};
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 16px;
  padding-right: 27px;
  font-size: 16px;
  min-height: 78px;
  cursor: pointer;
`
const DetailContainer = styled.div`
  display: flex;
`
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  padding-left: 8px;
`
const ExpandeDetail = styled.div`
  display: flex;
  flex-direction: column;
`
const DetailForOpportunity = styled.div`
  display: flex;
  flex-direction: column;
  .textType span {
    font-weight: bold;
  }
  .textType.other {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const DetailForEvents = styled.div`
  .title {
    display: flex;
    flex-direction: row;
  }
  .title .textType {
    white-space: nowrap;
  }
  .title .actionName {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 4px;
  }
  .contactName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const DetailForDocument = styled.div``
const DetailForTask = styled.div``

const OpportunityText = styled.div`
  padding-right: 5px;
  white-space: nowrap;
`
const TimeText = styled.div`
  font-size: 13px;
  white-space: nowrap;
  text-align: right;
  color: #7d828e;
`
const OpportunityContainer = styled.div`
  display: flex;
  flex-direction: column;
`
// eslint-disable-next-line
const OpportunityStatus = styled.div`
  display: flex;
`

const DetailItem: FC<CustomNotificationType> = (props) => {
  const {
    id,
    contactId = '',
    actionId = '',
    actionName = '',
    actionType = '',
    callback = () => {},
    latedTask = 0,
    currentDayTask = 0,
    subActionType = '',
    setOpenToConverse,
    info,
  } = props
  let history: HistoryType = useHistory()
  const eventStartDate = info?.eventStartDate || new Date()

  const textType = useMemo(() => {
    switch (actionType) {
      case 'event':
        return 'นัดหมาย'
      case 'task':
        return 'งานที่ต้องทำ'
      case 'document':
        return 'ตรวจสอบเอกสาร'
      case 'insurance_port':
        return 'คำสั่งซื้อ'
      default:
        return 'โอกาสในการขาย'
    }
  }, [actionType])

  const eventDate = useMemo(() => {
    const dateToday = moment().format('DD-MM-YYYY')
    let initial = eventStartDate
    let final = moment(dateToday, 'DD-MM-YYYY')
    let subDay = final.diff(initial, 'days')
    if (subDay > -6 && subDay < 6) {
      return `${moment(eventStartDate).calendar()} น.`
    } else {
      return `${
        moment(eventStartDate).format('DD/MM/') +
        (parseInt(moment(eventStartDate).format('YYYY')) + 543)
      } ${moment(eventStartDate).locale('en').format('H:mm') + ' น.'}`
    }
  }, [eventStartDate])

  const actionTypeIcon = useMemo(() => {
    switch (actionType) {
      case 'opportunity':
        return <Icon name="NotiOpportunity" />
      case 'document':
        return <Icon name="NotiDocument" />
      case 'task':
        return <Icon name="NotiTask" />
      case 'insurance_port':
        return <Icon name="NotiOrder" />
      default:
        return <Icon name="NotiEvent" />
    }
  }, [actionType])

  const onClickItem = useCallback(() => {
    setOpenToConverse({ id, actionType })
    switch (actionType) {
      case 'event':
        history.push(`/calendar/${actionId}`)
        setTimeout(() => {
          callback()
        })
        break
      case 'task':
        history.push(`/tasks`)
        setTimeout(() => {
          callback()
        })
        break
      case 'opportunity':
        if (['won', 'lose'].includes(info?.opportunityStatus || '')) {
          history.push(`/opportunities/?viewpoint=completed`)
          window.location.reload()
        } else if (subActionType === 'unassign_order') {
        } else {
          history.push(`/opportunities/${actionId}`)
          setTimeout(() => {
            callback()
          })
        }
        break
      case 'document':
        if (window.location.pathname === '/profile/documents') {
          window.location.reload()
        } else {
          history.push(`/profile/documents`)
          setTimeout(() => {
            callback()
          })
        }
        break
      case 'insurance_port':
        history.push(`/contacts/${contactId}/insurance_portfolio/${actionId}`)
        setTimeout(() => {
          callback()
        })
        break
      default:
        setTimeout(() => {
          callback()
        })
        break
    }
  }, [
    setOpenToConverse,
    id,
    actionType,
    actionId,
    info,
    contactId,
    subActionType,
    history,
    callback,
  ])

  return (
    <MainContainer
      isRead={props.isRead || false}
      onClick={onClickItem}
      actionType={actionType || ''}
    >
      <DetailContainer>
        {actionTypeIcon}
        <ListContainer>
          {actionType === 'task' ? (
            <DetailForTask>
              <b>{textType}</b>
              {currentDayTask > 0 && <u>{` วันนี้คุณมี ${currentDayTask} งานที่ต้องทำ`}</u>}
            </DetailForTask>
          ) : actionType === 'document' ? (
            <DetailForDocument>
              <b className="">{`${textType} : `}</b>
              <u className="">{actionName}</u>
            </DetailForDocument>
          ) : actionType === 'insurance_port' ? (
            <>
              <div className="">
                <b>คำสั่งซื้อ</b>
                {info?.orderNo && <label className="textType">{` #${info?.orderNo} `}</label>}
              </div>
              <div className="">
                <label>{actionName}</label>
                {props?.contactName && (
                  <>
                    <label>{` ของ `}</label>
                    <u>{props.contactName}</u>
                  </>
                )}
              </div>
            </>
          ) : actionType === 'opportunity' ? (
            <DetailForOpportunity>
              {subActionType === 'assign_order' ? (
                <label className="textType other">
                  <span>{textType}</span> {actionName}
                </label>
              ) : (
                <>
                  <label className="textType other">
                    <span>{textType}</span> {actionName}
                  </label>
                  {props.contactName && <u className="textType">{`: ${props.contactName}`}</u>}
                </>
              )}
            </DetailForOpportunity>
          ) : (
            <DetailForEvents>
              <div className="title">
                <b className="textType">{textType}</b>
                <label className="actionName">{actionName}</label>
                {props?.contactName && <u className="contactName">{` : ${props.contactName}`}</u>}
              </div>
            </DetailForEvents>
          )}
          <ExpandeDetail>
            {actionType === 'opportunity' && (
              <>
                {subActionType === 'assign_order' ? (
                  <div>
                    <label>{`มีคำสั่งซื้อ ${info?.orderNo} มาใหม่จากผู้แนะนำ `}</label>
                    <b>{info?.orderStatus}</b>
                  </div>
                ) : (
                  <>
                    {!['สถานะ', 'Action Code'].includes(info?.orderStatus || '') ? (
                      <>
                        <OpportunityText>
                          {`มีการอัปเดตสถานะเป็น `}
                          <b>{info?.orderStatus}</b>
                        </OpportunityText>
                      </>
                    ) : (
                      <OpportunityContainer>
                        <OpportunityText>
                          {`มีการอัปเดต `}
                          <b>{info?.orderStatus}</b>
                        </OpportunityText>
                        <OpportunityText>ของคำสั่งซื้อ #{info?.orderNo}</OpportunityText>
                      </OpportunityContainer>
                    )}
                  </>
                )}
              </>
            )}
            {actionType === 'event' && eventDate}
            {actionType === 'document' && (
              <>
                {subActionType === 'update_document_file' ? (
                  <label>มีอัปเดตโปรดตรวจสอบรายละเอียดเพื่อดำเนินการต่อ</label>
                ) : (
                  <label>
                    {`ของท่านกำลังจะหมดอายุวันที่ `}
                    <b>{info?.expired ? dateFormat(info?.expired) : dateFormat(new Date())}</b>
                    {` โปรดตระเตรียมการต่ออายุล่วงหน้าก่อนบัญชีของท่านจะถูกระงับ `}
                  </label>
                )}
              </>
            )}
            {actionType === 'task' && latedTask > 0 && (
              <label>{`มี ${latedTask} งานที่เกินกำหนด`}</label>
            )}
            {actionType === 'insurance_port' && (
              <>
                {subActionType === 'expired' && info?.expired && (
                  <div>
                    <b>{info.massage}</b>
                    <label>{` ในวันที่ ${dateFormat(info.expired)} `}</label>
                  </div>
                )}
                {subActionType === 'payment' && info?.paymentDate && (
                  <div>
                    <b>{info.massage}</b>
                    <label>{` ในวันที่ ${dateFormat(info.paymentDate)} `}</label>
                  </div>
                )}
              </>
            )}
          </ExpandeDetail>
        </ListContainer>
      </DetailContainer>
      <DetailContainer style={{ justifyContent: 'flex-end' }}>
        <TimeText>{moment(props.alertAt).fromNow()}</TimeText>
      </DetailContainer>
    </MainContainer>
  )
}

export default DetailItem
