import React, { FC, useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import queryString from 'querystring'
import styled from '@emotion/styled'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import { RootState } from 'store/rootReducer'
import { get, isEqual } from 'lodash'
import SearchProduct from './SearchProduct'
import { getSearch } from 'store/travelInsuranceSlice'
import { SearchStateType } from 'types/TravelInsuranceType'
import Loader from 'components/BackgroundLoading'
import Compare from '../compare'

type SearchType = {
  id: string
}
type FilterType = {
  companyCode: string | null
  coverCurrent: number | null
}

const testData = [
  {
    amount: '1991',
    carCheck: '199',
    carGroup: '199',
    carUsage: '199',
    companyCode: '199',
    companyLogoUrl: '',
    companyName: 'กรุงเทพประกันภัย',
    completeOnline: '199',
    cover: '199',
    cover3Rd: '199',
    cover3RdAsset: '199',
    cover3RdTime: '199',
    coverAcc: '199',
    coverAccNum: '199',
    coverAccPass: '199',
    coverAccPassNum: '199',
    coverDeduct: '199',
    coverFlooding: '199',
    coverLegal: '199',
    coverLostFire: '199',
    coverMed: '199',
    coverPeriod: '199',
    duty: '199',
    garage: '199',
    garageTotal: '199',
    level: '199',
    matrixId: '1',
    netAmount: '16199',
    premiumType: '199',
    productId: '199',
    promotionCode: '199',
    promotionCodeSupplier: '199',
    salepiority: 0,
    showDeductNoPaid: '199',
    startPolicyDate: '199',
    takephoto: '199',
    terrorism: '199',
    vat: '199',
  },
  {
    amount: '8199',
    carCheck: '199',
    carGroup: '199',
    carUsage: '199',
    companyCode: '199',
    companyLogoUrl: 'https://api-dev.agent-tools.tqld.xyz/images/avatar_agent.png',
    companyName: 'กรุงเทพประกันภัย',
    completeOnline: '199',
    cover: '199',
    cover3Rd: '199',
    cover3RdAsset: '199',
    cover3RdTime: '199',
    coverAcc: '199',
    coverAccNum: '199',
    coverAccPass: '199',
    coverAccPassNum: '199',
    coverDeduct: '199',
    coverFlooding: '199',
    coverLegal: '199',
    coverLostFire: '199',
    coverMed: '199',
    coverPeriod: '199',
    duty: '199',
    garage: '199',
    garageTotal: '199',
    level: '199',
    matrixId: '2',
    netAmount: '81199',
    premiumType: '199',
    productId: '199',
    promotionCode: '199',
    promotionCodeSupplier: '199',
    salepiority: 0,
    showDeductNoPaid: '199',
    startPolicyDate: '199',
    takephoto: '199',
    terrorism: '199',
    vat: '199',
  },
  {
    amount: '99',
    carCheck: '199',
    carGroup: '199',
    carUsage: '199',
    companyCode: '199',
    companyLogoUrl: 'https://api-dev.agent-tools.tqld.xyz/images/avatar_agent.png',
    companyName: 'กรุงเทพประกันภัย',
    completeOnline: '199',
    cover: '199',
    cover3Rd: '199',
    cover3RdAsset: '199',
    cover3RdTime: '199',
    coverAcc: '199',
    coverAccNum: '199',
    coverAccPass: '199',
    coverAccPassNum: '199',
    coverDeduct: '199',
    coverFlooding: '199',
    coverLegal: '199',
    coverLostFire: '199',
    coverMed: '199',
    coverPeriod: '199',
    duty: '199',
    garage: '199',
    garageTotal: '199',
    level: '199',
    matrixId: '3',
    netAmount: '7199',
    premiumType: '199',
    productId: '199',
    promotionCode: '199',
    promotionCodeSupplier: '199',
    salepiority: 0,
    showDeductNoPaid: '199',
    startPolicyDate: '199',
    takephoto: '199',
    terrorism: '199',
    vat: '199',
  },
  {
    amount: '9912',
    carCheck: '199',
    carGroup: '199',
    carUsage: '199',
    companyCode: '199',
    companyLogoUrl: 'https://api-dev.agent-tools.tqld.xyz/images/avatar_agent.png',
    companyName: 'กรุงเทพประกันภัย',
    completeOnline: '199',
    cover: '199',
    cover3Rd: '199',
    cover3RdAsset: '199',
    cover3RdTime: '199',
    coverAcc: '199',
    coverAccNum: '199',
    coverAccPass: '199',
    coverAccPassNum: '199',
    coverDeduct: '199',
    coverFlooding: '199',
    coverLegal: '199',
    coverLostFire: '199',
    coverMed: '199',
    coverPeriod: '199',
    duty: '199',
    garage: '199',
    garageTotal: '199',
    level: '199',
    matrixId: '5',
    netAmount: '19669',
    premiumType: '199',
    productId: '199',
    promotionCode: '199',
    promotionCodeSupplier: '199',
    salepiority: 0,
    showDeductNoPaid: '199',
    startPolicyDate: '199',
    takephoto: '199',
    terrorism: '199',
    vat: '199',
  },
]

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
`

const Header = styled.h3`
  margin-bottom: 10px;
`

const InsuranceProduct: FC = () => {
  let { id } = useParams<SearchType>()
  const location = useLocation().search
  const dispatch = useDispatch()
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const { travelInsurances, loading, options } = useSelector((state: RootState) => state.travel)

  const [newLocation, setNewLocation] = useState<string>(location)
  const [isCompare, setIsCompare] = useState<boolean>(false)
  const [compareData, setCompareData] = useState<Array<SearchStateType> | []>([])
  const [filter, setFilter] = useState<FilterType>({
    companyCode: null,
    coverCurrent: null,
  })

  const breadCrumbArr: Array<LinkValues> = [
    {
      path: `/opportunities/${id}`,
      label: get(opportunity, 'name', 'โอกาสในการขาย'),
    },
    {
      path: `/opportunities/${id}/catalog`,
      label: 'เลือกหมวดประกัน',
    },
    {
      path: `/opportunities/${id}/catalog/travel`,
      label: 'ประกันเดินทาง',
    },
    {
      label: isCompare ? 'รายละเอียดและเปรียบเทียบ' : 'แสดงแบบประกัน',
    },
  ]

  useEffect(() => {
    dispatch(getNewOpportunityById(id))
  }, [dispatch, id])

  useEffect(() => {
    if (id && newLocation) {
      console.log('newLocation....', newLocation)
      console.log(queryString.parse(newLocation.replace('?', '')))
      dispatch(getSearch(id, queryString.parse(newLocation.replace('?', ''))))
    }
  }, [id, newLocation])

  const onFilterChange = useCallback(
    (value) => {
      console.log('onFilterChange....', value)
      console.log('onFilterChange....filter', filter)
      if (!isEqual(value, filter)) {
        setFilter(value)
        let originalUrl = queryString.parse(location.replace('?', ''))
        const newJsonStr = { ...originalUrl, ...filter }
        console.log('newJsonStr....', newJsonStr)
        setNewLocation(`?${queryString.stringify(newJsonStr)}`)
      }
    },
    [filter],
  )

  const memoFilter = useMemo(() => filter, [filter])

  return (
    <Container>
      <Header>ประกันเดินทาง</Header>
      <BreadCrumb links={breadCrumbArr} />
      {isCompare ? (
        <Compare
          setIsCompare={setIsCompare}
          setCompareData={setCompareData}
          data={compareData}
          location={location}
        />
      ) : (
        <>
          {(loading.options || loading.search) && <Loader />}
          <SearchProduct
            data={travelInsurances}
            options={options}
            filter={memoFilter}
            setFilter={onFilterChange}
            setCompareData={setCompareData}
            compareData={compareData}
            setIsCompare={setIsCompare}
            location={location}
          />
        </>
      )}
    </Container>
  )
}

export default InsuranceProduct
