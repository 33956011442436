import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import styled from '@emotion/styled'
import { ModalCustomStyle } from 'components/Modal'
import Modal from 'react-modal'
import { getReHistoriesConsentsByAgent, sendNewConsent } from 'store/consentsSlice'
import Banner from '../assets/images/friday_biz.png'
import { Formik, Form, useFormikContext } from 'formik'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CustomModal = styled(Form)`
  width: 736px;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 34px;
  border-bottom: 1px solid var(--agt-background-color-2);
`
const Image = styled.img`
  width: 230px;
  margin-bottom: -8px;
  object-fit: cover;
`
const Content = styled.div`
  width: 100%;
  padding: 32px 96px;
  display: flex;
  flex-direction: column;
  .name {
    display: flex;
    margin-bottom: 16px;
    font-weight: bold;
    width: fit-content;
    color: var(--agt-primary-color-1);
    /* border-bottom: 4px solid var(--agt-primary-color-1); */
  }
  .consents {
    width: 100%;
    height: 100%;
    max-height: 300px;
    white-space: pre-wrap;
    overflow-y: auto;
    margin-bottom: 16px;
  }
  .checked {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    gap: 16px;
    color: var(--agt-primary-color-1);
    overflow-wrap: anywhere;
  }
  .checked .square {
    width: 16px;
    min-width: 16px;
    height: 16px;
    background: var(--agt-primary-color-1);
    border: 1px solid var(--agt-secondary-color-2);
    border-radius: 4px;
    position: relative;
  }
  .checked .square .faCheck {
    color: var(--agt-white-color);
    opacity: 0;
    position: absolute;
    font-size: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .checked input {
    display: none;
  }
  .checked input:checked ~ .square {
    /* background: #000000; */
  }
  .checked input:checked ~ .square .faCheck {
    opacity: 1;
  }
  .checked:last-of-type {
    margin-bottom: 16px;
  }
  .submit {
    align-self: center;
  }
`

const FormModal = (props) => {
  const { handleSubmit, agreement, values, setIsChecked, isChecked } = props
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if (agreement && agreement?.consents && values && values?.consents) {
      let checkedArrays = true
      agreement.consents.forEach((array: { forcedConsent: boolean }, index: number) => {
        if (values.consents[index]) {
          if (array.forcedConsent && !values.consents[index].value) {
            checkedArrays = false
            return null
          }
        } else if (array.forcedConsent) {
          checkedArrays = false
          return null
        }
      })
      setIsChecked(checkedArrays)
    }
  }, [setIsChecked, agreement, values])

  return (
    <CustomModal onSubmit={handleSubmit}>
      <Header>
        <Image alt="agentProtal" src={Banner} />
      </Header>
      <Content>
        <div className="name">
          {agreement?.name || 'นโยบายการเก็บรวบรวมและเปิดเผยข้อมูลส่วนบุคคล'}
        </div>
        <p
          className="consents"
          dangerouslySetInnerHTML={{
            __html: agreement?.detail || 'ยังไม่มีการระบุเนื้อหาใดๆ',
          }}
        />
        {(agreement?.consents || []).map((consent: { name: string }, index: number) => {
          return (
            <label className="checked">
              <input
                type="checkbox"
                checked={values?.consents[index]?.value}
                onClick={() => {
                  if (values?.consents[index]?.value) {
                    setFieldValue(`consents.${index}.value`, false)
                  } else {
                    setFieldValue(`consents.${index}.value`, true)
                  }
                }}
              />
              <div className="square">
                <FontAwesomeIcon icon={faCheck} className="faCheck" />
              </div>
              {consent?.name || ''}
            </label>
          )
        })}
        <button type="submit" className="submit" disabled={!isChecked}>
          ยืนยันข้อมูล
        </button>
      </Content>
    </CustomModal>
  )
}

const NewConsentModal = ({ currentAgent }) => {
  const dispatch = useDispatch()
  const agreement = useSelector((state: RootState) => state.consents.agreement)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [indexAgreement, setIndexAgreement] = useState<number>(0)
  const [isChecked, setIsChecked] = useState<boolean>(false)

  useEffect(() => {
    if (
      currentAgent &&
      currentAgent.agentType === 'guest' &&
      currentAgent.isExpired === false &&
      currentAgent.newAgreements.length > 0
    )
      setIsOpen(true)
    else if (
      currentAgent &&
      (currentAgent.agentType === 'affiliate' || currentAgent.agentType === 'tqm_staff') &&
      currentAgent.newAgreements.length > 0
    )
      setIsOpen(true)
  }, [currentAgent, setIsOpen])

  useEffect(() => {
    if (isOpen && currentAgent.newAgreements.length > indexAgreement) {
      let agreementId: number = currentAgent.newAgreements[indexAgreement].agreementId
      dispatch(
        getReHistoriesConsentsByAgent(agreementId, () => {
          setIsOpen(false)
        }),
      )
    } else if (isOpen && currentAgent.newAgreements.length === indexAgreement) {
      window.location.reload()
    }
  }, [dispatch, currentAgent, indexAgreement, isOpen])

  if (currentAgent && currentAgent?.newAgreements.length > 0) {
    return (
      <Fragment>
        <Modal isOpen={isOpen} style={ModalCustomStyle}>
          <Formik
            enableReinitialize
            initialValues={{
              agreementId: agreement?.id || '',
              consents: agreement?.consents || [],
            }}
            onSubmit={(values) => {
              dispatch(
                sendNewConsent(values, () => {
                  setIndexAgreement(indexAgreement + 1)
                  setIsChecked(false)
                  if (indexAgreement === currentAgent.newAgreements.length - 1) {
                    setIsOpen(false)
                  }
                }),
              )
            }}
          >
            {(props) => {
              return (
                <FormModal
                  agreement={agreement}
                  setIsChecked={setIsChecked}
                  isChecked={isChecked}
                  {...props}
                />
              )
            }}
          </Formik>
        </Modal>
      </Fragment>
    )
  }
  return null
}

export default NewConsentModal
