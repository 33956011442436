import React, { FC, useEffect } from 'react'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { useHistory } from 'routes'
import { getProfile } from 'store/profileSlice'
import UserForm from 'components/Form/UserForm'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import validation from './form.validation'

import { editProfle } from 'store/profileSlice'
import UserType from 'types/RegisterType'
import HistoryType from 'types/HistoryType'

type EditProfilePageType = {}

const Container = styled.div`
  width: 100%;
  padding: 16px 0 0;
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const Body = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  padding: 30px 0 0 0;
  .submitBtn {
    display: block;
    margin: 0 auto;
  }
`

const EditProfilePage: FC<EditProfilePageType> = () => {
  const history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const profile: UserType = useSelector((state: RootState) => state.profile.profile)

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  const breadCrumbArr: Array<LinkValues> = [
    { path: '/profile', label: 'ข้อมูลส่วนตัว' },
    {
      label: 'แก้ไขข้อมูลส่วนตัว',
    },
  ]
  return (
    <Container>
      <Header>ข้อมูลส่วนตัว</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Body>
        <Formik
          enableReinitialize
          initialValues={{ ...profile }}
          onSubmit={(values: UserType) => {
            // console.log('onSubmit : ', values)
            dispatch(editProfle(values, () => history.push('/profile')))
          }}
          validationSchema={validation}
        >
          {(props) => {
            const { isValid, dirty, submitForm } = props
            return (
              <>
                <UserForm
                  disableList={[
                    'prefixName',
                    'firstName',
                    'lastName',
                    'gender',
                    'nickname',
                    'birthdate',
                    'idCard',
                    'phoneNumber',
                    'email',
                    'idCardAddress',
                    'isOTP',
                    'insuranceCompanyName',
                    'agentLicenses',
                    'recruitType',
                  ]}
                />
                <button
                  className="submitBtn"
                  type="button"
                  disabled={!isValid || !dirty}
                  onClick={() => submitForm()}
                >
                  บันทึก
                </button>
              </>
            )
          }}
        </Formik>
      </Body>
    </Container>
  )
}

export default EditProfilePage
