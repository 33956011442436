import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import {
  getConsentByContact,
  getConsentDetail,
  getHistoriesConsentsByAgent,
} from 'store/consentsSlice'
import Banner from '../assets/images/friday_biz.png'
import ReactModal from 'react-modal'
import { ModalCustomStyle } from 'components/Modal'
import { RootState } from 'store/rootReducer'

const CustomModal = styled.div`
  width: 100%;
  min-width: 736px;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    padding: 0 34px;
    border-bottom: 1px solid var(--agt-background-color-2);
  }
  img {
    width: 230px;
    margin-bottom: -8px;
    object-fit: cover;
  }
  button {
    display: block;
    margin: 0 auto;
    margin-bottom: 24px;
  }
  .content {
    width: 100%;
    padding: 34px 96px 44px;
    display: flex;
    flex-direction: column;
  }
  .content .name {
    display: flex;
    margin-bottom: 24px;
    font-weight: bold;
    width: fit-content;
    color: var(--agt-primary-color-1);
  }
  .content .consents {
    width: 100%;
    height: 100%;
    max-height: 300px;
    white-space: pre-wrap;
    overflow-y: auto;
    margin-bottom: 44px;
  }
`
const Container = styled.div`
  width: 100%;
  section {
    padding: 16px 24px 0 24px;
  }
  .consents {
    margin-bottom: 24px;
  }
  .consents:last-of-type {
    margin-bottom: 0px;
  }
  .consents p {
    width: fit-content;
    text-decoration: underline;
    color: var(--agt-primary-color-1);
    cursor: pointer;
  }
  .consents p:hover {
    color: var(--agt-dark-sky-color);
  }
  .consents small {
    color: var(--agt-secondary-color-1);
  }
`

const ShowHistoriesConsent = ({ contactId, className, consents, type = 'contact' }) => {
  const [isOpenConsent, setIsOpenConsent] = useState<boolean>(false)
  const dispatch = useDispatch()
  const consentUrl = useSelector((state: RootState) => state.consents?.consentUrl || null)
  const agreementContact = useSelector((state: RootState) => state.consents?.agreement || null)
  const agreementAgent = useSelector((state: RootState) => state.consents?.agreement || null)

  useEffect(() => {
    if (contactId && consentUrl) {
      let searchConsentUrl = consentUrl.split('?')[1].split('&')
      let keyValues = { agreement_id: '', contact_id: '', jwt_contact_token: '' }
      searchConsentUrl.forEach((keySearch) => {
        let val = keySearch.split('=')
        keyValues[val[0]] = val[1]
      })
      dispatch(getConsentDetail(keyValues, 'contact'))
    }
  }, [dispatch, consentUrl, contactId])

  return (
    <Container className={className}>
      {type === 'contact' && agreementContact && (
        <ReactModal isOpen={isOpenConsent} style={ModalCustomStyle}>
          <CustomModal>
            <header>
              <img alt="agentProtal" src={Banner} />
            </header>
            <div className="content">
              <div className="name">
                {agreementContact?.name || 'นโยบายการเก็บรวบรวมและเปิดเผยข้อมูลส่วนบุคคล'}
              </div>
              <p
                className="consents"
                dangerouslySetInnerHTML={{
                  __html: agreementContact?.detail || 'ยังไม่มีการระบุเนื้อหาใดๆ',
                }}
              />
            </div>
            <button type="button" onClick={() => setIsOpenConsent(false)}>
              ตกลง
            </button>
          </CustomModal>
        </ReactModal>
      )}
      {type === 'setting' && agreementAgent && (
        <ReactModal isOpen={isOpenConsent} style={ModalCustomStyle}>
          <CustomModal>
            <header>
              <img alt="agentProtal" src={Banner} />
            </header>
            <div className="content">
              <div className="name">
                {agreementAgent?.name || 'นโยบายการเก็บรวบรวมและเปิดเผยข้อมูลส่วนบุคคล'}
              </div>
              <p
                className="consents"
                dangerouslySetInnerHTML={{
                  __html: agreementAgent?.detail || 'ยังไม่มีการระบุเนื้อหาใดๆ',
                }}
              />
            </div>
            <button type="button" onClick={() => setIsOpenConsent(false)}>
              ตกลง
            </button>
          </CustomModal>
        </ReactModal>
      )}
      <section>
        {consents.map(
          (consent: { name: string; id: string | number; version: string }, index: number) => (
            <div className="consents">
              <p
                key={index}
                onClick={() => {
                  if (type === 'contact') {
                    dispatch(
                      getConsentByContact(consent.id, () => {
                        setIsOpenConsent(true)
                      }),
                    )
                  } else if (type === 'setting') {
                    dispatch(
                      getHistoriesConsentsByAgent(consent.id, () => {
                        setIsOpenConsent(true)
                      }),
                    )
                  }
                }}
              >
                {consent.name}
              </p>
              {consent?.version && <small>{consent.version}</small>}
            </div>
          ),
        )}
      </section>
    </Container>
  )
}

export default ShowHistoriesConsent
