import React, { useState, useEffect, useMemo, FC } from 'react'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import { useHistory, useParams } from 'routes'
import { isUndefined, get, isEmpty } from 'lodash'
import { dateFormat } from 'utils/helper'
import TabSideBar from './TabSideBar'
import OrdersCard from './OrdersCard'
import CardForm from './CardForm'
import HistoryType from 'types/HistoryType'
import { useDispatch, useSelector } from 'react-redux'
import { createOpportunity, getOpportunityById, updateOpportunity } from 'store/opportunitiesSlice'
import { RootState } from 'store/rootReducer'
import initialValues from './OpportunityCard.utils'
import Icon from 'components/Icon'
import SetStateType from 'types/SetStateType'
import WebTourViewOrder from './WebTourViewOrder'
import WebTourNew from './WebTourNew'
import WebTourEdit from './WebTourEdit'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .Header {
    margin: 16px 0 10px;
  }
  .Body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .Body .Content {
    width: ${(764 / 1120) * 100}%;
    max-width: 764px;
    padding-top: 24px;
    padding-right: 32px;
  }
  .Body .Content .Submit {
    border-top: 1px solid var(--agt-secondary-color-1);
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .Body .Sub {
    border-left: 1px solid var(--agt-secondary-color-1);
    border-right: 1px solid var(--agt-secondary-color-1);
    width: ${(254 / 1120) * 100}%;
    max-width: 254px;
    height: 100%;
  }
  .Body .RowLayout {
    display: flex;
    justify-content: space-between;
  }
  .Body .RowLayout .Column {
    display: flex;
    flex-direction: column;
    width: 352px;
    margin-bottom: 16px;
  }
  .Body .RowLayout .SearchInsurance {
    width: 352px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .Body .Orders {
    border-top: 1px solid var(--agt-secondary-color-1);
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    background: var(--agt-background-color-1);
  }
`
const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  h3 {
    margin-top: 16px;
    margin-bottom: 10px;
  }
  .hint {
    position: absolute;
    top: 64px;
    right: 0px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    color: var(--agt-primary-color-1);
  }
`
const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`

const DisplayCard = ({ id, opportunity, orders, events, tasks, setIsEdit, isCanEdit = false }) => {
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  return (
    <div className="Body">
      <div className="Content">
        <div className="Detail">
          <div className="RowLayout" style={{ marginBottom: '16px', alignItems: 'center' }}>
            <div className="Column" style={{ marginBottom: '0' }}>
              <b>{get(opportunity, 'name', '')}</b>
            </div>
            <small style={{ color: 'var(--agt-secondary-color-1)' }}>
              {get(opportunity, 'opportunityKey', '')}
            </small>
          </div>
          <div
            className="RowLayout"
            style={{
              marginBottom: '16px',
              alignItems: 'flex-start',
              paddingBottom: '24px',
              borderBottom: '1px solid var(--agt-secondary-color-1)',
            }}
          >
            <div>รายชื่อลูกค้า : {get(opportunity, 'contactName', '')}</div>
            <div className="Column" style={{ marginBottom: '0', alignItems: 'flex-end' }}>
              <small>อัปเดตล่าสุด {dateFormat(get(opportunity, 'updatedAt', ''))}</small>
              <small style={{ color: 'var(--agt-secondary-color-1)' }}>
                สร้างเมื่อ {dateFormat(get(opportunity, 'createdAt', ''))}
              </small>
            </div>
          </div>
          <div className="Column" id="opportunity-id__note">
            <div style={{ marginBottom: '16px' }}>โน้ตเพิ่มเติม</div>
            <div style={{ marginBottom: '16px' }}>
              {isEmpty(get(opportunity, 'note', '')) ? '-' : get(opportunity, 'note')}
            </div>
            {!isUndefined(id) && <OrdersCard orders={orders} id={id} />}
          </div>
        </div>
        <div className="Submit" style={{ justifyContent: 'flex-end' }}>
          {orders.length === 0 && (
            <button
              type="button"
              className="secondary"
              disabled={currentAgent?.statusTh === 'ระงับ'}
              style={{
                marginRight: '24px',
                display: 'none',
              }}
            >
              ลบ
            </button>
          )}
          <button
            type="button"
            className="secondary"
            onClick={() => setIsEdit(true)}
            disabled={
              !isCanEdit ||
              get(opportunity, 'contactStatus') === 'รอยินยอม' ||
              currentAgent?.statusTh === 'ระงับ'
            }
          >
            แก้ไข
          </button>
        </div>
      </div>
      <div className="Sub">
        <TabSideBar header="นัดหมาย" events={events} addition={false} currentAgent={currentAgent} />
        <TabSideBar
          header="งานที่ต้องทำ"
          tasks={tasks}
          addition={false}
          currentAgent={currentAgent}
        />
      </div>
    </div>
  )
}

const HeaderComponent = ({
  setIsShowHint,
  id,
  isEdit,
}: {
  setIsShowHint: SetStateType<boolean>
  id?: string
  isEdit: boolean
}) => {
  return (
    <Header>
      <h3 className="Header">{id ? 'โอกาสในการขาย' : 'สร้างโอกาสในการขาย'}</h3>
      <div className="hint" onClick={() => setIsShowHint(true)}>
        <HintIcon name="hint" />
        <span>คำแนะนำ</span>
      </div>
    </Header>
  )
}

const OpportunityCard: FC = () => {
  const history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isCanEdit, setIsCanEdit] = useState<boolean>(false)
  const [isShowHint, setIsShowHint] = useState<boolean>(false)
  const [orders, setOrders] = useState<
    | Array<{
        affiliate: string
        companyLogoUrl: string
        companyName: string
        draftingParams: string | null
        id: string | number
        lastUpdatedAt: string
        name: string
        orderNo: string
        orderType: string
        premium: string
        salesman: string
        status: string
        submittedAt: string
      }>
    | []
  >([])
  const [events, setEvents] = useState([])
  const [tasks, setTasks] = useState([])
  const [opportunity, setOpportunity] = useState<{
    canEdit: boolean
    contactAge: number
    contactAvatar: string
    contactGender: string
    contactId: string | number
    contactName: string
    contactStatus: string
    createdAt: string
    createdByType: string
    events: []
    id: string | number
    name: string
    note: string
    opportunityKey: string
    orders: []
    state: string
    status: string | null
    tasks: []
    updatedAt: string
  } | null>(null)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/opportunities', label: 'โอกาสในการขาย' },
    {
      label: get(opportunity, 'name') || 'สร้างโอกาสในการขาย',
    },
  ]

  const modifiedValue = useMemo(() => {
    if (opportunity) {
      return {
        name: get(opportunity, 'name'),
        note: get(opportunity, 'note'),
        contactId: get(opportunity, 'contactId'),
        tasks: get(opportunity, 'tasks'),
        events: get(opportunity, 'events'),
        opportunityKey: get(opportunity, 'opportunityKey'),
      }
    } else {
      return initialValues
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity])

  useEffect(() => {
    if (!isUndefined(id)) {
      dispatch(
        getOpportunityById(
          id,
          (response) => {
            setOpportunity(response.data.data.opportunity)
            setIsCanEdit(response.data.data.canEdit)
          },
          () => {
            history.push('/opportunities/')
          },
        ),
      )
    }
  }, [dispatch, id, history])

  useEffect(() => {
    if (opportunity) {
      setOpportunity(opportunity)
      setOrders(get(opportunity, 'orders', []))
      setEvents(get(opportunity, 'events', []))
      setTasks(get(opportunity, 'tasks', []))
    }
  }, [opportunity])

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (id) {
        if (storage['opportunities']['opportunityById'] === false) {
          window.localStorage.setItem(
            'webtour',
            JSON.stringify({
              ...storage,
              opportunities: { ...storage.opportunities, opportunityById: true },
            }),
          )
          setTimeout(() => {
            setIsShowHint(true)
          }, 1000)
        }
      } else {
        if (storage['opportunities']['create'] === false) {
          window.localStorage.setItem(
            'webtour',
            JSON.stringify({
              ...storage,
              opportunities: { ...storage.opportunities, create: true },
            }),
          )
          setTimeout(() => {
            setIsShowHint(true)
          }, 1000)
        }
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: false, create: false, contactById: false },
          tasks: { tasks: false, create: false },
          calendar: { calendar: false, create: false },
          opportunities: {
            opportunities: false,
            create: id ? false : true,
            opportunityById: id ? true : false,
            completed: false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [id, setIsShowHint])

  return (
    <Container>
      {id && isEdit ? (
        <WebTourEdit visible={isShowHint} setVisible={setIsShowHint} />
      ) : id ? (
        <WebTourViewOrder visible={isShowHint} setVisible={setIsShowHint} />
      ) : (
        <WebTourNew visible={isShowHint} setVisible={setIsShowHint} />
      )}
      <HeaderComponent setIsShowHint={setIsShowHint} id={id} isEdit={isEdit} />
      <BreadCrumb links={breadCrumbArr} />
      {id && isEdit ? (
        <Formik
          initialValues={modifiedValue}
          enableReinitialize
          onSubmit={(values) => {
            let formatValues = {
              ...values,
              events: get(values, 'events', []),
              tasks: get(values, 'tasks', []),
              name: get(values, 'name', ''),
              note: get(values, 'note', ''),
              contactId: get(values, 'contactId', 0),
            }
            let newEvents: Array<number | string> | [] =
              events.length > 0 ? formatValues.events.map((e: { id: number | string }) => e.id) : []
            let newTasks: Array<number | string> | [] =
              tasks.length > 0 ? formatValues.tasks.map((e: { id: number | string }) => e.id) : []
            dispatch(
              updateOpportunity(id, { ...formatValues, tasks: newTasks, events: newEvents }, () => {
                window.location.reload()
              }),
            )
          }}
        >
          {(props) => (
            <CardForm {...props} {...opportunity} isDisabledContact={orders.length > 0} />
          )}
        </Formik>
      ) : id ? (
        <DisplayCard
          id={id}
          opportunity={opportunity}
          orders={orders}
          events={events}
          tasks={tasks}
          setIsEdit={setIsEdit}
          isCanEdit={isCanEdit}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values) => {
            let newEvents = values.events.map(({ id }) => id)
            let newTasks = values.tasks.map(({ id }) => id)
            dispatch(
              createOpportunity({ ...values, tasks: newTasks, events: newEvents }, (response) => {
                let id: string = response.data.data.opportunity.id
                history.push(`/opportunities/${id}`)
              }),
            )
          }}
        >
          {(props) => <CardForm {...props} />}
        </Formik>
      )}
    </Container>
  )
}

export default OpportunityCard
