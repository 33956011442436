import { isEmpty } from 'lodash'

type LicenseType = {
  id?: string
  licenseCode?: string
  licenseImageId?: string
  licenseImagePath?: string
  status?: string
}

type RegisterType = { agentLicenses?: Array<LicenseType> }

export const validate = (values) => {
  const errors: RegisterType = {}
  if (values.agentLicenses && values.agentLicenses.length > 0) {
    let errorAgents = values.agentLicenses.map((agent) => {
      let errorAgent: LicenseType = {}
      if (!agent.licenseCode) {
        errorAgent.licenseCode = 'กรุณากรอกเลขที่ใบอนุญาต'
      } else if (agent.licenseCode && agent.licenseCode.length < 10) {
        errorAgent.licenseCode = 'กรุณากรอกเลขที่ใบอนุญาต'
      } else if (!agent.licenseType) {
        errorAgent.licenseCode = 'กรุณาระบุประเภทใบอนุญาต'
      } else if (!agent.licenseImageId && !agent.licenseImagePath) {
        errorAgent.licenseCode = 'กรุณาอัพโหลดใบอนุญาต'
      }
      return errorAgent
    })
    if (!isEmpty(errorAgents))
      errors.agentLicenses = errorAgents.every((agent) => isEmpty(agent)) ? [] : errorAgents
  }
  if (isEmpty(errors.agentLicenses)) delete errors.agentLicenses
  return errors
}

export default validate
