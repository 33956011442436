import styled from '@emotion/styled'
import Icon from 'components/Icon'
import { Form } from 'formik'

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  .recruitment {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 24px;
    padding-bottom: 32px;
    background: #eff1f5;
    margin-bottom: 32px;
  }

  .recruitment .affiliateRecruit {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .recruitment .brokerRecruit {
    display: flex;
    flex-direction: column;
  }
  .recruitment .affiliateRecruit b,
  .recruitment .brokerRecruit b {
    margin-bottom: 8px;
  }
  .recruitment .brokerRecruit .nextRecruitment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-right: 10px; */
  }
  .recruitment .brokerRecruit .nextRecruitment small {
    color: var(--agt-error-color);
  }
  .recruitment .affiliateRecruit .affiliateStatus {
    background: #d8d9e0;
    border-radius: 5px;
    width: 240px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b5b6bf;
  }
  .recruitment .affiliateRecruit .affiliateStatus.success {
    background: #9bcc55;
    border-radius: 5px;
    width: 240px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff !important;
  }
  .recruitment .affiliateRecruit .affiliateStatus.process {
    background: #f5a71d;
    border-radius: 5px;
    width: 240px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff !important;
  }
  .recruitment .affiliateRecruit small {
    position: absolute;
    bottom: -24px;
    color: var(--agt-error-color);
  }
  .recruitment .groupRecruit {
    display: flex;
    column-gap: 18px;
  }
  .recruitment .groupRecruit .broker {
    display: flex;
    align-items: center;
    column-gap: 12px;
    position: relative;
  }
  .recruitment .groupRecruit .broker .brokerStatus {
    /* background: #d8d9e0; */
    border-radius: 5px;
    width: 240px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: #b5b6bf; */
  }
  .recruitment .groupRecruit .broker .brokerStatus.success {
    background: #9bcc55;
    border-radius: 5px;
    width: 280px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff !important;
  }
  .recruitment .groupRecruit .broker .brokerStatus.process {
    background: #f5a71d;
    border-radius: 5px;
    width: 280px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff !important;
  }
  .recruitment .groupRecruit .broker .notice {
    z-index: 1;
    position: absolute;
    width: max-content;
    background: #00529a;
    border-radius: 8px;
    color: #ffffff;
    padding: 14px;
    font-size: 13px;
  }
  .recruitment .groupRecruit .broker .notice.lifeBroker {
    top: 72px;
    right: -199px;
  }
  .recruitment .groupRecruit .broker .notice.nonLifeBroker {
    top: 72px;
    right: 8px;
  }
  .recruitment .groupRecruit .broker small {
    position: absolute;
    bottom: -24px;
    color: var(--agt-error-color);
  }
  .message {
    padding-left: 20px;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
  }
  .content .header .info {
    height: 48px;
    margin-left: 12px;
    padding: 0 14px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--agt-primary-color-1);
    font-size: 13px;
    color: var(--agt-white-color);
    position: absolute;
    top: -66%;
    left: 18%;
    @media (max-width: 1024px) {
      left: 20%;
    }
  }
  .content .header {
    position: relative;
    display: flex;
    padding-left: 16px;
    margin-top: 44px;
  }
  .content .header > label {
    margin-right: 12px;
    font-weight: bold;
  }
  .content .subtopic {
    display: grid;
    grid-template-columns: 65% 11% auto;
    color: var(--agt-secondary-color-1);
  }
  .content .body {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 65% 11% auto;
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--agt-background-color-2);
    > .upload {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      .title {
        display: flex;
        align-items: center;
      }
      .uploadbtn {
        display: flex;
        button:first-of-type {
          margin-right: 24px;
          @media (max-width: 1024px) {
            width: 140px;
            font-size: 13px;
          }
        }
      }
    }
  }
  .content .subbody {
    display: grid;
    grid-template-columns: 69% 9% auto;
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--agt-background-color-2);
    > .upload {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      .title {
        display: flex;
        align-items: center;
      }
      .uploadbtn {
        display: flex;
        button {
          width: 110px;
        }
        button:first-of-type {
          margin-right: 12px;
          @media (max-width: 1024px) {
            width: 100px;
            font-size: 13px;
          }
        }
      }
    }
  }
  .content .affiliate {
    padding-left: 16px;
    margin-top: 40px;
    display: flex;
    position: relative;
  }
  .content .affiliate .i {
    margin-left: 16px;
  }
  .content .affiliate .infoThird {
    height: 48px;
    margin-left: 12px;
    padding: 0 14px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--agt-primary-color-1);
    font-size: 13px;
    color: var(--agt-white-color);
    position: absolute;
    top: -66%;
    left: 18%;
    @media (max-width: 1024px) {
      left: 21%;
    }
  }
  .content .agentLicenses .licenseCode {
    width: 194px;
    @media (max-width: 1024px) {
      width: 172px;
    }
  }
  .content .agentLicenses .licenseType {
    width: 216px;
    @media (max-width: 1024px) {
      width: 166px;
    }
  }
  .content .addLicense {
    padding-left: 16px;
    margin-top: 24px;
    align-self: flex-start;
  }
  .lineclamp {
    width: 250px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1024px) {
      width: 200px;
    }
  }
`

export const ContentModal = styled.div`
  padding: 0 37.5px;
  padding-top: 56px;
  padding-bottom: 48px;
  text-align: center;
  .suggestion {
    color: var(--agt-secondary-text-color);
  }
`

export const MessageInfo = styled.div`
  .submit {
    margin-top: 8px;
  }
  .explanation {
    width: fit-content;
    max-width: 513px;
    min-height: 48px;
    border-radius: 5px;
    background-color: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    display: flex;
    align-items: flex-start;
    padding: 10px;
  }
`

export const ClockIcon = styled(Icon)`
  div {
    div {
      width: 26px;
      height: 26px;
      margin-right: 8px;
      svg {
        width: 26px;
        height: 26px;
        path {
          stroke: var(--agt-white-color);
        }
      }
    }
  }
`

export const CustomIcon = styled(Icon)`
  div {
    div {
      width: 26px;
      height: 26px;
      margin-right: 8px;
      svg {
        width: 26px;
        height: 26px;
      }
    }
  }
`

export const HoverInfo = styled.div`
  .infoI {
    cursor: pointer;
  }
  .suggest {
    opacity: 0;
    transition: all 0.1s;
  }
  .infoI:hover + .suggest {
    opacity: 1;
    cursor: pointer;
  }
`
