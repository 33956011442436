import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import api from 'api'
import { useDropzone } from 'react-dropzone'
import Icon from 'components/Icon'

type StatusUploadType = {
  small: boolean
}

const StatusUpload = styled.div<StatusUploadType>`
  width: ${(props) => (props.small ? '170px' : '200px')};
  height: 48px;
  font-size: 13px;
  .button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    padding-left: 12px;
  }
  .blank {
    background-color: var(--agt-background-color-1);
    color: var(--agt-secondary-color-1);
  }
  .waiting {
    background-color: var(--agt-warning-color);
    color: var(--agt-white-color);
  }
  .verified {
    background-color: var(--agt-success-color);
    color: var(--agt-white-color);
  }
  .banned {
    background-color: var(--agt-error-color);
    color: var(--agt-white-color);
  }
  @media (max-width: 1024px) {
    width: ${(props) => (props.small ? '150px' : '160px')};
    small {
      font-size: 10px;
    }
  }
  .file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    border: 1px solid var(--agt-background-color-2);
    border-radius: 5px;
    padding-left: 2px;
    padding-right: 16px;
  }
  .file .type {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 58px;
    height: 42px;
    background: var(--agt-background-color-2);
    border-radius: 4px;
    color: var(--agt-white-color);
  }
  .file .text {
    width: 100%;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .DOC {
    border-color: #cadcff;
    .upload__value-type {
      background-color: #cadcff;
    }
  }
  .PNG {
    border-color: #ffe782;
    .upload__value-type {
      background-color: #ffe782;
    }
  }
  .JPG {
    border-color: var(--agt-success-color);
    .upload__value-type {
      background-color: var(--agt-success-color);
    }
  }
  .PDF {
    border-color: #ffb4be;
    .upload__value-type {
      background-color: #ffb4be;
    }
  }
  .XLX {
    border-color: #a2dfc8;
    .upload__value-type {
      background-color: #a2dfc8;
    }
  }
  .PPT {
    border-color: #ffbe85;
    .upload__value-type {
      background-color: #ffbe85;
    }
  }
`

const CustomIconSM = styled(Icon)`
  div {
    div {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`

const CustomIconType = styled(Icon)`
  div {
    div {
      width: 16px;
      height: 16px;
      margin-top: 4px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`

const createAttachment = async (file: File, callback: (id: string) => void) => {
  try {
    const response = await api.post('/attachments', { image: file }, { isFormData: true })
    callback(response.data.data.attachment.id)
  } catch (err) {
    if (err && err.response) {
      console.error(err.response)
    }
    alert('เกิดข้อผิดพลาดบางอย่าง')
    throw err
  }
}

const getFileType = (file) => {
  if (file) {
    let type: string = ''
    switch (file.type) {
      case 'image/jpeg':
        type = 'JPG'
        break
      case 'image/png':
        type = 'PNG'
        break
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        type = 'DOC'
        break
      case 'application/pdf':
        type = 'PDF'
        break
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        type = 'PPT'
        break
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        type = 'XLX'
        break
      case 'text/plain':
        type = 'TXT'
        break
      default:
        break
    }
    return type
  }
  return ''
}

const UploadFile = (props) => {
  const {
    status = null,
    onChange = () => {},
    options = {},
    small = false,
    selectType = ['PDF', 'JPG', 'JPEG', 'PNG'],
  } = props
  const [previewFile, setFile] = useState<File | null>()
  const [fileType, setFileType] = useState<string>('')
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      createAttachment(file, (value) => {
        setFile(file)
        onChange(value)
      })
    },
    [onChange],
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...options,
  })
  const clearFile = useCallback(() => {
    setFile(null)
    onChange('')
  }, [setFile, onChange])
  useEffect(() => {
    if (previewFile) {
      let fileType: string = getFileType(previewFile)
      if (selectType.find((type: string) => type === fileType)) {
        setFileType(fileType)
      } else {
        let mapTypes: string = ''
        mapTypes = selectType.join(', ')
        alert(`รองรับไฟล์ ${mapTypes} เท่านั้น`)
        clearFile()
      }
    }
  }, [previewFile, selectType, clearFile])
  return (
    <div className="uploadbtn">
      <button
        className="secondary"
        type="button"
        disabled={!!previewFile || status === 'processing' || status === 'approved'}
        {...getRootProps({})}
      >
        {small ? 'อัปโหลด' : 'อัปโหลดเอกสาร'}
        <input {...getInputProps()} />
      </button>
      <StatusUpload small={small}>
        {previewFile ? (
          <div className={`file ${fileType}`}>
            <div className="type upload__value-type">{fileType}</div>
            <div className="text">{previewFile.name}</div>
            <div onClick={clearFile} style={{ cursor: 'pointer' }}>
              <CustomIconType name="cross" color="#000000" />
            </div>
          </div>
        ) : status === 'processing' ? (
          <div className="button waiting">
            <CustomIconSM name="glass" color={'var(--agt-white-color)'} />
            <small className="">
              สำเนาเอกสารสำคัญ
              <br />
              อยู่ระหว่างการตรวจสอบ
            </small>
          </div>
        ) : status === 'approved' ? (
          <div className="button verified">
            <CustomIconSM name="correct-outline" color={'var(--agt-white-color)'} />
            <small className="">
              เอกสารการสมัคร
              <br />
              ได้รับการยืนยันแล้ว
            </small>
          </div>
        ) : status === 'rejected' ? (
          <div className="button banned">
            <CustomIconSM name="banned" color={'var(--agt-white-color)'} />
            <small className="">
              เอกสารการสมัคร
              <br />
              ไม่ผ่านการตรวจสอบ
            </small>
          </div>
        ) : status === 'expired' ? (
          <div className="button banned">
            <CustomIconSM name="banned" color={'var(--agt-white-color)'} />
            <small className="">เอกสารใบอนุญาตหมดอายุ</small>
          </div>
        ) : (
          <div className="button blank">
            <CustomIconSM name="warning" color={'var(--agt-secondary-color-1)'} />
            {small ? (
              <small className="">
                รอแนบเอกสาร
                <br />
                สมัครตัวแทน
              </small>
            ) : (
              <small className="">รอแนบเอกสารสมัครตัวแทน</small>
            )}
          </div>
        )}
      </StatusUpload>
    </div>
  )
}

export default UploadFile
