import React, { FC, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import { useParams, useHistory } from 'routes'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import InsurancePortPage from './InsurancePort'
import CreateForm from 'pages/insurance_portfolio/components/InsurancePortForm'
import HistoryType from 'types/HistoryType'
import { InsurancePortType } from 'types/InsurancePortType'
import { go2Top } from 'utils/helper'
import { useDispatch } from 'react-redux'
import { editInsurance, getInsuranceById } from 'store/contactSlice'

const Container = styled.div`
  width: 100%;
`
const Content = styled.div`
  display: flex;
  justify-content: center;
`
const Header = styled.h3`
  margin: 16px 0 10px;
`
const SubHeader = styled.h4`
  padding-left: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`
const Loading = styled.div`
  padding: 40px 20px;
  text-align: center;
`

const InsurancePortFolio: FC = () => {
  let { id, insuranceId }: { id: string; insuranceId: string } = useParams()
  let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEditing, setEditing] = useState<boolean>(false)
  const [insurancePort, setInsurancePort] = useState<InsurancePortType>({})
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'ข้อมูลติดต่อ' },
    { path: `/contacts/${id}`, label: insurancePort?.ownerName || 'รายละเอียดข้อมูลติดต่อ' },
    { path: `/contacts/${id}/insurance_portfolio`, label: 'สรุปกรมธรรม์' },
    { label: insurancePort?.name || '' },
  ]

  useEffect(() => {
    dispatch(
      getInsuranceById(setInsurancePort, setIsLoading, id, insuranceId, () => {
        history.push(`/contacts/${id}/insurance_portfolio/`)
      }),
    )
  }, [dispatch, id, insuranceId, history])

  useEffect(() => {
    go2Top()
  }, [isEditing])

  return (
    <Container>
      <Header>รายละเอียดข้อมูลประกัน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <SubHeader>ข้อมูลกรมธรรม์</SubHeader>
      {isLoading ? (
        <Loading>Loading...</Loading>
      ) : (
        <Content>
          {insurancePort.id && isEditing ? (
            <Formik
              initialValues={insurancePort}
              onSubmit={(values) => {
                dispatch(
                  editInsurance(id, insuranceId, values, () => {
                    history.push(`/contacts/${id}/insurance_portfolio/`)
                  }),
                )
              }}
            >
              {(props) => {
                return (
                  <CreateForm
                    handleSubmit={props.handleSubmit}
                    values={props.values}
                    errors={props.errors}
                    touched={props.touched}
                    isSubmitting={props.isSubmitting}
                  />
                )
              }}
            </Formik>
          ) : (
            <InsurancePortPage instead={insurancePort} onEdit={() => setEditing(true)} />
          )}
        </Content>
      )}
    </Container>
  )
}
export default InsurancePortFolio
