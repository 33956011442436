import React, { FC, useState, useEffect, useMemo } from 'react'
import { ProtectedRoute, Route, Switch, Redirect } from 'routes'
import { useSelector } from 'react-redux'

import Calendar from 'pages/calendar'
import Contacts from 'pages/contacts'
import Login from 'pages/login'
import Opportunities from 'pages/opportunities'
import ForgetPassword from 'pages/forget-password'
import Tools from 'pages/tools'
import Tasks from 'pages/tasks'
import Workflows from 'pages/workflows'
import Announcements from 'pages/announcements'
import Notification from 'pages/notification'
import Profile from 'pages/profile'
import Dashboard from 'pages/dashboard'
import Landing from 'pages/landing'
import Register from 'pages/register'
import SetupPassword from 'pages/setup_password'
import ResetPassword from 'pages/reset_password'
import Recruitment from 'pages/profile/recruitment'
import Affiliate from 'pages/profile/recruitment/form'
import Consents from 'pages/consents'
import Branches from 'pages/branches'
import Setting from 'pages/setting'
import Commission from 'pages/commission'
import ChangeEmail from 'pages/change_email'
import session from 'utils/session'
import { RootState } from 'store/rootReducer'

const Pages: FC = (props) => {
  const [isLogin, setLogin] = useState<boolean>(false)
  const { initialized, currentAgent } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    if (session.isAuthTokenPresent()) {
      setLogin(true)
    }
  }, [setLogin])

  let route = useMemo(() => {
    if (isLogin && currentAgent) {
      if (currentAgent?.isExpired && currentAgent.agentType === 'guest') {
        return (
          <Switch>
            <ProtectedRoute path="/profile" component={Profile} />
            <ProtectedRoute path="/home" component={Landing} />
            <ProtectedRoute path="/landing" component={Recruitment} />
            <ProtectedRoute path="/notifications" component={Notification} />
            <ProtectedRoute path="/setting" component={Setting} />
            <Route path="/branches" component={Branches} />
            <Redirect to="/landing" />
          </Switch>
        )
      } else {
        return (
          <Switch>
            <Route path="/forget-password" component={ForgetPassword} />
            <Route path="/reset_password" component={ResetPassword} />
            <ProtectedRoute path="/setting" component={Setting} />
            <ProtectedRoute path="/consent" component={Consents} />
            <ProtectedRoute exact path="/" component={Landing} />
            <ProtectedRoute path="/contacts" component={Contacts} />
            <ProtectedRoute path="/workflows" component={Workflows} />
            <ProtectedRoute path="/tasks" component={Tasks} />
            <ProtectedRoute path="/calendar" component={Calendar} />
            <ProtectedRoute path="/opportunities" component={Opportunities} />
            <ProtectedRoute path="/tools" component={Tools} />
            <ProtectedRoute path="/announcements" component={Announcements} />
            <ProtectedRoute path="/notifications" component={Notification} />
            <ProtectedRoute path="/profile" component={Profile} />
            <ProtectedRoute path="/commission" component={Commission} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <Route path="/change_email" component={ChangeEmail} />
            <Route path="/branches" component={Branches} />
            <Redirect to="/" />
          </Switch>
        )
      }
    } else {
      return (
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/forget-password" component={ForgetPassword} />
          <Route path="/register" component={Register} />
          <Route path="/setup_password" component={SetupPassword} />
          <Route path="/reset_password" component={ResetPassword} />
          <Route path="/change_email" component={ChangeEmail} />
          <Route path="/affiliate" component={Affiliate} />
          <Route path="/landing" component={Recruitment} />
          <Route path="/consent" component={Consents} />
          <Route path="/branches" component={Branches} />
          <Redirect to="/landing" />
        </Switch>
      )
    }
  }, [isLogin, currentAgent])

  if (initialized) {
    return route
  }

  return <div />
}

export default Pages
