import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import styled from '@emotion/styled'
import { get } from 'lodash'
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { RootState } from 'store/rootReducer'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import Loader from 'components/BackgroundLoading'
import InsuranceDetail from '../InsuranceDetail'
import DropleadForm from '../form'
import { Formik } from 'formik'
import ShowDetail from './ShowDetail'

type AssuredTypes = {
  prefixName: string
  firstName: string
  lastName: string
  xxxx: string
  beneficiary: string
  idCard: string
  email: string
  phoneNumber: string
  birthdate: Date | null
  national: string
}

type DropleadTypes = {
  isType: string
  assured: Array<AssuredTypes>
  policyType: string
  startAt: Date
  numberStart: string
  endAt: Date
  numberEnd: string
  address: {
    aaaaaa: string
    housingNo: string
    villageNo: string
    building: string
    lane: string
    road: string
    subDistrict: string
    district: string
    province: string
    postcode: string
  }
}

const defaultProps: DropleadTypes = {
  isType: '',
  assured: [
    {
      firstName: '',
      lastName: '',
      prefixName: '',
      xxxx: '',
      idCard: '',
      beneficiary: '',
      email: '',
      phoneNumber: '',
      birthdate: null,
      national: '',
    },
  ],
  policyType: '',
  startAt: new Date(),
  numberStart: '',
  endAt: new Date(),
  numberEnd: '',
  address: {
    aaaaaa: '',
    housingNo: '',
    villageNo: '',
    building: '',
    lane: '',
    road: '',
    subDistrict: '',
    district: '',
    province: '',
    postcode: '',
  },
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
`

const Header = styled.h3`
  margin-bottom: 10px;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const EditTravel: FC = () => {
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const { travelInsurances, loading, options, order } = useSelector(
    (state: RootState) => state.travel,
  )

  let { id, orderNo } = useParams<{ id: string; orderNo: string }>()
  const dispatch = useDispatch()

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: get(opportunity, 'name', 'โอกาสในการขาย') },
    { label: 'รายละเอียดคำสั่งซื้อ' },
  ]

  const onSaving = (values) => {
    let params = { ...values, type: 'update' }
    // dispatch(
    //   createOrder(id, params, () => {
    //     history.push('/opportunities')
    //   }),
    // )
  }

  const insuranceDetailData = useMemo(
    () => ({
      companyLogoUrl: order?.order.companyLogoUrl || '',
      companyName: order?.order.motorInsurance.productDetails.companyName || '',
      netAmount: order?.order.motorInsurance.productDetails.netAmount || '',
      carUsage: order?.vehicle.carUsage || '',
      garage: order?.order.motorInsurance.productDetails.garage || '',
      garageTotal: order?.order.motorInsurance.productDetails.garageTotal || '',
      cover: order?.order.motorInsurance.productDetails.cover || '',
      carCheck: order?.order.motorInsurance.productDetails.carCheck || '',
      terrorism: order?.order.motorInsurance.productDetails.terrorism || '',
      coverFlooding: order?.order.motorInsurance.productDetails.coverFlooding || '',
      coverDeduct: order?.order.motorInsurance.productDetails.coverDeduct || '',
      cover3Rd: order?.order.motorInsurance.productDetails.cover3Rd || '',
      cover3RdTime: order?.order.motorInsurance.productDetails.cover3RdTime || '',
      cover3RdAsset: order?.order.motorInsurance.productDetails.cover3RdAsset || '',
      coverLostFire: order?.order.motorInsurance.productDetails.coverLostFire || '',
      coverAcc: order?.order.motorInsurance.productDetails.coverAcc || '',
      coverAccNum: order?.order.motorInsurance.productDetails.coverAccNum || '',
      coverAccPass: order?.order.motorInsurance.productDetails.coverAccPass || '',
      coverAccPassNum: order?.order.motorInsurance.productDetails.coverAccPassNum || '',
      coverLegal: order?.order.motorInsurance.productDetails.coverLegal || '',
      insureLevel: order?.order.motorInsurance.insureLevel || '',
    }),
    [order],
  )

  useEffect(() => {
    dispatch(getNewOpportunityById(id))
  }, [dispatch, id])

  return (
    <Container>
      <Header>ประกันเดินทาง</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      {(loading.options || loading.search) && <Loader />}
      {/* <Content>
        <InsuranceDetail data={insuranceDetailData} />
        <ShowDetail />
      </Content> */}
    </Container>
  )
}

export default EditTravel
