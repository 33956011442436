import React from 'react'
import styled from '@emotion/styled'
import { Field as FormikField, Form, useField } from 'formik'

const FormContainer = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  .firstName {
    width: 352px;
    margin-right: 32px;
  }
  .lastName {
    width: 352px;
  }
  .phoneNumber {
    width: 352px;
    margin-right: 32px;
  }
  .email {
    width: 352px;
  }
  .error {
    input {
      border-color: var(--agt-error-color);
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`
const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`
const Label = styled.label`
  margin-bottom: 4px;
  color: var(--agt-primary-text-color) !important;
`
const FieldValue = styled(FormikField)`
  width: 100%;

  border: ${(props) =>
    props.isError ? '1px solid var(--agt-error-color)' : '1px solid var(--agt-secondary-color-1)'};
`
const Error = styled.div`
  margin-top: 8px;
`
const Input = (props) => {
  const { field, form, ...rest } = props
  return <input {...field} {...rest} />
}

const Field = (props: any) => {
  const {
    className = '',
    label = '',
    placeholder = '',
    name = '',
    type = '',
    star = false,
    comment = false,
    onChange,
    ...rest
  } = props
  const [field, meta] = useField(name)
  let element: React.ReactNode
  switch (type) {
    case 'number':
      element = (
        <FieldValue
          name={name}
          placeholder={placeholder}
          component={Input}
          type="number"
          {...rest}
          onChange={(e) => {
            field.onChange(e)
            if (onChange) {
              onChange(e.target.value)
            }
          }}
        />
      )
      break
    default:
      element = (
        <FieldValue
          name={name}
          placeholder={placeholder}
          component={Input}
          {...rest}
          onChange={(e) => {
            field.onChange(e)
            if (onChange) {
              onChange(e.target.value)
            }
          }}
        />
      )
      break
  }
  return (
    <FieldContainer className={`${className} ${name} ${meta.touched && meta.error && 'error'}`}>
      <Label>
        {label}
        &nbsp;{star && <label style={{ color: 'var(--agt-error-color)' }}>*</label>}
        &nbsp;&nbsp;
        {comment && (
          <label style={{ color: 'var(--agt-secondary-color-1)' }}>
            อีเมลสำหรับติดต่อและรับข้อมูลข่าวสาร
          </label>
        )}
      </Label>
      {element}
      {meta.touched && meta.error && <Error className="small error">{meta.error}</Error>}
    </FieldContainer>
  )
}
type GuestProps = {
  className?: string
}

const GuestForm = ({ className = '' }: GuestProps) => {
  return (
    <FormContainer className={className}>
      <Field label="ชื่อ" name="firstName" placeholder="โปรดระบุ" star />
      <Field label="นามสกุล" name="lastName" placeholder="โปรดระบุ" star />
      <Field label="เบอร์โทรศัพท์" name="phoneNumber" placeholder="โปรดระบุ" maxLength="10" />
      <Field label="อีเมล" name="email" placeholder="โปรดระบุ" star comment />
    </FormContainer>
  )
}

export default GuestForm
