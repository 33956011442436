import React, { FC, useState } from 'react'
import styled from '@emotion/styled'
import Appointment from './Appointment'
import Opportunities from './Opportunities'
import Files from './Files'
import { QuickOpportunityByContact } from 'pages/opportunities/modal/New'
import { Link } from 'routes'
import { ContactType } from 'types/ContactType'
import { UserProps } from 'types/AppType'

type currentTabType = 'Appointment' | 'Opportunities' | 'Files'

const TAB_OPTIONS: Array<{ label: string; value: currentTabType }> = [
  { label: 'นัดหมาย', value: 'Appointment' },
  { label: 'โอกาสในการขาย', value: 'Opportunities' },
  // { label: 'ไฟล์', value: 'Files' },
]

type IndicatorProps = {
  active: number
}
type WorkType = {
  contactId?: number | null
  contactData?: ContactType | null
  currentAgent: UserProps | null
}

const Container = styled.div`
  padding: 24px 0 0;
  .btn {
    display: flex;
    justify-content: center;
  }
  .btn .createBtn {
    width: 160px;
    padding: 0 8px;
  }
  @media (max-width: 1024px) {
    .btn .createBtn {
      font-size: 13px;
    }
  }
`
const Header = styled.h4`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`
const NavWorkDetail = styled.ul`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style-type: none;
  align-items: center;
  margin-bottom: 26px;
  li {
    background: var(--agt-background-color-1);
    color: var(--agt-primary-color-1);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  li:hover {
    cursor: pointer;
  }
`
const Indicator = styled.div<IndicatorProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% / ${TAB_OPTIONS.length});
  height: 4px;
  background-color: var(--agt-primary-color-1);
  transform: ${(props) => (props.active ? `translateX(calc(100% * ${props.active}))` : ``)};
  transition: 0.5s transform;
`
const Work: FC<WorkType> = (props) => {
  const { contactId, contactData, currentAgent } = props
  const [currentTab, setCurrentTab] = useState<currentTabType>('Appointment')
  const [isCursor, setIsCursor] = useState<number>(0)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  return (
    <Container>
      {isModalOpen && (
        <QuickOpportunityByContact
          isModalOpen={isModalOpen}
          contactId={contactId}
          setIsModalOpen={setIsModalOpen}
          setReload={setReload}
        />
      )}
      <NavWorkDetail id={'contact-id__table'}>
        {TAB_OPTIONS.map(
          ({ label, value }: { label: string; value: currentTabType }, index: number) => (
            <li
              key={index}
              onClick={() => {
                setCurrentTab(value)
                setIsCursor(index)
              }}
            >
              {label}
            </li>
          ),
        )}
        {isCursor >= 0 && <Indicator active={isCursor} />}
      </NavWorkDetail>
      <Header id={'contact-id__table-navbar'}>
        {currentTab === 'Appointment'
          ? 'นัดหมาย'
          : currentTab === 'Opportunities'
          ? 'โอกาสในการขาย'
          : 'ไฟล์'}
      </Header>
      <Appointment contactId={props.contactId} active={currentTab === 'Appointment'} />
      <Opportunities
        contactId={props.contactId}
        active={currentTab === 'Opportunities'}
        reload={reload}
        setReload={setReload}
      />
      <Files contactId={props.contactId} active={currentTab === 'Files'} />
      <div className="btn">
        {currentTab === 'Appointment' ? (
          <Link to="/calendar" className="ghost">
            <button
              id="contact-id__create-appointment"
              type="button"
              className="createBtn"
              disabled={contactData?.status === 'รอยินยอม' || currentAgent?.statusTh === 'ระงับ'}
            >
              + สร้างนัดหมาย
            </button>
          </Link>
        ) : (
          currentTab === 'Opportunities' && (
            <button
              type="button"
              className="createBtn"
              onClick={() => setIsModalOpen(true)}
              style={{ display: 'none' }}
              disabled={contactData?.status === 'รอยินยอม' || currentAgent?.statusTh === 'ระงับ'}
            >
              สร้างโอกาสในการขาย
            </button>
          )
        )}
      </div>
    </Container>
  )
}
export default Work
