import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useField, useFormikContext, FormikProps, FormikValues, ErrorMessage } from 'formik'
import isEmpty from 'lodash/isEmpty'
import isObjectLike from 'lodash/isObjectLike'
import filter from 'lodash/filter'
import Select from 'react-select'
import RegisterType from 'types/RegisterType'
import AddressType from 'types/AddressType'
import { addressStructure } from 'utils/helper'

type OptionType = {
  label: string
  value: AddressType
}
type FieldType = {
  isDisabled: boolean
}
type UserForkmikType = FormikProps<FormikValues> & {
  values: RegisterType
}

const ADDRESS_FIELDS = [
  { key: 'subDistrict', label: 'ตำบล/แขวง' },
  { key: 'district', label: 'อำเภอ/เขต' },
  { key: 'province', label: 'จังหวัด' },
  { key: 'postcode', label: 'รหัสไปรษณีย์' },
]

const selectStyles = {
  option: (provided) => ({
    ...provided,
    padding: '10px 16px',
  }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '300px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'white',
    position: 'absolute',
    zIndex: '2',
  }),
  singleValue: () => ({
    color: 'var(--agt-primary-color-text)',
  }),
}

const selectError = {
  option: (provided) => ({
    ...provided,
    padding: '10px 16px',
  }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-error-color)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '300px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'white',
    position: 'absolute',
    zIndex: '2',
  }),
}

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 24px 16px;
  flex-wrap: wrap;
`

const AddressFieldGroup = styled.div<FieldType>`
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  > div {
    background-color: ${(props) =>
      props.isDisabled ? 'var(--agt-background-color-1)' : 'var(--agt-white-color)'};
  }
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'default')};
  @media (max-width: 1024px) {
    max-width: 272px;
  }
`
const Error = styled.small``

const Selector = styled(Select)`
  div[class$='ValueContainer'] {
    padding: 2px 16px;
  }
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

const Address = ({ address, disabled, addressType, setFilterOptions, filterOptions, validate }) => {
  const name = `${addressType}.${address.key}`
  const lastType = addressType.split('.').pop()
  const { values, setFieldValue }: UserForkmikType = useFormikContext()
  const [, meta, helpers] = useField(name)
  const { value, error, touched } = meta
  const { setValue, setTouched } = helpers

  return (
    <AddressFieldGroup key={address.key} isDisabled={disabled}>
      <label htmlFor={name}>
        {address.label} {validate && <span className="error">*</span>}
      </label>
      <Selector
        id={`${addressType}.${address.key}`}
        value={value && value ? { label: value } : false}
        placeholder="โปรดระบุ"
        options={filterOptions}
        // onBlur={() => setTouched(true)}
        onChange={(option: OptionType) => {
          if (isObjectLike(option)) {
            setValue(option.value[address.key])
            setFieldValue(addressType, {
              ...values?.order?.prbDetails[lastType],
              ...option.value,
            })
          }
        }}
        isDisabled={disabled}
        styles={touched && error ? selectError : selectStyles}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        onInputChange={(valueChange: string) => {
          if (!isEmpty(valueChange)) {
            setTimeout(() =>
              setFilterOptions(
                filter(addressStructure, (o) => o.value[address.key].startsWith(valueChange)),
              ),
            )
          }
        }}
        noOptionsMessage={() => ''}
      />
      {touched && error && <Error className="error">{error}</Error>}
    </AddressFieldGroup>
  )
}

const CustomAddress = ({ className = '', addressType, disabled = false, validate = false }) => {
  const [filterOptions, setFilterOptions] = useState<Array<OptionType>>([])
  return (
    <Container className={className}>
      {ADDRESS_FIELDS.map((address, _) => {
        return (
          <Address
            address={address}
            disabled={disabled}
            addressType={addressType}
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            validate={validate}
          />
        )
      })}
    </Container>
  )
}

export default React.memo(CustomAddress)
