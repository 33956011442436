import React, { FC, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { useHistory } from 'routes'
import mergeWith from 'lodash/mergeWith'

import Consent from './Consent'
import UserForm from 'components/Form/UserForm'
import validation, { schemaStageAgent as validationStageAgent } from './form.validation'

import { DEFAULT_VALUES, customizer } from './form.utils'
import UserType from 'types/RegisterType'
import HistoryType from 'types/HistoryType'
import { recruitmentProfle } from 'store/profileSlice'
import { getProfile } from 'store/profileSlice'
import { getConsentById, getConsentRecruitment } from 'store/appSlice'
import { dateFormat } from 'utils/helper'

type RecruitmentPageType = {}

const Container = styled.div`
  width: 100%;
  padding: 16px 0 0;
`
const Header = styled.h3`
  margin-bottom: 10px;
  text-align: center;
`
const Body = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  padding: 30px 0;
`
const SubmitButton = styled.button`
  display: block;
  margin: 52px auto 0;
`
const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--agt-primary-color-1);
  width: 100%;
  height: 461px;
  h4 {
    margin-bottom: 45px;
    color: var(--agt-white-color);
    text-align: center;
  }
  p {
    text-align: center;
    color: var(--agt-white-color);
  }
`

const RecruitmentPage: FC<RecruitmentPageType> = () => {
  const history: HistoryType = useHistory()
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const profile: UserType = useSelector((state: RootState) => state.profile.profile)
  const pdpa = useSelector((state: RootState) => state.app.pdpa)
  const termsAndConditions = useSelector((state: RootState) => state.app.termsAndConditions)
  const pdpaAgreement = useSelector((state: RootState) => state.app.pdpaAgreement)
  const termAgreement = useSelector((state: RootState) => state.app.termAgreement)
  const [isCheckedTerm, setIsCheckedTerm] = useState<boolean>(false)
  const [isCheckedPDPA, setIsCheckedPDPA] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProfile())
    dispatch(getConsentRecruitment())
  }, [dispatch])

  useEffect(() => {
    if (pdpa) dispatch(getConsentById(pdpa.id, 'pdpa'))
  }, [dispatch, pdpa])

  useEffect(() => {
    if (termsAndConditions) dispatch(getConsentById(termsAndConditions.id, 'term'))
  }, [dispatch, termsAndConditions])

  const modifiedAgreement = useMemo(() => {
    if (pdpaAgreement && termAgreement) {
      let modifiedConsents: Array<{
        name: string
        key: string
        value: boolean
        forcedConsent: boolean
      }> = []
      pdpaAgreement.consents?.forEach((consent) => {
        let newConsent = { ...consent, value: false }
        modifiedConsents.push(newConsent)
      })
      let pdpaModified = {
        id: pdpaAgreement.id,
        consents: modifiedConsents.length > 0 ? modifiedConsents : [],
      }
      modifiedConsents = []
      termAgreement.consents?.forEach((consent) => {
        let newConsent = { ...consent, value: false }
        modifiedConsents.push(newConsent)
      })
      let termModified = {
        id: termAgreement.id,
        consents: modifiedConsents.length > 0 ? modifiedConsents : [],
      }
      let array: Array<{
        id: string
        consents: Array<{
          name: string
          key: string
          forcedConsent: boolean
          value: boolean
        }>
      }> = []
      array.push(pdpaModified)
      array.push(termModified)
      return array
    } else return null
  }, [pdpaAgreement, termAgreement])

  const mockupTerm = useMemo(() => {
    if (termAgreement) {
      let modifiedConsents: Array<{
        name: string
        key: string
        value: boolean
        forcedConsent: boolean
      }> = []
      termAgreement.consents?.forEach((consent) => {
        let newConsent = { ...consent, value: false }
        modifiedConsents.push(newConsent)
      })
      let termModified = {
        id: termAgreement.id,
        consents: modifiedConsents.length > 0 ? modifiedConsents : [],
        name: termAgreement.name,
        detail: termAgreement.detail,
      }
      return termModified
    } else return null
  }, [termAgreement])

  const mockupPDPA = useMemo(() => {
    if (pdpaAgreement) {
      let modifiedConsents: Array<{
        name: string
        key: string
        value: boolean
        forcedConsent: boolean
      }> = []
      pdpaAgreement.consents?.forEach((consent) => {
        let newConsent = { ...consent, value: false }
        modifiedConsents.push(newConsent)
      })
      let pdpaModified = {
        id: pdpaAgreement.id,
        consents: modifiedConsents.length > 0 ? modifiedConsents : [],
        name: pdpaAgreement.name,
        detail: pdpaAgreement.detail,
      }
      return pdpaModified
    } else return null
  }, [pdpaAgreement])

  if (currentAgent?.agentStatus === 'ปฏิเสธชั่วคราว (1 ปี)') {
    return (
      <DownloadContainer>
        <h4>
          คุณไม่ได้รับอนุมัติเป็นตัวแทน
          <br />
          คุณสามารถยื่นสมัครใหม่ได้เมื่อถึงวันที่{' '}
          {currentAgent?.nextRecruitmentedAt
            ? dateFormat(currentAgent?.nextRecruitmentedAt)
            : '22/02/2565'}
          <br />
          <br />
        </h4>
        <p>หากมีข้อสงสัยเพิ่มเติม โปรดติดต่อเจ้าหน้าที่ฝ่ายดูแล หรือ โทร. 02-119-8888</p>
      </DownloadContainer>
    )
  } else if (currentAgent?.agentStatus === 'ปฏิเสธถาวร (Blacklist)') {
    return (
      <DownloadContainer>
        <h4>คุณไม่ได้รับอนุมัติเป็นตัวแทน</h4>
        <p>หากมีข้อสงสัยเพิ่มเติม โปรดติดต่อเจ้าหน้าที่ฝ่ายดูแล หรือ โทร. 02-119-8888</p>
      </DownloadContainer>
    )
  } else {
    return (
      <Container>
        <Header>สมัครเป็นตัวแทน</Header>
        <Body>
          <Formik
            enableReinitialize
            initialValues={mergeWith(
              {
                ...profile,
                idCardImageStatus:
                  profile.idCardImageStatus === 'new' ? '' : profile.idCardImageStatus,
                terms: mockupTerm,
                pdpa: mockupPDPA,
                agreements: modifiedAgreement,
                prefixName: profile?.prefixName || 'นาย',
                recruitType: profile?.recruitType || 'affiliate',
                brokerType: profile?.brokerType || [],
              },
              DEFAULT_VALUES,
              customizer,
            )}
            onSubmit={(values: UserType) => {
              let newAgreements: Array<{
                id: string
                consents: []
              }> = []
              let newTerms = values?.terms ? { ...values.terms } : {}
              let newPDPA = values?.pdpa ? { ...values.pdpa } : {}
              if (values.terms) newAgreements.push(newTerms)
              if (values.pdpa) newAgreements.push(newPDPA)
              let modifiedValues = {
                ...values,
                agreements: newAgreements,
                brokerType:
                  values?.recruitType === 'broker' &&
                  values?.brokerType &&
                  values?.brokerType?.length > 0
                    ? values.brokerType
                    : values?.recruitType === 'broker' &&
                      values?.brokerType &&
                      values?.brokerType?.length === 0
                    ? ['life_broker']
                    : [],
              }
              delete modifiedValues.isOTP
              delete modifiedValues.pdpa
              delete modifiedValues.terms
              dispatch(
                recruitmentProfle(modifiedValues, () =>
                  history.push('/profile/recruitment/download'),
                ),
              )
            }}
            validationSchema={
              currentAgent?.agentStatus === 'เก็บประวัติการสมัคร'
                ? validationStageAgent
                : validation
            }
          >
            {(props) => {
              const { submitForm, values } = props
              return (
                <>
                  {currentAgent?.agentStatus === 'เก็บประวัติการสมัคร' ? (
                    <UserForm disableList={['email', 'idCard']} {...props} />
                  ) : (
                    <UserForm disableList={['email']} {...props} />
                  )}
                  <Consent
                    consent={mockupTerm}
                    type="terms"
                    values={values.terms}
                    isChecked={isCheckedTerm}
                    setIsChecked={setIsCheckedTerm}
                  />
                  <Consent
                    consent={mockupPDPA}
                    type="pdpa"
                    values={values.pdpa}
                    isChecked={isCheckedPDPA}
                    setIsChecked={setIsCheckedPDPA}
                  />
                  <SubmitButton
                    type="submit"
                    onClick={() => submitForm()}
                    disabled={!isCheckedTerm || !isCheckedPDPA}
                  >
                    ยืนยันข้อมูล
                  </SubmitButton>
                </>
              )
            }}
          </Formik>
        </Body>
      </Container>
    )
  }
}

export default RecruitmentPage
