import React, { FC, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link } from 'routes'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'
import Select, { ValueType } from 'react-select'
import Icon from 'components/Icon'
import Task, { TaskType } from '../components/Task'
import { useDispatch, useSelector } from 'react-redux'
import { getMoreTasks, getTasks } from 'store/taskSlice'
import { RootState } from 'store/rootReducer'
import WebTour from './WebTour'
import SetStateType from 'types/SetStateType'

type SpanProps = {
  margin?: string
}
type OptionsButtonProps = {
  radius?: string
  active?: boolean
}
type TaskValues = {
  id: number
  name: string
  dueDate: string
  reminderTime: string
  priorityLevel: number | null
  contactId: number | null
  contactName: string | null
  contactAvatar: string
  contactStatus?: string | null
}
type FilterValues = {
  dataSearch: string
  priorityLevel: string | null
  dueDate: Object | null
}
type OptionType = {
  value: string | null
  label: string
}
type IncompleteTasksType = {
  tasksLated: Array<TaskValues>
  tasksCurrentDay: Array<TaskValues>
  tasksCurrentWeek: Array<TaskValues>
  tasksNextWeek: Array<TaskValues>
  tasksIncoming: Array<TaskValues>
}
type DisplayTasksType = {
  topic: string
  tasks: Array<TaskValues>
  status?: string
  onTopicClick?: Function
  forceExpand: number
  setObj?: Function
  index: number
}

const Container = styled.div`
  width: 100%;
`
const TasksContainer = styled.div`
  padding: 0 95px;
`
const Selector = styled(Select)`
  border-radius: 4px;
  width: 240px;
  .css-yk16xz-control {
    height: 48px;
  }
`
const MenuCustom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 24px 0px;
  .status {
    display: flex;
    align-items: center;
  }
  .status .option {
  }
  .seletor {
  }
`
const Span = styled.span<SpanProps>`
  margin-right: ${(props) => props.margin};
`
const OptionsButton = styled.button<OptionsButtonProps>`
  width: 176px;
  background: ${(props) => (props.active ? '#CAE6FF' : '#ffffff')};
  border: ${(props) =>
    props.active
      ? '1px solid var(--agt-primary-color-1)'
      : '1px solid var(--agt-secondary-color-1)'};
  color: ${(props) =>
    props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-color-1)'};
  border-radius: ${(props) =>
    props.radius === 'around'
      ? '4px'
      : props.radius === 'left'
      ? ' 4px 0px 0px 4px '
      : '0px 4px 4px 0px'};
  :hover {
    background: ${(props) => (props.active ? '#CAE6FF' : '#ffffff')};
    border: ${(props) =>
      props.active
        ? '1px solid var(--agt-primary-color-1)'
        : '1px solid var(--agt-secondary-color-1)'};
    color: ${(props) =>
      props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-color-1)'};
    border-radius: ${(props) =>
      props.radius === 'around'
        ? '4px'
        : props.radius === 'left'
        ? ' 4px 0px 0px 4px '
        : '0px 4px 4px 0px'};
  }
`
const FilterContainer = styled.div`
  padding: 24px 96px 24px 32px;
  margin-bottom: 24px;
  background: #eff1f5;
`
const GroupFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Label = styled.div`
  width: 160px;
`
const Filter = styled.div`
  display: flex;
  align-items: center;
`
const ResetValue = styled.p`
  cursor: pointer;
  color: var(--agt-primary-color-1);
  text-decoration: underline;
`
const TopCollapse = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0 16px;

  background-color: #eff1f5;
  border-bottom: 1px solid #ffffff;

  cursor: pointer;
`
const IconStyle = styled(Icon)`
  width: 11px;
  height: 11px;
  margin-bottom: 16px;
`
const Header = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
  padding: 32px 46px;
  background: var(--agt-background-color-4);
  color: var(--agt-primary-color-1);
  h3 {
    color: var(--agt-primary-color-1);
  }
  .hint {
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    font-size: 17px;
    color: var(--agt-primary-color-1);
  }
`
const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`
const FieldValue = styled.input`
  width: 352px;
  @media only screen and (max-width: 1024px) {
    width: 300px;
  }
`
const DatePickerStyle = styled(DatePicker)`
  width: 352px;
  @media only screen and (max-width: 1024px) {
    width: 300px;
  }
`

const options: Array<OptionType> = [
  { value: null, label: 'ไม่ระบุ' },
  { value: '1', label: 'น้อย' },
  { value: '2', label: 'ปานกลาง' },
  { value: '3', label: 'มาก' },
]

const defaultSearchValue: FilterValues = {
  dataSearch: '',
  priorityLevel: null,
  dueDate: '',
}

const DisplayTasks: FC<DisplayTasksType> = (props) => {
  const { topic = '', tasks = [], status, forceExpand, setObj = () => {}, index } = props
  const toggle = () => {
    setObj()
  }

  return (
    <>
      <TopCollapse onClick={toggle}>
        <div
          style={{
            color: status === 'expire' ? 'var(--agt-error-color)' : 'var(--agt-secondary-color-2)',
          }}
        >
          {topic} {status !== 'complete' && `(${tasks.length})`}
        </div>
        {forceExpand === index ? (
          <IconStyle name="vector_top" />
        ) : (
          <IconStyle name="vector_bottom" />
        )}
      </TopCollapse>
      {forceExpand === index &&
        tasks.map((task: TaskType, index: number) => (
          <Task key={index} {...task} status={status} />
        ))}
    </>
  )
}

const HeaderComponent = ({ setIsShowHint }: { setIsShowHint: SetStateType<boolean> }) => {
  return (
    <Header>
      <h3>งานที่ต้องทำ</h3>
      <div className="hint" onClick={() => setIsShowHint(true)}>
        <HintIcon name="hint" />
        <span>คำแนะนำ</span>
      </div>
    </Header>
  )
}

const MenuComponent = ({
  viewpoint,
  setViewpoint,
  setIndex,
  setFilter,
  isFilter,
  currentAgent,
}) => {
  return (
    <MenuCustom>
      <div className="status">
        <Span margin="52px">สถานะ</Span>
        <div className="option" id="task__option">
          <OptionsButton
            radius="left"
            active={viewpoint === 'incomplete'}
            onClick={() => setViewpoint('incomplete')}
          >
            งานที่ยังไม่เสร็จ
          </OptionsButton>
          <OptionsButton
            radius="right"
            active={viewpoint === 'complete'}
            onClick={() => {
              setViewpoint('complete')
              setIndex(6)
            }}
          >
            งานที่เสร็จแล้ว
          </OptionsButton>
        </div>
      </div>
      <div className="seletor">
        <button
          id="task__filter"
          type="button"
          className="secondary"
          onClick={() => {
            setFilter(!isFilter)
          }}
        >
          ปรับตัวเลือก
        </button>
        <Link style={{ marginLeft: '16px' }} to="/tasks/new">
          <button
            id="task__create-work"
            type="button"
            disabled={currentAgent?.statusTh === 'ระงับ'}
          >
            สร้างงานที่ต้องทำ
          </button>
        </Link>
      </div>
    </MenuCustom>
  )
}

const FilterComponent = ({
  isFilter,
  searchValue,
  setSearchValue,
  selectedOption,
  setSelectedOption,
  dueDate,
  setDueDate,
}) => {
  return (
    <FilterContainer hidden={!isFilter}>
      <GroupFilter style={{ marginBottom: '16px' }}>
        <Filter style={{ marginRight: '24px' }}>
          <Label>ค้นหา</Label>
          <FieldValue
            type="text"
            value={searchValue.dataSearch}
            placeholder="ชื่องาน,ผู้ติดต่อ,รายละเอียดงาน"
            onChange={(event) => setSearchValue({ ...searchValue, dataSearch: event.target.value })}
          />
        </Filter>
        <Filter>
          <Label>ระดับความสำคัญ</Label>
          <Selector
            value={selectedOption}
            onChange={(option: OptionType) => {
              setSelectedOption(option)
              const value = (option as OptionType).value
              setSearchValue({ ...searchValue, priorityLevel: value })
            }}
            options={options}
          />
        </Filter>
      </GroupFilter>
      <GroupFilter>
        <Filter>
          <Label>วันครบกำหนด</Label>
          <DatePickerStyle
            value={dueDate}
            onChange={(val) => {
              setDueDate(val)
              setSearchValue({
                ...searchValue,
                dueDate: val,
              })
            }}
          />
        </Filter>
        <ResetValue
          onClick={() => {
            setSearchValue(defaultSearchValue)
            setDueDate('')
            setSelectedOption(options[0])
          }}
        >
          รีเซ็ตค่าตัวเลือก
        </ResetValue>
      </GroupFilter>
    </FilterContainer>
  )
}

const TaskComponent = ({
  viewpoint,
  tasks,
  tasksIncomplete,
  setindex,
  isindex,
  setIncomingWeek,
  incomingWeek,
}) => {
  if (viewpoint === 'incomplete') {
    return (
      <TasksContainer id="task__table">
        <DisplayTasks
          index={1}
          topic="เกินกำหนด"
          tasks={tasksIncomplete.tasksLated}
          status="expire"
          forceExpand={isindex}
          setObj={() => {
            if (isindex !== 1) setindex(1)
            else setindex(-1)
          }}
        />
        <DisplayTasks
          index={2}
          topic="วันนี้"
          tasks={tasksIncomplete.tasksCurrentDay}
          forceExpand={isindex}
          setObj={() => {
            if (isindex !== 2) setindex(2)
            else setindex(-1)
          }}
        />
        <DisplayTasks
          index={3}
          topic="สัปดาห์นี้"
          tasks={tasksIncomplete.tasksCurrentWeek}
          forceExpand={isindex}
          setObj={() => {
            if (isindex !== 3) setindex(3)
            else setindex(-1)
          }}
        />
        <DisplayTasks
          index={4}
          topic="สัปดาห์หน้า"
          tasks={tasksIncomplete.tasksNextWeek}
          forceExpand={isindex}
          setObj={() => {
            if (isindex !== 4) setindex(4)
            else setindex(-1)
          }}
        />
        <DisplayTasks
          index={5}
          topic="กำลังมาถึง"
          onTopicClick={() => {
            setIncomingWeek(incomingWeek + 1)
          }}
          tasks={tasksIncomplete.tasksIncoming}
          forceExpand={isindex}
          setObj={() => {
            if (isindex !== 5) setindex(5)
            else setindex(-1)
          }}
        />
      </TasksContainer>
    )
  }
  return (
    <TasksContainer>
      <DisplayTasks
        index={6}
        topic="งานที่ทำเสร็จแล้ว"
        tasks={tasks}
        status="complete"
        forceExpand={isindex}
        setObj={() => {
          if (isindex !== 6) setindex(6)
          else setindex(-1)
        }}
      />
    </TasksContainer>
  )
}

const Tasks: FC = (props) => {
  const dispatch = useDispatch()
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [viewpoint, setViewpoint] = useState<string>('incomplete')
  const [isFilter, setFilter] = useState<boolean>(false)
  const [tasks, setTasks] = useState<Array<TaskValues>>([])
  const [selectedOption, setSelectedOption] = useState<ValueType<OptionType>>(options[0])
  const [dueDate, setDueDate] = useState<string>()
  const [isindex, setindex] = useState<number>(-1)
  const [incomingWeek, setIncomingWeek] = useState<number>(0)
  const [isShowHint, setIsShowHint] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<FilterValues>({
    dataSearch: '',
    priorityLevel: null,
    dueDate: null,
  })
  const [tasksIncomplete, setTasksIncomplete] = useState<IncompleteTasksType>({
    tasksLated: [],
    tasksCurrentDay: [],
    tasksCurrentWeek: [],
    tasksNextWeek: [],
    tasksIncoming: [],
  })

  useEffect(() => {
    setTasks([])
    setTasksIncomplete({
      tasksLated: [],
      tasksCurrentDay: [],
      tasksCurrentWeek: [],
      tasksNextWeek: [],
      tasksIncoming: [],
    })
    dispatch(getTasks(setTasks, setTasksIncomplete, viewpoint, searchValue))
  }, [dispatch, viewpoint, searchValue])

  useEffect(() => {
    if (tasksIncomplete.tasksCurrentDay.length > 0) {
      setindex(2)
    } else if (tasksIncomplete.tasksLated.length > 0) {
      setindex(1)
    } else if (tasksIncomplete.tasksCurrentWeek.length > 0) {
      setindex(3)
    } else if (tasksIncomplete.tasksNextWeek.length > 0) {
      setindex(4)
    } else if (tasksIncomplete.tasksIncoming.length > 0) {
      setindex(5)
    }
  }, [tasksIncomplete])

  useEffect(() => {
    if (incomingWeek) dispatch(getMoreTasks(setTasksIncomplete, searchValue, incomingWeek))
    // eslint-disable-next-line
  }, [dispatch, incomingWeek])

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (storage['tasks']['tasks'] === false) {
        window.localStorage.setItem(
          'webtour',
          JSON.stringify({ ...storage, tasks: { ...storage.tasks, tasks: true } }),
        )
        setTimeout(() => {
          setIsShowHint(true)
        }, 1000)
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: false, create: false, contactById: false },
          tasks: { tasks: true, create: false },
          calendar: { calendar: false, create: false },
          opportunities: {
            opportunities: false,
            create: false,
            opportunityById: false,
            completed: false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [setIsShowHint])

  return (
    <Container>
      <WebTour visible={isShowHint} setVisible={setIsShowHint} />
      <HeaderComponent setIsShowHint={setIsShowHint} />
      <MenuComponent
        viewpoint={viewpoint}
        setViewpoint={setViewpoint}
        setIndex={setindex}
        setFilter={setFilter}
        isFilter={isFilter}
        currentAgent={currentAgent}
      />
      <FilterComponent
        isFilter={isFilter}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        dueDate={dueDate}
        setDueDate={setDueDate}
      />
      <TaskComponent
        viewpoint={viewpoint}
        tasks={tasks}
        tasksIncomplete={tasksIncomplete}
        setindex={setindex}
        isindex={isindex}
        setIncomingWeek={setIncomingWeek}
        incomingWeek={incomingWeek}
      />
    </Container>
  )
}

export default Tasks
