import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Api from 'api'
import { ContactType } from 'types/OpportunityType'
import SetStateType from 'types/SetStateType'
import { ActThunk } from './store'

type address = {
  building: string
  district: string
  housingNo: string
  lane: string
  postcode: string
  province: string
  road: string
  subDistrict: string
  villageNo: null
}
type selectOptons = Array<{ value: string; label: string }> | []
type prbOptions = Array<{ value: string; prbCarType: string; prbCode: string }> | []
type addressOptions =
  | Array<{
      value: string | address | null
      label: string
    }>
  | []

export type actOptions = {
  carSubModelOptions: selectOptons
  carYearOptions: selectOptons
  familyCodeOptions: selectOptons
  contact: ContactType | null
  insuranceCompanyOptions: selectOptons
  makeCodeOptions: selectOptons
  prbInsuranceCompanyOptions: selectOptons
  prbOptions: prbOptions
  vehicleOptions: selectOptons
  provinceOptions: selectOptons
  addressOptions: addressOptions
}

export type prbInsurance = {
  amount: string
  companyId: string
  companyName: string
  duty: string
  netAmount: string
  prbCarType: string
  prbCode: string
  prbName: string
  seat: string | number
  vat: string
  vehicleType: string
}

type order = {
  contact: {
    id: number
    birthdate: string
    firstName: string
    lastName: string
    prefixName: string
    idCard: string
    phoneNumber: string
    email: string
  }
  order: {
    id: number
    name: string
    companyName: string
    companyLogoUrl: string
    orderNo: string
    status: string
    premium: number
    draftingParams: string
    salesman: string
    affiliate: string
    prbInsurance: {
      startPolicyAt: string
      endPolicyAt: string
      inspectionLocation: string
      insuranceRenewalNotification: boolean
      oldCompanyName: string
      policyAddress: addressOptions
      policyDeliveryAddress: addressOptions
      policyForm: string
      receiptAddress: addressOptions
      productDetails?: {
        amount: string
        companyId: string
        companyName: string
        duty: string
        netAmount: string
        prbCarType: string
        prbCode: string
        prbName: string
        seat: string
        vat: string
        vehicleType: string
        companyCode: string
      }
    }

    lastUpdatedAt: string
    submittedAt: string
  }
  vehicle: {
    id: number
    name: string
    insureLevel: string
    year: string
    vehicleBrand: string
    vehicleModel: string
    vehicleSubModel: number
    redLicencePlate: string
    expiryDate: string
    vehicleProvince: { value: string; canEdit: boolean }
    vehicleLicense: { value: string; canEdit: boolean }
    vehicleChassis: { value: string; canEdit: boolean }
    cc: string
    carUsage: string
    makeCode: string
    familyCode: string
    vehicleKey: string
  }
}

type initialStateType = {
  initialized: boolean
  error: string | null
  options: actOptions | null
  order: order | null
  prbInsurance: prbInsurance | null
}

const initialState: initialStateType = {
  initialized: false,
  error: null,
  options: null,
  order: null,
  prbInsurance: null,
}

const actSlice = createSlice({
  name: 'act',
  initialState,
  reducers: {
    setInitialize(state, action: PayloadAction<boolean>) {
      state.initialized = action.payload
    },
    getOptionsSuccess(state, action: PayloadAction<actOptions>) {
      state.options = action.payload
      state.error = null
    },
    getOptionsFailed(state, action) {
      state.error = action.payload
    },
    getOrderSuccess(state, action: PayloadAction<order>) {
      state.order = action.payload
      state.error = null
    },
    getOrderFailed(state, action) {
      state.error = action.payload
    },
    createOrderSuccess(state) {
      state.order = null
      state.error = null
    },
    createOrderFailed(state, action) {
      state.error = action.payload
    },
    getPrbInsurancesSuccess(state, action: PayloadAction<prbInsurance>) {
      state.prbInsurance = action.payload
      state.error = null
    },
    getPrbInsurancesFailed(state, action) {
      state.error = action.payload
    },
  },
})

export const {
  setInitialize,
  getOptionsSuccess,
  getOptionsFailed,
  getOrderSuccess,
  getOrderFailed,
  createOrderSuccess,
  createOrderFailed,
  getPrbInsurancesSuccess,
  getPrbInsurancesFailed,
} = actSlice.actions

export default actSlice.reducer

export const getActOptions = (
  id: number | string,
  params?: {
    year?: string
    makeCode?: string
    familyCode?: string
  },
): ActThunk => async (dispatch) => {
  try {
    dispatch(setInitialize(true))
    const response = await Api.get(`/opportunities/${id}/product/prb_insurances/options`, params)
    dispatch(getOptionsSuccess(response.data.data))
  } catch (err) {
    dispatch(getOptionsFailed(err.toString()))
  } finally {
    dispatch(setInitialize(false))
  }
}

export const searchAct = (
  id: number | string,
  params: {
    carUsage: string
    companyCode: string
  },
  setIsBlocked?: SetStateType<boolean>,
  callback?: Function,
): ActThunk => async (dispatch) => {
  let isBlocked: boolean = true
  try {
    const response = await Api.get(`/opportunities/${id}/product/prb_insurances/search`, params)
    dispatch(getPrbInsurancesSuccess(response.data.data.prbInsurance))
    if (callback) callback()
    isBlocked = false
  } catch (err) {
    dispatch(getPrbInsurancesFailed(err.toString()))
    alert(err?.response?.data?.message.toString())
  } finally {
    if (setIsBlocked) setIsBlocked(isBlocked)
  }
}

export const getOrder = (id: number | string, orderId: number | string): ActThunk => async (
  dispatch,
) => {
  try {
    const response = await Api.get(`/opportunities/${id}/product/prb_insurances/order`, { orderId })
    dispatch(getOrderSuccess(response.data.data))
  } catch (err) {
    dispatch(getOrderFailed(err.toString()))
  } finally {
  }
}

export const createOrder = (id: number | string, values, cb: Function): ActThunk => async (
  dispatch,
) => {
  try {
    await Api.post(`/opportunities/${id}/product/prb_insurances`, values)
    dispatch(createOrderSuccess())
    cb()
  } catch (err) {
    console.log(err?.response?.data?.message)
    alert(err?.response?.data?.message.toString())
    dispatch(createOrderFailed(err.toString()))
  } finally {
  }
}
