import styled from '@emotion/styled'
import React, { FC, useEffect, useMemo } from 'react'
import { phoneNumberFormat } from 'utils/helper'
import Avatar from 'components/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { getConsentDetail, sendConsentDetail } from 'store/consentsSlice'
import { RootState } from 'store/rootReducer'
import useQuery from 'hooks/useQuery'
import { ErrorMessage, Field, FieldArray, Formik } from 'formik'
import * as Yup from 'yup'
import HistoryType from 'types/HistoryType'
import { useHistory } from 'routes'
import { get } from 'lodash'

type ConsentsType = {}

const Container = styled.div`
  width: 100%;
  max-width: 736px;
  padding: 60px 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .name {
    align-self: flex-start;
    color: var(--agt-primary-color-1);
    margin-bottom: 40px;
  }
  .contact {
    display: flex;
    margin-bottom: 50px;
  }
  .contact .detail {
    padding: 0 14px;
    display: flex;
    flex-direction: column;
  }
  .contact .detail .contactName {
    color: var(--agt-primary-color-1);
  }
  form {
    width: 100%;
  }
  form .content {
    margin-bottom: 56px;
  }
  form .consent {
    color: var(--agt-primary-color-1);
    display: flex;
  }
  form .consent input {
    width: 16px;
    height: 16px;
    margin-top: 4px;
    margin-right: 16px;
  }
  form .consent .text {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
  }
`
const Error = styled.small`
  color: var(--agt-error-color);
`

let schema = Yup.object().shape({
  consents: Yup.array().of(
    Yup.object().shape({
      forced_consent: Yup.boolean().required('กรุณายอมรับเงื่อนไขข้อตกลงนี้เพื่อดำเนินการต่อ'),
      value: Yup.boolean().when(['forced_consent'], {
        is: (forced_consent) => forced_consent === true,
        then: Yup.boolean()
          .required('กรุณายอมรับเงื่อนไขข้อตกลงนี้เพื่อดำเนินการต่อ')
          .oneOf([true], 'กรุณายอมรับเงื่อนไขข้อตกลงนี้เพื่อดำเนินการต่อ'),
      }),
    }),
  ),
})

const Consents: FC<ConsentsType> = () => {
  const jwtContactToken = useQuery().get('jwt_contact_token') || ''
  const agreementId = useQuery().get('agreement_id') || ''
  const contactId = useQuery().get('contact_id') || ''
  const dispatch = useDispatch()
  const history: HistoryType = useHistory()
  const contact = useSelector((state: RootState) => state.consents.contact)
  const agreement = useSelector((state: RootState) => state.consents.agreement)

  useEffect(() => {
    if (agreementId && contactId && jwtContactToken) {
      let keyValues = {
        agreement_id: agreementId,
        contact_id: contactId,
        jwt_contact_token: jwtContactToken,
      }
      dispatch(getConsentDetail(keyValues))
    }
  }, [dispatch, agreementId, contactId, jwtContactToken])

  const modifiedValues = useMemo(() => {
    if (agreement?.consents) {
      let array: Array<{
        name: string
        key: string
        forced_consent: boolean
        value: boolean
      }> = []
      agreement.consents.forEach((consent) => {
        array.push({ ...consent, value: false })
      })
      return array
    } else return null
  }, [agreement])

  return (
    <Container>
      <b className="name">{agreement?.name || 'นโยบายความเป็นส่วนตัว'}</b>
      <div className="contact">
        <Avatar avatar={contact?.avatar || '-'} type="circle" width="48px" height="48px" />
        <div className="detail">
          <label className="contactName">{contact?.name || 'โรแลนด์ แบงส์ (โรดี้)'}</label>
          <small>{contact?.occupation || 'ไม่ระบุอาชีพ'}</small>
          <small>{phoneNumberFormat(get(contact, 'phone_number', '0874154193'))}</small>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          agreementId: agreementId,
          consents: modifiedValues || [],
        }}
        onSubmit={(values) => {
          let modifiedValues = {
            agreement_id: values.agreementId,
            consents: values.consents,
          }
          dispatch(
            sendConsentDetail(contactId, modifiedValues, jwtContactToken, () =>
              history.push('/contacts'),
            ),
          )
        }}
        validationSchema={schema}
      >
        {({ handleSubmit, values }) => (
          <form id="agreementForm" onSubmit={handleSubmit}>
            <p
              className="content"
              dangerouslySetInnerHTML={{
                __html: agreement?.detail || 'ยังไม่มีการระบุเนื้อหาใดๆ',
              }}
            />
            <div className="consents">
              <FieldArray
                name="consents"
                render={(arrayHelpers) => {
                  if (values?.consents.length > 0)
                    return (
                      <>
                        {values.consents.map((consents, index) => (
                          <div className="consent" key="index">
                            <Field name={`consents[${index}].value`} type="checkbox" />
                            <div className="text">
                              <label>{consents.name || ''}</label>
                              <ErrorMessage
                                name={`consents[${index}].value`}
                                className="errorMessage"
                                component={Error}
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    )
                  return null
                }}
              />
            </div>
          </form>
        )}
      </Formik>
      <button type="submit" form="agreementForm">
        ยืนยันข้อมูล
      </button>
    </Container>
  )
}

export default Consents
