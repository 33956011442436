import React, { FC } from 'react'
import styled from '@emotion/styled'
import { useLocation, Link } from 'routes'
import { useSelector } from 'react-redux'
import authToken from 'utils/session'
import Icon from '../Icon'
import Navbar from './Navbar'
import Recruitment from './Recruitment'
import { RootState } from 'store/rootReducer'
import authorize, { UnauthorizedAction } from 'utils/authorize'

type TabType = {
  disabled?: boolean
}
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 149px;
  justify-content: center;
  background: var(--agt-tqm-pantone-1);
`
const ButtonLinkContainer = styled.ul`
  display: flex;
  width: 60%;
  height: 149px;
`
const Tab = styled.li<TabType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  a {
    width: 100%;
    > span {
      display: block;
      margin: 0 auto;
    }
    > h4 {
      color: white;
      text-align: center;
    }
  }
`

const AgentTools = () => {
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  return (
    <Tab disabled={currentAgent?.isExpired && currentAgent.agentType === 'guest'}>
      {currentAgent?.isExpired && currentAgent.agentType === 'guest' ? (
        <>
          <Icon name="agent_tools" />
          <h4>Agent Tools</h4>
        </>
      ) : (
        <Link to="/contacts" className="ghost">
          <Icon name="agent_tools" />
          <h4>Agent Tools</h4>
        </Link>
      )}
    </Tab>
  )
}

const TQMLife = authorize(
  (props) => {
    const { disabled = false } = props
    const tqmLink = `${
      process.env.REACT_APP_TQM_LIFE
    }auth/agt/${authToken.getAuthTqmLifeToken()}?redirectPath=${
      process.env.REACT_APP_TQM_LIFE
    }&fromPortal=true`

    return (
      <Tab disabled={disabled}>
        {disabled ? (
          <>
            <Icon name="tqm_life" />
            <h4>Life</h4>
          </>
        ) : (
          <a className="ghost" href={tqmLink} target="_blank" rel="noopener noreferrer">
            <Icon name="tqm_life" />
            <h4>Life</h4>
          </a>
        )}
      </Tab>
    )
  },
  { roles: ['tqm_staff'], params: { unauthorizedAction: UnauthorizedAction.DISABLED } },
)

const TQMMotor = (props) => {
  const { disabled = true } = props
  return (
    <Tab disabled={disabled}>
      {disabled ? (
        <>
          <Icon name="motor" />
          <h4>Motor</h4>
        </>
      ) : (
        <a className="ghost" href="/" target="_blank" rel="noopener noaffiliate">
          <Icon name="motor" />
          <h4>Motor</h4>
        </a>
      )}
    </Tab>
  )
}

const MainMenu = () => {
  return (
    <>
      <Container>
        <ButtonLinkContainer>
          <AgentTools />
          <TQMLife />
          <TQMMotor />
        </ButtonLinkContainer>
      </Container>
      <Recruitment />
    </>
  )
}

const Tabs: FC = () => {
  const location = useLocation()
  const indexPage: string = location.pathname.split('/')[1]
  if (['', 'home', 'dashboard', 'announcements'].includes(indexPage)) {
    return <MainMenu />
  } else if (
    ['profile', 'landing', 'documents', 'setting', 'consent', 'commission'].includes(indexPage)
  ) {
    return null
  } else {
    return <Navbar />
  }
}

export default Tabs
