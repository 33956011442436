import React, { Fragment } from 'react'
import { Link } from 'routes'
import { currency, dateFormat } from 'utils/helper'
import Avatar from 'components/Avatar'
import styled from '@emotion/styled'

type StatusType = { status: string }

const Status = styled.div<StatusType>`
  font-size: 12px;
  color: ${(props) =>
    props.status === 'บริษัทประกันอนุมัติ' || props.status === 'สั่งซื้อสำเร็จ'
      ? 'var(--agt-success-color)'
      : props.status === 'พนักงานยกเลิก' || props.status === 'ยกเลิก'
      ? 'var(--agt-error-color)'
      : 'var(--agt-primary-text-color)'};
`

const OrdersCard = ({ orders = [], id = '' }) => {
  let sumPremium = 0
  const sumOrderPremium = (premium: string) => {
    sumPremium = sumPremium + parseFloat(premium)
  }
  return (
    <Fragment>
      {orders.length > 0 && (
        <div className="Orders">
          <b style={{ marginBottom: '12px' }}>รายการคำสั่งซื้อ</b>
          {orders.map(
            (
              {
                companyLogoUrl,
                companyName,
                orderNo,
                id: orderId,
                name,
                status,
                premium,
                lastUpdatedAt,
                submittedAt,
                orderType,
              },
              index,
            ) => {
              sumOrderPremium(premium)
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '16px',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <Avatar avatar={companyLogoUrl} type="circle" />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingLeft: '8px',
                        }}
                      >
                        <div>{name}</div>
                        <div style={{ color: 'var(--agt-secondary-color-1)' }}>{companyName}</div>
                        <Status status={status}>รหัสการขาย {orderNo}</Status>
                        {submittedAt && (
                          <small style={{ color: 'var(--agt-secondary-color-1)' }}>
                            วันที่แจ้งการแนะนำ {dateFormat(submittedAt)}
                          </small>
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Status status={status}>{status}</Status>
                        <b>{currency(premium, 2)}</b>
                        <div style={{ color: 'var(--agt-secondary-color-1)', fontSize: 12 }}>
                          {lastUpdatedAt && `ข้อมูลเมื่อ ${dateFormat(lastUpdatedAt)}`}
                          &nbsp;&nbsp;
                          {orderType === 'life' && (
                            <Link to={`/opportunities/${id}/order-detail/${orderNo}`}>
                              รายละเอียดคำสั่งซื้อ
                            </Link>
                          )}
                          {orderType === 'prb' && ['รอแจ้งงาน', 'รอแจ้งแนะนำ'].includes(status) ? (
                            <Link to={`/opportunities/${id}/catalog/act/form/${orderId}`}>
                              รายละเอียดคำสั่งซื้อ
                            </Link>
                          ) : orderType === 'prb' ? (
                            <Link to={`/opportunities/${id}/catalog/act/${orderId}`}>
                              รายละเอียดคำสั่งซื้อ
                            </Link>
                          ) : null}
                          {orderType === 'motor' &&
                          ['รอแจ้งงาน', 'รอแจ้งแนะนำ'].includes(status) ? (
                            <Link to={`/opportunities/${id}/motor/droplead/edit/${orderId}`}>
                              รายละเอียดคำสั่งซื้อ
                            </Link>
                          ) : orderType === 'motor' ? (
                            <Link to={`/opportunities/${id}/motor/droplead/${orderId}`}>
                              รายละเอียดคำสั่งซื้อ
                            </Link>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            },
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>ค่าเบี้ยประกันรวม</div>
            <b style={{ color: 'var(--agt-primary-color-1)' }}>{currency(sumPremium, 2)}</b>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default OrdersCard
