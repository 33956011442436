import React, { useState, useEffect, useMemo, useCallback, FC } from 'react'
import styled from '@emotion/styled'
import Icon from 'components/Icon'
import SetStateType from 'types/SetStateType'
import { getPositionById } from 'utils/helper'
import { UserProps } from 'types/AppType'
import Avatar from 'components/Avatar'

type WebTourType = {
  currentAgent: UserProps | null
  visible: boolean
  setVisible: SetStateType<boolean>
  remainingDays: number
  logout: Function
}
type ContainerType = {
  visible: boolean
  height: string
}
type DialogBoxType = {
  width?: string
  top?: string
  right?: string
}

const Container = styled.div<ContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.visible ? 10000 : -1)};
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`
const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Position = styled.div`
  position: absolute;
`
const DialogBox = styled.div<DialogBoxType>`
  position: absolute;
  top: ${(props) => props.top || '0'};
  right: ${(props) => props.right || '0'};
  background-color: var(--agt-light-sky-color);
  color: var(--agt-primary-color-1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  width: ${(props) => props.width || 'fit-content'};
  .labelArrow {
    position: absolute;
    top: 16px;
    right: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(135deg);
    background-color: var(--agt-light-sky-color);
    border-radius: 4px 0 0;
  }
  .labelArrow.top {
    top: -8px;
    left: 16px;
    border-radius: 0px 0px 0px 4px;
    transform: rotate(135deg);
  }
  .top {
    display: flex;
    flex-direction: column;
  }
  .top label {
    margin-bottom: 4px;
  }
  .top label b {
  }
  .bottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .bottom .choice {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
  .bottom .choice .next,
  .bottom .choice .close {
    cursor: pointer;
  }
  .bottom .choice .next:hover,
  .bottom .choice .close:hover {
    color: var(--agt-dark-sky-color);
  }
  .bottom .choice.end {
    display: flex;
    justify-content: flex-end;
  }
`

const Header = styled.div`
  display: flex;
  > div:first-of-type {
    width: 36px;
  }
  .avatar {
    background-color: var(--agt-background-color-1);
    border-radius: 4px;
    padding: 8px;
    color: var(--agt-primary-text-color);
    display: flex;
    align-items: center;
    gap: 14px;
  }
`

const Menu = styled.ul`
  background: var(--agt-white-color);
  border: 1px solid var(--agt-secondary-color-1);
  border-radius: 4px;
  margin-top: 16px;
  width: fit-content;
  float: right;
  list-style: none;
  > a {
    text-decoration: unset;
    color: var(--agt-primary-text-color);
  }
  li {
    padding: 12px 24px 0;
    width: 224px;
    height: 48px;
  }
  li:hover {
    background-color: var(--agt-background-color-1);
    cursor: pointer;
  }
  > li:last-of-type {
    font-weight: bold;
  }
`

const FirstTimeTour: FC<WebTourType> = (props) => {
  const { visible, setVisible, currentAgent, remainingDays, logout } = props
  const totalPage = 1
  const layout = [
    {
      top: 'calc(64px + 16px)',
      right: 'calc(240px + 16px)',
      width: '332px',
      arrow: 'left',
    },
    {
      top: '0px',
      right: 'calc(240px + 16px)',
      width: '280px',
      arrow: 'left',
    },
  ]
  const [step, setStep] = useState<number>(1)
  const [height, setHeight] = useState<string>('100vh')
  const [addContactPosition, setAddContactPosition] = useState({
    top: '0',
    left: '0',
  })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)

    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function getPosition() {
      getPositionById('header__image_user', setAddContactPosition)
      // getPositionById('contact__view-consent', setViewConsentPosition)
    }
    setInterval(() => {
      getPosition()
    }, 100)
    document.addEventListener('scroll', getPosition)
    return () => {
      document.removeEventListener('scroll', getPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStep(1)

    return () => {
      setStep(1)
    }
  }, [setStep])

  const onNextClick = useCallback(() => {
    setStep(step + 1)
  }, [step, setStep])

  const onClose = useCallback(() => {
    setVisible(false)
    setStep(1)
    localStorage.setItem('isFirstSignIn', 'Y')
  }, [setVisible])

  const LabelSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="top">
          <label>
            ยินดีตอนรับคุณ{' '}
            <b>
              {currentAgent?.firstName || ''} {currentAgent?.lastName || ''}
            </b>
          </label>
          <small>
            คุณมีอายุสิทธิ์ในการทดลองใช้งาน {remainingDays} วัน
            <br />
            สามารถเข้าสู่เมนู <b>สมัครตัวแทน</b> ได้ผ่านทางโปรไฟล์ของคุณ
          </small>
        </div>
      )
    } else return null
  }, [step, currentAgent, remainingDays])

  const ButtonSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="bottom">
          {/* <small>
            {step}/{totalPage}
          </small> */}
          <div />
          <div className="choice end">
            <small className="close" onClick={() => onClose()}>
              ปิดคำแนะนำ
            </small>
          </div>
        </div>
      )
    }
    return (
      <div className="bottom">
        <small>
          {step}/{totalPage}
        </small>
        <div className="choice">
          <small className="next" onClick={() => onNextClick()}>
            ถัดไป
          </small>
          <small className="close" onClick={() => onClose()}>
            ปิดคำแนะนำ
          </small>
        </div>
      </div>
    )
    // eslint-disable-next-line
  }, [step])

  const ContentSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <>
          <Header>
            <div></div>
            <div className="avatar">
              <Avatar
                width="38px"
                height="38px"
                avatar={currentAgent?.avatar || ''}
                type="circle"
              />
              <label>
                {currentAgent?.firstName || ''} {currentAgent?.lastName || ''}
              </label>
            </div>
          </Header>
          <Menu>
            <a href="/profile/recruitment">
              <li>สมัครเป็นตัวแทน</li>
            </a>
            <li onClick={() => logout()}>ออกจากระบบ</li>
          </Menu>
        </>
      )
    }
  }, [step, currentAgent, logout])

  const StyledPosition = () => {
    if (step === 1) {
      return addContactPosition
    }
    return { top: 0, left: 0 }
  }

  return (
    <Container visible={visible} height={height}>
      <Content>
        <Position style={StyledPosition()}>
          {ContentSection}
          <DialogBox
            width={layout[step - 1].width}
            top={layout[step - 1].top}
            right={layout[step - 1].right}
          >
            {LabelSection}
            {ButtonSection}
            <div className={`labelArrow ${layout[step - 1].arrow}`} />
          </DialogBox>
        </Position>
      </Content>
    </Container>
  )
}

export default FirstTimeTour
