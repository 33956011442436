import React, { FC, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'

import Icon from 'components/Icon'
import useWindowSize from 'hooks/useWindowSize'

const Container = styled.div`
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background: #4d86b8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`
const Header = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
`
const ImageContainer = styled.div`
  margin: 32px;
`
const Text = styled.div`
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
`

const Mask: FC = () => {
  const { width, orientation } = useWindowSize()

  const isPortrait = useMemo(() => {
    var mql = window.matchMedia('(orientation: portrait)')
    if (orientation) return orientation === 'portrait'
    return mql.matches
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, orientation])

  useEffect(() => {
    const body = document.getElementById('mark__blue_screen')
    if (body) {
      if (isMobileOnly || isPortrait) {
        body.style.zIndex = '10001'
      } else {
        body.style.zIndex = '0'
      }
    }
  }, [isMobileOnly, isPortrait])

  if (isMobileOnly || isPortrait) {
    return (
      <Container id="mark__blue_screen">
        <Header>คำแนะนำการใช้งาน</Header>
        <ImageContainer>
          <Icon name="Landscape" />
        </ImageContainer>
        <Text>
          {isMobileOnly ? 'เปลี่ยนอุปกรณ์ใช้งาน' : 'หมุนอุปกรณ์ใช้งานในแนวนอน'}
          <br />
          เพื่อประสิทธิภาพการใช้งาน
        </Text>
      </Container>
    )
  }

  return null
}

export default Mask
