import React, { FC, FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { ErrorMessage, Field, Form, useFormikContext, useField } from 'formik'
import styled from '@emotion/styled'
import Select from 'react-select'
import { DatePickerComponent } from 'components/DatePicker'
import { addDays, addYears } from 'date-fns'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'

import CustomAddress from '../../../act/CustomAddress'
import Icon from 'components/Icon'
import { OptionByTypeTaStateType, OptionStateType } from 'types/TravelInsuranceType'
import HistoryType from 'types/HistoryType'
import { OptionType } from 'types/GeneralType'
import { createOrder } from 'store/motorInsuranceSlice'

import moment, { Moment } from 'moment'

const today = new Date()

type AssuredTypes = {
  prefixName: string
  firstName: string
  lastName: string
  xxxx: string
  beneficiary: string
  idCard: string
  email: string
  phoneNumber: string
  birthdate: Date | null
  national: string
}

type DropleadTypes = {
  countryId: string
  dsc: string
  src: string
  travelDateFrom: string
  travelDateTo: string
  travelPassenger: string
  travelType: string
  travelTypeCountry: string
  vehicleType: string

  // assured: Array<AssuredTypes>
  // policyType: string
  // startAt: Date
  // numberStart: string
  // endAt: Date
  // numberEnd: string
  // address: {
  //   aaaaaa: string
  //   housingNo: string
  //   villageNo: string
  //   building: string
  //   lane: string
  //   road: string
  //   subDistrict: string
  //   district: string
  //   province: string
  //   postcode: string
  // }
}

type DropleadProps = {
  id: number | string
  values: DropleadTypes
  options: OptionStateType | null
  optionsByTypeTa: OptionByTypeTaStateType | null
  handleSubmit: ((event: FormEvent<HTMLFormElement>) => void) | undefined
  onSaving: (values: DropleadTypes) => void
}

const FormValue = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 40px 0 0 18px;
  width: 68%;
  .header {
    margin-bottom: 52px;
  }
  .detail {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  .detail b {
    width: 100%;
  }
  .detail label {
    width: 50%;
  }
  .detail .domestic {
    width: 100%;
  }
  .assured {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    border-bottom: 1px solid var(--agt-secondary-color-1);
    padding-bottom: 24px;
    margin-bottom: 24px;
    justify-content: space-between;
  }
  .assured b {
    width: 100%;
    color: var(--agt-primary-color-1);
  }
  .assured .input,
  .assured .selector,
  .assured .datepicker {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .assured .input label span,
  .assured .selector label span,
  .assured .datepicker label span {
    color: var(--agt-error-color);
  }
  .assured .input input {
    width: 100%;
  }
  .assured .firstName,
  .assured .lastName {
    max-width: 272px;
  }
  .assured .prefix {
    max-width: 160px;
  }
  .assured .prefix label ~ div {
    width: 100%;
  }
  .assured .idcard,
  .assured .birthdate,
  .assured .national {
    max-width: 230px;
  }
  .assured .national label ~ div {
    width: 100%;
  }
  .assured .email,
  .assured .phoneNumber,
  .assured .whos,
  .assured .relationship {
    width: 100%;
    max-width: 352px;
  }
  .assured .beneficiary {
    width: 100%;
  }
  .policyType {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .flight {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    margin-bottom: 24px;
    justify-content: space-between;
  }
  .flight b {
    width: 100%;
  }
  .flight .row {
    width: 100%;
    max-width: 352px;
  }
  .flight .row label span {
    color: var(--agt-white-color);
  }
  .flight .row label ~ div {
    width: 100%;
  }
  .flight .row input {
    width: 100%;
  }
  .tqm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  .tqm label {
    text-align: justify;
  }
  .tqm u {
    color: var(--agt-primary-color-1);
  }
  .groupBtn {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
`

const AddressComponent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px 16px;
  margin-bottom: 24px;
  .aaaaaa {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .aaaaaa > div:last-child {
    width: 100%;
    max-width: 352px;
  }
  .housingNo,
  .villageNo,
  .road,
  .land {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    max-width: 352px;
    @media (max-width: 1024px) {
      max-width: 272px;
    }
  }
  .housingNo > input,
  .villageNo > input,
  .road > input,
  .land > input {
    width: 100%;
  }
  .building {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    max-width: 736px;
    @media (max-width: 1024px) {
      width: 89%;
    }
  }
  .building > input {
    width: 100%;
  }
  .housingNo > label span {
    color: var(--agt-error-color);
  }
  .aaaaaa > label span,
  .villageNo > label span,
  .building > label span,
  .land > label span,
  .road > label span {
    color: var(--agt-white-color);
  }
  .topic {
    width: 100%;
    margin-top: unset;
  }
  .group {
    justify-content: space-between;
  }
  .group > div {
    max-width: 352px;
  }
  .group > div label ~ div {
    max-width: 352px;
  }
`

const OtherComponent = styled.div`
  &.policyType,
  &.beneficiary,
  &.xxxx {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    > label span {
      color: var(--agt-error-color);
    }
    > div:last-child {
      display: flex;
      column-gap: 52px;
    }
    > div:last-child label {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }
    > div:last-child label:hover {
      cursor: pointer;
    }
    > div:last-child label .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid var(--agt-secondary-color-2);
      border-radius: 50%;
    }
    > div:last-child label input {
      display: none;
    }
    > div:last-child label input:checked ~ .checkmark {
      border: 2px solid var(--agt-primary-color-1);
    }
    > div:last-child label .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    > div:last-child label input:checked ~ .checkmark:after {
      display: block;
    }
    > div:last-child label .checkmark:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--agt-primary-color-1);
    }
  }
  &.policyType > label {
    font-weight: bold;
  }
`

const Error = styled.small`
  color: var(--agt-error-color);
`

const OptionsStyles = {
  // container: (styles, { isDisabled }) => ({
  //   cursor: isDisabled ? 'not-allowed' : 'default',
  // }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: (styles, { isDisabled }) => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: isDisabled ? 'var(--agt-background-color-1)' : 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle, { isDisabled }) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
  singleValue: (defaultStyle, { isDisabled }) => ({
    ...defaultStyle,
    color: isDisabled ? 'var(--agt-primary-text-color)' : 'var(--agt-primary-text-color)',
  }),
}

const OptionsError = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-error-color)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}

const Selector = styled(Select)`
  width: 356px;
  height: 48px;
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

const OptionsComponent = (props) => {
  const {
    field,
    options,
    className = '',
    label = '',
    disabled = false,
    onChange = () => {},
    placeholder = 'เลือก',
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find(
    (select: { value: string; label: string }) => select.value === value,
  )
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <Selector
        value={defaultSeletor ? defaultSeletor : ''}
        onChange={(option: { value: string; label: string; details: {} }) => {
          setValue(option.value)
          if (onChange) onChange(option)
        }}
        styles={meta.error && meta.touched ? OptionsError : OptionsStyles}
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

const DatePicker = (props) => {
  const { field, label, className, disabled = false, minDate, cb = () => {} } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <DatePickerComponent
        value={value}
        showSelector
        onChange={(e: string) => {
          setValue(e)
          cb(e)
        }}
        minDate={minDate}
        disabled={disabled}
      />
    </div>
  )
}

const SamePerson = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <div>
        <label htmlFor="xxxx__1" onClick={() => setValue('online')}>
          <input id="xxxx__1" type="radio" name={name} checked={value === 'online'} />
          <span className="checkmark" />
          ใช่
        </label>
        <label htmlFor="xxxx__2" onClick={() => setValue('original')}>
          <input id="xxxx__2" type="radio" name={name} checked={value === 'original'} />
          <span className="checkmark" />
          ไม่ใช่
        </label>
      </div>
    </OtherComponent>
  )
}

const BeneficiaryType = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <div>
        <label htmlFor="beneficiary__1" onClick={() => setValue('online')}>
          <input id="beneficiary__1" type="radio" name={name} checked={value === 'online'} />
          <span className="checkmark" />
          ใช่
        </label>
        <label htmlFor="beneficiary__2" onClick={() => setValue('original')}>
          <input id="beneficiary__2" type="radio" name={name} checked={value === 'original'} />
          <span className="checkmark" />
          ไม่ใช่
        </label>
      </div>
    </OtherComponent>
  )
}

const PolicyType = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <div>
        <label htmlFor="policy__1" onClick={() => setValue('online')}>
          <input id="policy__1" type="radio" name={name} checked={value === 'online'} />
          <span className="checkmark" />
          กรมธรรม์ออนไลน์
        </label>
        <label htmlFor="policy__2" onClick={() => setValue('original')}>
          <input id="policy__2" type="radio" name={name} checked={value === 'original'} />
          <span className="checkmark" />
          กรมธรรม์ฉบับจริง
        </label>
      </div>
    </OtherComponent>
  )
}

const InputComponent = (props) => {
  const {
    field,
    className = '',
    label = '',
    placeholder = 'โปรดระบุ',
    disabled = false,
    maxlength,
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        style={meta.error && meta.touched ? { border: '1px solid var(--agt-error-color)' } : {}}
        maxlength={maxlength}
        {...field}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

const AddressOptionsComponent = (props) => {
  const { field, options, className = '', label = '', disabled = false } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = (options || []).find((select) => select.label === value)
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <Selector
        value={defaultSeletor ? defaultSeletor : ''}
        onChange={(option) => {
          setValue(option.label)
        }}
        styles={meta.error && meta.touched ? OptionsError : OptionsStyles}
        options={options}
        placeholder="เลือก"
        isDisabled={disabled}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

const CustomAddressComponent = (props) => {
  const { label = '', name = '', options, address } = props
  return (
    <AddressComponent>
      <div className="topic">
        <b>{label}</b>
      </div>
      <Field
        label="ใช้ข้อมูลที่อยู่จาก"
        name={`${name}.aaaaaa`}
        className="aaaaaa"
        component={AddressOptionsComponent}
        options={options}
        // disabled
      />
      <Field
        label="เลขที่"
        name={`${name}.housingNo`}
        className="housingNo"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="หมู่"
        name={`${name}.villageNo`}
        className="villageNo"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="หมู่บ้าน/อาคาร"
        name={`${name}.building`}
        className="building"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="ซอย/ตรอก"
        name={`${name}.lane`}
        className="land"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="ถนน"
        name={`${name}.road`}
        className="road"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <CustomAddress
        className="group"
        addressType={name}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
        validate
      />
    </AddressComponent>
  )
}

function formatTravelType(travelType: string) {
  switch (travelType) {
    case 'D':
      return 'รายเที่ยว'
    case 'Y':
      return 'รายเที่ยว'
    default:
      return '-'
  }
}

function diffTravelDate(form: Moment, to: Moment) {
  return form.diff(to, 'days')
}

const DropleadForm: FC<DropleadProps> = (props) => {
  const { values, handleSubmit, options, optionsByTypeTa, id, onSaving } = props
  const { setFieldValue } = useFormikContext()
  const dispatch = useDispatch()
  const history: HistoryType = useHistory()

  return (
    <FormValue onSubmit={handleSubmit}>
      <b className="header">กรอกข้อมูลผู้เอาประกันภัย</b>
      {values.travelTypeCountry === 'OUT' && (
        <div className="detail">
          <b>รายละเอียดการเดินทาง</b>
          <label>การเดินทาง : ต่างประเทศ</label>
          <label>
            ประเทศปลายทาง :{' '}
            {(optionsByTypeTa?.taCountries || []).find(
              (e) => e.value.toString() === values.countryId,
            )?.label || '-'}
          </label>
          <label>จำนวนผู้เดินทาง : {values.travelPassenger}</label>
          <label>ประกันภัยท่องเที่ยว : {formatTravelType(values.travelType)}</label>
          <label>วันที่ออกเดินทาง : {moment(values.travelDateFrom).format('DD/MM/YYYY')}</label>
          <label>
            วันที่กลับถึงไทย : {moment(values.travelDateTo).format('DD/MM/YYYY')} (5 วัน)
          </label>
        </div>
      )}
      {values.travelTypeCountry === 'IN' && (
        <div className="detail">
          <b>รายละเอียดการเดินทาง</b>
          <label className="domestic">การเดินทาง : ในประเทศ</label>
          <label>จำนวนผู้เดินทาง : {values.travelPassenger}</label>
          <label>ประกันภัยท่องเที่ยว : {formatTravelType(values.travelType)}</label>
          <label>
            จังหวัดต้นทาง :{' '}
            {(options?.provinceOptions || []).find((e) => e.value === values.dsc)?.label || '-'}
          </label>
          <label>
            จังหวัดปลายทาง :{' '}
            {(options?.provinceOptions || []).find((e) => e.value === values.src)?.label || '-'}
          </label>
          <label>วันที่ออกเดินทาง : {moment(values.travelDateFrom).format('DD/MM/YYYY')}</label>
          <label>
            วันที่กลับถึงไทย : {moment(values.travelDateTo).format('DD/MM/YYYY')} (
            {diffTravelDate(moment(values.travelDateFrom), moment(values.travelDateTo))} วัน)
          </label>
        </div>
      )}

      {/* {values.assured.map((d, index) => (
        <div className="assured">
          <b>
            ข้อมูลผู้เดินทาง (ผู้เอาประกัน){' '}
            {values.assured.length === 1 ? `` : `ลำดับที่ ${index + 1}`}
          </b>
          {index === 0 && (
            <Field
              label="คุณเป็นบุคคลเดียวกับผู้ซื้อ ใช่หรือไม่"
              name={`assured[${index}].xxxx`}
              className="xxxx"
              component={SamePerson}
            />
          )}
          <Field
            label="คำนำหน้าชื่อ"
            name={`assured[${index}].prefixName`}
            className="selector prefix"
            component={OptionsComponent}
            options={[
              { label: 'นาย', value: 'นาย' },
              { label: 'นาง', value: 'นาง' },
              { label: 'นางสาว', value: 'นางสาว' },
            ]}
            disabled
          />
          <Field
            label={`ชื่อจริง ${values.isType === 'domestic' ? '(ภาษาไทย)' : '(ภาษาอังกฤษ)'}`}
            name={`assured[${index}].firstName`}
            className="input firstName"
            component={InputComponent}
            disabled
          />
          <Field
            label={`นามสกุล ${values.isType === 'domestic' ? '(ภาษาไทย)' : '(ภาษาอังกฤษ)'}`}
            name={`assured[${index}].lastName`}
            className="input lastName"
            component={InputComponent}
            disabled
          />
          <Field
            label="วันเกิด"
            name={`assured[${index}].birthdate`}
            className="datepicker birthdate"
            component={DatePicker}
            placeholder="โปรดระบุ"
            disabled
          />
          <Field
            label={`เลขบัตรประจำตัวประชาชน`}
            name={`assured[${index}].idCard`}
            className="input idcard"
            component={InputComponent}
            disabled
          />
          <Field
            label="สัญชาติ"
            name={`assured[${index}].national`}
            className="selector national"
            component={OptionsComponent}
            options={[
              { label: 'ไทย', value: 'ไทย' },
              { label: 'ไทยลาว', value: 'ไทยลาว' },
              { label: 'ไทยไทย', value: 'ไทยไทย' },
            ]}
          />
          <Field
            label={`อีเมล`}
            name={`assured[${index}].email`}
            className="input email"
            component={InputComponent}
            disabled
          />
          <Field
            label={`เบอร์โทรศัพท์`}
            name={`assured[${index}].phoneNumber`}
            className="input phoneNumber"
            component={InputComponent}
            disabled
          />
          <Field
            label="ผู้รับผลประโยชน์ ทายาทโดยธรรม"
            name={`assured[${index}].beneficiary`}
            className="beneficiary"
            component={BeneficiaryType}
          />
          {values.assured[index].beneficiary === 'online' ? null : (
            <>
              <Field
                label="ผู้รับผลประโยชน์"
                name={`assured[${index}].whos`}
                className="input whos"
                component={InputComponent}
              />
              <Field
                label="ความสัมพันธ์"
                name={`assured[${index}].relationship`}
                className="input relationship"
                component={InputComponent}
              />
            </>
          )}
        </div>
      ))} */}
      {/* <CustomAddressComponent
        label="ที่อยู่บนกรมธรรม์"
        name="address"
        options={(options?.addressOptions || []).filter(
          (options) => options?.label !== 'เหมือนที่อยู่บนกรมธรรม์',
        )}
        address={values.address}
      /> */}
      <Field
        label="รูปแบบกรมธรรม์"
        name={`policyType`}
        className="policyType"
        component={PolicyType}
      />
      {values.vehicleType === 'plane' && (
        <div className="flight">
          <b>ข้อมูลเที่ยวบิน (กรณีมี)</b>
          <Field
            label="วันที่เดินทางไป"
            name={`startAt`}
            className="row"
            component={DatePicker}
            placeholder="โปรดระบุ"
          />
          <Field
            label={`หมายเลขเที่ยวบินขาไป`}
            name={`numberStart`}
            className="row"
            component={InputComponent}
          />
          <Field
            label="วันที่เดินทางกลับ"
            name={`endAt`}
            className="row"
            component={DatePicker}
            placeholder="โปรดระบุ"
          />
          <Field
            label={`หมายเลขเที่ยวบินขากลับ`}
            name={`numberEnd`}
            className="row"
            component={InputComponent}
          />
        </div>
      )}
      <div className="tqm">
        <label>
          ** บริการแจ้งเตือนเที่ยวบินล่าช้า/ยกเลิก สำหรับลูกค้า TQM
          ที่กรอกหมายเลขเที่ยวบินและวันที่เดินทางครบถ้วน และถูกต้อง TQM
          มีบริการแจ้งเตือนให้แก่ท่านก่อนการเดินทางผ่านช่องทาง SMS, แอปพลิเคชัน TQM24 และ Line
          เพื่ออำนวยความสะดวกให้ท่าน
        </label>
        <u>รายละเอียด</u>
      </div>
      <div className="groupBtn">
        <button
          type="button"
          onClick={() => {
            onSaving(values)
          }}
        >
          บันทึก
        </button>
        <button className="secondary" type="submit" disabled>
          แจ้งแนะนำ
        </button>
      </div>
    </FormValue>
  )
}

export default DropleadForm
