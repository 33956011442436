import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Api from 'api'
import {
  ContactType,
  FilterValuesType,
  OpportunitiesCompleteType,
  OpportunitiesProcessingType,
  paramsType,
  initialStateType,
  opportunityType,
} from 'types/OpportunityType'
import SetStateType from 'types/SetStateType'
import { OpportunitiesThunk } from './store'

const initialState: initialStateType = {
  initialized: false,
  error: null,
  opportunity: null,
}

const opportunitiesSlice = createSlice({
  name: 'opportunities',
  initialState,
  reducers: {
    setInitialize(state, action: PayloadAction<boolean>) {
      state.initialized = true
    },
    getOpportunitySuccess(state, action: PayloadAction<opportunityType>) {
      state.opportunity = action.payload
      state.error = null
    },
    getOpportunityFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  },
})

export const {
  setInitialize,
  getOpportunitySuccess,
  getOpportunityFailed,
} = opportunitiesSlice.actions

export default opportunitiesSlice.reducer

export const getOpportunities = (
  viewpoint: string,
  sortBy: string,
  setOpportunities: SetStateType<Array<OpportunitiesProcessingType>>,
  setOpportunitiesComplete: SetStateType<Array<OpportunitiesCompleteType>>,
  setContacts: SetStateType<Array<ContactType>>,
): OpportunitiesThunk => async () => {
  try {
    const response = await Api.get('/opportunities/' + viewpoint, { order: sortBy })
    if (viewpoint === 'processing') {
      setOpportunities(response.data.data.pipelineStates)
    } else {
      setOpportunitiesComplete(response.data.data.opportunities)
    }
    const responseContacts = await Api.get('/contacts/options')
    setContacts(responseContacts.data.data.contacts)
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
    }
    throw err
  }
}

export const getOpportunitiesByFilter = (
  viewpoint: string,
  values: FilterValuesType,
  setOpportunities: SetStateType<Array<OpportunitiesProcessingType>>,
  setOpportunitiesComplete: SetStateType<Array<OpportunitiesCompleteType>>,
  setContacts: SetStateType<Array<ContactType>>,
): OpportunitiesThunk => async () => {
  try {
    const response = await Api.get('/opportunities/' + viewpoint, values)
    if (viewpoint === 'processing') {
      setOpportunities(response.data.data.pipelineStates)
    } else {
      setOpportunitiesComplete(response.data.data.opportunities)
    }
    const responseContacts = await Api.get('/contacts/options')
    setContacts(responseContacts.data.data.contacts)
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
    }
    throw err
  } finally {
  }
}

export const updatePipelineState = (
  draggableId: string,
  droppableId: string,
  cb: Function,
): OpportunitiesThunk => async () => {
  try {
    await Api.put(`/opportunities/${draggableId}/update_pipeline_state`, {
      pipeline_state_id: droppableId,
    })
    cb()
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
    }
    throw err
  }
}

export const updateStatus = (
  id: string | number,
  status: string,
  setOpportunities: SetStateType<Array<OpportunitiesProcessingType>>,
): OpportunitiesThunk => async () => {
  try {
    const response = await Api.put(`/opportunities/${id}/update_status`, { status: status })
    setOpportunities(response.data.data.pipelineStates)
  } catch (err) {
    if (err && err.response) {
      const errorMessages = err.response.data.errors.map((error) => {
        return error.message
      })
      alert(errorMessages.join('\n'))
    }
    throw err
  }
}

export const getOpportunityById = (
  id: number | string,
  cb: Function,
  previousPage: Function,
): OpportunitiesThunk => async () => {
  try {
    const response = await Api.get(`/opportunities/${id}`, {})
    cb(response)
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
      switch (err.response.status) {
        case 404:
          previousPage()
          break
        default:
          break
      }
    }
    throw err
  }
}

export const getNewOpportunityById = (id: number | string): OpportunitiesThunk => async (
  dispatch,
) => {
  try {
    const response = await Api.get(`/opportunities/${id}`, {})
    dispatch(getOpportunitySuccess(response.data.data.opportunity))
  } catch (err) {
    dispatch(getOpportunityFailed(err.toString()))
  }
}

export const createOpportunity = (
  params: paramsType,
  cb: Function,
): OpportunitiesThunk => async () => {
  try {
    const response = await Api.post('/opportunities/', params)
    cb(response)
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
      return err.response
    }
    throw err
  }
}

export const updateOpportunity = (
  id: string,
  params: paramsType,
  cb: Function,
): OpportunitiesThunk => async () => {
  try {
    await Api.put(`/opportunities/${id}`, params)
    cb()
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}

export const quickCreateOpportunity = (
  values: {
    name: string
    type: string
    note: string
    contactId: number
    saleImmediately: boolean
  },
  cb: Function,
): OpportunitiesThunk => async () => {
  try {
    // console.log('values...', values)
    const response = await Api.post('/opportunities/', values)
    cb(response.data.data.opportunity.id)
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}
