import styled from '@emotion/styled'
import React, { FC, useCallback } from 'react'
import { Link, useHistory, useLocation } from 'routes'

import Avatar from 'components/Avatar'
import Notification from 'components/Notification'
import Banner from 'assets/images/friday_biz.png'
import Dropdown, { DropdownList, DropdownLink } from 'components/Dropdown'

import authToken from 'utils/session'
import { UserProps } from 'types/AppType'
import HistoryType from 'types/HistoryType'
import Icon from 'components/Icon'

type HeaderProps = {
  currentAgent?: UserProps | null
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`
const ContainerRight = styled.div`
  display: flex;
`
const CustomDropdown = styled(Dropdown)`
  .agt-dropdown__button {
    width: fit-content;
    height: fit-content;
  }
  .agt-dropdown__content-wrapper {
    right: 0;
    width: 224px;
  }
  .main {
    width: 100%;
    height: fit-content;
    background-color: #ccdceb;
  }
  .column {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  .column p {
    font-size: 13px;
    line-height: 20px;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .row p {
    font-size: 13px;
    line-height: 20px;
  }
  .column .row .position {
    display: flex;
    flex-direction: row;
  }
  .column .row .position .agentTypeTh {
    display: flex;
    flex-direction: column;
  }
  .column .row .codeNumber {
    font-size: 13px;
    line-height: 20px;
    color: #00529a;
  }
`
const ArrowTop = styled.div`
  position: absolute;
  background-color: #ffff;
  border-radius: 4px 0px 0px 0px;
  transform: rotate(45deg);
  width: 16.97px;
  height: 16.97px;
  right: 32px;
  z-index: -16;
  top: -3px;
  border: 1px solid #a0a8bc;
  transform: rotate(45deg);
  border-bottom: 0px;
  border-right: 0px;
`
const UserContainer = styled.div`
  .anonymous {
    display: flex;
  }
  .anonymous .login {
    padding-right: 8px;
    border-right: 1px solid var(--agt-primary-color-1);
  }
  .anonymous .register {
    display: flex;
    align-items: center;
    padding-left: 8px;
    span {
      padding-right: 15px;
    }
    &:hover {
      svg {
        path {
          stroke: var(--agt-dark-sky-color);
        }
      }
    }
  }
`
const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`
const UserName = styled.div`
  margin-left: 14px;
`
const LinkTabs = styled(Link)`
  color: var(--agt-primary-text-color);
`
const Line = styled.div`
  border-top: 1px solid white;
  width: 21px;
  position: absolute;
  right: 30px;
  top: 5px;
`
const Image = styled.img`
  width: 308px;
  margin-bottom: -8px;
  object-fit: cover;
  opacity: 1;
`
const CustomIcon = styled(Icon)`
  width: 11px;
  height: 18px;
  svg {
    path {
      width: 11px;
      height: 18px;
    }
  }
`

const Header: FC<HeaderProps> = (props) => {
  const { currentAgent } = props
  let status: boolean = authToken.isAuthTokenPresent()
  let history: HistoryType = useHistory()
  const location = useLocation()
  const pathName = location.pathname.split('/')[1]

  const logout = useCallback(async () => {
    authToken.removeAuthToken()
    authToken.removeAuthTqmLifeToken()
    localStorage.removeItem('isFirstSignIn')
    history.push('/landing')
    window.location.reload()
  }, [history])

  return (
    <Container>
      <LinkTabs to="/">
        <Image alt="agentProtal" src={Banner} />
      </LinkTabs>
      <UserContainer id="header__image_user">
        {status ? (
          <ContainerRight>
            <Notification />
            <CustomDropdown
              content={
                <>
                  <ArrowTop />
                  <Line />
                  {['affiliate', 'tqm_staff', 'broker'].includes(currentAgent?.agentType || '') && (
                    <div className="main">
                      <div className="column">
                        <div className="row">
                          <div className="position">
                            <p>ตำแหน่ง</p>&nbsp;
                            <div className="agentTypeTh">
                              {(currentAgent?.agentTypeTh || []).map((test) => (
                                <p>: {test}</p>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <p> สถานะ : {currentAgent?.statusTh}</p>
                          <b className="codeNumber">
                            {currentAgent?.agentType === 'affiliate'
                              ? currentAgent?.agentKey
                              : currentAgent?.agentCode || '-'}
                          </b>
                        </div>
                      </div>
                    </div>
                  )}
                  {['affiliate', 'broker'].includes(currentAgent?.agentType || '') && (
                    <DropdownLink to="/profile">
                      <DropdownList>ข้อมูลส่วนตัว</DropdownList>
                    </DropdownLink>
                  )}
                  {['guest'].includes(currentAgent?.agentType || '') &&
                  ![
                    'ปฏิเสธชั่วคราว (1 ปี)',
                    'ปฏิเสธถาวร (Blacklist)',
                    'เก็บประวัติการสมัคร',
                    null,
                    '',
                  ].includes(currentAgent?.agentStatus || '') ? (
                    <DropdownLink to="/profile/documents">
                      <DropdownList>เอกสารตัวแทน</DropdownList>
                    </DropdownLink>
                  ) : ['affiliate', 'broker'].includes(currentAgent?.agentType || '') ? (
                    <DropdownLink to="/profile/documents">
                      <DropdownList>เอกสารตัวแทน</DropdownList>
                    </DropdownLink>
                  ) : (
                    ['guest'].includes(currentAgent?.agentType || '') && (
                      <DropdownLink to="/profile/recruitment">
                        <DropdownList>สมัครตัวแทน</DropdownList>
                      </DropdownLink>
                    )
                  )}
                  {['affiliate', 'tqm_staff', , 'broker'].includes(
                    currentAgent?.agentType || '',
                  ) && (
                    <DropdownLink to="/commission">
                      <DropdownList>รายการค่าตอบแทน</DropdownList>
                    </DropdownLink>
                  )}
                  {['affiliate', 'tqm_staff', 'broker'].includes(currentAgent?.agentType || '') && (
                    <DropdownLink to="/profile/diagram">
                      <DropdownList>แผนผังสายงาน</DropdownList>
                    </DropdownLink>
                  )}
                  {['guest', 'affiliate', 'broker'].includes(currentAgent?.agentType || '') && (
                    <DropdownLink to="/setting">
                      <DropdownList>ตั้งค่าบัญชี</DropdownList>
                    </DropdownLink>
                  )}
                  <DropdownList onClick={logout}>
                    <b>ออกจากระบบ</b>
                  </DropdownList>
                </>
              }
            >
              <UserWrapper>
                <Avatar avatar={currentAgent?.avatar} width="36px" height="36px" type="circle" />
                <UserName>{`${currentAgent?.firstName || ''} ${
                  currentAgent?.lastName || ''
                }`}</UserName>
              </UserWrapper>
            </CustomDropdown>
          </ContainerRight>
        ) : (
          pathName === 'landing' && (
            <div className="anonymous">
              <Link to="/login" className="ghost login">
                เข้าสู่ระบบ
              </Link>
              <Link to="/register" className="ghost register">
                <span>ลงทะเบียน</span>
                <CustomIcon name="Arrow_dashboard" />
              </Link>
            </div>
          )
        )}
      </UserContainer>
    </Container>
  )
}

const defaultProps: HeaderProps = {
  currentAgent: null,
}
Header.defaultProps = defaultProps

export default Header
