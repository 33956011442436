import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useHistory, useLocation, useParams } from 'routes'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import { get } from 'lodash'
import { getContactById } from 'store/contactSlice'
import { Formik } from 'formik'
import DetailForm from './DetailForm'
import * as Yup from 'yup'
import { createOrder, getActOptions, searchAct } from 'store/actSlice'
import Loader from 'components/Loader'
import ActAside from './ActAside'
import HistoryType from 'types/HistoryType'
import { isValidId } from 'utils/helper'

import { addDays, addYears } from 'date-fns'

const today = new Date()
const tomorrow = addDays(today, 1)

type initialValuesType = {
  prefixName: string
  firstName: string
  lastName: string
  birthdate: string
  idCard: string
  email: string
  phoneNumber: string
  type: string
  insureLevel: string
  companyCode: string
  vehicleDetails: {
    id: string | null
    // vehicleId: string
    name: string
    year: string
    vehicleBrand: string
    vehicleModel: string
    vehicleSubModel: string
    redLicencePlate: boolean
    vehicleProvince: string
    vehicleLicense: string
    vehicleChassis: string
    expiryDate: string
    // bbbb: '',
    cc: string
    carUsage: string
    makeCode: string
    familyCode: string
    vehicleKey: string
    vehicleProvinceEdit?: boolean
    vehicleLicenseEdit?: boolean
    vehicleChassisEdit?: boolean
  }
  order: {
    orderId: string
    premium: number
    netPremium: string
    prbDetails: {
      inspectionLocation: string
      vehicleInspectionDate: Date
      insuranceRenewalNotification: boolean
      policyForm: string | null
      prbCarType: string
      prbCode: string
      oldCompanyName: string
      policyAddress: {
        aaaaaa?: string
        housingNo: string
        villageNo: string
        building: string
        lane: string
        road: string
        subDistrict: string
        district: string
        province: string
        postcode: string
      }
      policyDeliveryAddress: {
        aaaaaa?: string
        housingNo: string
        villageNo: string
        building: string
        lane: string
        road: string
        subDistrict: string
        district: string
        province: string
        postcode: string
      }
      receiptAddress: {
        aaaaaa?: string
        housingNo: string
        villageNo: string
        building: string
        lane: string
        road: string
        subDistrict: string
        district: string
        province: string
        postcode: string
      }
      startPolicyAt: Date
      endPolicyAt: Date
      productDetails: {
        prbCode: string
        prbName: string
        amount: string
        vat: string
        duty: string
        netAmount: string
        vehicleType: string
        seat: string
        prbCarType: string
        companyId: string
        companyName: string
        companyCode: string
      }
    }
  }
}

let addressSchema = Yup.object().shape({
  housingNo: Yup.string().nullable().required('กรุณาระบุเลขที่'),
  subDistrict: Yup.string().nullable().required('กรุณาระบุตำบล/แขวง'),
  district: Yup.string().nullable().required('กรุณาระบุอำเภอ/เขต'),
  province: Yup.string().nullable().required('กรุณาระบุจังหวัด'),
  postcode: Yup.string().nullable().required('กรุณาระบุรหัสไปรษณีย์'),
})

let vehicalSchema = Yup.object().shape({
  name: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  year: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleBrand: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleModel: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleSubModel: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleLicense: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleProvince: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleChassis: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
})

let prbDetailsSchema = Yup.object().shape({
  policyAddress: addressSchema,
  policyDeliveryAddress: addressSchema,
  receiptAddress: addressSchema,
})

let orderSchema = Yup.object().shape({
  prbDetails: prbDetailsSchema,
})

export let validateSchema = Yup.object().shape({
  email: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  idCard: Yup.string()
    .nullable()
    .test('is-id', 'รูปแบบเลขบัตรประชาชนไม่ถูกต้อง', (value) => {
      if (value) return isValidId(value)
      return true
    })
    .required('กรุณาระบุข้อมูล'),
  phoneNumber: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleDetails: vehicalSchema,
  order: orderSchema,
})

const initialValues: initialValuesType = {
  email: '',
  phoneNumber: '',
  type: 'droplead',
  insureLevel: '',
  vehicleDetails: {
    id: null,
    name: '',
    year: '',
    vehicleBrand: '',
    vehicleModel: '',
    vehicleSubModel: '',
    redLicencePlate: false,
    vehicleProvince: '',
    vehicleLicense: '',
    vehicleChassis: '',
    vehicleProvinceEdit: true,
    vehicleLicenseEdit: true,
    vehicleChassisEdit: true,
    expiryDate: '',
    // bbbb: '',
    cc: '',
    carUsage: '',
    makeCode: '',
    familyCode: '',
    vehicleKey: '',
  },
  order: {
    orderId: '',
    premium: 0,
    netPremium: '',
    prbDetails: {
      inspectionLocation: 'policy',
      vehicleInspectionDate: new Date(),
      insuranceRenewalNotification: false,
      policyForm: null,
      prbCarType: '',
      prbCode: '',
      oldCompanyName: '',
      policyAddress: {
        aaaaaa: 'ระบุเอง',
        housingNo: '',
        villageNo: '',
        building: '',
        lane: '',
        road: '',
        subDistrict: '',
        district: '',
        province: '',
        postcode: '',
      },
      policyDeliveryAddress: {
        aaaaaa: 'ระบุเอง',
        housingNo: '',
        villageNo: '',
        building: '',
        lane: '',
        road: '',
        subDistrict: '',
        district: '',
        province: '',
        postcode: '',
      },
      receiptAddress: {
        aaaaaa: 'ระบุเอง',
        housingNo: '',
        villageNo: '',
        building: '',
        lane: '',
        road: '',
        subDistrict: '',
        district: '',
        province: '',
        postcode: '',
      },
      startPolicyAt: tomorrow,
      endPolicyAt: addYears(new Date(tomorrow), 1),
      productDetails: {
        prbCode: '',
        prbName: '',
        amount: '',
        vat: '',
        duty: '',
        netAmount: '',
        vehicleType: '',
        seat: '',
        prbCarType: '',
        companyId: '',
        companyName: '',
        companyCode: '',
      },
    },
  },
  prefixName: '',
  firstName: '',
  lastName: '',
  birthdate: '',
  idCard: '',
  companyCode: '',
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
`

const Header = styled.h3`
  margin-bottom: 10px;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const Form = styled.div`
  width: 68%;
  padding: 40px 0 0 18px;
`

const BackgroundLoading = styled.div<{ height: string }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: ${(props) => (props.height ? props.height : '100vh')};
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.3s;
  > div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const NewAct: FC = () => {
  let { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const history: HistoryType = useHistory()
  const { state } = useLocation()
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const { contact } = useSelector((state: RootState) => state.contacts)
  const { options, initialized, prbInsurance } = useSelector((state: RootState) => state.act)
  const [height, setHeight] = useState<string>('100vh')

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: get(opportunity, 'name', 'โอกาสในการขาย') },
    { path: `/opportunities/${id}/catalog`, label: 'เลือกหมวดประกัน' },
    { path: `/opportunities/${id}/catalog/act`, label: 'ประกัน พ.ร.บ.' },
    { label: 'ข้อมูลผู้เอาประกันภัย' },
  ]

  const onSaving = (values) => {
    let params = { ...values, type: 'update' }
    dispatch(
      createOrder(id, params, () => {
        history.push('/opportunities')
      }),
    )
  }

  useEffect(() => {
    dispatch(getNewOpportunityById(id))
    dispatch(getActOptions(id))
  }, [dispatch, id])

  useEffect(() => {
    if (id && state) {
      dispatch(
        searchAct(id, {
          carUsage: get(state, 'prbCode', ''),
          companyCode: get(state, 'companyCode', ''),
        }),
      )
    }
  }, [dispatch, id, state])

  useEffect(() => {
    let contactId: string | number = get(opportunity, 'contactId', '')
    if (contactId === '') {
    } else {
      dispatch(getContactById(contactId))
    }
  }, [dispatch, opportunity])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      {initialized && (
        <BackgroundLoading height={height}>
          <div>
            <Loader />
          </div>
        </BackgroundLoading>
      )}
      <Header>ประกัน พ.ร.บ.</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Content>
        <ActAside
          prbCarType={get(state, 'prbCarType', '')}
          prbCode={get(state, 'prbCode', '')}
          companyCode={get(state, 'companyCode', '')}
          options={options}
          prbInsurance={prbInsurance}
        />
        <Form>
          <b>กรอกข้อมูลผู้เอาประกันภัย</b>
          <Formik
            initialValues={{
              ...initialValues,
              prefixName: contact?.prefixName || '',
              firstName: contact?.firstName || '',
              lastName: contact?.lastName || '',
              birthdate: contact?.birthdate || new Date(),
              idCard: contact?.idCard || '',
              email: contact?.email || '',
              phoneNumber: contact?.phoneNumber,
              companyCode: get(state, 'companyCode', ''),
              order: {
                ...initialValues.order,
                prbDetails: {
                  ...initialValues.order.prbDetails,
                  prbCarType: get(state, 'prbCarType', ''),
                  prbCode: get(state, 'prbCode', ''),
                  productDetails: { ...prbInsurance, companyCode: get(state, 'companyCode', '') },
                },
              },
            }}
            enableReinitialize
            validationSchema={validateSchema}
            onSubmit={(values) => {
              dispatch(
                createOrder(id, values, () => {
                  history.push('/opportunities')
                }),
              )
            }}
          >
            {(props) => <DetailForm {...props} options={options} onSaving={onSaving} />}
          </Formik>
        </Form>
      </Content>
    </Container>
  )
}
export default NewAct
