import React, { FC, Fragment, useState } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { camelizeKeys } from 'humps'
import moment from 'moment'
import { isNull, isEmpty } from 'lodash'
import TableComponet from './work'
import TaskComponent from './Tasks'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import Avatar from 'components/Avatar'
import ContactModal, { ContactForOpportunity } from 'components/ContactModal'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { OpportunityByContact } from 'pages/opportunities/modal/New'
import { deleteContactById } from 'store/contactSlice'
import { getLifeStylesOptions } from 'pages/contacts/utils'
import authorize, { UnauthorizedAction } from 'utils/authorize'
import { dateFormat, phoneNumberFormat, getAge } from 'utils/helper'
import { ContactType, lastConsentHistoryType, ProfilePageProps } from 'types/ContactType'
import ConsentModal from '../components/ConsentModal'
import ShowHistoriesConsent from 'components/ShowHistoriesConsent'
import { RootState } from 'store/rootReducer'
import { UserProps } from 'types/AppType'
import HistoryType from 'types/HistoryType'
import { useHistory } from 'react-router'
import SetStateType from 'types/SetStateType'
import { Link } from 'react-router-dom'

type MenuGroupType = {
  contactId?: number | null
  disabled?: boolean
  contact?: ContactType | null
  getContactByIdAgain: Function
  currentAgent: UserProps | null
}
type CustomButtonType = {
  status?: string
}
type LeftSideType = {
  contact: ContactType | null
  consentCurrentVersion: string | null
  getContactByIdAgain: Function
}

const CustomModal = styled.div`
  min-width: 380px;
  padding: 0 12px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: #000000;
    margin: 0px;
  }
  .name {
    font-weight: bold;
    color: var(--agt-primary-color-1);
  }
  .image-frame {
    margin: 0px;
    margin-bottom: 24px;
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    img {
      width: 100px;
      height: 100px;
    }
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 16px 0 10px;
  .hint {
    position: absolute;
    top: 48px;
    right: 0px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    color: var(--agt-primary-color-1);
  }
`
const Content = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  .Profile {
    display: flex;
    flex-direction: column;
    width: calc(100% - 288px);
    height: 100%;
    padding: 32px 32px 0;
  }
  .Profile .Personal {
    padding: 25px;
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 5px;
  }
  .Profile .Personal .TopSide {
    display: flex;
  }
  .Profile .Personal .BottomSide {
    width: 100%;
  }
  .Profile .Personal .BottomSide .HideBtn {
    display: block;
    width: fit-content;
    margin: 17px auto 0;
    color: var(--agt-primary-color-1);
    text-decoration: underline;
    cursor: pointer;
  }
  .Profile .Personal .BottomSide .ConsentHistories h4 {
    padding: 24px 0 11px 0;
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  .Profile .Personal .BottomSide .ConsentHistories .ShowHistories {
    width: 100%;
  }
`
const DetailContentProfile = styled.div`
  width: 100%;
  .note {
    display: flex;
  }
  .note .topic {
    padding-right: 4px;
  }
  .note .hidden {
    width: 100%;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--agt-secondary-text-color);
    @media only screen and (max-width: 1120px) {
      max-width: 400px;
    }
  }
`
const DetailContentExtend = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 120px;
`
const DetailContentImage = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 71px 20px;
`
const DetailContentOption = styled.div`
  position: absolute;
  right: 0;
  top: 112px;
  width: 160px;
  height: 48px;
`
const DetailContentHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
  border-bottom: 1px solid #a0a8bc;
  h4 {
    margin-right: 10px;
  }
  label {
    color: var(--agt-secondary-color-1);
  }
`
const DetailContentBody = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0 0;
`
const CustomFormBodyPart = styled.div`
  display: flex;
  flex-direction: column;
  &.contact_detail-left {
    width: 40%;
    max-width: 240px;
    padding-right: 30px;
  }
  &.contact_detail-right {
    width: 60%;
    padding-right: 30px;
  }
  p {
    margin: 0px;
  }
  a {
    width: 100%;
    text-decoration: none;
    overflow-wrap: break-word;
  }
  .contact_detail-address {
    color: var(--agt-secondary-color-1);
  }
  .consentBtn {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
  .consentBtn .consent {
    margin-right: 10px;
  }
  .consentBtn .icon {
  }
  .consentBtn .icon:hover {
    cursor: pointer;
  }
  .consentBtn .icon:hover + .info {
    display: flex;
  }
  .consentBtn .info {
    display: none;
    position: absolute;
    top: 56px;
    left: -48px;
    background: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    padding: 14px;
    min-width: 272px;
    z-index: 1;
    border-radius: 8px;
  }
  @media (max-width: 1024px) {
    .consent {
      font-size: 13px;
    }
  }
`
const ContactDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  .rightDetail {
    width: 35%;
    min-width: 70px;
  }
  .leftDetail {
    width: 65%;
    color: var(--agt-secondary-text-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .note {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
  }
  .two-lines {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
`
const MoreDetail = styled.div`
  h4 {
    border-bottom: 1px solid #a0a8bc;
  }
  .profile-title {
    padding: 0px 0px 11px 0px;
  }
  .interest-title {
    padding: 40px 0px 11px 0px;
  }
`
const MoreDetailLayer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0px 15px 0px 15px;
  padding: 15px 0px 6px 0px;
  border-bottom: 1px solid #d2d4db;
`
const MoreDetailTitle = styled.div`
  width: 50%;
  padding-right: 10px;
  align-self: left;
  text-align: left;
`
const MoreDetailInfo = styled.div`
  width: 50%;
  padding-left: 10px;
  align-self: right;
  text-align: right;
  .office-address {
    margin: 0px;
  }
`
const IconStyle = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-bottom: 10px;
  margin-right: 5px;
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 24px 0 0;
`
const RegisterInfo = styled.div`
  width: 188px;
  height: 48px;
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  background: #00aeef;
  border-radius: 5px;
  padding-left: 10.85px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: default;
  .label {
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
    text-align: right;
    margin-right: 12px;
  }
  .icon {
    padding-top: 5.5px;
  }
  @media (max-width: 1024px) {
    .icon {
      display: none;
    }
    .label .hidden {
      display: none;
    }
  }
`
const PortButton = styled.button<CustomButtonType>`
  width: 173px;
  margin-right: 24px;
  :disabled {
    cursor: not-allowed;
    color: ${(props) =>
      props.status === 'รอยินยอม' ? 'var(--agt-disable-color-2)' : 'var(--agt-white-color)'};
    background-color: ${(props) =>
      props.status === 'รอยินยอม' ? 'var(--agt-disable-color-1)' : 'var(--agt-tqm-pantone-1)'};
    opacity: ${(props) => (props.status === 'รอยินยอม' ? 1 : 0.3)};
  }
  @media (max-width: 1024px) {
    font-size: 13px;
  }
`
const PlanningButton = styled.button<CustomButtonType>`
  width: 181px;
  :disabled {
    cursor: not-allowed;
    color: ${(props) =>
      props.status === 'รอยินยอม' ? 'var(--agt-disable-color-2)' : 'var(--agt-white-color)'};
    background-color: ${(props) =>
      props.status === 'รอยินยอม' ? 'var(--agt-disable-color-1)' : 'var(--agt-tqm-pantone-1)'};
    opacity: ${(props) => (props.status === 'รอยินยอม' ? 1 : 0.3)};
  }
  @media (max-width: 1024px) {
    font-size: 13px;
  }
`
const PencilIcon = styled(IconStyle)`
  margin-right: 8px;
`
const CustomButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
  path {
    fill: var(--agt-primary-color-1);
  }
  &:hover {
    path {
      fill: var(--agt-dark-sky-color);
    }
  }
  &:disabled {
    path {
      fill: var(--agt-secondary-color-1);
    }
  }
  &:disabled:hover {
    path {
      fill: var(--agt-secondary-color-1);
    }
  }
`
const IconButton = styled(Icon)`
  margin-top: 4px;
`
const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`

const ContactDetailLeftSide = ({
  contact,
  consentCurrentVersion,
  getContactByIdAgain,
}: LeftSideType) => {
  const [isConsent, setIsConsent] = useState<boolean>(false)
  return contact ? (
    <CustomFormBodyPart className="contact_detail-left">
      {isConsent && (
        <ConsentModal
          isOpen={isConsent}
          onConfirm={() => {
            setIsConsent(false)
            getContactByIdAgain()
          }}
          onCancel={() => {
            setIsConsent(false)
          }}
          onLater={() => {
            setIsConsent(false)
          }}
          values={contact}
        />
      )}
      <ContactDetailRow>
        <PencilIcon name="phone_square_icon" />
        <label>{contact?.phoneNumber ? phoneNumberFormat(contact?.phoneNumber) : '-'}</label>
      </ContactDetailRow>
      <ContactDetailRow className="status">
        <label id={'contact-id__status'} style={{ marginRight: '12px' }}>
          สถานะลูกค้า
        </label>
        <label>{contact?.status ? contact.status : '-'}</label>
      </ContactDetailRow>
      {contact?.status !== 'รอยินยอม' ? (
        <ContactDetailRow>
          <label className="rightDetail">หมายเหตุ</label>
          <label className="leftDetail">{contact?.remark ? contact.remark : '-'}</label>
        </ContactDetailRow>
      ) : (
        <div className="consentBtn">
          <button
            id="contact-id__consent-btn"
            type="button"
            className="primary consent"
            onClick={() => {
              setIsConsent(true)
            }}
          >
            ขอความยินยอม
          </button>
          <div className="icon">
            <Icon name="I" color={'var(--agt-primary-color-1)'} />
          </div>
          <div className="info">
            <small>
              มีข้อกำหนดการเก็บรวบรวมและใช้ข้อมูล
              <br />
              {consentCurrentVersion ||
                'ความยินยอมข้อตกลงและเงื่อนไข การลงทะเบียน ฉบับมิถุนายน 2565'}
              <br />
              กรุณาขอความยินยอมจากลูกค้าภายใน 30 วัน
              <br />
              มิฉะนั้นข้อมูลลูกค้าท่านนี้จะถูกลบออกจากระบบ
            </small>
          </div>
        </div>
      )}
    </CustomFormBodyPart>
  ) : (
    <p> Undefined contact </p>
  )
}
const ContactDetailRightSide = ({ contact }: { contact: ContactType | null }) => {
  return contact ? (
    <CustomFormBodyPart className="contact_detail-right">
      <p>ที่อยู่สำหรับติดต่อ</p>
      {isNull(contact.currentAddress) ||
      (contact.currentAddress && isEmpty(contact.currentAddress.postcode)) ? (
        <p className="contact_detail-address">-</p>
      ) : (
        <p className="contact_detail-address">
          {contact?.currentAddress?.housingNo && `${contact.currentAddress.housingNo} `}
          {contact?.currentAddress?.villageNo && `หมู่ ${contact.currentAddress.villageNo} `}
          {contact?.currentAddress?.building && `${contact.currentAddress.building} `}
          {contact?.currentAddress?.lane && `ซอย ${contact.currentAddress.lane} `}
          {contact?.currentAddress?.road && `ถนน ${contact.currentAddress.road} `}
          {contact?.currentAddress?.subDistrict}&nbsp;
          {contact?.currentAddress?.district}&nbsp;
          {contact?.currentAddress?.province}&nbsp;
          {contact?.currentAddress?.postcode}
        </p>
      )}
    </CustomFormBodyPart>
  ) : (
    <p> Undefined contact </p>
  )
}
const OfficeAddressDetail = ({ contact }: { contact: ContactType | null }) => {
  return (
    <Fragment>
      {contact && contact.isSameCurrentAddress ? (
        <p className="contact_detail-address">
          {isEmpty(contact?.currentAddress?.housingNo) ? (
            '-'
          ) : (
            <>
              {contact?.currentAddress?.housingNo && `${contact.currentAddress.housingNo} `}
              {contact?.currentAddress?.villageNo && `หมู่ ${contact.currentAddress.villageNo} `}
              {contact?.currentAddress?.building && `${contact.currentAddress.building} `}
              {contact?.currentAddress?.lane && `ซอย ${contact.currentAddress.lane} `}
              {contact?.currentAddress?.road && `ถนน ${contact.currentAddress.road} `}
              {contact?.currentAddress?.subDistrict}&nbsp;
              {contact?.currentAddress?.district}&nbsp;
              {contact?.currentAddress?.province}&nbsp;
              {contact?.currentAddress?.postcode}
            </>
          )}
        </p>
      ) : (
        <p className="office-address">
          {isEmpty(contact?.officeAddress?.housingNo) ? (
            '-'
          ) : (
            <>
              {contact?.officeAddress?.housingNo && `${contact.officeAddress.housingNo} `}
              {contact?.officeAddress?.villageNo && `หมู่ ${contact.officeAddress.villageNo} `}
              {contact?.officeAddress?.building && `${contact.officeAddress.building} `}
              {contact?.officeAddress?.lane && `ซอย ${contact.officeAddress.lane} `}
              {contact?.officeAddress?.road && `ถนน ${contact.officeAddress.road} `}
              {contact?.officeAddress?.subDistrict}&nbsp;
              {contact?.officeAddress?.district}&nbsp;
              {contact?.officeAddress?.province}&nbsp;
              {contact?.officeAddress?.postcode}
            </>
          )}
        </p>
      )}
    </Fragment>
  )
}
const MoreDetailProfile = ({ contact }: { contact: ContactType | null }) => {
  return contact ? (
    <Fragment>
      <h4 className="profile-title">ข้อมูลเพิ่มเติม</h4>
      <MoreDetailLayer>
        <MoreDetailTitle>อีเมล</MoreDetailTitle>
        <MoreDetailInfo>
          {contact.email ? (
            <a
              href={`mailto:${contact.email}`}
              style={{ textDecoration: 'none', color: '#000000' }}
            >
              {contact.email}
            </a>
          ) : (
            `-`
          )}
        </MoreDetailInfo>
      </MoreDetailLayer>
      <MoreDetailLayer>
        <MoreDetailTitle>เพศ</MoreDetailTitle>
        <MoreDetailInfo>
          {contact.gender ? (contact.gender === `M` ? `ชาย` : `หญิง`) : `-`}
        </MoreDetailInfo>
      </MoreDetailLayer>
      <MoreDetailLayer>
        <MoreDetailTitle>วันเกิด</MoreDetailTitle>
        <MoreDetailInfo>{contact?.birthdate ? dateFormat(contact?.birthdate) : `-`}</MoreDetailInfo>
      </MoreDetailLayer>
      <MoreDetailLayer>
        <MoreDetailTitle>สถานภาพ</MoreDetailTitle>
        <MoreDetailInfo>
          {contact?.maritalStatus ? getMaritalStatusTh(contact?.maritalStatus) : `-`}
        </MoreDetailInfo>
      </MoreDetailLayer>
      <MoreDetailLayer>
        <MoreDetailTitle>บริษัท</MoreDetailTitle>
        <MoreDetailInfo>{contact?.company ? contact?.company : `-`}</MoreDetailInfo>
      </MoreDetailLayer>
      <MoreDetailLayer>
        <MoreDetailTitle>ตำแหน่ง</MoreDetailTitle>
        <MoreDetailInfo>{contact?.occupation ? contact?.occupation : `-`}</MoreDetailInfo>
      </MoreDetailLayer>
      <MoreDetailLayer>
        <MoreDetailTitle style={{ alignSelf: 'flex-end' }}>ที่อยู่ที่ทำงาน</MoreDetailTitle>
        <MoreDetailInfo>
          <OfficeAddressDetail contact={contact} />
        </MoreDetailInfo>
      </MoreDetailLayer>
    </Fragment>
  ) : (
    <p> Undefined contact </p>
  )
}
const ShowMoreDetailInteresting = (category: string, moreDetailProps: object) => {
  const camelizeKeyMoreDetailProps = camelizeKeys(moreDetailProps)
  const lifeStyleObject: any = getLifeStylesOptions(category)
  return (
    <div>
      {Object.entries(camelizeKeyMoreDetailProps).map(([key, object], i) => (
        <div key={i}>
          <MoreDetailLayer>
            <MoreDetailTitle>{lifeStyleObject[key].label}</MoreDetailTitle>
            <MoreDetailInfo>
              {moreDetailProps &&
                object.values.map((value: string, index: number) => (
                  <span key={index}>
                    {
                      lifeStyleObject[key]['fields'].find((field: any) => {
                        return field.value === value
                      }).label
                    }
                    {index !== object.values.length - 1 ? ', ' : ''}
                  </span>
                ))}
            </MoreDetailInfo>
          </MoreDetailLayer>
        </div>
      ))}
    </div>
  )
}
const MoreDetailInteresting = ({ contact }: { contact: ContactType | null }) => {
  const entertainments = contact?.entertainment
  const healths = contact?.health
  const travels = contact?.travel
  const foods = contact?.food
  const isEmptyEntertainments: boolean | undefined =
    entertainments && Object.keys(entertainments).length !== 0 ? false : true
  const isEmptyHealths: boolean | undefined =
    healths && Object.keys(healths).length !== 0 ? false : true
  const isEmptyTravels: boolean | undefined =
    travels && Object.keys(travels).length !== 0 ? false : true
  const isEmptyFoods: boolean | undefined = foods && Object.keys(foods).length !== 0 ? false : true
  const isEmptyLifeStyle: boolean =
    isEmptyEntertainments && isEmptyHealths && isEmptyTravels && isEmptyFoods
  return contact ? (
    <Fragment>
      <h4 className="interest-title">สิ่งที่ลูกค้าสนใจ</h4>
      {isEmptyLifeStyle ? (
        <MoreDetailLayer>
          <MoreDetailTitle>ยังไม่มีสิ่งที่สนใจ</MoreDetailTitle>
        </MoreDetailLayer>
      ) : (
        <div>
          {entertainments && ShowMoreDetailInteresting('entertainment', entertainments)}
          {healths && ShowMoreDetailInteresting('health', healths)}
          {travels && ShowMoreDetailInteresting('travel', travels)}
          {foods && ShowMoreDetailInteresting('food', foods)}
        </div>
      )}
    </Fragment>
  ) : (
    <p> Undefined contact </p>
  )
}
const getMaritalStatusTh = (maritalStatus?: string) => {
  if (maritalStatus === 'single') return 'โสด'
  else if (maritalStatus === 'married') return 'สมรส'
  else if (maritalStatus === 'divorced') return 'หย่า'
  else return '-'
}
function checkContact(contactData: {
  id?: string
  prefixName?: string
  firstName?: string
  lastName?: string
  gender?: string
  birthdate?: string
}) {
  let fact: boolean = false
  if (
    contactData.id &&
    contactData.prefixName &&
    contactData.firstName &&
    contactData.lastName &&
    contactData.gender &&
    contactData.birthdate
  ) {
    fact = true
  }
  return fact
}

const GuestMenu = authorize(
  () => {
    return (
      <Fragment>
        <RegisterInfo>
          <div className="label">
            สมัครตัวแทนเพื่อปลดล็อก
            <span className="hidden">
              <br />
              การใช้งานทั้งหมด
            </span>
          </div>
          <div className="icon">
            <Icon name="lock" />
          </div>
        </RegisterInfo>
        <PortButton id={'contact-id__offer-sale'} type="button" disabled>
          เสนอขายทันที
        </PortButton>
        <PortButton id={'contact-id__insurances'} type="button" disabled>
          สรุปกรมธรรม์
        </PortButton>
        <PlanningButton id={'contact-id__planning'} type="button" disabled>
          วางแผนประกัน
        </PlanningButton>
      </Fragment>
    )
  },
  { roles: ['guest'], params: { unauthorizedAction: UnauthorizedAction.HIDDEN } },
)

const AffiliateMenu = authorize(
  ({ contactId, contactData, getContactByIdAgain, currentAgent }) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const [isModalContact, setModalContact] = useState<boolean>(false)
    const [isModalContactOpps, setModalContactOpps] = useState<boolean>(false)
    return (
      <Fragment>
        <OpportunityByContact
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          contactId={contactId}
          contactData={contactData}
        />
        <ContactForOpportunity
          isOpen={isModalContactOpps}
          onConfirm={() => {}}
          onCancel={setModalContactOpps}
          setModalOpen={setModalOpen}
          contactData={contactData}
          getContactByIdAgain={getContactByIdAgain}
        />
        <ContactModal
          isOpen={isModalContact}
          onConfirm={() => {}}
          onCancel={setModalContact}
          contactData={contactData}
        />
        <PortButton
          id={'contact-id__offer-sale'}
          type="button"
          status={contactData.status}
          onClick={() => {
            if (checkContact(contactData)) {
              setModalOpen(!isModalOpen)
            } else {
              setModalContactOpps(!isModalContactOpps)
            }
          }}
          disabled={contactData.status === 'รอยินยอม' || currentAgent?.statusTh === 'ระงับ'}
        >
          เสนอขายทันที
        </PortButton>
        <PortButton
          id={'contact-id__insurances'}
          type="button"
          status={contactData.status}
          onClick={() => {
            if (checkContact(contactData)) {
              document.location.href = `/contacts/${contactId}/insurance_portfolio`
            } else {
              setModalContact(!isModalContact)
            }
          }}
          disabled={contactData.status === 'รอยินยอม'}
        >
          สรุปกรมธรรม์
        </PortButton>
        <PlanningButton
          id={'contact-id__planning'}
          type="button"
          status={contactData.status}
          onClick={() => {
            if (checkContact(contactData)) {
              document.location.href = `/contacts/${contactId}/planning`
            } else {
              setModalContact(!isModalContact)
            }
          }}
          disabled={contactData.status === 'รอยินยอม' || currentAgent?.statusTh === 'ระงับ'}
        >
          วางแผนประกัน
        </PlanningButton>
      </Fragment>
    )
  },
  { roles: ['affiliate'], params: { unauthorizedAction: UnauthorizedAction.HIDDEN } },
)

const StaffMenu = authorize(
  ({ contactId, contactData, getContactByIdAgain, currentAgent }) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const [isModalContact, setModalContact] = useState<boolean>(false)
    const [isModalContactOpps, setModalContactOpps] = useState<boolean>(false)
    return (
      <Fragment>
        <OpportunityByContact
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          contactId={contactId}
          contactData={contactData}
        />
        <ContactForOpportunity
          isOpen={isModalContactOpps}
          onConfirm={() => {}}
          onCancel={setModalContactOpps}
          setModalOpen={setModalOpen}
          contactData={contactData}
          getContactByIdAgain={getContactByIdAgain}
        />
        <ContactModal
          isOpen={isModalContact}
          onConfirm={() => {}}
          onCancel={setModalContact}
          contactData={contactData}
        />
        <PortButton
          id={'contact-id__offer-sale'}
          type="button"
          status={contactData.status}
          onClick={() => {
            if (checkContact(contactData)) {
              setModalOpen(!isModalOpen)
            } else {
              setModalContactOpps(!isModalContactOpps)
            }
          }}
          disabled={contactData.status === 'รอยินยอม' || currentAgent?.statusTh === 'ระงับ'}
        >
          เสนอขายทันที
        </PortButton>
        <PortButton
          id={'contact-id__insurances'}
          type="button"
          status={contactData.status}
          onClick={() => {
            if (checkContact(contactData)) {
              document.location.href = `/contacts/${contactId}/insurance_portfolio`
            } else {
              setModalContact(!isModalContact)
            }
          }}
          disabled={contactData.status === 'รอยินยอม'}
        >
          สรุปกรมธรรม์
        </PortButton>
        <PlanningButton
          id={'contact-id__planning'}
          type="button"
          status={contactData.status}
          onClick={() => {
            if (checkContact(contactData)) {
              document.location.href = `/contacts/${contactId}/planning`
            } else {
              setModalContact(!isModalContact)
            }
          }}
          disabled={contactData.status === 'รอยินยอม' || currentAgent?.statusTh === 'ระงับ'}
        >
          วางแผนประกัน
        </PlanningButton>
      </Fragment>
    )
  },
  { roles: ['tqm_staff', 'broker'], params: { unauthorizedAction: UnauthorizedAction.HIDDEN } },
)

const AuthorizeMenu = (props: MenuGroupType) => {
  const { contactId, contact = {}, getContactByIdAgain, currentAgent } = props
  return (
    <ButtonContainer>
      <GuestMenu />
      <AffiliateMenu
        contactId={contactId}
        contactData={contact}
        getContactByIdAgain={getContactByIdAgain}
        currentAgent={currentAgent}
      />
      <StaffMenu
        contactId={contactId}
        contactData={contact}
        getContactByIdAgain={getContactByIdAgain}
        currentAgent={currentAgent}
      />
    </ButtonContainer>
  )
}

const HeaderComponent = ({ setIsShowHint }: { setIsShowHint: SetStateType<boolean> }) => {
  return (
    <Header>
      <h3>รายชื่อลูกค้า</h3>
      <div className="hint" onClick={() => setIsShowHint(true)}>
        <HintIcon name="hint" />
        <span>คำแนะนำ</span>
      </div>
    </Header>
  )
}

const DeleteModalComponent = ({
  isDelete,
  setIsDelete,
  contact,
  confirmDelete,
}: {
  isDelete: boolean
  setIsDelete: SetStateType<boolean>
  contact: ContactType | null
  confirmDelete: Function
}) => {
  return (
    <Modal
      isOpen={isDelete}
      onConfirm={() => confirmDelete(contact?.id || 0)}
      onCancel={() => setIsDelete(false)}
      hiddenButton={!contact?.canDeleted}
    >
      <CustomModal>
        <Avatar avatar={contact?.avatar || ''} width="100px" height="100px" type="circle" />
        {contact?.canDeleted ? (
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <p>ข้อมูลอื่น (นัดหมาย, งานถัดไป, โอกาสในการขาย)</p>
            <p>
              ที่เกี่ยวข้องกับ&nbsp;
              <span className="name">
                {contact?.name && contact?.name?.split(' ')[0]}&nbsp;
                {contact?.name && contact?.name.split(' ')[1]}
              </span>
              &nbsp;จะถูกลบไปด้วย
            </p>
            <p>คุณแน่ใจหรือไม่ว่าต้องการลบ?</p>
          </div>
        ) : (
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <p>ข้อมูลติดต่อนี้มีข้อมูลคำสั่งสั่งซื้อแล้ว</p>
            <p className="name">
              {contact?.name && contact?.name?.split(' ')[0]}&nbsp;
              {contact?.name && contact?.name.split(' ')[1]}
            </p>
            <p>ไม่สามารถลบได้</p>
          </div>
        )}
      </CustomModal>
    </Modal>
  )
}

const MoreDetailCompoent = ({
  isMoreDetail,
  setIsMoreDetail,
  contact,
  lastConsentHistory,
}: {
  isMoreDetail: boolean
  setIsMoreDetail: SetStateType<boolean>
  contact: ContactType | null
  lastConsentHistory: lastConsentHistoryType | null
}) => {
  if (isMoreDetail) {
    return (
      <div className="BottomSide">
        <MoreDetail>
          <MoreDetailProfile contact={contact} />
          <MoreDetailInteresting contact={contact} />
        </MoreDetail>
        {lastConsentHistory && (
          <div className="ConsentHistories">
            <h4>ข้อตกลงและการขออนุญาตที่ใช้งาน</h4>
            <ShowHistoriesConsent
              contactId={contact?.id || 0}
              className="ShowHistories"
              consents={[lastConsentHistory]}
              type="contact"
            />
          </div>
        )}
        <div className="HideBtn" onClick={() => setIsMoreDetail(!isMoreDetail)}>
          ซ่อนรายละเอียด
        </div>
      </div>
    )
  }
  return (
    <div className="BottomSide">
      <div className="HideBtn" onClick={() => setIsMoreDetail(!isMoreDetail)}>
        ดูข้อมูลแบบละเอียด
      </div>
    </div>
  )
}

const ProfilePage: FC<ProfilePageProps> = ({
  contact,
  consentCurrentVersion,
  lastConsentHistory,
  getContactByIdAgain,
  setIsShowHint,
}) => {
  const history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [isMoreDetail, setIsMoreDetail] = useState<boolean>(false)
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'รายชื่อลูกค้า' },
    {
      label: contact?.name || 'รายละเอียดข้อมูลติดต่อ',
    },
  ]
  const confirmDelete = (id: number | string) => {
    if (id) {
      setIsDelete(false)
      dispatch(
        deleteContactById(id, () => {
          history.push('/contacts')
        }),
      )
    }
  }

  return (
    <Container>
      <DeleteModalComponent
        isDelete={isDelete}
        setIsDelete={setIsDelete}
        contact={contact}
        confirmDelete={confirmDelete}
      />
      <HeaderComponent setIsShowHint={setIsShowHint} />
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Content>
        <div className="Profile">
          <div id="contact-id__personal" className="Personal">
            <div className="TopSide">
              <DetailContentProfile>
                <DetailContentHeader>
                  <h4 id="contact-id__modal" className="name">
                    {contact?.name}
                  </h4>
                  <label className="occupation">
                    {contact?.birthdate &&
                      `(${getAge(moment(contact?.birthdate))} ปี) ${
                        contact?.occupation ? '-' : ''
                      } `}
                    {contact?.occupation}
                  </label>
                </DetailContentHeader>
                <DetailContentBody>
                  <ContactDetailLeftSide
                    contact={contact}
                    consentCurrentVersion={consentCurrentVersion}
                    getContactByIdAgain={getContactByIdAgain}
                  />
                  <ContactDetailRightSide contact={contact} />
                </DetailContentBody>
                {contact?.status !== 'รอยินยอม' && (
                  <div className="note">
                    <div className="topic">โน้ต</div>
                    <span className="hidden">{contact && contact.note ? contact.note : '-'}</span>
                  </div>
                )}
              </DetailContentProfile>
              <DetailContentExtend>
                <DetailContentImage>
                  <Avatar
                    avatar={contact?.avatar || ''}
                    width="100px"
                    height="100px"
                    type="circle"
                  />
                </DetailContentImage>
                <DetailContentOption>
                  <Link
                    to={`/contacts/${contact?.id}/edit`}
                    style={currentAgent?.statusTh === 'ระงับ' ? { pointerEvents: 'none' } : {}}
                  >
                    <CustomButton
                      id={'contact-id__edit-icon'}
                      type="button"
                      className="secondary"
                      style={{ right: '58px' }}
                      disabled={currentAgent?.statusTh === 'ระงับ'}
                    >
                      <IconButton name="pencil_icon" />
                    </CustomButton>
                  </Link>
                  <CustomButton
                    type="button"
                    className="secondary"
                    onClick={() => setIsDelete(true)}
                    disabled={currentAgent?.statusTh === 'ระงับ'}
                  >
                    <IconButton name="bin" />
                  </CustomButton>
                </DetailContentOption>
              </DetailContentExtend>
            </div>
            {contact?.status !== 'รอยินยอม' && (
              <MoreDetailCompoent
                isMoreDetail={isMoreDetail}
                setIsMoreDetail={setIsMoreDetail}
                contact={contact}
                lastConsentHistory={lastConsentHistory}
              />
            )}
          </div>
          <AuthorizeMenu
            contactId={contact?.id}
            contact={contact}
            getContactByIdAgain={getContactByIdAgain}
            currentAgent={currentAgent}
          />
          <TableComponet
            contactId={contact?.id || null}
            contactData={contact}
            currentAgent={currentAgent}
          />
        </div>
        <TaskComponent
          contactId={contact?.id || null}
          contactData={contact}
          currentAgent={currentAgent}
        />
      </Content>
    </Container>
  )
}
export default ProfilePage
