import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import session from 'utils/session'
import { AppThunk } from 'store/store'
import Api from 'api'
import isFuture from 'date-fns/isFuture'
import authToken from 'utils/session'
import SetStateType from 'types/SetStateType'
import GuestType from 'types/GuestType'
import HistoryType from 'types/HistoryType'
import {
  AppState,
  CurrentAgentProps,
  getConsentByIdSusscessType,
  getConsentSusscessType,
  LoginValues,
  SocialValue,
} from 'types/AppType'

const initialState: AppState = {
  initialized: false,
  error: null,
  currentAgent: null,
  isNavbarErrorShow: false,
  abilities: [],
  pdpa: null,
  termsAndConditions: null,
  pdpaAgreement: null,
  termAgreement: null,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initializeApp(state) {
      state.initialized = true
    },
    getCurrentAgentSuccess(state, action: PayloadAction<CurrentAgentProps>) {
      session.setAuthToken(action.payload.token)
      state.currentAgent = action.payload.currentAgent
      state.abilities = action.payload.abilities
      state.error = null
    },
    getCurrentAgentFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setIsNavbarErrorShow(state, action: PayloadAction<boolean | string>) {
      state.isNavbarErrorShow = action.payload
    },
    getConsentSusscess(state, action: PayloadAction<getConsentSusscessType>) {
      state.pdpa = action.payload.pdpa
      state.termsAndConditions = action.payload.termsAndConditions
    },
    getConsentFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    getConsentByIdSusscess(state, action: PayloadAction<getConsentByIdSusscessType>) {
      if (action.payload.type === 'pdpa') state.pdpaAgreement = action.payload.agreement
      else if (action.payload.type === 'term') state.termAgreement = action.payload.agreement
    },
    getConsentByIdFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  },
})

export const {
  initializeApp,
  getCurrentAgentSuccess,
  getCurrentAgentFailed,
  setIsNavbarErrorShow,
  getConsentSusscess,
  getConsentFailed,
  getConsentByIdSusscess,
  getConsentByIdFailed,
} = appSlice.actions

export default appSlice.reducer

export const loginUser = (
  newUser: LoginValues,
  history: HistoryType,
  setLoading: SetStateType<boolean>,
): AppThunk => async (dispatch) => {
  try {
    setLoading(true)
    const response = await Api.post('/auth/sign_in/', newUser)
    authToken.setAuthToken(response.data.data.token)
    authToken.setAuthTqmLifeToken(response.data.data.tqmLifeToken)
    history.push('/')
    window.location.reload()
  } catch (err) {
    console.error(err.response.data.message)
    dispatch(setIsNavbarErrorShow(err.response.data.message))
  } finally {
    setLoading(false)
  }
}

export const loginUserBySocial = (
  type: string,
  newUser: SocialValue,
  history: HistoryType,
): AppThunk => async (dispatch) => {
  try {
    if (type === 'google') {
      const response = await Api.post('/auth/google_sign_in', newUser)
      authToken.setAuthToken(response.data.data.token)
      authToken.setAuthTqmLifeToken(response.data.data.tqmLifeToken)
    } else {
      const response = await Api.post('/auth/facebook_sign_in', newUser)
      authToken.setAuthToken(response.data.data.token)
      authToken.setAuthTqmLifeToken(response.data.data.tqmLifeToken)
    }
    history.push('/')
    window.location.reload()
  } catch (err) {
    console.error(err.response.data.message)
    dispatch(setIsNavbarErrorShow(err.response.data.message))
  }
}

export const fetchCurrentAgent = (): AppThunk => async (dispatch) => {
  if (session.isAuthTokenPresent()) {
    try {
      const {
        data: { data },
      } = await Api.get('/auth/current_agent')
      const currentAgent = {
        ...data,
        currentAgent: {
          ...data.currentAgent,
          isExpired:
            data.currentAgent.agentType === 'guest'
              ? !isFuture(new Date(data.currentAgent.expiredAt))
              : null,
        },
      }
      dispatch(getCurrentAgentSuccess(currentAgent))
    } catch (err) {
      console.error(err.response.data.message)
      dispatch(getCurrentAgentFailed(err.toString()))
    } finally {
      dispatch(initializeApp())
    }
  } else {
    dispatch(initializeApp())
  }
}

export const sendResetPassword = (values, callback): AppThunk => async () => {
  try {
    await Api.post('/auth/send_reset_password_instructions', values)
    callback()
  } catch (err) {
    console.error(err.response.data.message)
    if (err.response) {
      if (err.response.status === 422) alert('ไม่มี email อยู่ในระบบ')
      else alert('เกิดข้อผิดพลาดบางอย่าง')
    }
  }
}

export const sendNewEmail = (
  confirmationToken: string = '',
  isSuccess: Function = () => {},
  isLoading: Function = () => {},
  callback: Function = () => {},
): AppThunk => async () => {
  try {
    await Api.post('/auth/confirm_change_email', { confirmationToken })
    isSuccess()
  } catch (err) {
    console.error(err.response.data.message)
    if (err.response) {
      if (err.response.status === 422) alert('ไม่มี email อยู่ในระบบ')
      else alert('เกิดข้อผิดพลาดบางอย่าง')
    }
    callback()
  } finally {
    isLoading()
  }
}

export const createUser = (
  user: GuestType,
  callback: () => void,
  setEmail: SetStateType<string>,
): AppThunk => async () => {
  try {
    await Api.post('/auth/register', user)
    setEmail(user.email || '')
    callback()
  } catch (err) {
    console.error(err || err.response)
    if (err?.response.status === 422) {
      alert('มีบัญชีผู้ใช้อยู่ในระบบแล้ว')
    } else {
      alert('เกิดข้อผิดพลาดบางอย่าง')
    }
  }
}

export const getConsentRecruitment = (): AppThunk => async (dispatch) => {
  try {
    const response = await Api.get('/consents/agents/recruitment')
    dispatch(getConsentSusscess(response.data.data))
  } catch (err) {
    console.error(err || err.response)
    if (err?.response.status === 422) {
      alert('มีบัญชีผู้ใช้อยู่ในระบบแล้ว')
    } else {
      alert('เกิดข้อผิดพลาดบางอย่าง')
    }
    dispatch(getConsentFailed(err.response.data.message))
  }
}

export const getConsentRegister = (): AppThunk => async (dispatch) => {
  try {
    const response = await Api.get('/consents/agents/register')
    dispatch(getConsentSusscess(response.data.data))
  } catch (err) {
    console.error(err || err.response)
    if (err?.response.status === 422) {
      alert('มีบัญชีผู้ใช้อยู่ในระบบแล้ว')
    } else {
      alert('เกิดข้อผิดพลาดบางอย่าง')
    }
    dispatch(getConsentFailed(err.response.data.message))
  }
}

export const getConsentById = (id: string | number, type: string): AppThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/consents/agents/${id}`)
    let modifiedResponse = { agreement: response.data.data.agreement, type: type }
    dispatch(getConsentByIdSusscess(modifiedResponse))
  } catch (err) {
    console.error(err || err.response)
    if (err?.response.status === 422) {
      alert('มีบัญชีผู้ใช้อยู่ในระบบแล้ว')
    } else {
      alert('เกิดข้อผิดพลาดบางอย่าง')
    }
    dispatch(getConsentByIdFailed(err.response.data.message))
  }
}

export const checkAccount = (
  values: { type: string; email: string; token: string },
  setForm: Function,
): AppThunk => async () => {
  try {
    await Api.get('/auth/check_account', values)
    setForm()
  } catch (err) {
    console.error(err || err.response)
    if (err?.response.status === 422) {
      alert('มีบัญชีผู้ใช้อยู่ในระบบแล้ว')
    } else {
      alert('เกิดข้อผิดพลาดบางอย่าง')
    }
  }
}
