import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfileThunk } from 'store/store'
import api from 'api'
import UserType, { DocumentsType, OptionsType, OtpType } from 'types/RegisterType'
import downloadjs from 'downloadjs'
import mime from 'mime-types'

type agreementType = {
  agreementType: string
  beginDate: string | null
  consentBy: string
  consents: Array<{
    name: string
    forced_consent: boolean
    key: string
    value?: boolean
  }>
  contentDisplay: string
  detail: string
  id: number
  name: string
  status: string
  update_by: string
  updated_at: string
  version: string
}
type UnitType = {
  label: string
  key: string
  value: number
}
type DashboardType = {
  month: Array<UnitType>
  quarter: Array<UnitType>
  year: Array<UnitType>
}
export type SettingType = {
  email: string
  facebookEmail: string
  googleEmail: string
  consentHistories: []
  tempEmail: string
}
type ProfileState = {
  initialized: boolean
  error?: string
  profile: UserType
  documents: DocumentsType
  options: OptionsType
  otp: OtpType
  dashboard: DashboardType
  setting: SettingType
  agreement: agreementType | null
}

const initialState: ProfileState = {
  initialized: false,
  error: '',
  profile: {},
  documents: {},
  options: { licenseTypeOptions: [], insuranceCompanyNameOptions: [] },
  otp: {},
  dashboard: { month: [], quarter: [], year: [] },
  setting: { email: '', facebookEmail: '', googleEmail: '', consentHistories: [], tempEmail: '' },
  agreement: null,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setInitialized(state, action: PayloadAction<boolean>) {
      state.initialized = action.payload
    },
    setProfile(state, action: PayloadAction<UserType>) {
      state.profile = action.payload
    },
    setDocuments(state, action: PayloadAction<DocumentsType>) {
      state.documents = action.payload
    },
    setOptions(state, action: PayloadAction<OptionsType>) {
      state.options = action.payload
    },
    setOtp(state, action: PayloadAction<OtpType>) {
      state.otp = action.payload
    },
    setOtpFaile(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setDashboard(state, action: PayloadAction<DashboardType>) {
      state.dashboard = action.payload
    },
    setSetting(state, action: PayloadAction<SettingType>) {
      state.setting = action.payload
    },
    setHistoriesByAgent(state, action: PayloadAction<agreementType | null>) {
      state.agreement = action.payload
    },
  },
})

export const {
  setInitialized,
  setProfile,
  setDocuments,
  setOptions,
  setOtp,
  setDashboard,
  setSetting,
  setHistoriesByAgent,
} = profileSlice.actions

export default profileSlice.reducer

export const getProfile = (): ProfileThunk => async (dispatch) => {
  try {
    dispatch(setInitialized(true))
    const response = await api.get('profiles')
    dispatch(setProfile(response.data.data.agent))
  } catch (error) {
    console.error(error.response || error)
  } finally {
    dispatch(setInitialized(false))
  }
}

export const editProfle = (user: UserType, callback: () => void): ProfileThunk => async (
  dispatch,
) => {
  try {
    const response = await api.put('profiles', { ...user })
    dispatch(setProfile(response.data.data.agent))
    callback()
  } catch (error) {
    console.error(error.response || error)
  }
}

export const recruitmentProfle = (user: UserType, callback: () => void): ProfileThunk => async (
  dispatch,
) => {
  try {
    const response = await api.put('profiles/recruitment', { ...user })
    dispatch(setProfile(response.data.data.agent))
    callback()
  } catch (error) {
    console.error(error.response || error)
    let errorResponse: {
      code: string
      message?: string
      errors: Array<{ field: string; message: string }>
    } = error.response.data
    if (errorResponse?.message) {
      alert(errorResponse.message)
    } else if (errorResponse?.errors) {
      let fieldErrorText: string = ''
      errorResponse.errors.forEach((e: { field: string; message: string }, index: number) => {
        if (index === errorResponse.errors.length - 1) fieldErrorText += e.field + ' ' + e.message
        else fieldErrorText += e.field + ' ' + e.message + '\n'
      })
      alert(fieldErrorText)
    } else {
      alert('มีข้อผิดพลาดบางอย่าง')
    }
  }
}

export const getDocuments = (): ProfileThunk => async (dispatch) => {
  try {
    dispatch(setInitialized(true))
    const response = await api.get('profiles/documents')
    dispatch(setDocuments(response.data.data))
    const res = await api.get('profiles/options')
    dispatch(setOptions(res.data.data))
  } catch (error) {
    console.error(error.response || error)
  } finally {
    dispatch(setInitialized(false))
  }
}

export const editDocuments = (values: DocumentsType): ProfileThunk => async () => {
  try {
    await api.put('profiles/documents', { ...values })
    window.location.reload()
  } catch (error) {
    console.error(error.response || error)
  }
}

export const getProileOptions = (): ProfileThunk => async (dispatch) => {
  try {
    const response = await api.get('profiles/options')
    dispatch(setOptions(response.data.data))
  } catch (error) {
    console.error(error.response || error)
  }
}

export const sendOtp = (Tel = ''): ProfileThunk => async (dispatch) => {
  try {
    const response = await api.post('profiles/send_otp', { phoneNumber: Tel })
    dispatch(setOtp(response.data.data))
  } catch (error) {
    console.error(error.response || error)
  }
}

export const verifyOtp = (
  phoneNumber: string = '',
  otpNumber: string = '',
  otpRefCode: string = '',
  isSetSucessOTP: Function,
  setIsModalOTP: Function,
  setIsWaitOTP: Function,
  cb = () => {},
): ProfileThunk => async () => {
  try {
    await api.post('profiles/verify_otp', {
      phoneNumber: phoneNumber,
      otpNumber: otpNumber,
      otpRefCode: otpRefCode,
    })
    isSetSucessOTP(true)
    setIsModalOTP(true)
    setIsWaitOTP(false)
    cb()
  } catch (error) {
    console.error(error.response || error)
    isSetSucessOTP(false)
    setIsModalOTP(false)
    setIsWaitOTP(true)
    alert('กรุณาระบุใหม่')
  }
}

export const downloadDocTqm = (): ProfileThunk => async () => {
  try {
    const response = await api.get(
      '/profiles/download_document/',
      {},
      {
        responseType: 'blob',
      },
    )
    const { data, headers } = response
    const filename = headers['content-disposition'].match(/filename="?(.*)"?/)[1]
    const mimeType = mime.lookup(filename)
    downloadjs(data, decodeURI(filename), mimeType)
  } catch (err) {
    console.error(err || err.response)
  }
}

export const getTqmOfficeAddresses = (cb: (e) => void): ProfileThunk => async () => {
  try {
    const response = await api.get('profiles/tqm_office_addresses')
    cb(response.data.data.tqmOfficeAddresses)
  } catch (err) {
    console.error(err || err.response)
  }
}

export const getdashboard = (): ProfileThunk => async (dispatch) => {
  try {
    const response = await api.get('dashboard')
    dispatch(setDashboard(response.data.data.dashboard))
  } catch (error) {
    console.error(error.response || error)
  }
}

export const getSetting = (): ProfileThunk => async (dispatch) => {
  try {
    dispatch(setInitialized(true))
    const response = await api.get('profiles/setting')
    dispatch(setSetting(response.data.data))
  } catch (error) {
    console.error(error.response || error)
  } finally {
    dispatch(setInitialized(false))
  }
}

export const changePassword = (values: {}, callback: () => void): ProfileThunk => async () => {
  try {
    await api.put('profiles/change_password', { ...values })
    callback()
  } catch (error) {
    if (error && error.response) {
      alert(error.response.data?.errors[0]?.message)
    }
    throw error
  }
}

export const linkAccount = (values: {}): ProfileThunk => async () => {
  try {
    await api.put('profiles/link_account', { ...values })
    window.location.reload()
  } catch (error) {
    if (error && error.response) {
      alert(error.response?.data?.message)
    }
    throw error
  }
}

export const unlinkAccount = (values: {}): ProfileThunk => async () => {
  try {
    await api.put('profiles/unlink_account', { ...values })
    window.location.reload()
  } catch (error) {
    if (error && error.response) {
      alert(error.response.data?.errors[0]?.message)
    }
    throw error
  }
}

export const changeEmail = (values: {}, callback = () => {}): ProfileThunk => async () => {
  try {
    await api.put('profiles/change_email', { ...values })
    callback()
  } catch (error) {
    if (error && error.response) {
      alert(error.response.data?.message)
    }
    throw error
  }
}

export const resendConfirmation = (values: {}, callback = () => {}): ProfileThunk => async () => {
  try {
    await api.put('profiles/resend_confirmation', { ...values })
    callback()
  } catch (error) {
    if (error && error.response) {
      alert(error.response.data?.message)
    }
    throw error
  }
}
