import React from 'react'
import styled from '@emotion/styled'
import { dateFormat } from 'utils/helper'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
  padding: 40px 0;
  .redLicensePlate {
    align-self: flex-end;
  }
`

const InputCustom = styled.div<{ width?: number }>`
  width: 100%;
  max-width: ${(props) => (props.width ? `${props.width}px` : 'unset')};
  p {
    border-bottom: 1px solid var(--agt-secondary-color-1);
    padding: 4px 0;
  }
  @media (max-width: 1024px) {
    /* max-width: ${(props) => (props.width === 272 ? `260px` : 'unset')}; */
  }
`

const InputComponent = ({ title, content, width = 0 }) => {
  return (
    <InputCustom width={width}>
      <b>{title}</b>
      <p>{content}</p>
    </InputCustom>
  )
}

function displayAddress(address: {
  housingNo: string
  villageNo: string | null
  building: string | null
  lane: string | null
  road: string | null
  subDistrict: string
  district: string
  province: string
  postcode: string
}) {
  return (
    <>
      {address.housingNo && `${address.housingNo} `}
      {address.villageNo && `หมู่ ${address.villageNo} `}
      {address.building && `${address.building} `}
      {address.lane && `ซอย ${address.lane} `}
      {address.road && `ถนน ${address.road} `}
      {address.subDistrict} {address.district} {address.province} {address.postcode}
    </>
  )
}

const Detail = (props) => {
  const { data, options } = props
  return (
    <Container>
      <InputComponent
        title={'คำนำหน้าชื่อ'}
        content={data?.contact?.prefixName || '-'}
        width={160}
      />
      <InputComponent title={'ชื่อจริง'} content={data?.contact?.firstName || '-'} width={272} />
      <InputComponent title={'นามสกุล'} content={data?.contact?.lastName || '-'} width={272} />
      <InputComponent
        title={'วันเกิด'}
        content={dateFormat(data?.contact?.birthdate || '-')}
        width={360}
      />
      <InputComponent
        title={'เลขบัตรประจำตัวประชาชน'}
        content={data?.contact?.idCard || '-'}
        width={360}
      />
      <InputComponent title={'อีเมล'} content={data?.contact?.email || '-'} width={360} />
      <InputComponent
        title={'เบอร์โทรศัพท์'}
        content={data?.contact?.phoneNumber || '-'}
        width={360}
      />
      <InputComponent
        title={'ประกันเดิม'}
        content={
          data?.order?.prbInsurance?.oldCompanyName
            ? (options?.prbInsuranceCompanyOptions || []).find(
                (prbInsuranceCompanyOption: { label: string; value: string }) =>
                  prbInsuranceCompanyOption.value === data.order.prbInsurance.oldCompanyName,
              )?.label
            : '-'
        }
        width={736}
      />
      <InputComponent
        title={'ที่อยู่บนกรมธรรม์'}
        content={
          data?.order?.prbInsurance?.policyAddress
            ? displayAddress(data.order.prbInsurance.policyAddress)
            : '-'
        }
        // content={data?.contact?.homeAddress ? displayAddress(data.contact.homeAddress) : '-'}
        width={736}
      />
      <InputComponent
        title={'ที่อยู่จัดส่งกรมธรรม์'}
        content={
          data?.order?.prbInsurance?.policyDeliveryAddress
            ? displayAddress(data.order.prbInsurance.policyDeliveryAddress)
            : '-'
        }
        width={736}
      />
      <InputComponent
        title={'ที่อยู่ใบเสร็จรับเงิน'}
        content={
          data?.order?.prbInsurance?.receiptAddress
            ? displayAddress(data.order.prbInsurance.receiptAddress)
            : '-'
        }
        width={736}
      />
      <p>
        <b>รายละเอียดกรมธรรม์</b>
      </p>
      {/* <InputComponent
        title={'สถานที่ตรวจสภาพรถยนต์'}
        content={data?.contact?.phoneNumber || '-'}
        width={736}
      />
      <InputComponent
        title={'วันที่ตรวจสภาพรถยนต์'}
        content={data?.contact?.phoneNumber || '-'}
        width={736}
      /> */}
      <InputComponent
        title={'รูปแบบกรมธรรม์'}
        content={
          data?.order?.prbInsurance?.policyForm === 'online'
            ? 'กรมธรรม์ออนไลน์'
            : data?.order?.prbInsurance?.policyForm === 'original'
            ? 'กรมธรรม์ฉบับจริง'
            : '-'
        }
        width={736}
      />
      <InputComponent
        title={'เริ่มการคุ้มครอง'}
        content={
          data?.order?.prbInsurance?.startPolicyAt
            ? dateFormat(data?.order?.prbInsurance?.startPolicyAt)
            : '-'
        }
        width={360}
      />
      <InputComponent
        title={'สิ้นสุดการคุ้มครอง'}
        content={
          data?.order?.prbInsurance?.endPolicyAt
            ? dateFormat(data?.order?.prbInsurance?.endPolicyAt)
            : '-'
        }
        width={360}
      />
      <p>
        <b>รายละเอียดรถยนต์</b>
      </p>
      <InputComponent
        title={'เลือกจากข้อมูลรถของลูกค้า'}
        content={data?.vehicle?.name || '-'}
        width={736}
      />
      <InputComponent title={'ปีรถยนต์'} content={data?.vehicle?.year || '-'} width={360} />
      <InputComponent
        title={'ยี่ห้อรถยนต์'}
        // content={data?.vehicle?.vehicleBrand || '-'}
        content={
          (options?.makeCodeOptions || []).find(
            (option) => option.value === data?.vehicle?.vehicleBrand,
          )?.label
        }
        width={360}
      />
      <InputComponent
        title={'รุ่น'}
        // content={data?.vehicle?.vehicleModel || '-'}
        content={
          (options?.familyCodeOptions || []).find(
            (option) => option.value === data?.vehicle?.vehicleModel,
          )?.label
        }
        width={360}
      />
      <InputComponent
        title={'รุ่นย่อย'}
        // content={data?.vehicle?.vehicleSubModel || '-'}
        content={
          (options?.carSubModelOptions || []).find(
            (option) => option.value === data?.vehicle?.vehicleSubModel,
          )?.label
        }
        width={360}
      />
      <InputComponent
        title={'เลขที่ทะเบียนรถยนต์'}
        content={data?.vehicle?.vehicleLicense?.value || '-'}
        width={360}
      />
      <InputComponent
        title={'จังหวัดที่จดทะเบียน'}
        content={
          (options?.provinceOptions || []).find(
            (option) => option.value === data?.vehicle?.vehicleProvince?.value,
          )?.label
        }
        width={360}
      />
      <InputComponent
        title={'เลขตัวถัง'}
        content={data?.vehicle?.vehicleChassis?.value || '-'}
        width={360}
      />
      {data?.vehicle?.redLicencePlate && <b className="redLicensePlate">เป็นรถป้ายแดง</b>}
    </Container>
  )
}

export default Detail
