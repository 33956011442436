import React, {
  useEffect,
  useState,
  // useState, useMemo
} from 'react'
import styled from '@emotion/styled'
import { ErrorMessage, Form, Field, useField, useFormikContext } from 'formik'
import {
  // Link, useHistory,
  useParams,
} from 'routes'
// import moment from 'moment'
import Select from 'react-select'
import { DatePickerComponent } from 'components/DatePicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import CustomAddress from './CustomAddress'
import Icon from 'components/Icon'
import { useDispatch } from 'react-redux'
import { getActOptions } from 'store/actSlice'
import { addDays, addYears } from 'date-fns'
import vehicleChassis from '../../../../assets/images/vehicle_chassis.png'

const today = new Date()
const tomorrow = addDays(today, 1)

const Container = styled(Form)`
  margin-top: 52px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
  .firstName,
  .lastName {
    width: 100%;
    max-width: 272px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .birthdate,
  .idCard,
  .email,
  .phoneNumber,
  .plateNumber,
  .startDate,
  .endDate,
  .kkkkk,
  .blank {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    @media (max-width: 1024px) {
      max-width: 272px;
    }
  }
  .firstName,
  .lastName,
  .kkkkk {
    > label span {
      color: var(--agt-white-color);
    }
  }
  .birthdate,
  .idCard,
  .email,
  .phoneNumber,
  .plateNumber,
  .startDate,
  .endDate {
    > label span {
      color: var(--agt-error-color);
    }
  }
  .prefixName {
    width: 100%;
    max-width: 160px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .prefixName > div:last-of-type {
    width: 100%;
  }
  .insuranceCompany,
  .ffff {
    width: 100%;
    max-width: 736px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    @media (max-width: 1024px) {
      width: 89%;
    }
  }
  .insuranceCompany > div:last-of-type,
  .ffff > div:last-of-type {
    width: 100%;
  }
  .aaaa,
  .bbbb,
  .cccc,
  .dddd,
  .eeee,
  .provice {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    @media (max-width: 1024px) {
      max-width: 272px;
    }
  }
  .aaaa > div:last-of-type,
  .bbbb > div:last-of-type,
  .cccc > div:last-of-type,
  .dddd > div:last-of-type,
  .eeee > div:last-of-type,
  .provice > div:last-of-type {
    width: 100%;
  }
  .eeee,
  .prefixName,
  .insuranceCompany {
    > label span {
      color: var(--agt-white-color);
    }
  }
  .aaaa,
  .bbbb,
  .cccc,
  .dddd,
  .ffff,
  .provice,
  .dDate {
    > label span {
      color: var(--agt-error-color);
    }
  }
  .dDate {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .dDate > div:last-of-type {
    width: 100%;
    max-width: 360px;
  }
  .topic {
    width: 100%;
    margin-top: 24px;
  }
  .submitBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
`

const OtherComponent = styled.div`
  &.checkup,
  &.policy {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    > label span {
      color: var(--agt-error-color);
    }
    > div:last-child {
      display: flex;
      column-gap: 52px;
    }
    > div:last-child label {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }
    > div:last-child label:hover {
      cursor: pointer;
    }
    > div:last-child label .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid var(--agt-secondary-color-2);
      border-radius: 50%;
    }
    > div:last-child label input {
      display: none;
    }
    > div:last-child label input:checked ~ .checkmark {
      border: 2px solid var(--agt-primary-color-1);
    }
    > div:last-child label .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    > div:last-child label input:checked ~ .checkmark:after {
      display: block;
    }
    > div:last-child label .checkmark:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--agt-primary-color-1);
    }
  }
  &.notice {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 8px;
    > input {
      display: none;
    }
    > .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid var(--agt-secondary-color-2);
      border-radius: 4px;
    }
    > .checkmark .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
      font-size: 10px;
      color: var(--agt-white-color);
    }
    > input:checked ~ .checkmark {
      background-color: var(--agt-primary-color-1);
    }
    > input:checked ~ .checkmark .icon {
      display: block;
    }
  }
  &.idCar {
    position: relative;
    width: 100%;
    max-width: 328px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    @media (max-width: 1024px) {
      /* max-width: 272px; */
      max-width: 230px;
    }
  }
  &.idCar {
    > label span {
      color: var(--agt-error-color);
    }
  }
  &.idCar .icon {
    position: absolute;
    top: 40px;
    right: -36px;
    :hover {
      cursor: pointer;
    }
  }
  &.redLabel {
    width: 100%;
    max-width: 360px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 8px;
    @media (max-width: 1024px) {
      /* max-width: 316px; */
      max-width: 270px;
    }
    > input {
      display: none;
    }
    > .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid var(--agt-secondary-color-2);
      border-radius: 4px;
    }
    > .checkmark .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
      font-size: 10px;
      color: var(--agt-white-color);
    }
    > input:checked ~ .checkmark {
      background-color: var(--agt-primary-color-1);
    }
    > input:checked ~ .checkmark .icon {
      display: block;
    }
  }
`

const AddressComponent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
  .aaaaaa {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .aaaaaa > div:last-child {
    width: 100%;
    max-width: 360px;
  }
  .housingNo,
  .villageNo,
  .road,
  .land {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    max-width: 360px;
    @media (max-width: 1024px) {
      max-width: 272px;
    }
  }
  .housingNo > input,
  .villageNo > input,
  .road > input,
  .land > input {
    width: 100%;
  }
  .building {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    max-width: 736px;
    @media (max-width: 1024px) {
      width: 89%;
    }
  }
  .building > input {
    width: 100%;
  }
  .housingNo > label span {
    color: var(--agt-error-color);
  }
  .aaaaaa > label span,
  .villageNo > label span,
  .building > label span,
  .land > label span,
  .road > label span {
    color: var(--agt-white-color);
  }
  .topic {
    width: 100%;
    margin-top: unset;
  }
`

const Error = styled.small`
  color: var(--agt-error-color);
`

const OptionsStyles = {
  // container: (styles, { isDisabled }) => ({
  //   cursor: isDisabled ? 'not-allowed' : 'default',
  // }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: (styles, { isDisabled }) => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: isDisabled ? 'var(--agt-background-color-1)' : 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle, { isDisabled }) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
  singleValue: (defaultStyle, { isDisabled }) => ({
    ...defaultStyle,
    color: isDisabled ? 'var(--agt-primary-text-color)' : 'var(--agt-primary-text-color)',
  }),
}

const OptionsError = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-error-color)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}

const Selector = styled(Select)`
  width: 356px;
  height: 48px;
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

const OptionsComponent = (props) => {
  const {
    field,
    options,
    className = '',
    label = '',
    disabled = false,
    onChange = () => {},
    placeholder = 'เลือก',
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find(
    (select: { value: string; label: string }) => select.value === value,
  )
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <Selector
        value={defaultSeletor ? defaultSeletor : ''}
        onChange={(option: { value: string; label: string; details: {} }) => {
          setValue(option.value)
          if (onChange) onChange(option)
        }}
        styles={meta.error && meta.touched ? OptionsError : OptionsStyles}
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

const AddressOptionsComponent = (props) => {
  const { field, options, className = '', label = '', disabled = false } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = (options || []).find((select) => select.label === value)
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <Selector
        value={defaultSeletor ? defaultSeletor : ''}
        onChange={(option) => {
          setValue(option.label)
        }}
        styles={meta.error && meta.touched ? OptionsError : OptionsStyles}
        options={options}
        placeholder="เลือก"
        isDisabled={disabled}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

const InputComponent = (props) => {
  const {
    field,
    className = '',
    label = '',
    placeholder = 'โปรดระบุ',
    disabled = false,
    maxlength,
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        style={meta.error && meta.touched ? { border: '1px solid var(--agt-error-color)' } : {}}
        maxlength={maxlength}
        {...field}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

const ChassisNumberComponent = (props) => {
  const {
    field,
    className = '',
    label = '',
    placeholder = 'โปรดระบุ',
    disabled = false,
    isSuggest = false,
    onSuggest = () => {},
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        style={meta.error && meta.touched ? { border: '1px solid var(--agt-error-color)' } : {}}
        {...field}
      />
      <ErrorMessage component={Error} name={name} />
      <div onClick={onSuggest}>
        <Icon className="icon" name="I" color="var(--agt-primary-color-1)" />
      </div>
      <div
        className="suggest"
        style={
          isSuggest
            ? {
                position: 'absolute',
                bottom: '60px',
                width: 'fit-content',
                display: 'block',
              }
            : { display: 'none', width: '0px' }
        }
      >
        <div className="label">
          <div>
            <img alt="Vehicle Chassis" src={vehicleChassis} />
          </div>
        </div>
      </div>
    </OtherComponent>
  )
}

const RedLabelCarComponent = (props) => {
  const { field, label, className = '', cb = () => {}, disabled = false } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  return (
    <OtherComponent className={className} style={disabled ? { cursor: 'not-allowed' } : {}}>
      <input id="red_label__1" type="checkbox" checked={value} />
      <span
        className="checkmark"
        onClick={() => {
          if (disabled) {
          } else {
            setValue(!value)
            cb(!value)
          }
        }}
      >
        <FontAwesomeIcon icon={faCheck} className="icon" />
      </span>
      <label
        htmlFor="red_label__1"
        onClick={() => {
          if (disabled) {
          } else {
            setValue(!value)
            cb(!value)
          }
        }}
      >
        {label}
      </label>
    </OtherComponent>
  )
}

const DatePicker = (props) => {
  const { field, label, className, disabled = false, minDate, cb = () => {} } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <DatePickerComponent
        value={value}
        showSelector
        onChange={(e: string) => {
          setValue(e)
          cb(e)
        }}
        minDate={minDate}
        disabled={disabled}
      />
    </div>
  )
}

// eslint-disable-next-line
const CarCheckUp = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <div>
        <label htmlFor="radio__1" onClick={() => setValue('policy')}>
          <input id="radio__1" type="radio" name={name} checked={value === 'policy'} />
          <span className="checkmark" />
          ใช้ที่อยู่กรมธรรม์
        </label>
        <label htmlFor="radio__2" onClick={() => setValue('policyDelivery')}>
          <input id="radio__2" type="radio" name={name} checked={value === 'policyDelivery'} />
          <span className="checkmark" />
          ใช้ที่อยู่ใบเสร็จรับเงิน
        </label>
        <label htmlFor="radio__3" onClick={() => setValue('receipt')}>
          <input id="radio__3" type="radio" name={name} checked={value === 'receipt'} />
          <span className="checkmark" />
          ใช้ที่อยู่กรมธรรม์
        </label>
      </div>
    </OtherComponent>
  )
}

const PolicyType = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <div>
        <label htmlFor="policy__1" onClick={() => setValue('online')}>
          <input id="policy__1" type="radio" name={name} checked={value === 'online'} />
          <span className="checkmark" />
          กรมธรรม์ออนไลน์
        </label>
        <label htmlFor="policy__2" onClick={() => setValue('original')}>
          <input id="policy__2" type="radio" name={name} checked={value === 'original'} />
          <span className="checkmark" />
          กรมธรรม์ฉบับจริง
        </label>
      </div>
    </OtherComponent>
  )
}

const NoticeComponent = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <input id="checkbox__1" type="checkbox" checked={value} />
      <span
        className="checkmark"
        onClick={() => {
          setValue(!value)
        }}
      >
        <FontAwesomeIcon icon={faCheck} className="icon" />
      </span>
      <label
        htmlFor="checkbox__1"
        onClick={() => {
          setValue(!value)
        }}
      >
        {label}
      </label>
    </OtherComponent>
  )
}

const CustomAddressComponent = (props) => {
  const { label = '', name = '', options, address } = props
  return (
    <AddressComponent>
      <div className="topic">
        <b>{label}</b>
      </div>
      <Field
        label="ใช้ข้อมูลที่อยู่จาก"
        name={`${name}.aaaaaa`}
        className="aaaaaa"
        component={AddressOptionsComponent}
        options={options}
        // disabled
      />
      <Field
        label="เลขที่"
        name={`${name}.housingNo`}
        className="housingNo"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="หมู่"
        name={`${name}.villageNo`}
        className="villageNo"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="หมู่บ้าน/อาคาร"
        name={`${name}.building`}
        className="building"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="ซอย/ตรอก"
        name={`${name}.lane`}
        className="land"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="ถนน"
        name={`${name}.road`}
        className="road"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <CustomAddress addressType={name} disabled={address?.aaaaaa !== 'ระบุเอง'} validate />
    </AddressComponent>
  )
}

const DetailForm = (props) => {
  const { values, options, onSaving, isEdit = false, initialValues } = props
  let { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { setFieldValue } = useFormikContext()
  const [isEditForm, setIsEditForm] = useState(false)
  const [isSuggest, setIsSuggest] = useState(false)

  const onSuggest = () => {
    setIsSuggest(!isSuggest)
  }

  useEffect(
    () => {
      if (
        (values?.vehicleDetails?.year && values?.vehicleDetails?.id === null && !isEdit) ||
        (values?.vehicleDetails?.year && values?.vehicleDetails?.id === null && isEditForm)
      ) {
        setFieldValue('vehicleDetails.vehicleBrand', '')
        setFieldValue('vehicleDetails.vehicleModel', '')
        setFieldValue('vehicleDetails.vehicleSubModel', '')
        dispatch(
          getActOptions(id, { year: values.vehicleDetails.year, makeCode: '', familyCode: '' }),
        )
      }
    },
    // eslint-disable-next-line
    [values?.vehicleDetails?.year, values?.vehicleDetails?.id],
  )

  useEffect(
    () => {
      if (
        (values?.vehicleDetails?.vehicleBrand && values?.vehicleDetails?.id === null && !isEdit) ||
        (values?.vehicleDetails?.vehicleBrand && values?.vehicleDetails?.id === null && isEditForm)
      ) {
        setFieldValue('vehicleDetails.vehicleModel', '')
        setFieldValue('vehicleDetails.vehicleSubModel', '')
        dispatch(
          getActOptions(id, {
            year: values.vehicleDetails.year,
            makeCode: values.vehicleDetails.vehicleBrand,
            familyCode: '',
          }),
        )
      }
    },
    // eslint-disable-next-line
    [values?.vehicleDetails?.vehicleBrand, values?.vehicleDetails?.id],
  )

  useEffect(
    () => {
      if (
        (values?.vehicleDetails?.vehicleModel && values?.vehicleDetails?.id === null && !isEdit) ||
        (values?.vehicleDetails?.vehicleModel && values?.vehicleDetails?.id === null && isEditForm)
      ) {
        setFieldValue('vehicleDetails.vehicleSubModel', '')
        dispatch(
          getActOptions(id, {
            year: values.vehicleDetails.year,
            makeCode: values.vehicleDetails.vehicleBrand,
            familyCode: values.vehicleDetails.vehicleModel,
          }),
        )
      }
    }, // eslint-disable-next-line
    [values?.vehicleDetails?.vehicleModel, values?.vehicleDetails?.id],
  )

  useEffect(
    () => {
      let policyAddress = values?.order?.prbDetails?.policyAddress?.aaaaaa
      if (policyAddress && options?.addressOptions) {
        if (
          [
            'ที่อยู่ปัจจุบัน',
            'ที่อยู่ตามทะเบียนบ้าน',
            'ที่อยู่ที่ทำงาน',
            'ที่อยู่ตามบัตรประชาชน',
          ].includes(policyAddress)
        ) {
          let params = options.addressOptions.find((address) => address.label === policyAddress)
          setFieldValue('order.prbDetails.policyAddress.housingNo', params?.value?.housingNo || '')
          setFieldValue('order.prbDetails.policyAddress.villageNo', params?.value?.villageNo || '')
          setFieldValue('order.prbDetails.policyAddress.building', params?.value?.building || '')
          setFieldValue('order.prbDetails.policyAddress.lane', params?.value?.lane || '')
          setFieldValue('order.prbDetails.policyAddress.road', params?.value?.road || '')
          setFieldValue(
            'order.prbDetails.policyAddress.subDistrict',
            params?.value?.subDistrict || '',
          )
          setFieldValue('order.prbDetails.policyAddress.district', params?.value?.district || '')
          setFieldValue('order.prbDetails.policyAddress.province', params?.value?.province || '')
          setFieldValue('order.prbDetails.policyAddress.postcode', params?.value?.postcode || '')
        } else {
        }
      }
    }, // eslint-disable-next-line
    [values?.order?.prbDetails?.policyAddress?.aaaaaa, options],
  )

  useEffect(
    () => {
      if (values?.order?.prbDetails?.policyDeliveryAddress?.aaaaaa === 'เหมือนที่อยู่บนกรมธรรม์') {
        let params = {
          ...values?.order?.prbDetails?.policyAddress,
          aaaaaa: 'เหมือนที่อยู่บนกรมธรรม์',
        }
        setFieldValue('order.prbDetails.policyDeliveryAddress', params)
      }
      if (values?.order?.prbDetails?.receiptAddress?.aaaaaa === 'เหมือนที่อยู่บนกรมธรรม์') {
        let params = {
          ...values?.order?.prbDetails?.policyAddress,
          aaaaaa: 'เหมือนที่อยู่บนกรมธรรม์',
        }
        setFieldValue('order.prbDetails.receiptAddress', params)
      }
    }, // eslint-disable-next-line
    [values?.order?.prbDetails?.policyAddress],
  )

  useEffect(
    () => {
      let policyDeliveryAddress = values?.order?.prbDetails?.policyDeliveryAddress?.aaaaaa
      if (policyDeliveryAddress && options?.addressOptions) {
        if (
          [
            'ที่อยู่ปัจจุบัน',
            'ที่อยู่ตามทะเบียนบ้าน',
            'ที่อยู่ที่ทำงาน',
            'ที่อยู่ตามบัตรประชาชน',
          ].includes(policyDeliveryAddress)
        ) {
          let params = options.addressOptions.find(
            (address) => address.label === policyDeliveryAddress,
          )
          setFieldValue(
            'order.prbDetails.policyDeliveryAddress.housingNo',
            params?.value?.housingNo,
          )
          setFieldValue(
            'order.prbDetails.policyDeliveryAddress.villageNo',
            params?.value?.villageNo,
          )
          setFieldValue('order.prbDetails.policyDeliveryAddress.building', params?.value?.building)
          setFieldValue('order.prbDetails.policyDeliveryAddress.lane', params?.value?.lane)
          setFieldValue('order.prbDetails.policyDeliveryAddress.road', params?.value?.road)
          setFieldValue(
            'order.prbDetails.policyDeliveryAddress.subDistrict',
            params?.value?.subDistrict,
          )
          setFieldValue('order.prbDetails.policyDeliveryAddress.district', params?.value?.district)
          setFieldValue('order.prbDetails.policyDeliveryAddress.province', params?.value?.province)
          setFieldValue('order.prbDetails.policyDeliveryAddress.postcode', params?.value?.postcode)
        } else if (policyDeliveryAddress === 'เหมือนที่อยู่บนกรมธรรม์') {
          let params = {
            ...values?.order?.prbDetails?.policyAddress,
            aaaaaa: 'เหมือนที่อยู่บนกรมธรรม์',
          }
          setFieldValue('order.prbDetails.policyDeliveryAddress', params)
        } else {
        }
      }
    }, // eslint-disable-next-line
    [values?.order?.prbDetails?.policyDeliveryAddress?.aaaaaa, options?.addressOptions],
  )

  useEffect(
    () => {
      let receiptAddress = values?.order?.prbDetails?.receiptAddress?.aaaaaa
      if (receiptAddress && options?.addressOptions) {
        if (
          [
            'ที่อยู่ปัจจุบัน',
            'ที่อยู่ตามทะเบียนบ้าน',
            'ที่อยู่ที่ทำงาน',
            'ที่อยู่ตามบัตรประชาชน',
          ].includes(receiptAddress)
        ) {
          let params = options.addressOptions.find((address) => address.label === receiptAddress)
          setFieldValue('order.prbDetails.receiptAddress.housingNo', params?.value?.housingNo)
          setFieldValue('order.prbDetails.receiptAddress.villageNo', params?.value?.villageNo)
          setFieldValue('order.prbDetails.receiptAddress.building', params?.value?.building)
          setFieldValue('order.prbDetails.receiptAddress.lane', params?.value?.lane)
          setFieldValue('order.prbDetails.receiptAddress.road', params?.value?.road)
          setFieldValue('order.prbDetails.receiptAddress.subDistrict', params?.value?.subDistrict)
          setFieldValue('order.prbDetails.receiptAddress.district', params?.value?.district)
          setFieldValue('order.prbDetails.receiptAddress.province', params?.value?.province)
          setFieldValue('order.prbDetails.receiptAddress.postcode', params?.value?.postcode)
        } else if (receiptAddress === 'เหมือนที่อยู่บนกรมธรรม์') {
          let params = {
            ...values?.order?.prbDetails?.policyAddress,
            aaaaaa: 'เหมือนที่อยู่บนกรมธรรม์',
          }
          setFieldValue('order.prbDetails.receiptAddress', params)
        } else {
        }
      }
    }, // eslint-disable-next-line
    [values?.order?.prbDetails?.receiptAddress?.aaaaaa, options?.addressOptions],
  )

  useEffect(
    () => {
      let ownVehicle = values?.vehicleDetails?.id || null

      if (ownVehicle && options?.vehicleOptions && options?.vehicleOptions.length > 0) {
        let params = options.vehicleOptions.find((vehicle) => vehicle.value === ownVehicle)
        dispatch(
          getActOptions(id, {
            year: params.vehicle.year,
            makeCode: params.vehicle.vehicleBrand,
            familyCode: params.vehicle.vehicleModel,
          }),
        )
        setFieldValue('vehicleDetails', params?.vehicle)
        setFieldValue('vehicleDetails.vehicleChassis', params?.vehicle?.vehicleChassis?.value)
        setFieldValue('vehicleDetails.vehicleChassisEdit', params?.vehicle?.vehicleChassis?.canEdit)
        setFieldValue('vehicleDetails.vehicleLicense', params?.vehicle?.vehicleLicense?.value)
        setFieldValue('vehicleDetails.vehicleLicenseEdit', params?.vehicle?.vehicleLicense?.canEdit)
        setFieldValue('vehicleDetails.vehicleProvince', params?.vehicle?.vehicleProvince?.value)
        setFieldValue(
          'vehicleDetails.vehicleProvinceEdit',
          params?.vehicle?.vehicleProvince?.canEdit,
        )
      } else {
      }
    }, // eslint-disable-next-line
    [values?.vehicleDetails?.id],
  )

  return (
    <Container>
      <Field
        label="คำนำหน้าชื่อ"
        name="prefixName"
        className="prefixName"
        component={OptionsComponent}
        options={[
          { label: 'นาย', value: 'นาย' },
          { label: 'นาง', value: 'นาง' },
          { label: 'นางสาว', value: 'นางสาว' },
        ]}
        disabled
      />
      <Field
        label="ชื่อจริง"
        name="firstName"
        className="firstName"
        component={InputComponent}
        disabled
      />
      <Field
        label="นามสกุล"
        name="lastName"
        className="lastName"
        component={InputComponent}
        disabled
      />
      <Field
        label="วันเกิด"
        name="birthdate"
        className="birthdate"
        component={DatePicker}
        placeholder="โปรดระบุ"
        disabled
      />
      <Field
        label="เลขบัตรประชาชน"
        name="idCard"
        className="idCard"
        component={InputComponent}
        maxlength={13}
        disabled={initialValues?.idCard}
      />
      <Field label="อีเมล" name="email" className="email" component={InputComponent} />
      <Field
        label="เบอร์โทร"
        name="phoneNumber"
        className="phoneNumber"
        component={InputComponent}
      />
      <Field
        label="ประกันเดิม"
        name="order.prbDetails.oldCompanyName"
        className="insuranceCompany"
        component={OptionsComponent}
        options={options?.insuranceCompanyOptions || []}
        placeholder="โปรดระบุ"
      />
      <CustomAddressComponent
        label="ที่อยู่บนกรมธรรม์"
        name="order.prbDetails.policyAddress"
        options={(options?.addressOptions || []).filter(
          (options) => options?.label !== 'เหมือนที่อยู่บนกรมธรรม์',
        )}
        address={values.order.prbDetails.policyAddress}
      />
      <CustomAddressComponent
        label="ที่อยู่จัดส่งกรมธรรม์"
        name="order.prbDetails.policyDeliveryAddress"
        options={options?.addressOptions || []}
        address={values.order.prbDetails.policyDeliveryAddress}
      />
      <CustomAddressComponent
        label="ที่อยู่ใบเสร็จรับเงิน"
        name="order.prbDetails.receiptAddress"
        options={options?.addressOptions || []}
        address={values.order.prbDetails.receiptAddress}
      />
      <div className="topic">
        <b>รายละเอียดกรมธรรม์</b>
      </div>
      {/* <Field
        label="สถานที่ตรวจสภาพรถยนต์"
        name="order.prbDetails.inspectionLocation"
        className="checkup"
        component={CarCheckUp}
      />
      <Field
        label="วันที่ตัวสภาพรถยนต์"
        name="order.prbDetails.vehicleInspectionDate"
        className="dDate"
        component={DatePicker}
        placeholder="โปรดระบุ"
      /> */}
      <Field
        label="รูปแบบกรมธรรม์"
        name="order.prbDetails.policyForm"
        className="policy"
        component={PolicyType}
      />
      <Field
        label="เริ่มคุ้มครอง"
        name="order.prbDetails.startPolicyAt"
        className="startDate"
        component={DatePicker}
        placeholder="โปรดระบุ"
        minDate={tomorrow}
        cb={(e) => {
          setFieldValue('order.prbDetails.endPolicyAt', addYears(new Date(e), 1))
        }}
      />
      <Field
        label="สิ้นสุดการคุ้มครอง"
        name="order.prbDetails.endPolicyAt"
        className="endDate"
        component={DatePicker}
        placeholder="โปรดระบุ"
        disabled
      />
      {/* <Field
        label="แจ้งเตือนต่อประกัน"
        name="order.prbDetails.insuranceRenewalNotification"
        className="notice"
        component={NoticeComponent}
      />
      */}
      <div className="topic">
        <b>รายละเอียดรถยนต์</b>
      </div>
      <Field
        label="เลือกจากข้อมูลรถของลูกค้า"
        name="vehicleDetails.id"
        className="eeee"
        component={OptionsComponent}
        options={options?.vehicleOptions || []}
        onChange={(e) => {
          if (e.value) {
          } else {
            if (isEdit) {
              setFieldValue('vehicleDetails', {
                ...initialValues?.vehicleDetails,
              })
              dispatch(
                getActOptions(id, {
                  year: initialValues.vehicleDetails.year,
                  makeCode: initialValues.vehicleDetails.vehicleBrand,
                  familyCode: initialValues.vehicleDetails.vehicleModel,
                }),
              )
            } else {
              setFieldValue('vehicleDetails', {
                id: null,
                name: '',
                year: '',
                vehicleBrand: '',
                vehicleModel: '',
                vehicleSubModel: '',
                redLicencePlate: false,
                vehicleProvince: '',
                vehicleLicense: '',
                vehicleChassis: '',
                vehicleProvinceEdit: true,
                vehicleLicenseEdit: true,
                vehicleChassisEdit: true,
                expiryDate: '',
                cc: '',
                carUsage: '',
                makeCode: '',
                familyCode: '',
                vehicleKey: '',
              })
            }
          }
        }}
      />
      <Field
        label="ตั้งชื่อ"
        name="vehicleDetails.name"
        className="ffff"
        component={InputComponent}
        disabled={values.vehicleDetails.id !== null}
      />
      <Field
        label="ปีรถยนต์"
        name="vehicleDetails.year"
        className="aaaa"
        component={OptionsComponent}
        options={options?.carYearOptions || []}
        disabled={values.vehicleDetails.id}
        onChange={() => {
          if (isEdit && !isEditForm) {
            setIsEditForm(true)
          }
        }}
      />
      <Field
        label="ยี่ห้อรถยนต์"
        name="vehicleDetails.vehicleBrand"
        className="bbbb"
        component={OptionsComponent}
        options={options?.makeCodeOptions || []}
        disabled={!values?.vehicleDetails?.year || values.vehicleDetails.id}
        onChange={(e) => {
          if (isEdit && !isEditForm) {
            setIsEditForm(true)
          }
          setFieldValue('vehicleDetails.makeCode', e?.value || '')
        }}
      />
      <Field
        label="รุ่น"
        name="vehicleDetails.vehicleModel"
        className="cccc"
        component={OptionsComponent}
        options={options?.familyCodeOptions || []}
        disabled={!values?.vehicleDetails?.vehicleBrand || values.vehicleDetails.id}
        onChange={(e) => {
          if (isEdit && !isEditForm) {
            setIsEditForm(true)
          }
          setFieldValue('vehicleDetails.familyCode', e?.value || '')
        }}
      />
      <Field
        label="รุ่นย่อย"
        name="vehicleDetails.vehicleSubModel"
        className="dddd"
        component={OptionsComponent}
        options={options?.carSubModelOptions || []}
        disabled={!values?.vehicleDetails?.vehicleModel || values.vehicleDetails.id}
        onChange={(e) => {
          setFieldValue('vehicleDetails.cc', e?.details?.cc || '')
          setFieldValue('vehicleDetails.vehicleKey', e?.details?.vehicleKey || '')
        }}
      />
      <Field
        label="เลขทะเบียนรถยนต์ (ตย. 1กก8888)"
        name="vehicleDetails.vehicleLicense"
        className="plateNumber"
        component={InputComponent}
        // disabled={values.vehicleDetails.id && values.vehicleDetails.vehicleLicense}
        // disabled={isEdit && !values.vehicleDetails.vehicleLicenseEdit}
        disabled={
          !values.vehicleDetails.vehicleLicenseEdit || values.vehicleDetails.redLicencePlate
        }
      />
      <Field
        label="จังหวัดที่จดทะเบียน"
        name="vehicleDetails.vehicleProvince"
        className="provice"
        component={OptionsComponent}
        options={options?.provinceOptions || []}
        // disabled={values.vehicleDetails.id && values.vehicleDetails.vehicleProvince}
        // disabled={isEdit && !values.vehicleDetails.vehicleProvinceEdit}
        disabled={!values.vehicleDetails.vehicleProvinceEdit}
      />
      <Field
        label="เลขตัวถัง"
        name="vehicleDetails.vehicleChassis"
        className="idCar"
        component={ChassisNumberComponent}
        // disabled={values.vehicleDetails.id && values.vehicleDetails.vehicleChassis}
        // disabled={isEdit && !values.vehicleDetails.vehicleChassisEdit}
        disabled={!values.vehicleDetails.vehicleChassisEdit}
        onSuggest={onSuggest}
        isSuggest={isSuggest}
      />
      {/* <div className="blank" />
      <Field
        label="มูลค่ารถยนต์ (บาท)"
        name="vehicleDetails.bbbb"
        className="kkkkk"
        component={InputComponent}
      /> */}
      <Field
        label="เป็นรถป้ายแดง"
        name="vehicleDetails.redLicencePlate"
        className="redLabel"
        component={RedLabelCarComponent}
        cb={(redLicencePlate: boolean) => {
          if (redLicencePlate) setFieldValue('vehicleDetails.vehicleLicense', 'ป้ายแดง')
        }}
        disabled={!values.vehicleDetails.vehicleChassisEdit}
      />
      <div className="submitBtn">
        <button
          type="button"
          onClick={() => {
            onSaving(values)
          }}
        >
          บันทึก
        </button>
        <button type="submit" className="secondary" disabled>
          แจ้งแนะนำ
        </button>
      </div>
    </Container>
  )
}

export default DetailForm
