import React, { FC } from 'react'
import { Route, Switch } from 'routes'
import Register from 'pages/register/root'

const Pages: FC = (props) => (
  <Switch>
    <Route exact path="/register" component={Register} />
  </Switch>
)

export default Pages
